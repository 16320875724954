<template>
  <v-stepper v-model="e1" light>
    <v-stepper-header class="px-10">
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        ID4Good
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Create Profile
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 3"
        step="3">
        Add Tokens
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4">
        Discover
      </v-stepper-step>

    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <h1 class="text-center">Download ID4Good for free on your device
        </h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
          elevation="0"
        >
          <div class="text-center">
            <div cols="8" class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="px-5 py-5 txtCajas" >ID4Good is used to login to Dept. of Good on any device.<br> No personal data collection to join - ID4Good is private and secure.
              </p>
              <v-row>
                <v-col-8>
                  <img style="margin-bottom: 9.5px" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1623801600&h=0be826ae6347e24d2c33dfcef7429046" alt="Download on the App Store">
                  <img alt='Get it on Google Play' style="width: 20%" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'>
                </v-col-8>
              </v-row>
            </div>
          </div>
        </v-card>

        <v-btn
          rounded
          color="primary"
          @click="e1 = 2"
        >
          Continue
        </v-btn>

        <v-btn rounded
               text>
          Cancel
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <h1 class="text-center">
          Create your Dept. of Good profile
        </h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
          elevation="0"
        >
          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas">
                Let other Dept. of Good users learn more about you by creating your personal profile on Dept. of Good - share what matters to you.
              </p>
            </div>
          </div>
        </v-card>

        <v-btn
          rounded
          color="primary"
          @click="e1 = 3"
        >
          Continue
        </v-btn>

        <v-btn
          rounded
          text>
          Cancel
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <h1 class="text-center">Add Tokens</h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
          elevation="0"
        >
          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas px-8">
                Add Token4Good-USD to your Wallet4Good so you can start minting and buying on Dept. of Good.
                Your Wallet4Good (www.wallet4good.com) is created automatically when you download ID4Good.
                You can buy tokens from inside your Dept. of Good account or inside your Wallet.
              </p>
            </div>
          </div>
        </v-card>

        <v-btn
          rounded
          color="primary"
          @click="e1 = 4"
        >
          Continue
        </v-btn>

        <v-btn
          rounded
          text>
          Cancel
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="4">
        <h1 class="text-center">Discover and Create</h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
          elevation="0"
        >
          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas">
                You're good to Go. <br> Discover what others have shared on Dept. of Good or start minting your own creations.
              </p>
            </div>
          </div>
          <div style="text-align: center;">
            <v-btn to="/discover" dark color="primary" rounded class=" mr-5 animate__animated animate__fadeInUp" height="30">Discover</v-btn>
            <v-btn to="/create" dark color="primary" rounded class="animate__animated animate__fadeInUp" height="30">Create</v-btn>
          </div>
        </v-card>

        <v-btn
          rounded
          color="primary"
          href="https://engine.deptofgood.com"
          target="_blank"
        >
          Go to Engine
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import './_Steps.scss'

export default {
  name: 'loginJoin',
  data () {
    return {
      e1: 1
    }
  }
}
</script>

<style scoped>

</style>
