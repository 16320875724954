<template>
    <div class="MainMobile">
      <v-img class="Loading rotate-center LoadingBackground" v-if="loading" :src="require('@/assets/colors.jpg')" max-height="200vh" max-width="200vh" min-width="200vh" min-height="200vh"></v-img>
      <div class="LoadingBar" v-if="transition">
        <v-img class="Loading rotate-center LoadingBackground"  :src="require('@/assets/colors.jpg')" max-height="200vh" max-width="200vh" min-width="200vh" min-height="200vh"></v-img>
      </div>
      <v-container fluid class="fill-height Loading" v-if="loading">
        <v-row>
          <v-col align-self="center" align="center">
            <v-progress-circular v-if="loading"
                                 indeterminate
                                 color="#ffffff55"
                                 size="128"
                                 width="15"
            >
              <v-img :src="require('@/assets/dog-engine-white.svg')" contain width="50" />
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="fill-height Request" v-if="requestPermission">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="RequestBox pa-5">
              <div>For a better experience you need to accept permissions</div>
              <div align="center" class="pt-5">
                <v-btn color="#70AFD0" dark rounded class="mx-1" @click="requestPermissionAccess">Continue</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div :class="{ AssetDetail: true, 'fade-in': detail, 'fade-out': !detail }" v-if="!loading" v-show="detail" @click="hiddenDetail">
        <v-container class="" fluid style="padding-top: 150px">
          <v-row>
            <v-col align-self="center" align="center" class="AssetTitle">
              {{ current.title }}
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" class="AssetDescription pa-10">
              {{ current.metadata.description }}
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" class="">
              <v-btn rounded class="px-10 py-7 ButtonBuy" large @click.stop="test()">Buy Now</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" class="">
            </v-col>
          </v-row>
        </v-container>
        <div class="Menu" align="right">
          <v-avatar size="48" class="AvatarIcon"><v-img :src="current.avatar ? current.avatar : defaultAvatar" contain max-height="48px" max-width="48px" /></v-avatar>
          <div align="center" class="Author">{{ current.author }}</div>
        </div>
      </div>
      <div v-if="!loading" :class="{ Transition: true, 'slide-out-left': leftT, 'slide-out-right': rightT, 'slide-out-top': topT, 'slide-out-bottom': bottomT }" v-show="transition" ref="mainRenderClone">
      </div>
      <div v-if="!loading" class="MainRender" ref="mainRender" v-touch="{
      left: () => nextAsset(),
      right: () => prevAsset(),
      up: () => prevCategory(),
      down: () => nextCategory()
    }" @click="showDetail">
        <v-img :src="current.video || current.render ? current.video ? require('@/assets/px.gif') : require('@/assets/background.svg') : current.image" min-height="calc(100vh - calc(100vh - 100%))" ref="mainImage" class="ImageDisplay" oncontextmenu="return false;">
          <video v-bind:key="current.video" autoplay muted loop v-show="current.video" class="ImageDisplay" style="left: 50%; transform: translateX(-50%)" ref="mainVideo">
            <source :src="current.video" type="video/mp4">
          </video>
          <ModelPlayer :src="current.render" v-if="current.render && !transition" :height="'100%'" :width="'100%'"></ModelPlayer>
          <div class="Logo" v-if="!transition">
            <v-img :src="require('@/assets/dog-engine-white.svg')" contain width="70" />
          </div>
          <div :class="{ AssetType: true, AssetTypeOpacity: !this.transition }">
            Digital
          </div>
          <div class="Menu" align="right" v-show="!transition">
            <div class="MenuItem">
              <v-btn icon @click.stop="null" small>
                <v-icon color="white">mdi-share-outline</v-icon>
              </v-btn>
              <div class="MenuItemText">
                Share
              </div>
            </div>
            <div class="MenuItem">
              <v-btn icon @click.stop="setWish(!current.wish)" small>
                <v-icon color="white" v-if="!current.wish">mdi-gift-open-outline</v-icon>
                <v-icon color="blue" v-if="current.wish">mdi-gift-open</v-icon>
              </v-btn>
              <div class="MenuItemText">
                Wish
              </div>
            </div>
            <div class="MenuItem">
              <v-btn icon @click.stop="setHeart(!current.heart)" small>
                <v-icon color="white" v-if="!current.heart">mdi-heart-outline</v-icon>
                <v-icon color="red" v-if="current.heart">mdi-heart</v-icon>
              </v-btn>
              <div class="MenuItemText">
                3.3M
              </div>
            </div>
            <div class="MenuItem">
              <v-btn icon @click.stop="null" small>
                <v-icon color="white">mdi-comment-text-multiple-outline</v-icon>
              </v-btn>
              <div class="MenuItemText">
                1039
              </div>
            </div>
            <v-avatar size="48" class="AvatarIcon"><v-img :src="current.avatar ? current.avatar : defaultAvatar" contain max-height="48px" max-width="48px" /></v-avatar>
            <div align="center" class="Author">{{ current.author }}</div>
          </div>
        </v-img>
      </div>
      <div v-if="!loading" class="MobileBar">
        <div class="PriceContainer">
          <div class="ItemQuantity" v-if="current.pieces === 1">
            Unique
          </div>
          <div class="ItemQuantity" v-if="current.pieces > 1">
            {{ current.available }}  / {{ current.pieces }} pieces
          </div>
          <div class="Price" align="right">
            <div class="PriceIcon">
              <v-btn fab small color="#ffffff88" height="54" width="54">
                <v-icon class="color-change-2x ShadowIcon">
                  mdi-cart-variant
                </v-icon>
              </v-btn>
            </div>
            <div class="PriceValue">
              {{ current.price }} <div class="PriceCurrency">{{ currencies.find(item => item.code === current.currency).name }}</div>
            </div>
            <div class="PriceUSD">
              ${{ current.price }} USD
            </div>
          </div>
        </div>
        <div class="MobileBarButtons">
          <v-container fluid>
            <v-row dense>
              <v-col></v-col>
              <v-col align-self="center" align="center">
                <v-btn dark small rounded class="mt-2">
                  <v-icon small class="ShadowIcon">
                    mdi-nfc-search-variant
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col align-self="center" align="center" v-if="false">
                <v-btn dark small rounded class="mt-2">
                  <v-icon small class="ShadowIcon">
                    mdi-cog-outline
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col align-self="center" align="center">
                <v-btn dark rounded to="/myassets/mint">
                  <v-icon large class="color-change-2x ShadowIcon">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
</template>

<script>
import './_MainMobile.scss'
import assets from '../../mixins/assets'
import instant from '../../mixins/instant'
import { randInt } from 'three/src/math/MathUtils'
import ModelPlayer from '../../components/ModelPlayer/ModelPlayer'

export default {
  name: 'MainMobile',
  components: { ModelPlayer },
  data () {
    return {
      items: [],
      loading: true,
      current: null,
      currentFixed: null,
      transition: false,
      index: 0,
      leftT: false,
      rightT: false,
      topT: false,
      bottomT: false,
      detail: false,
      debug: '',
      offset: 50,
      delta: 0,
      requestPermission: false,
      defaultAvatar: require('@/assets/default_profile_white.svg'),
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ]
    }
  },
  async mounted () {
    // this.fixHeight()
    this.$store.state.hiddenMenus = true
    this.$store.state.hiddenFooter = true
    if (typeof window.DeviceOrientationEvent.requestPermission === 'function') {
      // Handle iOS 13+ devices.
      try {
        await window.DeviceOrientationEvent.requestPermission()
      } catch (e) {
        this.requestPermission = true
      }
    }
    this.getItems()
    window.addEventListener('deviceorientation', (event) => {
      if (this.$refs.mainImage) {
        this.offset = this.offset + (event.gamma > 0 ? 0.1 : -0.1)
        if (this.offset <= 0) {
          this.offset = 0
        }
        if (this.offset >= 100) {
          this.offset = 100
        }
        this.$refs.mainImage.$el.children[1].style.backgroundPosition = this.offset + '% center'
        this.$refs.mainImageFixed.$el.children[1].style.backgroundPosition = this.offset + '% center'
        this.delta = event.gamma
      }
    })
  },
  beforeDestroy () {
    window.removeEventListener('deviceorientation')
  },
  mixins: [assets, instant],
  methods: {
    test () {
      console.log('test')
    },
    fixHeight () {
      const appHeight = () => {
        const doc = document.documentElement
        console.log(window.innerHeight)
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
      }
      window.addEventListener('resize', appHeight)
      appHeight()
    },
    async requestPermissionAccess () {
      await window.DeviceOrientationEvent.requestPermission()
      this.requestPermission = false
      window.location.reload()
    },
    startTransition () {
      this.hiddenDetail()
      this.transition = true
      this.$refs.mainVideo.pause()
      const currentTime = this.$refs.mainVideo.currentTime
      const clone = this.$refs.mainRender.cloneNode(true)
      clone.firstChild.lastChild.firstChild.pause()
      clone.firstChild.lastChild.firstChild.currentTime = currentTime
      this.$refs.mainRenderClone.append(clone)
    },
    finishTransition () {
      console.log('finish Transition')
      this.transition = false
      this.rightT = false
      this.leftT = false
      this.topT = false
      this.bottomT = false
      this.currentFixed = this.current
      this.$refs.mainRenderClone.innerHTML = ''
    },
    prevAsset () {
      this.startTransition()
      console.log('prev')
      setTimeout(async () => {
        this.index--
        if (this.index < 0) {
          this.index = this.items.length - 1
        }
        this.current = this.items[this.index]
        setTimeout(() => {
          this.rightT = true
        }, 200)
        setTimeout(() => {
          this.finishTransition()
        }, 500)
      }, 300)
    },
    nextAsset () {
      this.startTransition()
      console.log('next')
      setTimeout(async () => {
        this.index++
        if (this.index >= this.items.length) {
          this.index = 0
        }
        this.current = this.items[this.index]
        setTimeout(() => {
          this.leftT = true
        }, 200)
        setTimeout(() => {
          this.finishTransition()
        }, 500)
      }, 300)
    },
    prevCategory () {
      this.startTransition()
      console.log('prev')
      setTimeout(async () => {
        this.index--
        if (this.index < 0) {
          this.index = this.items.length - 1
        }
        this.current = this.items[this.index]
        setTimeout(() => {
          this.topT = true
        }, 200)
        setTimeout(() => {
          this.finishTransition()
        }, 500)
      }, 300)
    },
    nextCategory () {
      this.startTransition()
      console.log('next')
      setTimeout(async () => {
        this.index++
        if (this.index >= this.items.length) {
          this.index = 0
        }
        this.current = this.items[this.index]
        setTimeout(() => {
          this.bottomT = true
        }, 200)
        setTimeout(() => {
          this.finishTransition()
        }, 500)
      }, 300)
    },
    setWish (wish) {
      this.current.wish = wish
    },
    setHeart (heart) {
      this.current.heart = heart
    },
    showDetail () {
      this.$store.state.hiddenMenus = false
      this.detail = true
    },
    hiddenDetail () {
      this.$store.state.hiddenMenus = true
      this.detail = false
    },
    getItems () {
      this.$axios.get('/api/data/getRecommended', { params: { crc: new Date() } }).then(async (result) => {
        for (const item of result.data.items) {
          item.metadata = await this.getMetadata('https://asset4good.com/' + item.cid)
          item.title = item.metadata.name ? item.metadata.name : item.collection.name
          item.author = item.identity.name
          item.avatar = item.identity.avatar
          item.heart = false
          item.wish = false
          item.pieces = parseFloat(item.metadata.quantity)
          item.available = parseFloat(1)
          item.type = item.collection.count > 1 ? 'Collection' : 'Single'
          item.image = item.previews ? item.previews[0].url : item.metadata.image_url
          item.video = false
          item.render = false
          for (const preview of item.previews) {
            if (preview.type === 'video') {
              item.video = preview.url
              break
            }
          }
          for (const preview of item.previews) {
            if (preview.type === 'render') {
              item.render = preview.url
              break
            }
          }
          item.currency = item.collection.currency
          item.price = item.collection.individual ? item.collection.prices[item.hash] : item.collection.price
          this.items.push(item)
        }
        this.index = randInt(0, this.items.length)
        this.current = this.items[this.index]
        this.currentFixed = this.current
        this.loading = false
      })
    }
  }
}
</script>
