<template>
  <div class="SliderComponent">
    <div class="borderGradiant"></div>
    <Splide :options="{ drag: 'free', rewind: true, perPage: perPage, perMove: 1, trimSpace: false }">
      <SplideSlide v-for="(item, index) in items" v-bind:key="index">
        <CardComponent :title="item.title" :image="item.image" :author="item.author" :avatar="item.avatar" :type="item.type" :pieces="item.pieces" :available="item.available" @click.native="showCardDetail(item)" />
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
import '@splidejs/splide/dist/css/splide.min.css'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import './_SliderComponent.scss'
import CardComponent from '../CardComponent/CardComponent'

export default {
  name: 'SliderComponent',
  props: {
    items: Array
  },
  components: {
    CardComponent,
    Splide,
    SplideSlide
  },
  data () {
    return {
      perPage: 10
    }
  },
  created () {
    this.perPage = Math.round(this.$vuetify.breakpoint.width / 450)
  },
  methods: {
    showCardDetail (item) {
      console.log('show')
      this.$store.state.cardDetail = item
    }
  }
}
</script>

<style scoped>

</style>
