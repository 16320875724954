<template>
  <div class="Payment">
    <v-container fluid v-if="loading" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center" class="overline">
          <v-progress-circular v-if="loading && !finish && !finishMessage && !shipping && !wishlistPayment && !error"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
          <div v-if="error" class="red--text">payment failed</div>
          <div v-if="step === 1 && !error">generating payment</div>
          <div v-if="step === 2 && !error">waiting access</div>
          <div v-if="step === 3 && !error">waiting payment</div>
          <div v-if="step === 4 && !error">payment complete</div>
          <div v-if="step === 0 && wishlistPayment">
            <v-container>
              <v-row>
                <v-col>
                  <div class="Box">
                    <v-container fluid>
                      <v-row dense>
                        <v-col align="center">
                          <div style="font-size: 1.5em" class="mt-10 layoutColor">Wishlist</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col align-self="center" align="left">
                          <v-container style="max-width: 800px">
                            <v-row v-if="getWishlistCount() === 0">
                              <v-col align-self="center" align="center">
                                Nothing Here
                              </v-col>
                            </v-row>
                            <v-row dense v-for="(item, index) in getWishlist()" v-bind:key="index" style="font-size: 1rem">
                              <v-col>
                                {{ item.title }}
                                <div class="d-flex">
                                  <v-img :src="item.previews[0].url" max-width="100px" contain></v-img>
                                  <div class="mx-2" style="font-size: 0.7rem; line-height: 110%"> {{ item.metadata.description.substr(0,128) }}...</div>
                                </div>
                              </v-col>
                              <v-col cols="3">
                                <div align="right">
                                  $ {{ item.collection.individual ? item.collection.prices[item.hash] : item.collection.price }}
                                  <div style="font-size: 0.8rem; line-height: 100%">{{ currencies.find(currency => currency.code === item.collection.currency).name }}</div>
                                </div>
                              </v-col>
                              <v-col cols="1">
                                <v-btn icon x-small @click="removeWishlist(item)"><v-icon>mdi-delete</v-icon></v-btn>
                              </v-col>
                            </v-row>
                            <v-row style="font-size: 1.2rem">
                              <v-col align="right" class="font-weight-bold">
                                Total
                              </v-col>
                              <v-col align="right" class="font-weight-bold" cols="3">
                                <div align="right">
                                  $ {{ getWishlistTotal() }}
                                  <div style="font-size: 0.7rem; line-height: 100%">T4G-USD</div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-btn x-large rounded color="#70AFD0" @click="getPaymentWishlist">Continue</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div v-if="step === 0 && shipping">
            <v-container>
              <v-row>
                <v-col>
                  <div class="Box">
                    <v-container fluid>
                      <v-row dense>
                        <v-col cols="12" sm="4" align="center" v-if="!wishlistMode">
                          <div style="font-size: 1.5em" class="mt-10 layoutColor">Product</div>
                          <v-container v-if="complete">
                            <v-row>
                              <v-col align-self="center" align="center">
                                <v-img :src="assets[0].previews[0].url" max-width="500px" max-height="400px" style="border-radius: 15px" />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col align-self="center" align="center" class="">
                                <div class="text-h6 font-weight-bold">{{ collection.name }}</div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                        <v-col>
                          <div v-show="!card">
                            <div style="font-size: 1.5em" class="mt-10 layoutColor">Shipping Information</div>
                            <div align="center" class="my-2 layoutColor">
                              <v-text-field class="layoutColor" v-model="shippingData.name" outlined label="Name" dense style="width: 90%;"></v-text-field>
                              <div style="width: 90%;" class="d-inline-flex" v-if="$vuetify.breakpoint.width >= 600">
                                <v-text-field v-model="shippingData.email" class="mr-2" outlined label="Email" type="email" dense style="width: 60%;"></v-text-field>
                                <v-text-field v-model="shippingData.phone" class="ml-2" outlined label="Phone" type="phone" dense style="width: 40%;"></v-text-field>
                              </div>
                              <div style="width: 90%;" v-if="$vuetify.breakpoint.width < 600">
                                <v-text-field v-model="shippingData.email" class="" outlined label="Email" type="email" dense></v-text-field>
                                <v-text-field v-model="shippingData.phone" class="" outlined label="Phone" type="phone" dense></v-text-field>
                              </div>
                              <div>
                                <div style="width: 90%;" class="d-inline-flex" v-if="$vuetify.breakpoint.width >= 600">
                                  <v-text-field v-model="shippingData.address" class="mr-2" outlined label="Address" dense style="width: 70%;"></v-text-field>
                                  <v-text-field v-model="shippingData.city" class="ml-2" outlined label="City" dense style="width: 30%;"></v-text-field>
                                </div>
                                <div style="width: 90%;" v-if="$vuetify.breakpoint.width < 600">
                                  <v-text-field v-model="shippingData.address" class="" outlined label="Address" dense></v-text-field>
                                  <v-text-field v-model="shippingData.city" class="" outlined label="City" dense></v-text-field>
                                </div>
                                <div style="width: 90%;" class="d-inline-flex">
                                  <v-autocomplete :items="countries" v-model="shippingData.country" class="mr-2" outlined label="Country" dense style="width: 25%;"></v-autocomplete>
                                  <v-autocomplete :items="states" v-model="shippingData.state" class="mr-2" outlined label="State" dense style="width: 25%;"></v-autocomplete>
                                  <v-text-field v-model="shippingData.zip" class="ml-2" outlined label="ZIP" dense style="width: 50%;"></v-text-field>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-show="card">
                            <div style="font-size: 1.5em" class="mt-10 layoutColor">Credit Card Information</div>
                            <div id="card-element" class="my-10" style="max-width: 400px"><!--Stripe.js injects the Card Element--></div>
                          </div>
                          <div class="">
                            <div style="font-size: 1.5em" align="right" class="mx-16 mt-5 font-weight-light">Product Price</div>
                            <div v-if="!wishlistMode" style="font-size: 2em" align="right" class="mx-16 mb-2"><span style="font-size: 60%">{{ (currencies.find(currency => currency.code === collection.currency)).name }}$</span> {{ collection.price }}</div>
                            <div v-if="wishlistMode" style="font-size: 2em" align="right" class="mx-16 mb-2"><span style="font-size: 60%">T4G-USD$</span> {{ getWishlistTotal() }}</div>
                            <div v-if="tax > 0">
                              <div style="font-size: 1.5em" align="right" class="mx-16 mt-2 font-weight-light">TAX rate</div>
                              <div style="font-size: 2em" align="right" class="mx-16 mb-5"><span style="font-size: 60%">{{ (currencies.find(currency => currency.code === collection.currency)).name }}$</span> {{ tax }}</div>
                            </div>
                            <div style="font-size: 2.5em" align="right" class="mx-16 mt-5 font-weight-bold">Total</div>
                            <div v-if="!wishlistMode" style="font-size: 2.5em" align="right" class="mx-16 mb-5"><span style="font-size: 60%">{{ (currencies.find(currency => currency.code === collection.currency)).name }}$</span> {{ parseFloat(tax + rate + parseFloat(collection.price)).toFixed(2) }}</div>
                            <div v-if="wishlistMode" style="font-size: 2.5em" align="right" class="mx-16 mb-5"><span style="font-size: 60%">T4G-USD$</span> {{ parseFloat(tax + rate + parseFloat(getWishlistTotal())).toFixed(2) }}</div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-btn v-if="!card" x-large rounded color="#70AFD0" @click="getPaymentCollection" :disabled="!validate">Continue</v-btn>
                    <v-btn v-if="card" x-large rounded color="#70AFD0" @click="Pay">Pay</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div v-if="finish">
            <div class="Box" style="width: fit-content">
            <div id="content-qr">
              <div>Congratulations, your purchase ends successfully</div>
              <v-img width="300" height="300" contain  style="border-radius: 15px" :src="qr"></v-img>
              <div>Use the QR to claim your purchase</div>
            </div>
            <div v-if="link && ($device.ios || $device.android)">
              <v-btn :href="link" v-if="link && ($device.ios || $device.android)">Add with ID4Good</v-btn>
            </div>
            <div class="mt-15">Send information to an email</div>
            <v-text-field v-model="email" class="mt-2" label="Email" type="email" outlined style="width: 300px" dense hide-details></v-text-field>
            <v-btn class="mt-2" dark @click="sendEmail">Send</v-btn>
          </div>
        </div>
          <div v-if="finishMessage">
            <div class="Box mt-10" style="width: fit-content">
              <div class="mt-5">
                <div>Congratulations, your purchase ends successfully</div>
              </div>
              <div>
                <v-icon size="128" class="my-10" color="green">mdi-check-all</v-icon>
              </div>
              <div class="mb-5">You will receive the rest of the information by e-mail</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Mail sent successful
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_Payment.scss'
import { generateQR, waitPayment } from 'id4good.auth'
import { loadStripe } from '@stripe/stripe-js'
import wishlist from '../../mixins/wishlist'

export default {
  name: 'Payment',
  data () {
    return {
      id: null,
      collection: null,
      assets: [],
      stripe: null,
      card: null,
      elements: null,
      complete: false,
      user: null,
      snackbar: false,
      hash: null,
      loading: true,
      amount: null,
      step: 0,
      tax: 0,
      email: '',
      error: false,
      individual: false,
      index: null,
      qr: null,
      link: false,
      finish: false,
      finishMessage: false,
      shipping: false,
      rate: 11.99,
      wishlistPayment: false,
      wishlistMode: false,
      currencies: [
        { code: 't4g', name: 'USD' },
        { code: 't4gcad', name: 'CAD' },
        { code: 't4gbrl', name: 'BRL' },
        { code: 't4gclp', name: 'CLP' }
      ],
      countries: [
        'United States'
      ],
      states: ['Alaska', 'Alabama', 'Arkansas', 'American Samoa', 'Arizona', 'California', 'Colorado', 'Connecticut', 'District of Columbia', 'Delaware', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Iowa', 'Idaho', 'Illinois', 'Indiana', 'Kansas', 'Kentucky', 'Louisiana', 'Massachusetts', 'Maryland', 'Maine', 'Michigan', 'Minnesota', 'Missouri', 'Mississippi', 'Montana', 'North Carolina', 'North Dakota', 'Nebraska', 'New Hampshire', 'New Jersey', 'New Mexico', 'Nevada', 'New York', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Virginia', 'Virgin Islands', 'Vermont', 'Washington', 'Wisconsin', 'West Virginia', 'Wyoming'],
      shippingData: {
        origin: 'DeptOfGood',
        sku: 0,
        quantity: 1,
        price: 0,
        shippingPrice: 0,
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        countryCode: 'US',
        state: '',
        zip: ''
      }
    }
  },
  mixins: [wishlist],
  mounted () {
    console.log('payment')
    if (this.$route.meta.wishlist) {
      this.step = 0
      this.wishlistPayment = true
      this.wishlistMode = true
      return
    }
    if (this.$route.query.payment) {
      if (this.$route.query.payment === 'success') {
        this.step = 5
        this.finishMessage = true
        return
      }
    }
    this.id = this.$route.params.id.replaceAll('%2F', '/')
    this.getCollection()
    if (this.$route.meta.individual) {
      this.individual = true
      this.index = this.$route.params.index
    }
    if (this.$route.query.amount) {
      this.amount = this.$route.query.amount
      console.log(this.amount)
    }
    if (this.$route.meta.finish) {
      this.hash = this.$route.params.hash
      this.step = 4
      try {
        this.finishPayment(this.hash)
      } catch (e) {
        console.log(e)
        this.error = true
      }
    } else {
      if (this.$route.query.shipping) {
        this.shipping = true
      } else {
        this.step = 1
        this.getPaymentCollection()
      }
    }
  },
  computed: {
    validate () {
      for (const item in this.shippingData) {
        if (this.shippingData[item].length === 0) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    getCollection () {
      this.$axios.get('/api/collections/getCollection', {
        params: {
          hash: this.id,
          crc: Date.now()
        }
      }).then(async (result) => {
        this.user = result.data.user
        this.collection = result.data.collection
        if (this.collection.labels.indexOf('Ginormous') >= 0) {
          this.rate = 11.99
          this.shippingData.size = 'Ginormous'
        }
        if (this.collection.labels.indexOf('Jumbo') >= 0) {
          this.rate = 11.99
          this.shippingData.size = 'Jumbo'
        }
        if (this.collection.labels.indexOf('Extra Large') >= 0) {
          this.rate = 7.99
          this.shippingData.size = 'Extra Large'
        }
        if (this.collection.labels.indexOf('Large') >= 0) {
          this.rate = 4.99
          this.shippingData.size = 'Large'
        }
        if (this.collection.labels.indexOf('Hybrid') >= 0) {
          this.rate = 1
          this.shippingData.size = 'Hybrid'
        }
        if (this.collection.labels.indexOf('Subscription') >= 0) {
          this.rate = 0
          this.shippingData.size = 'Subscription'
          this.stripe = await loadStripe('pk_live_OMVdiT4EPYeP85TYcvvoBe7X00lo2FH2Jf')
        }
        if (this.collection.price > 150) {
          this.rate = 0
        }
        if (this.collection.labels.indexOf('SharkCon') >= 0) {
          this.shippingData.address = 'SharkCon'
          this.rate = 0
        }
        if (this.collection.labels.indexOf('TAX7.5')) {
          this.tax = parseFloat((parseFloat(this.collection.price) * 0.075).toFixed(2))
        }
        let count = this.collection.assets.length
        console.log(this.collection)
        for (const key in this.collection.assets) {
          console.log('load assets')
          this.$axios.get('/api/assets/getAssetInfo', {
            params: {
              hash: this.collection.assets[key],
              crc: parseInt(Date.now() / 100000)
            }
          }).then(async (result) => {
            result.data.asset.metadata = await this.getMetadata(result.data.asset.metadata)
            this.assets[key] = result.data.asset
            count--
            if (count === 0) {
              console.log(this.assets)
              // this.loading = false
              this.complete = true
            }
          })
        }
      }).catch((e) => {
        console.log('error', e)
        // this.loading = false
        this.notfound = true
      })
    },
    async completeStripe () {
      const subscription = (await this.$axios.post('https://us-central1-pay4good-bloqs4good.cloudfunctions.net/pay-createSubscriptionPayment?crc=' + Date.now(), {
        name: this.shippingData.name,
        email: this.shippingData.email,
        product: this.shippingData.sku,
        shipping: this.shippingData
      })).data
      const elements = this.stripe.elements({ clientSecret: subscription.clientSecret, appearance: { theme: 'flat', labels: 'floating' } })
      const card = elements.create('payment', { hidePostalCode: true })
      card.mount('#card-element')
      this.card = card
      this.elements = elements
    },
    async Pay () {
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: window.location.href + '&payment=success'
        }
      })
      if (error) {
        this.error = true
      }
    },
    sendEmail () {
      if (this.email.length === 0) {
        return
      }
      this.$axios.post('https://pay4good.com/api/sendAssetToEmail?email=' + this.email + '&crc=' + Date.now(), {
        image: this.qr
      }).then(() => {
        this.snackbar = true
      })
    },
    finishPayment (hash) {
      console.log('finish', hash)
      this.$axios.get('/api/payment/finishPaymentCollection', {
        params: {
          hash: this.id,
          token: hash,
          individual: this.individual,
          index: this.index,
          crc: Date.now()
        }
      }).then(async (result) => {
        if (this.$device.mobile) {
          if (this.$device.ios) {
            this.link = 'https://id4good.com/' + result.data.token
          }
          if (this.$device.android) {
            this.link = 'id4good://wallet4good/' + result.data.token + ';scheme=id4good;package=com.bloqs4good.id4good;end'
          }
        }
        this.qr = await generateQR(result.data.token)
        this.step++
        this.finish = true
        if (result.data.payment.shipping) {
          const shipping = JSON.parse(result.data.payment.shipping)
          this.$axios.post('https://pay4good.com/api/sendAssetToEmail?email=' + shipping.email + '&crc=' + Date.now(), {
            image: this.qr
          })
        }
      }).catch((e) => {
        this.error = true
      })
    },
    continuePayment (hash) {
      waitPayment(hash, 0, false).then((result) => {
        this.step++
        waitPayment(hash, 0, true).then((result) => {
          this.step++
          console.log('check ok o failed')
          this.finishPayment(hash)
        })
      }).catch(() => {
        this.error = true
      })
    },
    async getMetadata (url) {
      const metadataRaw = await this.$axios.get(url)
      const metadata = metadataRaw.data
      return metadata
    },
    getPaymentWishlist () {
      const list = this.getWishlist()
      for (const item of list) {
        console.log(item.collection)
        if (item.collection.shipping) {
          this.collection = item.collection
          this.wishlistPayment = false
          this.rate = 11.99
          if (this.getWishlistTotal() > 150) {
            this.rate = 0
          }
          this.shipping = true
          return
        }
      }
    },
    getPaymentCollection () {
      const shipping = this.shipping
      if (shipping && !this.wishlistMode) {
        this.shippingData.sku = this.assets[0].metadata.sku ? this.assets[0].metadata.sku : this.assets[0].sku
        this.shippingData.price = (this.currencies.find(currency => currency.code === this.collection.currency)).name + '$ ' + this.collection.price
        this.shippingData.shippingPrice = (this.currencies.find(currency => currency.code === this.collection.currency)).name + '$ ' + this.rate
      }
      if (shipping && this.wishlistMode) {
        const products = []
        for (const item of this.getWishlist()) {
          products.push({ sku: item.metadata.sku ? item.metadata.sku : item.sku, price: (this.currencies.find(currency => currency.code === item.collection.currency)).name + '$ ' + item.collection.price })
        }
        this.shippingData.products = products
        this.shippingData.shippingPrice = 'USD$ ' + this.rate
      }
      const products = []
      if (this.wishlistMode) {
        for (const item of this.getWishlist()) {
          products.push({ hash: item.hash, collection: item.collection.key })
        }
      }
      if (this.tax > 0) {
        this.amount = parseFloat(this.tax + parseFloat(this.collection.price)).toFixed(2)
      }
      if (this.stripe) {
        this.completeStripe()
        return
      }
      this.shipping = false
      this.step = 1
      console.log(products)
      this.$axios.get('/api/payment/getPaymentCollection', {
        params: {
          hash: this.id,
          mobile: this.$device.mobile,
          individual: this.individual,
          context: this.$store.state.context,
          index: this.index,
          amount: this.amount,
          shipping: shipping ? this.rate : false,
          wishlistMode: this.wishlistMode,
          products: products,
          shippingData: this.shippingData,
          crc: Date.now()
        }
      }).then((result) => {
        console.log(result.data)
        this.step++
        window.location.href = 'https://pay4good.com/?hash=' + result.data.payment.hash
        /*        if (this.$device.mobile) {
          window.location.href = 'https://pay4good.com/?hash=' + result.data.payment.hash
        }
        this.continuePayment(result.data.payment.hash)
        setTimeout(() => window.open('https://pay4good.com/?hash=' + result.data.payment.hash, '_blank'), 500) */
      })
    }
  }
}
</script>
