import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aquarium: {
      download: false
    },
    context: 'default',
    drawer: false,
    cardDetail: null,
    identity: false,
    walletIdentity: false,
    wallet: false,
    instantPay: false,
    instantPayLimit: 0,
    instantPayCurrent: 0,
    instantLoading: false,
    instantLoadingResult: false,
    modeTour: false,
    modeVR: false,
    hiddenBar: false,
    hiddenMenus: false,
    hiddenFooter: false,
    balance: 0,
    backgroundImage: './assets/background.svg',
    colors: {
      background: '#063e5b22',
      primary: '#00355Bff',
      secondary: '#6a9ec3ff',
      color1: '#000000ff',
      color2: '#000000ff',
      color3: '#000000ff',
      color4: '#000000ff',
      header: '#ffffff55',
      headerFont: '#00355B',
      font: '#000000DE',
      footer: '#70AFD0'
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
