 <template>
    <div class="ResourcesBanner">
      <v-container fluid class="px-0 pb-0 ">
        <v-row class="mx-0">
          <v-col class="px-0 pb-0">
            <v-parallax ref="banner" :src="require('@/assets/patrick-perkins-ETRPjvb0KM0-unsplash.jpg')" class="filter" height="500" />
            <div align="center" class="content">
              <div class="mb-2 giving" data-aos="fade-up"
                   data-aos-offset="100"
                   data-aos-delay="30"
                   data-aos-duration="800"
                   data-aos-easing="ease-in-out"
                   data-aos-mirror="true"
                   data-aos-once="false"
                   data-aos-anchor-placement="top-center" >Want to <b>learn more</b>?</div>
              <div class="mb-2 giving" data-aos="fade-up"
                   data-aos-offset="100"
                   data-aos-delay="30"
                   data-aos-duration="800"
                   data-aos-easing="ease-in-out"
                   data-aos-mirror="true"
                   data-aos-once="false"
                   data-aos-anchor-placement="top-center" >Visit our corporate website.</div>
              <div class="mb-5 restTxt" data-aos="fade-up"
                   data-aos-offset="100"
                   data-aos-delay="30"
                   data-aos-duration="800"
                   data-aos-easing="ease-in-out"
                   data-aos-mirror="true"
                   data-aos-once="false"
                   data-aos-anchor-placement="top-center"><a href="http://www.bloqs4good.com" style="text-decoration: none"><v-btn class="resourceBtn">www.bloqs4good.com</v-btn></a></div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>

<script>
import './_resourcesBanner.scss'

export default {
  name: 'resourcesBanner'
}
</script>
