<template>
  <div class="SlapitonPopularCollectionsComponent my-10">
    <Splide :options="{ drag: 'free', rewind: true, perPage: perPage, perMove: 1, trimSpace: false }" v-if="ready">
      <SplideSlide v-for="(item, index) in filterList" v-bind:key="index">
        <CardCollectionSlapitonComponent :hash="item.collection.key" @click.native="showCardDetail(item)" />
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
import './_SlapitonPopularCollectionsComponent.scss'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import CardCollectionSlapitonComponent from '../CardCollectionSlapitonComponent/CardCollectionSlapitonComponent'

export default {
  name: 'SlapitonPopularCollectionsComponent',
  props: {
    items: Array
  },
  components: {
    CardCollectionSlapitonComponent,
    Splide,
    SplideSlide
  },
  data () {
    return {
      perPage: 3,
      ready: false,
      filterList: []
    }
  },
  mounted () {
    this.items.forEach(item => {
      if (!this.filterList.find(asset => asset.collection.key === item.collection.key)) {
        this.filterList.push(item)
      }
    })
    this.ready = true
  },
  created () {
    this.perPage = Math.round(this.$vuetify.breakpoint.width / 500) - 1
    if (this.perPage === 0) {
      this.perPage = 1
    }
    console.log(this.perPage)
  },
  methods: {
    showCardDetail (item) {
      console.log('show')
      this.$store.state.cardDetail = item
    }
  }
}
</script>

<style scoped>

</style>
