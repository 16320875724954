<template>
  <div class="Landing">
    <v-container fluid class="fill-height">
      <v-row></v-row>
      <v-row>
        <v-col align="center" align-self="center" :cols="$vuetify.breakpoint.width < 960 ? 12 : 6">
          <leftColumn></leftColumn>
        </v-col>
        <v-col align="center" align-self="center">
          <rightColumn></rightColumn>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left">
            <script type="application/javascript" id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=6b720033-598c-48d9-bd5a-45bb963a93d4"> </script>
        </v-col>
        <v-col align-self="center" align="center" v-if="$vuetify.breakpoint.lgAndUp" class="d-inline-flex">
          <v-spacer></v-spacer>
          <v-img class="mx-3 mb-3 IconReverse" :src="require('@/assets/icons/facebook.svg')" width="32" height="32" max-width="26" contain />
          <v-img class="mx-3 mb-3 IconReverse" :src="require('@/assets/icons/twitter.svg')" width="32" height="32" max-width="26" contain />
          <v-img class="mx-3 mb-3 IconReverse" :src="require('@/assets/icons/instagram.svg')" width="32" height="32" max-width="26" contain />
          <v-img class="mx-3 mb-3 IconReverse" :src="require('@/assets/icons/linkedin.svg')" width="32" height="32" max-width="26" contain />
          <v-spacer></v-spacer>
        </v-col>
        <v-col align="right">
          <v-img :src="require('@/assets/icons/bloqs4_CHAT.svg')" contain max-width="200px" min-width="100px" width="50%" class="mr-15" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_Landing.scss'
import LeftColumn from '../../components/Landing/Left Column/leftColumn'
import RightColumn from '../../components/Landing/Right Column/rightColumn'

export default {
  name: 'Landing',
  components: { RightColumn, LeftColumn }
}
</script>

<style scoped>

</style>
