<template>
  <div class="Main">
    <v-container fluid v-if="loading" class="fill-height mb-16 pb-16">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="!loading">
      <v-row>
        <h1 class="trending">Trending NFTs</h1>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <carousel-3d display="10" :width="400" :perspective="1" :inverse-scaling="100" :height="350" v-if="viewMode" data-aos="zoom-in">
            <slide v-for="(item, i) in slider.top" :index="i" :key="i">
              <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
                <CardComponent :title="item.title" :image="item.image" :author="item.author" :avatar="item.avatar" :type="item.type" :pieces="item.pieces" :available="item.available" :class="{ rounded: true, current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :data-index="index" @click.native="showCardDetail(item)" />
              </template>
            </slide>
          </carousel-3d>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <div ><h1 class="trending">The Engine for All Things NFT</h1></div>
          <div align="center" class="body">
            Dept. of Good is built to empower individuals, creators, and businesses to exchange value.<br>
            We prioritize security, speed, ease of use, environment and humanity.<br>
            We are proudly donating up to 10% of our net revenue from Dept. of Good to non-profits and community organizations.
          </div>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col>
          <div class="ml-10"><h1 class="trending">Top Selling</h1></div>
          <SliderComponent :items="slider.top" v-if="slider.top.length > 0" />
        </v-col>
      </v-row>
      <v-row class="">
        <v-col>
          <div class="ml-10"><h1 class="trending">Recently Published</h1></div>
          <SliderComponent :items="slider.recently" v-if="slider.recently.length > 0" />
        </v-col>
      </v-row>
      <v-row class="">
        <v-col>
          <div class="ml-10"><h1 class="trending">Recommended for you</h1></div>
          <SliderComponent :items="slider.recommended" v-if="slider.recommended.length > 0" />
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog fullscreen v-model="dialogArt" v-if="dialogArt" content-class="Main">
      <v-container fluid class="fill-height dialog-art">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="content-box">
              <v-container :class="$vuetify.breakpoint.mdAndUp ? 'mx-5' : ''">
                <v-row>
                  <v-col align-self="center" align="left" class="overline ml-2" v-if="art.collectionCount > 1">
                    <v-btn icon @click="prevAsset(art)">
                      <v-icon>mdi-arrow-left-bold</v-icon>
                    </v-btn>
                    <v-btn icon @click="nextAsset(art)">
                      <v-icon>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col align-self="center" align="right" class="overline mr-15">
                    {{ art.collectionCount > 1 ? 'Collection' : 'Single' }}
                  </v-col>
                </v-row>
                <v-row v-if="!$vuetify.breakpoint.mdAndUp">
                  <v-col align-self="center" align="center">
                    <div class="font-weight-bold text-uppercase">{{ art.name }}</div>
                    <div class="overline" style="line-height: unset">{{ art.description }}</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12" align-self="center" class="pt-15" :align="!$vuetify.breakpoint.mdAndUp ? 'center' : ''">
                    <v-img align="right" v-if="art.previews[currentDisplay].type === 'image'" :src="art.previews[currentDisplay].url" contain width="100%" :max-width="previewSize" style="border-radius: 5px">
                      <v-btn dark icon @click="fullscreen()"><v-icon>mdi-fullscreen</v-icon></v-btn>
                    </v-img>
                    <VideoPlayer v-if="art.previews[currentDisplay].type === 'video'" style="border-radius: 5px;" :width="previewSize" :height="previewSize" :src="art.previews[currentDisplay].url" />
                    <div class="flex-nowrap d-flex mt-2">
                      <v-spacer></v-spacer>
                      <span v-for="(preview, index) in art.previews" :key="index" @click="currentDisplay = index" class="flex-nowrap d-flex preview-select">
                        <v-img v-if="currentDisplay !== index && preview.type === 'image'" class="mx-1" :src="preview.url" contain width="50" height="50" max-width="50" style="border-radius: 2px" />
                        <v-icon style="border: 1px solid #0072F5EF; border-radius: 5px; padding: 10px 10px 10px 10px" color="#0072F5EF" size="30" v-if="currentDisplay !== index && preview.type === 'video'" class="mx-1">mdi-video-vintage</v-icon>
                      </span>
                      <v-spacer></v-spacer>
                    </div>
                    <div  class="overline" v-if="art.quantity == 1 && $vuetify.breakpoint.mdAndUp">Unique</div>
                    <div class="overline" v-if="art.quantity > 1 && $vuetify.breakpoint.mdAndUp">{{ art.available }} of {{ art.quantity }} pieces</div>
                  </v-col>
                  <v-col align-self="center" align="left">
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="font-weight-bold text-uppercase">{{ art.name }}</div>
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="overline" style="line-height: unset">{{ art.description }}</div>
                    <div class="mt-5 overline font-weight-bold">{{ art.quantity == 1 ? 'Unique Piece' : art.available + ' of ' + art.quantity + ' Pieces' }}</div>
                    <div class="mt-5 overline font-weight-bold" style="line-height: unset">Created by {{ art.creator }}</div>
                    <div class="overline font-weight-bold" style="line-height: unset">Created on {{ new Date(art.created * 1000).toLocaleString() }}</div>
                  </v-col>
                  <v-col v-if="!art.exhibition" align-self="center" align="center" :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12">
                    <v-img width="200" height="200" contain  style="border-radius: 10px" :src="art.sold === 'sold' || art.sold ? require('@/assets/sold.png') : art.qr"></v-img>
                    <v-btn :disabled="art.sold === 'sold' || art.sold" v-if="art.collectionCount == 1 || art.individual" plain class="overline mt-1 text-uppercase" :href="art.buy">buy now</v-btn>
                    <v-btn :disabled="art.sold === 'sold' || art.sold" v-if="art.collectionCount > 1 && !art.individual" plain class="overline mt-1 text-uppercase" :href="art.buy">buy collection now</v-btn>
                    <v-btn :disabled="art.sold === 'sold' || art.sold" v-if="$store.state.instantPay" @click="getInstantPayment" color="green" icon><v-icon>mdi-credit-card-settings-outline</v-icon></v-btn>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row v-if="!art.exhibition">
                  <v-col align-self="center" align="center" class="text-h4 font-weight-thin" style="font-weight: 400 !important">
                    $ {{ art.price }} {{ currencies.find(item => item.code === art.currency).name }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align-self="center">
                    <v-btn class="mx-2" dark :href="art.link" v-if="art.collectionCount > 1">Go to Collection</v-btn>
                    <v-btn class="mx-2" dark :href="art.link" v-if="art.collectionCount == 1">Go to Sale</v-btn>
                    <v-btn class="mx-2" dark @click="dialogArt = false">close</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog fullscreen v-model="dialogFullscreen" v-if="dialogFullscreen" content-class="Collection">
      <v-container fluid class="fill-height dialog-art">
        <v-row>
          <v-col align-self="center" align="center">
            <v-img :src="art.previews[currentDisplay].url" width="100%" height="100%" max-width="1024px" max-height="1024px" contain></v-img>
            <v-btn icon @click="dialogFullscreen = false"><v-icon>mdi-fullscreen-exit</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import './_Main.scss'
import assets from '../../mixins/assets'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer'
import { generateQR } from 'id4good.auth'
import instant from '../../mixins/instant'
import SliderComponent from '../../components/SliderComponent/SliderComponent'
import CardComponent from '../../components/CardComponent/CardComponent'
import { logEvent } from 'firebase/analytics'

export default {
  name: 'Main',
  components: { CardComponent, SliderComponent, VideoPlayer },
  data () {
    return {
      dialogArt: false,
      viewMode: true,
      dialogFullscreen: false,
      art: null,
      top: [],
      slider: {
        top: [],
        recently: [],
        recommended: []
      },
      recent: [],
      assets: {},
      loading: true,
      currentDisplay: 0,
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      mainView: [],
      slides: [
      ]
    }
  },
  mixins: [assets, instant],
  mounted () {
    // this.getCollections()
    this.getTopSelling()
    this.getRecommended()
    this.getRecentPublished()
  },
  watch: {
    loading: {
      deep: true,
      handler: function () {
        this.processRecent()
      }
    }
  },
  computed: {
    previewSize: function () {
      return this.$vuetify.breakpoint.width < 600 ? parseInt(this.$vuetify.breakpoint.width * 0.7) + 'px' : '400px'
    },
    size: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.$vuetify.breakpoint.height * 0.6
      }
      return this.$vuetify.breakpoint.width * 0.6
    }
  },
  methods: {
    showCardDetail (item) {
      console.log('show')
      this.$store.state.cardDetail = item
      logEvent(this.$analytics, 'view_item', { component: 'Main', asset_hash: this.item.key, name: this.item.title, device: this.$device, user: this.$store.state.identity })
    },
    getTopSelling () {
      this.$axios.get('/api/data/getTopSelling', {}).then(async (result) => {
        for (const item of result.data.items) {
          item.metadata = await this.getMetadata('https://asset4good.com/' + item.cid)
          item.title = item.metadata.name ? item.metadata.name : item.collection.name
          item.author = item.identity.name
          item.avatar = item.identity.avatar
          item.pieces = parseFloat(item.metadata.quantity)
          item.available = parseFloat(1)
          item.type = item.collection.count > 1 ? 'Collection' : 'Single'
          item.image = item.previews ? item.previews[0].url : item.metadata.image_url
          this.slider.top.push(item)
        }
        this.loading = false
      })
    },
    getRecentPublished () {
      this.$axios.get('/api/data/getRecentPublished', {}).then(async (result) => {
        for (const item of result.data.items) {
          item.metadata = await this.getMetadata('https://asset4good.com/' + item.cid)
          item.title = item.metadata.name ? item.metadata.name : item.collection.name
          item.author = item.identity.name
          item.avatar = item.identity.avatar
          item.pieces = parseFloat(item.metadata.quantity)
          item.available = parseFloat(1)
          item.type = item.collection.count > 1 ? 'Collection' : 'Single'
          item.image = item.previews ? item.previews[0].url : item.metadata.image_url
          this.slider.recently.push(item)
        }
      })
    },
    getRecommended () {
      this.$axios.get('/api/data/getRecommended', {}).then(async (result) => {
        for (const item of result.data.items) {
          item.metadata = await this.getMetadata('https://asset4good.com/' + item.cid)
          item.title = item.metadata.name ? item.metadata.name : item.collection.name
          item.author = item.identity.name
          item.avatar = item.identity.avatar
          item.pieces = parseFloat(item.metadata.quantity)
          item.available = parseFloat(1)
          item.type = item.collection.count > 1 ? 'Collection' : 'Single'
          item.image = item.previews ? item.previews[0].url : item.metadata.image_url
          this.slider.recommended.push(item)
        }
      })
    },
    getCollectionsFilter (items) {
      const collections = items.slice(0, 5)
      const result = []
      for (const collection of collections) {
        const index = parseInt(collection.assets.length * Math.random())
        const item = collection.assets[index]
        if (this.assets[item]) {
          if (this.assets[item].previews !== undefined) {
            result.push({
              hash: item,
              src: this.assets[item].previews[0].url,
              name: collection.name,
              description: collection.description
            })
          }
        }
      }
      return result
    },
    processRecent () {
      for (const collection of this.recent) {
        const index = parseInt(collection.assets.length * Math.random())
        const item = collection.assets[index]
        if (!this.assets[item]) {
          this.$axios.get('/api/assets/getAssetInfo', {
            params: {
              hash: item,
              crc: parseInt(Date.now() / 100000)
            }
          }).then((result) => {
            this.assets[item] = result.data.asset
            this.assets[item].collection = collection.key
            this.assets[item].index = index
            this.assets[item].currency = collection.currency
            this.assets[item].individual = collection.individual
            this.assets[item].exhibition = collection.exhibition
            if (collection.individual) {
              this.assets[item].price = collection.prices[item]
            } else {
              this.assets[item].price = collection.price
            }
            this.assets[item].collectionCount = collection.assets.length
          })
        }
      }
    },
    getCollections () {
      this.$axios.get('/api/collections/getPublicAssetsFromCollections', {
        params: {
          crc: parseInt(Date.now() / 100000)
        }
      }).then((result) => {
        this.top = result.data.top
        this.recent = result.data.recent
        let count = 0
        for (const collection of this.top) {
          count += 1
          const index = parseInt(collection.assets.length * Math.random())
          const item = collection.assets[index]
          if (!this.assets[item]) {
            this.$axios.get('/api/assets/getAssetInfo', {
              params: {
                hash: item,
                crc: parseInt(Date.now() / 100000)
              }
            }).then(async (result) => {
              if (result.data.asset.previews && !this.assets[item]) {
                const metadata = await this.getMetadata(result.data.asset.metadata)
                this.mainView.push({ hash: item, name: metadata.name, description: metadata.description, src: result.data.asset.previews[0].url })
              }
              this.assets[item] = result.data.asset
              this.assets[item].collection = collection.key
              this.assets[item].index = index
              this.assets[item].currency = collection.currency
              this.assets[item].individual = collection.individual
              this.assets[item].exhibition = collection.exhibition
              if (collection.individual) {
                this.assets[item].price = collection.prices[item]
                this.assets[item].available = collection.unit ? (collection.unit[item] ? collection.unit[item] : 1) : 1
                this.assets[item].sold = collection.sold ? collection.sold[item] : false
                this.assets[item].available = this.assets[item].available - (this.assets[item].sold > 0 ? this.assets[item].sold : 0)
                if (this.assets[item].available === 0) {
                  this.assets[item].sold = true
                }
              } else {
                this.assets[item].available = 1
                this.assets[item].price = collection.price
                this.assets[item].sold = !!collection.soldComplete
              }
              this.assets[item].collectionCount = collection.assets.length
              count--
              if (count === 0) {
                this.loading = false
              }
            })
          } else {
            count--
            if (count === 0) {
              this.loading = false
            }
          }
        }
      })
    },
    fullscreen () {
      this.dialogFullscreen = true
    },
    prevAsset (asset) {
      const collection = [...this.top, ...this.recent].find(item => item.key === asset.collection)
      const current = collection.assets.findIndex(item => item === asset.hash)
      let prev = current - 1
      if (prev < 0) {
        prev = collection.assets.length - 1
      }
      console.log(asset)
      console.log(collection.assets[prev])
      this.showArt(collection.assets[prev])
    },
    nextAsset (asset) {
      const collection = [...this.top, ...this.recent].find(item => item.key === asset.collection)
      const current = collection.assets.findIndex(item => item === asset.hash)
      let next = current + 1
      if (next >= collection.assets.length) {
        next = 0
      }
      console.log(asset)
      console.log(collection.assets[next])
      this.showArt(collection.assets[next])
    },
    async showArt (hash) {
      this.currentDisplay = 0
      const art = this.assets[hash]
      const metadata = await this.getMetadata(art.metadata)
      art.hash = hash
      art.name = metadata.name
      art.description = metadata.description
      art.quantity = metadata.quantity
      art.creator = metadata.creator
      art.created = metadata.created
      const base = (window.location.href.split('#'))[0]
      art.link = base + '#/c/' + art.collection.toString().replaceAll('/', '%2F')
      if (art.individual) {
        this.instant = {
          id: art.collection.toString().replaceAll('/', '%2F'),
          index: art.index,
          individual: true
        }
        const revolved = this.$router.resolve({
          name: 'PaymentIndividual',
          params: { id: art.collection.toString().replaceAll('/', '%2F'), index: art.index }
        })
        art.buy = base + revolved.href
        await generateQR(base + revolved.href).then((qr) => {
          art.qr = qr
        })
      } else {
        this.instant = {
          id: art.collection.toString().replaceAll('/', '%2F'),
          individual: false
        }
        const revolved = this.$router.resolve({
          name: 'Payment',
          params: { id: art.collection.toString().replaceAll('/', '%2F') }
        })
        art.buy = base + revolved.href
        await generateQR(base + revolved.href).then((qr) => {
          art.qr = qr
        })
      }
      this.art = art
      this.dialogArt = true
    }
  }
}
</script>
