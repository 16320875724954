<template>
  <div class="SlapItOn">
    <v-container fluid class="ma-0 pa-0">
      <v-row dense>
        <v-col align-self="center" align="center" class="pa-0">
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_TestView.scss'
export default {
  name: 'TestView',
  data () {
    return {
    }
  }
}
</script>
