<template>
  <div class="Mint" ref="Mint">
    <Nav />
    <v-progress-linear v-if="loadingFile" v-model="loadingFile" indeterminate height="10" dark></v-progress-linear>
    <div v-if="!loading && !finish" class="float-menu">
      <v-btn v-if="$store.state.walletIdentity" to="/myassets/buytoken" color="#80aecd" rounded class="mr-5">Buy Token</v-btn>
      <v-btn @click="loadFile" color="#80aecd" rounded class="mr-5">Mint from File</v-btn>
      <div v-if="balanceToken > 0" class="overline mr-5" align="right">Balance: {{ (balanceToken) | numFormat('0,0.[00]') }} T4G-USD</div>
    </div>
    <div class="DotNav" v-if="!finish">
      <div>
        <v-btn icon><v-icon color="#80aecd" @click="step = 1" :class="{ 'CircleShadow': step === 1, 'jello-horizontal': step === 1 }">mdi-circle</v-icon></v-btn>
      </div>
      <div>
        <v-btn :disabled="step < 2" icon><v-icon color="#80aecd" @click="step = 2" :class="{ 'CircleShadow': step === 2, 'jello-horizontal': step === 2 }">mdi-circle</v-icon></v-btn>
      </div>
      <div>
        <v-btn :disabled="step < 3" icon><v-icon color="#80aecd" @click="step = 3" :class="{ 'CircleShadow': step === 3, 'jello-horizontal': step === 3 }">mdi-circle</v-icon></v-btn>
      </div>
      <div>
        <v-btn :disabled="step < 4" icon><v-icon color="#80aecd" @click="step = 4" :class="{  'CircleShadow': step === 4, 'jello-horizontal': step === 4 }">mdi-circle</v-icon></v-btn>
      </div>
      <div>
        <v-btn :disabled="step < 5" icon><v-icon color="#80aecd" @click="step = 5" :class="{  'CircleShadow': step === 5, 'jello-horizontal': step === 5 }">mdi-circle</v-icon></v-btn>
      </div>
    </div>
    <div ref="step1" class="stepBox" :style="{ 'z-index': step !== 1 ? -1 : 1 }">
      <v-container fuild v-if="!loading && !finish" :class="{ 'swing-in-top-fwd': step === 1, 'swing-out-top-bck': step !== 1 }">
        <v-row class="pt-10">
          <v-col>
            <div class="titleSection text-h3 mb-5" align="left">Mint</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" align-self="center">
            <div class="titleSection small mb-5">Select Asset Type</div>
            <v-container fluid>
              <v-row>
                <v-col v-if="$vuetify.breakpoint.lgAndUp"></v-col>
                <v-col :cols="!$vuetify.breakpoint.lgAndUp ? 12 : ''">
                  <v-btn tile fab x-large class="pa-16 rounded-xl mx-5" color="#80aecd" dark @click="setType('digital')">
                    Digital
                  </v-btn>
                </v-col>
                <v-col :cols="!$vuetify.breakpoint.lgAndUp ? 12 : ''">
                  <v-btn tile fab x-large class="pa-16 rounded-xl mx-5" color="#80aecd" dark @click="setType('hybrid')">
                    Hybrid
                  </v-btn>
                </v-col>
                <v-col :cols="!$vuetify.breakpoint.lgAndUp ? 12 : ''">
                  <v-btn tile fab x-large class="pa-16 rounded-xl mx-5" color="#80aecd" dark @click="setType('physical')">
                    Physical
                  </v-btn>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.lgAndUp"></v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div ref="step2" class="stepBox" :style="{ 'z-index': step !== 2 ? -1 : 1 }">
      <v-container fuild v-if="!loading && !finish" :class="{ 'swing-in-top-fwd': step === 2, 'swing-out-top-bck': step !== 2 }">
        <v-row class="pt-10">
          <v-col>
            <div class="titleSection text-h3 mb-5" align="left">Mint</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" align-self="center">
            <div class="titleSection small mb-5">General Information</div>
            <v-text-field :rules="[value => value.length > 0 || 'Name required']" v-model="name" dense label="Digital Asset Name" filled rounded/>
            <v-text-field :rules="[value => value.length > 0 || 'Creator required']" v-model="creator" dense label="Creator Name" filled rounded/>
            <v-text-field v-show="type !== 'digital'" v-model="sku" dense label="SKU" filled rounded/>
            <v-text-field step="1" :rules="[value => value > 0 || 'Quantity required']" v-model="quantity" type="number" dense label="Quantity" filled rounded/>
            <v-text-field v-model="royalty" dense v-show="$store.state.walletIdentity"
                          :rules="[value => (Number(value) <= 100 && Number(value) >= 0) || 'Incorrect percentage']"
                          label="Royalty" suffix="%" type="number" step="1" min="0" max="100" filled rounded/>
            <v-textarea :rules="[value => value.length > 0 || 'Description required']" v-model="description" rows="3" dense label="Description" filled rounded/>
            <div class="mt-5">
            <v-btn rounded color="#80aecd" class="mx-5" @click="step--">Back</v-btn>
            <v-btn rounded :disabled="name.length === 0 || description.length === 0 || creator.length === 0" color="#80aecd" class="mx-5" @click="step++">Continue</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div ref="step3" class="stepBox" :style="{ 'z-index': step !== 3 ? -1 : 1 }">
      <v-container fuild v-if="!loading && !finish" :class="{ 'swing-in-top-fwd': step === 3, 'swing-out-top-bck': step !== 3 }">
        <v-row class="pt-10">
          <v-col>
            <div class="titleSection text-h3 mb-5" align="left">Mint</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" align-self="center">
            <div class="titleSection small mb-5">Preview Image</div>
            <v-img :src="preview" class="rounded-xl" contain max-width="250" max-height="250" height="250"
                   width="250">
              <v-btn class="EditButton" icon dark @click="$refs.inputPreview.click()"><v-icon>mdi-image-edit-outline</v-icon></v-btn>
            </v-img>
            <input type="file" style="display: none" accept="image/*" ref="inputPreview"
                   @change="previewImage"/>
            <div class="mt-5">
              <v-btn rounded color="#80aecd" class="mx-5" @click="step--">Back</v-btn>
              <v-btn rounded color="#80aecd" class="mx-5" @click="step++">Continue</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div ref="step4" class="stepBox" :style="{ 'z-index': step !== 4 ? -1 : 1 }">
      <v-container fuild v-if="!loading && !finish" :class="{ 'swing-in-top-fwd': step === 4, 'swing-out-top-bck': step !== 4 }">
        <v-row class="pt-10">
          <v-col>
            <div class="titleSection text-h3 mb-5" align="left">Mint</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" align-self="center">
            <div class="titleSection small mb-5">Files</div>
              <v-card class="rounded-lg my-5">
                <v-card-text>
                  <v-container class="pa-0">
                    <v-row dense v-for="(file, index) in files" v-bind:key="index">
                      <v-col>
                        <v-file-input hide-details filled rounded :class="{ 'mx-1': true, uniqueFile: file.unique !== undefined ? file.unique : false , noUniqueFile: file.unique  !== undefined ? !file.unique : false }" v-model="file.file"
                                      prepend-icon="mdi-circle-multiple-outline" dense :error-messages="file.unique  !== undefined ? !file.unique ? 'The file already exists on the platform' : '' : ''"
                                      label="File" show-size @change="(e) => { calculateFee(e, index) }">
                        </v-file-input>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field hide-details filled rounded class="mx-1" v-model="file.tag" dense label="Tag"/>
                      </v-col>
                      <v-col cols="1" align-self="center" align="center">
                        <v-btn rounded color="#80aecd" dark @click="files.splice(index, 1)">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="mt-5 overline text-uppercase" align="center">
                        <div>The content of the digital asset will be encrypted, and will only be accessible by the owner
                          of the digital asset.
                        </div>
                        <div>Cost depend of size of digital asset.</div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="mt-5 overline black--text text-uppercase" align="center">
                        <div>Estimate Cost: {{ (currentFee).toFixed(7) | numFormat('0,0.00[00]') }} T4G</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            <v-btn rounded color="#80aecd" class="mx-5" @click="addFile">
              Add File
            </v-btn>
            <div class="mt-5">
              <v-btn rounded color="#80aecd" class="mx-5" @click="step--">Back</v-btn>
              <v-btn rounded color="#80aecd" class="mx-5" @click="step++">Continue</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div ref="step5" class="stepBox" :style="{ 'z-index': step !== 5 ? -1 : 1 }">
      <v-container fuild v-if="!loading && !finish" :class="{ 'swing-in-top-fwd': step === 5, 'swing-out-top-bck': step !== 5 }">
        <v-row class="pt-10">
          <v-col>
            <div class="titleSection text-h3 mb-5" align="left">Mint</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" align-self="center">
            <div class="titleSection small mb-5">Checkout</div>
            <div>
              <v-container fluid>
                <v-row>
                  <v-col :cols="!$vuetify.breakpoint.lgAndUp ? 12 : ''">
                    <div align="right">
                    <v-chip class="my-1 mx-2 py-3 px-5" color="#80aecd" dark>{{ type.toUpperCase() }}</v-chip>
                    </div>
                    <v-text-field readonly label="Digital Asset Name" filled rounded v-model="name" hide-details class="my-1"></v-text-field>
                    <v-text-field readonly label="Creator" filled rounded v-model="creator" hide-details class="my-1"></v-text-field>
                    <v-text-field readonly label="SKU" filled rounded v-model="sku" hide-details v-if="type !== 'digital'" class="my-1"></v-text-field>
                    <v-container class="pa-0">
                      <v-row dense>
                        <v-col><v-text-field readonly label="Quantity" filled rounded v-model="quantity" hide-details></v-text-field></v-col>
                        <v-col><v-text-field readonly label="Royalty" filled rounded v-model="royalty" suffix="%" hide-details></v-text-field></v-col>
                      </v-row>
                    </v-container>
                    <v-textarea readonly label="Description" filled rounded v-model="description" hide-details class="my-1"></v-textarea>
                    <v-container class="pa-0">
                      <v-row dense>
                        <v-col cols="5"><v-text-field readonly label="Size" filled rounded :value="parseFloat(size / 1024 / 1024).toFixed(2)" hide-detail suffix="MBytes"></v-text-field></v-col>
                        <v-col class="mt-5 overline black--text text-uppercase" align="center">
                          <div>Estimate Cost: {{ (payment === 'p4g' ? currentFee * 1.015 : currentFee).toFixed(7) | numFormat('0,0.00[00]') }} T4G</div>
                          <div v-if="$store.state.walletIdentity" class="red--text font-weight-bold" v-show="balanceToken < (currentFee).toFixed(7)">Insufficient T4G in your Wallet</div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col :cols="!$vuetify.breakpoint.lgAndUp ? 12 : 4" align-self="center" align="center">
                    <div class="titleSection small mb-5">Preview Image</div>
                    <v-img :src="preview" class="rounded-xl" contain max-width="250" max-height="250" height="250"
                           width="250">
                    </v-img>
                    <div class="mt-6">
                      <div class="titleSection small mb-5">Payment Method</div>
                      <div class="d-inline-flex overline">
                        <div align="center" v-if="$store.state.walletIdentity">
                          <v-btn tile fab x-large :class="{ 'pa-16': true, 'rounded-xl': true, 'mx-5': true, CircleShadow: payment === 'w4g' }" color="#80aecd" dark @click="setPayment('w4g')">
                            <v-img :src="require('@/assets/w4g_icon.svg')" max-width="64" class="IconShadow">
                            </v-img>
                          </v-btn>
                          <div align="center" class="mt-2">
                            Wallet4Good
                          </div>
                        </div>
                        <div align="center">
                          <v-btn tile fab x-large :class="{ 'pa-16': true, 'rounded-xl': true, 'mx-5': true, CircleShadow: payment === 'p4g' }" color="#80aecd" dark @click="setPayment('p4g')">
                            <v-img :src="require('@/assets/p4g_icon.svg')" max-width="64" class="IconShadow">
                            </v-img>
                          </v-btn>
                          <div align="center" class="mt-2">
                            Pay4Good
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div class="warning overline font-weight-bold rounded-xl" v-if="paymentFail">
              The payment has failed, please try again
            </div>
            <div class="mt-5">
              <v-btn rounded color="#80aecd" class="mx-5" @click="step--">Back</v-btn>
              <v-btn @click="processMint" rounded color="#80aecd" class="mx-5" :disabled="!payment || name.length === 0 || creator.length === 0 || description.length === 0 || this.files.find(item => item.unique === false)">Mint</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container fuild v-if="finish && !loading" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center" class="title">
          <div v-if="!sell" class="titleSection small">Mint in Queue</div>
          <div class="overline mt-2" v-if="!sell">
            You can see your asset in a few minutes in the <strong>My Assets</strong> section
            <div>
              <v-btn rounded color="#80aecd" to="/myassets" class="pa-5 mt-10">My Assets</v-btn>
            </div>
          </div>
          <div v-if="sell">
            <div class="mt-16">
              <div class="titleSection small mb-5">Congratulations, your mint successfully queued</div>
              <v-img width="300" height="300" contain  style="border-radius: 10px" :src="sell.qr"></v-img>
              <div class="overline">Use ID4Good to claim your mint</div>
            </div>
            <div v-if="sell.link && ($device.ios || $device.android)" class="my-5">
              <v-btn rounded color="#80aecd" :href="sell.link" v-if="link && ($device.ios || $device.android)">Add with ID4Good</v-btn>
            </div>
            <div class="py-10 d-inline-flex">
              <v-img :src="require('@/assets/apple-store-badge.svg')" max-height="40px" max-width="120px" contain class="mx-5"></v-img>
              <v-img :src="require('@/assets/google-play-badge.png')" max-height="40px" max-width="120px" contain class="mx-5"></v-img>
            </div>
            <div class="mt-15 titleSection small">Send mint information to an email</div>
            <v-text-field v-model="email" class="mt-2" label="Email" type="email" filled rounded style="width: 300px" dense hide-details></v-text-field>
            <v-btn class="mt-5" rounded color="#80aecd" @click="sendEmail">Send</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="loading" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="256"
                               width="15"
          >
            Uploading...
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        {{ text }}
      </div>
    </v-snackbar>
    <v-dialog fullscreen v-model="paymentDialog" v-if="paymentDialog" content-class="Mint" class="OverDialog">
      <v-container fluid class="fill-height OverDialog">
        <v-row>
          <v-col align-self="center" align="center">
            <v-progress-circular v-if="loadingPayment"
                                 indeterminate
                                 color="black lighten-5"
                                 size="256"
                                 width="15"
                                 class="ma-10 overline"
            >
              {{ waitPayment ? 'waiting for payment' : '' }}
            </v-progress-circular>
            <v-img v-if="!loadingPayment" :src="qr" contain width="300px" height="300px" class="mb-2 qr"/>
            <div class="text-h6 font-weight-bold mb-10" style="color: #00355B" v-if="!loadingPayment">
              Scan or <v-btn small rounded color="#80aecd" :href="paymentLink" target="_blank">pay here</v-btn>
            </div>
            <div class="text-h6 font-weight-thin">
              To finish the process, you must pay the cost of minting through Pay4Good, <br> for this you can scan the QR code with your phone or pay directly from your browser.
            </div>
            <div class="closeFloatButton">
              <v-btn fab small @click="paymentDialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog fullscreen v-model="editPreview" v-if="editPreview" content-class="Mint" class="OverDialog">
      <v-container fluid class="fill-height dialog-profile">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="editor-box">
              <PinturaEditor
                v-bind="editorDefaults"
                :src="preview"
                :imageCropAspectRatio="1"
                @pintura:process="finishPreview"
              ></PinturaEditor>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <input type="file" style="display: none" accept="application/zip" ref="inputCompressFile"
           @change="compressFile"/>
  </div>
</template>

<script>
import './_Mint.scss'
import Nav from '../../../components/Nav/Nav'
import CryptoJS from 'crypto-js'
import { create } from 'ipfs-http-client'
import { getEditorDefaults, createDefaultImageWriter } from 'pintura'
import { PinturaEditor } from 'vue-pintura'
import { unzip } from 'unzipit'
import { createPayment, generateQR, waitPayment } from 'id4good.auth'
import QRCodeStyling from 'qr-code-styling'

const chunkSize = 1024 * 1024

export default {
  name: 'Mint',
  components: { Nav, PinturaEditor },
  data () {
    return {
      email: null,
      paymentDialog: false,
      loadingPayment: false,
      waitPayment: false,
      paymentLink: false,
      paymentHash: false,
      paymentFail: false,
      step: 1,
      type: 'digital',
      balanceToken: 0,
      currentFee: 1,
      snackbar: false,
      editPreview: false,
      editorDefaults: getEditorDefaults(),
      loading: false,
      amount: 1,
      payment: null,
      callback: null,
      link: null,
      qr: null,
      name: '',
      creator: '',
      sku: null,
      quantity: 1,
      royalty: 0,
      roct: 0,
      sell: null,
      attr: {},
      description: '',
      track: null,
      files: [],
      filePreview: {
        file: null,
        tag: '_preview',
        cid: ''
      },
      filesEncrypted: {},
      process: 0,
      processText: '',
      count: 0,
      size: 0,
      stack: false,
      progressSize: 0,
      loadingFile: false,
      finish: false,
      preview: require('@/assets/vasset.webp'),
      text: 'Copy Success'
    }
  },
  async mounted () {
    this.loading = true
    if (this.$store.state.walletIdentity) {
      this.balanceToken = (await this.$axios.get('/api/auth/getWalletBalance', { params: { address: this.$store.state.wallet } })).data.balance
    }
    this.editorDefaults.imageWriter = createDefaultImageWriter({
      targetSize: {
        width: 250,
        height: 250,
        upscale: true
      }
    })
    const response = await fetch(this.preview)
    const data = await response.blob()
    this.filePreview.file = new File([data], 'preview.webp', { type: '' })
    this.loading = false
  },
  watch: {
    step: {
      deep: true,
      handler: function (value) {
        console.log(this.$refs['step' + value].clientHeight)
        this.$refs.Mint.style.minHeight = this.$refs['step' + value].clientHeight + 'px'
        console.log(this.$refs.Mint.style)
      }
    },
    royalty: {
      deep: true,
      handler: function (value) {
        this.royalty = Math.round(value)
      }
    }
  },
  methods: {
    sendEmail () {
      if (this.email.length === 0) {
        return
      }
      this.$axios.post('https://pay4good.com/api/sendAssetToEmail?email=' + this.email + '&crc=' + Date.now(), {
        image: this.sell.qr
      }).then(() => {
        this.text = 'Email sent'
        this.snackbar = true
      })
    },
    async processMint () {
      this.paymentFail = false
      if (this.payment === 'p4g') {
        this.paymentDialog = true
        this.loadingPayment = true
        const payment = await createPayment('-N2IzhvrKVy6YHDCZ5Pj', this.currentFee)
        this.paymentHash = payment.hash
        this.paymentLink = 'https://pay4good.com/?hash=' + payment.hash
        this.qr = await this.getPay4GoodQR(this.paymentLink)
        this.loadingPayment = false
        waitPayment(payment.hash).then(result => {
          if (result === 'complete') {
            this.paymentDialog = false
            this.preCreateDigitalAsset()
          }
          this.loadingPayment = true
          this.waitPayment = true
          waitPayment(payment.hash, 0, true).then(result => {
            console.log('OK')
            this.paymentDialog = false
            this.preCreateDigitalAsset()
          }).catch(error => {
            console.log(error)
            this.paymentFail = true
            this.paymentDialog = false
          })
        }).catch(error => {
          console.log(error)
          this.paymentFail = true
          this.paymentDialog = false
        })
      }
      if (this.payment === 'w4g') {
        await this.preCreateDigitalAsset()
      }
    },
    setPayment (method) {
      this.payment = method
    },
    setType (type) {
      this.type = type
      this.step++
    },
    async getPay4GoodQR (link) {
      const qrcode = new QRCodeStyling({
        width: 300,
        height: 300,
        margin: 10,
        data: link,
        image: require('@/assets/p4g_icon.svg'),
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#0076b4'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 10
        }
      })
      await qrcode._canvasDrawingPromise
      return await qrcode._canvas.getCanvas().toDataURL('image/png')
    },
    recursiveDeletePrivate (project) {
      for (const item in project) {
        if (item.startsWith('priv')) {
          delete project[item]
        }
        if (Array.isArray(project[item])) {
          for (const index in project[item]) {
            this.recursiveDeletePrivate(project[item][index])
          }
        }
        if (typeof project[item] === 'object') {
          this.recursiveDeletePrivate(project[item])
        }
      }
    },
    loadFile () {
      this.$refs.inputCompressFile.click()
    },
    async compressFile (e) {
      this.loadingFile = true
      try {
        console.log(e)
        const file = e.target.files[0]
        const url = URL.createObjectURL(file)
        const { entries } = await unzip(url)
        console.log(entries)
        const project = (await entries['project.json'].json())
        if (!project.ROCX) {
          this.text = 'Unsupported File'
          this.snackbar = true
          this.loadingFile = false
          return
        }
        this.name = project.Project.pubProjectName
        this.creator = project.Project.pubOrganizationName
        this.description = project.Project.pubProjectExcerpt
        this.roct = Number(project.pubTotalCarbon)
        this.royalty = 25

        const preview = await entries['preview.jpg'].blob()
        const urlPreview = URL.createObjectURL(preview)
        const img = new Image()
        img.src = urlPreview
        await img.decode()
        this.filePreview.file = new File([preview], 'preview.jpg')
        this.preview = urlPreview

        // upload
        console.log('file project')
        const projectBlob = await entries['project.json'].blob()
        this.files.push({
          file: new File([projectBlob], 'project.json', {
            type: 'application/json'
          }),
          tag: 'JSON',
          cid: null
        })
        console.log('file project')
        console.log('file AssetManifest')
        if (project.AssetManifest) {
          for (const asset of project.AssetManifest) {
            this.files.push({
              file: new File([await entries[asset.pubAssetFeaturedImagePath].blob()], asset.pubAssetFeaturedImagePath, {
                type: 'image/jpeg'
              }),
              tag: 'AssetFeatured',
              cid: null
            })
            this.files.push({
              file: new File([await entries[asset.pubAssetFileName].blob()], asset.pubAssetFileName, {
                type: asset.pubAssetMimeType
              }),
              tag: 'Asset',
              cid: null
            })
          }
        }
        console.log('file AssetManifest')
        console.log(project)
        this.recursiveDeletePrivate(project)
        this.attr = project
        console.log(project)
      } catch (e) {
        console.log(e)
        this.text = 'Unsupported File'
        this.snackbar = true
        this.loadingFile = false
      }
      this.loadingFile = false
      await this.preCreateDigitalAsset()
    },
    addFile () {
      console.log('add')
      this.files.push({
        file: null,
        tag: null,
        cid: null
      })
      this.$forceUpdate()
    },
    async calculateFee (e, index) {
      console.log(e)
      if (e === null) {
        return
      }
      if (e.size > 20 * 1024 * 1024 && !this.$store.state.localBox) {
        this.text = 'Files over 20MB require a Box4Good and Wallet4Good'
        this.snackbar = true
        setTimeout(() => {
          this.files[index].file = null
          this.$forceUpdate()
        }, 500)
        return
      }

      this.currentFee = 1
      let size = 0
      for (const file of this.files) {
        size += file.file.size
      }
      this.size = size
      if (size > 50 * 1024 * 1024) {
        this.currentFee += 1 + 0.020 * size / 1024 / 1024
        this.text = 'The files size generates a cost of ' + this.currentFee.toFixed(4)
        this.snackbar = true
      }
      const reader = new FileReader()
      reader.onloadend = async (event) => {
        console.log(this.files)
        const md5 = CryptoJS.MD5(event.target.result).toString()
        this.files[index].md5 = md5
        this.$axios.get('https://pay4good.com/asset/verifyUniqueDigitalAsset', {
          params: {
            md5: md5,
            valid: new Date(),
            crc: Date.now()
          }
        }).then((file) => {
          console.log(file)
          this.files[index].unique = false
          this.$forceUpdate()
        }).catch(() => {
          console.log('unique')
          this.files[index].unique = true
          this.$forceUpdate()
        })
        console.log(this.files)
      }
      setTimeout(() => {
        reader.readAsBinaryString(e)
      }, 500)
    },
    async finishPreview (result) {
      console.log(result.dest)
      this.filePreview.file = result.dest
      this.preview = URL.createObjectURL(result.dest)
      this.editPreview = false
    },
    async previewImage (e) {
      const file = e.target.files[0]
      if (file.size > 5 * 1024 * 1024) {
        this.text = '5MB limit supported'
        this.snackbar = true
        return
      }
      const url = URL.createObjectURL(file)
      // const img = new Image()
      // img.src = url
      // await img.decode()
      this.filePreview.file = file
      this.preview = url
      this.editPreview = true
    },
    async preCreateDigitalAsset () {
      this.loading = true
      if (this.paymentHash) {
        this.sell = (await this.$axios.get('/api/payment/createEmptySell')).data
        this.sell.qr = await generateQR(this.sell.token)
        if (this.$device.mobile) {
          if (this.$device.ios) {
            this.sell.link = 'https://id4good.com/' + this.sell.token
          }
          if (this.$device.android) {
            this.sell.link = 'id4good://wallet4good/' + this.sell.token + ';scheme=id4good;package=com.bloqs4good.id4good;end'
          }
        }
      }
      this.processText = 'Checking...'
      let size = 0
      for (const file of this.files) {
        size += file.file.size
      }

      const track = await this.$axios.post('https://pay4good.com/asset/preCreateDigitalAsset', {
        owner: this.$store.state.wallet ? this.$store.state.wallet : process.env.VUE_APP_CORE_ADDRESS,
        type: this.type,
        name: this.name,
        creator: this.creator,
        quantity: this.quantity,
        royalty: this.royalty,
        description: this.description,
        dog: true,
        roct: this.roct > 0 ? this.roct : null,
        attr: this.attr,
        identity: this.$store.state.walletIdentity,
        size: size,
        payment: this.paymentHash ? this.paymentHash : false,
        sell: this.sell ? this.sell.sell : false,
        sku: this.sku ? this.sku : false,
        crc: Date.now()
      })
      this.track = track.data
      await this.uploadFiles()
    },
    async createDigitalAsset () {
      if (!this.finish) {
        await this.stackDigitalAsset()
        return
      }
      this.processText = 'Mint Asset'
      this.files.push(this.filePreview)
      const transaction = await this.$axios.get('https://pay4good.com/asset/createDigitalAsset', {
        params: {
          id: this.track.id,
          files: this.files,
          crc: Date.now()
        }
      })
      this.transactionResult = transaction.data
      this.files.pop()
      this.process = 100
      this.processText = 'Mint Completed'
      this.loading = false
      this.finish = true
    },
    async stackDigitalAsset () {
      this.stack = true
      this.processText = 'Mint Asset'
      this.files.push(this.filePreview)
      const transaction = await this.$axios.get('https://pay4good.com/asset/stackDigitalAsset', {
        params: {
          id: this.track.id,
          files: this.files,
          crc: Date.now()
        }
      })
      this.transactionResult = transaction.data
      this.files.pop()
      this.process = 100
      this.processText = 'Mint in queue'
      this.loading = false
      this.finish = true
    },
    async uploadFiles () {
      if (this.track.id) {
        // upload Preview
        this.count++
        this.filePreview.type = this.filePreview.file.type
        this.filePreview.name = this.filePreview.file.name
        this.filePreview.lastModified = this.filePreview.file.lastModified
        await this._uploadFile(this.filePreview, 0)
        // upload files
        for (const file of this.files) {
          file.type = file.file.type
          file.name = file.file.name
          file.lastModified = file.file.lastModified
          file.cid = ''
          this.count++
          await this._uploadFile(file, 0)
        }
      }
    },
    _uploadFile (file, offset) {
      if (offset === 0) {
        this.filesEncrypted[file.name] = []
      }
      const nextSlice = offset + chunkSize + 1
      const blob = file.file.slice(offset, nextSlice)
      const reader = new FileReader()
      reader.onloadend = async (event) => {
        if (event.target.readyState !== FileReader.DONE) {
          return
        }
        this.progressSize += chunkSize
        this.processText = 'Encrypted ' + file.name
        const dataString = JSON.stringify(Array.from(new Uint8Array(event.target.result)))
        if (file.tag === '_preview') {
          this.filesEncrypted[file.name].push(dataString)
        } else {
          const encrypted = CryptoJS.AES.encrypt(dataString, this.track.key)
          this.filesEncrypted[file.name].push(encrypted.toString())
        }
        if (nextSlice < file.file.size) {
          this.process = Math.floor(this.progressSize / this.size * 50)
          this._uploadFile(file, nextSlice)
        } else {
          const encryptedFile = new File([JSON.stringify(this.filesEncrypted[file.name])], file.name + '.encrypted', {
            type: file.type,
            lastModified: file.lastModified
          })
          const ipfs = create(this.$store.state.localBox ? process.env.VUE_APP_IPFS_LOCAL.replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : process.env.VUE_APP_IPFS)
          const CID = await ipfs.add(encryptedFile, { progress: (prog) => console.log(prog) })
          file.cid = CID.path
          file.size = encryptedFile.size
          this.process = Math.floor(this.progressSize / this.size * 50)
          this.count--
          // Upload File
          if (this.count === 0) {
            console.log('no more files')
            await this.createDigitalAsset()
          }
        }
      }
      reader.readAsArrayBuffer(blob)
    }
  }
}
</script>
