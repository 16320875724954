<template>
  <div class="SlapitonMailingListComponent" id="subscribe">
    <div class="filter">
      <v-container>
        <v-row>
          <v-col>
          <h1 class="joinTxt" v-if="$vuetify.breakpoint.width > 600">
            Join Our Mailing List
          </h1>
          <h2 class="joinSubtxt" v-if="$vuetify.breakpoint.width > 600">And Never Miss an Update</h2>
            <div class="hsform">
              <v-container>
                <v-row>
                  <v-col cols="2" align-self="center" align="right" class="text-h6 white--text font-weight-bold">Email</v-col>
                  <v-col align-self="center" align="left">
                    <script type="application/javascript">
                      window.hbspt.forms.create({
                        region: "na1",
                        portalId: "5179500",
                        formId: "d9f17996-111c-4aed-88c3-da00db259d1b"
                      });
                    </script>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import './_SlapitonMailingListComponent.scss'
export default {
  name: 'SlapitonMailingListComponent',
  mounted () {
    setTimeout(() => {
      this.setStyles()
    }, 1000)
  },
  methods: {
    setStyles () {
      const frameElement = document.getElementsByClassName('hbspt-form')[0].children
      console.log(frameElement)
      const doc = frameElement[0].contentDocument
      console.log(doc)
      doc.head.innerHTML = doc.head.innerHTML + '<style> .submitted-message { height: 100%; align-content: center; display: grid; color: white !important; font-size: 1.3rem !important; } form { display: flex; align-items: flex-start; } label { visibility: hidden; position: absolute } .hs-input { font-size 1.2rem !important; border-color: transparent !important; padding: 22px 10px !important; border-radius: 25px !important; color: white !important; background-color: transparent !important; } .actions { margin-top: 0px !important; padding-top: 0px !important; margin-left: 10px; } .actions input { border-color: red !important; background-color: red !important; color: white !important; padding: 15px 40px !important; font-size: 1.2rem !important; border-radius: 25px !important; }</style>'
    }
  }
}
</script>

<style scoped>

</style>
