<template>
    <div class="ImageSectionComponent">
      <v-container fluid>
        <v-sheet
          class="mx-auto"
          elevation="0"
          max-width="2500"
          color="grey darken-2"
          style="background-color: transparent !important;"
        >
          <v-slide-group
            v-model="model"
            class="pa-4"
            active-class="success"
            show-arrows
          >
            <v-slide-item
              v-for="n in 15"
              :key="n"
              v-slot="{ active, toggle }"
            >
              <v-row dense>
                <v-col v-for="item in items" :key="item.id">
                  <div class="ComingSoon" v-if="item.disabled">
                    Coming soon
                  </div>
                  <v-card
                    :color="active ? undefined : ''"
                    class="ma-4 rounded-lg"
                    height="300"
                    :max-width="$vuetify.breakpoint.width < 400 ? 220 : 300"
                    style="background-color: #2d2d2ddd !important;"
                    @click="toggle"
                    :to="item.link"
                    :disabled="item.disabled"
                  >
                    <v-card-title class="my-2 white--text font-weight-bold text-h5" style="font-size: 1.2rem" v-text="item.title"></v-card-title>
                    <v-img class="mx-4 my-2 rounded-lg"
                          :src="item.image"
                           height="200px"
                    ></v-img>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                    >
                      <v-scale-transition>
                        <v-icon
                          v-if="active"
                          color="white"
                          size="48"
                          v-text="'mdi-close-circle-outline'"
                        ></v-icon>
                      </v-scale-transition>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-container>
    </div>
</template>

<script>
import './_ImageSectionComponent.scss'

export default {
  name: 'ImageSectionComponent',
  data: () => ({
    show: false,
    model: 0,
    rounded: [
      '0',
      'sm',
      'md',
      'lg',
      'xl',
      'pill',
      'circle'
    ],
    items: [
      {
        id: 1,
        title: 'Aquarium',
        disabled: false,
        image: require('@/assets/slapItOn/sections/Aquarium.png'),
        link: '/m/aquarium'
      },
      {
        id: 2,
        title: 'Sports',
        disabled: true,
        image: require('@/assets/slapItOn/sections/Sports.png'),
        to: null
      },
      {
        id: 3,
        title: 'Foundation',
        disabled: true,
        image: require('@/assets/slapItOn/sections/Foundation.png'),
        to: null
      },
      {
        id: 4,
        title: 'Influencers',
        disabled: true,
        image: require('@/assets/slapItOn/sections/Influencers.png'),
        to: null
      },
      {
        id: 5,
        title: 'Chefs',
        disabled: true,
        image: require('@/assets/slapItOn/sections/Chef.png'),
        to: null
      }]
  })
}
</script>
