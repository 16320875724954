<template>
  <div class="SlapitonFounderSectionComponent">
    <v-container>
            <v-row>
              <v-col>
                <h1 class="textSlapiton my-10">
                  Founded by <span class="slap">TRUE</span> legends in the <br> world of sports
                </h1>
              </v-col>
            </v-row>
          </v-container>
    <v-container class="mb-5 px-10" fluid>
      <v-row>
        <v-col
          v-for="n in items"
          :key="n.id"
          cols="12"
          sm="12"
          md="3">
          <v-card
            class="mx-auto rounded-xl"
            width="400"
            height="650"
            color="rgba(0,0,0,0.5)"
          >
            <v-img
              :src=n.img
              height="500"
              class="rounded-xl"
            >
            </v-img>
            <br>
            <p class="text-center white--text textabovebutton text-h5">
              {{n.name}}
            </p>
            <div>
              <v-btn
                color="#FF0000"
                dark
                absolute
                x-large
                height="50"
                width="200"
                :style="{left: '50%', transform:'translateX(-50%)'}"
                class="rounded-pill jainesh"
              >
                <span class="white--text" @click="founder = !founder">{{n.button}}</span>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="founder" fullscreen>
      <v-container fluid class="fill-height" style="background: #0e161999; backdrop-filter: blur(15px); position: fixed; overflow-y: scroll;">
        <v-row>
          <v-col align-self="center" align="center">
            <div style="color: white;max-width: 755px">
            <div id="comp-kh3mee3u" class="_1Q9if _3bcaz" data-testid="richTextElement"><h3 class="font_3" style="font-size:60px;"><span style="font-size:60px;">About Us:</span></h3></div>
            <div id="comp-kh3mf61s" class="_1Q9if _3bcaz" data-testid="richTextElement"><p class="font_7" style="font-size:22px;"><span style="color:#F60419;">SlapItOnAR</span> is owned and operated by true legends in the world of sports in the United States. The company was started by NFL All-Pro Placekicker Mike Vanderjagt with a desire to create a new dynamic engagement platform for fans of sports, entertainment, fitness and influencers. Mike chatted with a few of his close friends including Steve Smith and Johnny Damon, who also happen to be truly legendary in their respective sports, and <span style="color:#E21C21;">SlapItOnAR</span> launched in 2020. By Integrating laser-printed life-size stickers with mobile augmented reality, a new digital channel of fan engagement has emerged for truly interactive and immersive experiences.</p></div>
            <v-img :src="require('@/assets/slapItOn/Owners_Top_Row.webp')" contain max-width="100%"></v-img>
            <v-img :src="require('@/assets/slapItOn/Owners_Bottom_Row.webp')" contain max-width="100%"></v-img>
            </div>
            <v-btn dark rounded @click="founder = !founder">Close</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import './_SlapitonFounderSectionComponent.scss'

export default {
  name: 'SlapitonFounderSectionComponent',
  data: () => ({
    founder: false,
    items: [
      {
        id: 1,
        name: 'Mike Vanderjagt',
        icon: 'mdi-rocket-launch-outline',
        button: 'More',
        img: require('@/assets/slapItOn/founders/Mike.png'),
        color: 'solid purple'

      },
      {
        id: 2,
        name: 'Johnny Damon',
        button: 'More',
        icon: 'mdi-message-name-outline ',
        color: 'solid Aqua',
        img: require('@/assets/slapItOn/founders/Johnny.png')
      },
      {
        id: 3,
        name: 'Cobi Jones',
        button: 'More',
        icon: 'mdi-cart-minus',
        color: 'solid #FF5733',
        img: require('@/assets/slapItOn/founders/Cobi.png')
      },
      {
        id: 4,
        name: 'Steve Smith',
        button: 'More',
        img: require('@/assets/slapItOn/founders/Steve.png'),
        color: 'solid purple'
      }
    ]
  })

}
</script>
