export default {
  mounted () {
    this.setColors()
  },
  methods: {
    setColor (name, color) {
      this.$store.state.colors[name] = color
    },
    setBackground (image) {
      const doc = document.documentElement
      this.$store.state.backgroundImage = image
      doc.style.setProperty('--app-background-image', 'url("' + image + '")')
    },
    setColors () {
      const doc = document.documentElement
      if (this.$store.state.colors.background) {
        doc.style.setProperty('--app-background-color', this.$store.state.colors.background)
      }
      if (this.$store.state.colors.primary) {
        doc.style.setProperty('--app-primary-color', this.$store.state.colors.primary)
        this.$vuetify.theme.themes.light.primary = this.$store.state.colors.primary
      }
      if (this.$store.state.colors.secondary) {
        console.log('set')
        doc.style.setProperty('--app-secondary-color', this.$store.state.colors.secondary)
        this.$vuetify.theme.themes.light.secondary = this.$store.state.colors.secondary
      }
      if (this.$store.state.colors.header) {
        console.log('set')
        doc.style.setProperty('--app-header-color', this.$store.state.colors.header)
        this.$vuetify.theme.themes.light.header = this.$store.state.colors.header
      }
      if (this.$store.state.colors.footer) {
        console.log('set')
        doc.style.setProperty('--app-footer-color', this.$store.state.colors.footer)
        this.$vuetify.theme.themes.light.footer = this.$store.state.colors.footer
      }
      if (this.$store.state.colors.headerFont) {
        console.log('set')
        doc.style.setProperty('--app-header-font-color', this.$store.state.colors.headerFont)
        this.$vuetify.theme.themes.light.headerFont = this.$store.state.colors.headerFont
      }
      if (this.$store.state.colors.font) {
        console.log('set')
        doc.style.setProperty('--app-font-color', this.$store.state.colors.font)
        this.$vuetify.theme.themes.light.font = this.$store.state.colors.font
      }
    }
  }
}
