<template>
    <div class="SlapItOnHelpView">
        <v-container fluid class="ma-0 pa-0">
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
              <ArYourWorldComponent />
            </v-col>
          </v-row>
          <v-row dense style="background-color: #ffffff11">
            <v-col align-self="center" align="center" class="pa-0">
              <div class="trending display-4">FAQ's</div>
              <div style="color: white; max-width: 1200px; margin-bottom: 100px">
                <div class="text-h4 font-weight-bold mt-10 mb-5">Tips to scanning your SlapItOn Decal</div>
                <div class="text-h5 font-weight-light">Please note that although all decals will feature an educational video, we are still uploading media to our database. We appreciate your patience while we continue to upload content and ask that you keep checking back as videos are being uploaded as fast as we can rip them. Our goal is to have all images and corresponding video uploaded for decals presently for sale by August 31, 2022.</div>
                <div class="text-h4 font-weight-bold mt-10 mb-5">Decal not scanning?</div>
                <div class="text-h5 font-weight-light">The SlapItOn technology is similar to facial recognition software. So be sure to only scan your decal on a solid colored wall. They will not scan when still attached to their shipping backing. Also, it is important to have strong WiFi or cell service to access the educational videos when scanned. Remember to allow your educational decal video to fully load before removing the camera from the target fish decal.</div>
                <div class="text-h4 font-weight-bold mt-10 mb-5">Decal still is not scanning?</div>
                <div class="text-h5 font-weight-light">As our technology evolves, we will be updating our APP. During these updates, decal activation maybe temporarily unavailable. While we try to minimize this time, we appreciate your patience as we continue to improve our systems to enhance your enjoyment of our product. Again, you patience and understanding is appreciated.</div>
              </div>
            </v-col>
          </v-row>
          <v-row dense style="">
            <v-col align-self="center" align="center" class="pa-0">
              <div class="trending display-4">Installation Instructions</div>
              <div style="color: white; max-width: 1200px; margin-bottom: 100px">
                <div class="text-h5 font-weight-light">
                  Hey, our wall decals are amazing, and we want you to have the best installation experience ever... sadly, our installation video is a little behind, so please check out this video, demonstrating the hinge method for decal installation, perfect for our Ginormous and Jumbo decal installation.<br> Our installation video will be up soon, but in the meantime, follow this video, and other "Hinge Method" wall decal instructions to install your Ginormous and Jumbo SlapItOn decals. <br>Remember to grab a friend to help to install these large decals. <br> Thank you for your patience.
                </div>
                <div class="my-10">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/b-wzT0Xw5Y4?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row dense style="">
            <v-col align-self="center" align="center" class="pa-0">
              <div id="contact" class="trending display-4">Contact</div>
              <div style="color: white; max-width: 1200px; margin-bottom: 100px">
                <div class="text-h5 font-weight-light">
                  <div>
                    Write Us
                    <div class="font-weight-bold">
                      <a href="mailto:info@slapiton.com">info@slapiton.com</a>
                    </div>
                  </div>
                  <div class="my-5">
                    Call Us
                    <div class="font-weight-bold">
                      1-877-slapiton
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_SlapItOnHelpView.scss'
import ArYourWorldComponent from '../../../components/SlapItOn/ArYourWorldComponent/ArYourWorldComponent'

export default {
  name: 'SlapItOnHelpView',
  components: { ArYourWorldComponent },
  mounted () {
  }
}
</script>
