<template>
  <div style="background-color: #eef6f9 !important;margin-bottom: -200px !important">
    <EmpowermentHero ></EmpowermentHero>
    <EmpowermentBanner class="mt-6" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000"></EmpowermentBanner>
  </div>
</template>

<script>
import EmpowermentHero from '../../components/Empowerment/EmpowermentHero'
import EmpowermentBanner from '../../components/Empowerment/EmpowermentBanner'
export default {
  name: 'Empowerment',
  components: { EmpowermentBanner, EmpowermentHero }
}
</script>

<style scoped>

</style>
