<template>
  <div class="Previews">
    <div v-if="!loading" class="float-menu">
      <v-btn outlined class="mr-5" color="green" @click="save">Save</v-btn>
      <v-btn to="/myassets" dark class="mr-5">Back</v-btn>
    </div>
    <v-container fuild v-show="!loading" class="fill-height">
      <v-row :class="$vuetify.breakpoint.smAndUp ? 'pt-10' : 'pt-15'">
        <v-col align-self="center" align="center" class="title">
          {{ asset.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <div v-if="!loading" class="float-menu" style="padding-top: 10px">
            <v-btn outlined class="mr-5" color="green" @click="$refs.inputImage.click()">Upload Preview</v-btn>
          </div>
          <div :class="$vuetify.breakpoint.smAndUp ? 'overline pt-10' : 'overline pt-15'" style="font-size: 0.9rem !important;">Previews</div>
          <div v-if="previews.length === 0" style="margin-top: 5%; margin-bottom: 5%">
            <div>OOPS!! You don't have any preview</div>
            <div class="overline mt-2">
              First you need create preview
            </div>
          </div>
          <div v-if="previews.length > 0">
            <v-container>
              <v-row>
                <v-col v-for="(preview, index) in previews" :key="index" align-self="center" align="center">
                  <v-img v-if="preview.type === 'image'" class="image-preview" :src="preview.url" contain width="auto" :max-width="index ? 200 : 350" :max-height="index ? 200 : 350">
                  </v-img>
                  <VideoPlayer v-if="preview.type === 'video'" :src="preview.url" :width="index ? '200px' : '350px'" :height="index ? '200px' : '350px'" />
                  <ModelPlayer v-if="preview.type === 'render'" :src="preview.url" :width="index ? '200px' : '350px'" :height="index ? '200px' : '350px'" />
                  <v-btn v-if="index === 0" icon>
                    <v-icon size="30" color="green">mdi-numeric-1-circle-outline</v-icon>
                  </v-btn>
                  <v-btn @click="setPrimary(index)" v-if="index !== 0 && preview.type !== 'video' && preview.type !== 'render'" icon>
                    <v-icon size="30">mdi-numeric-1-circle-outline</v-icon>
                  </v-btn>
                  <v-btn icon @click="previews.splice(index, 1)">
                    <v-icon size="30">mdi-card-remove-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col>
          <div class="images-nav">
            <div class="overline" align="center" style="font-size: 0.9rem !important;">Asset Images</div>
            <v-divider></v-divider>
            <carousel-3d display="3" :width="size" :height="size" :space="size">
              <slide v-for="(item, i) in [...asset.images, ...asset.videos, ...asset.renders]" :index="i" :key="i">
                <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
                  <v-container fluid class="fill-height">
                    <v-row>
                      <v-col align-self="center" align="center">
                        <v-img v-if="item.type === 'image'" @click="isCurrent ? editCurrentImage(item.src) : null" style="border-radius: 15px" contain :max-height="size - 40" :max-width="size" :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="item.src" >
                        </v-img>
                        <VideoPlayer v-if="item.type === 'video'" @click.native="isCurrent ? editCurrentVideo(item.src) : null" style="border-radius: 15px" contain :height=" (size - 40) + 'px'" :width="size + 'px'" :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="item.src" >
                        </VideoPlayer>
                        <ModelPlayer v-if="item.type === 'render'" @click.native="isCurrent ? editCurrentRender(item.src) : null" :src="item.src" :height=" (size - 40) + 'px'" :width="size + 'px'"></ModelPlayer>
                        <div class="overline">{{ item.tag }}</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </slide>
            </carousel-3d>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="loading" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
                               class="overline"
          >SYNC</v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog fullscreen v-model="editImage" v-if="editImage" content-class="Profile">
      <v-container fluid class="fill-height dialog-profile">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="editor-box">
              <PinturaEditor
                v-bind="editorDefaults"
                :src="image"
                :imageCropAspectRatio="0"
                @pintura:process="finishImage"
              ></PinturaEditor>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <input type="file" style="display: none" :accept="previews.length > 0 ? 'image/*,video/*' : 'image/*'" ref="inputImage"
           @change="_uploadImage"/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Successful save
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_Previews.scss'
import assets from '../../../mixins/assets'
import { getEditorDefaults, createDefaultImageWriter } from 'pintura'
import { PinturaEditor } from 'vue-pintura'
import { create, urlSource } from 'ipfs-http-client'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'
import ModelPlayer from '../../../components/ModelPlayer/ModelPlayer'

export default {
  name: 'Previews',
  props: {
    asset: Object
  },
  components: { ModelPlayer, VideoPlayer, PinturaEditor },
  data () {
    return {
      loading: false,
      editImage: false,
      image: null,
      snackbar: false,
      text: '',
      previews: [
      ],
      editorDefaults: getEditorDefaults()
    }
  },
  mixins: [assets],
  mounted () {
    if (this.asset.previews) {
      this.asset.previews.forEach(item => {
        this.previews.push(item)
      })
    }
    this.editorDefaults.imageWriter = createDefaultImageWriter({
      targetSize: {
        width: 1024,
        height: 1024,
        upscale: true
      }
    })
    this.loading = true
    this.loadImages()
    this.loadVideos()
    this.loadRenders()
    console.log(this.asset)
  },
  computed: {
    size: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.$vuetify.breakpoint.height * 0.3
      }
      return this.$vuetify.breakpoint.width * 0.5
    }
  },
  methods: {
    setPrimary (index) {
      const old = this.previews[0]
      const primary = this.previews[index]
      this.previews[index] = old
      this.previews[0] = primary
      this.$forceUpdate()
    },
    async _uploadImage (e) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      console.log(file.type)
      if (file.type.includes('image/')) {
        this.editCurrentImage(url)
      }
      if (file.type.includes('video/')) {
        this.editCurrentVideo(url)
      }
      if (file.type.includes('model/')) {
        this.editCurrentRender(url)
      }
    },
    finishImage (result) {
      const url = URL.createObjectURL(result.dest)
      this.previews.push({ url: url, type: 'image' })
      this.editImage = false
    },
    editCurrentVideo (video) {
      this.previews.push({ url: video, type: 'video' })
      console.log('add')
    },
    editCurrentRender (render) {
      this.previews.push({ url: render, type: 'render' })
      console.log('add')
    },
    editCurrentImage (image) {
      this.image = image
      this.editImage = true
    },
    async loadImages () {
      console.log(this.asset.images)
      for (const image of this.asset.images) {
        image.src = await this.decodeFile(image, this.asset.kind.toString() !== '7' ? this.asset.key : this.asset.owner)
        image.type = 'image'
      }
      this.loading = false
      this.$forceUpdate()
    },
    async loadVideos () {
      for (const video of this.asset.videos) {
        video.src = await this.decodeFile(video, this.asset.kind.toString() !== '7' ? this.asset.key : this.asset.owner)
        video.type = 'video'
      }
      this.loading = false
      this.$forceUpdate()
    },
    async loadRenders () {
      console.log(this.asset.renders)
      for (const render of this.asset.renders) {
        render.src = await this.decodeFile(render, this.asset.kind.toString() !== '7' ? this.asset.key : this.asset.owner)
        render.type = 'render'
      }
      this.loading = false
      this.$forceUpdate()
    },
    async save () {
      this.loading = true
      const ipfs = create(process.env.VUE_APP_IPFS)
      const previews = []
      for (const preview of this.previews) {
        if (!preview.url.includes('asset4good.com')) {
          console.log('upload')
          const { cid } = await ipfs.add(urlSource(preview.url), { })
          await this.$axios.get('https://pay4good.com/core/registerUploadFile', { params: { cid: cid.toString() } })
          previews.push({ url: 'https://asset4good.com/asset/' + cid.toString(), type: preview.type })
        } else {
          previews.push(preview)
        }
      }
      // Save List Previews
      this.$axios.post('/api/assets/updatePreviews', {
        identity: this.$store.state.walletIdentity,
        hash: this.asset.hash,
        previews: previews,
        params: {
          crc: Date.now()
        }
      }).then((result) => {
        this.snackbar = true
        setTimeout(() => {
          this.$router.push({ name: 'MyArts' })
        }, 1000)
      })
    }
  }
}
</script>
