<template>
  <div class="TermsComponent">
    <div align="left" data-mesh-id="Containerx2k94inlineContent-gridContainer" data-testid="mesh-container-content">
      <div id="comp-j4y188gv" class="_1Q9if _3bcaz" data-testid="richTextElement"><h1 class="font_4"
                                                                                      style="text-align:center; font-size:60px;">
        <span style="color:#E21C21;"><span style="letter-spacing:0.1em;">TERMS &amp; CONDITIONS</span></span></h1></div>
      <div id="comp-j59airt6" class="_1Q9if _3bcaz" data-testid="richTextElement"><h2 class="font_2"
                                                                                      style="font-size:28px;">SlapItOnAR
        Terms of Service</h2>

        <h3 class="font_3" style="font-size:24px;"></h3>

        <h3 class="font_3" style="font-size:24px;"><span style="font-size:24px;">1. Terms</span></h3>

        <p class="font_8" style="font-size:18px;">By accessing our app, SlapitonAR, you are agreeing to be bound by
          these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance
          with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or
          accessing SlapitonAR. The materials contained in SlapitonAR are protected by applicable copyright and
          trademark law.</p>

        <h3 class="font_3" style="font-size:24px;"></h3>

        <h3 class="font_3" style="font-size:24px;"><span style="font-size:24px;">2. Use License</span></h3>

        <ol class="font_8" style="font-size:18px;">
          <li>
            <p class="font_8" style="font-size:18px;">Permission is granted to temporarily download one copy of
              SlapitonAR per device for personal, non-commercial transitory viewing only. This is the grant of a
              license, not a transfer of title, and under this license you may not:</p>

            <ol class="font_8" style="font-size:18px;">
              <li>
                <p class="font_8" style="font-size:18px;">modify or copy the materials;</p>
              </li>
              <li>
                <p class="font_8" style="font-size:18px;">use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);</p>
              </li>
              <li>
                <p class="font_8" style="font-size:18px;">attempt to decompile or reverse engineer any software
                  contained in SlapitonAR;</p>
              </li>
              <li>
                <p class="font_8" style="font-size:18px;">remove any copyright or other proprietary notations from the
                  materials; or</p>
              </li>
              <li>
                <p class="font_8" style="font-size:18px;">transfer the materials to another person or "mirror" the
                  materials on any other server.</p>
              </li>
            </ol>
          </li>
          <li>
            <p class="font_8" style="font-size:18px;">This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by SlapItOnAR at any time. Upon terminating your viewing of these
              materials or upon the termination of this license, you must destroy any downloaded materials in your
              possession whether in electronic or printed format.</p>
          </li>
        </ol>

        <h3 class="font_3" style="font-size:24px;"></h3>

        <h3 class="font_3" style="font-size:24px;"><span style="font-size:24px;">3. Disclaimer</span></h3>

        <ol class="font_8" style="font-size:18px;">
          <li>
            <p class="font_8" style="font-size:18px;">The materials within SlapitonAR are provided on an 'as is' basis.
              SlapItOnAR makes no warranties, expressed or implied, and hereby disclaims and negates all other
              warranties including, without limitation, implied warranties or conditions of merchantability, fitness for
              a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
          </li>
          <li>
            <p class="font_8" style="font-size:18px;">Further, SlapItOnAR does not warrant or make any representations
              concerning the accuracy, likely results, or reliability of the use of the materials on its website or
              otherwise relating to such materials or on any sites linked to SlapitonAR.</p>
          </li>
        </ol>

        <h3 class="font_3" style="font-size:24px;"></h3>

        <h3 class="font_3" style="font-size:24px;"><span style="font-size:24px;">4. Limitations</span></h3>

        <p class="font_8" style="font-size:18px;">In no event shall SlapItOnAR or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or profit, or due to business interruption)
          arising out of the use or inability to use SlapitonAR, even if SlapItOnAR or a SlapItOnAR authorized
          representative has been notified orally or in writing of the possibility of such damage. Because some
          jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.</p>

        <h3 class="font_3" style="font-size:24px;"></h3>

        <h3 class="font_3" style="font-size:24px;"><span style="font-size:24px;">5. Accuracy of materials</span></h3>

        <p class="font_8" style="font-size:18px;">The materials appearing in SlapitonAR could include technical,
          typographical, or photographic errors. SlapItOnAR does not warrant that any of the materials on SlapitonAR are
          accurate, complete or current. SlapItOnAR may make changes to the materials contained in SlapitonAR at any
          time without notice. However SlapItOnAR does not make any commitment to update the materials.</p>

        <h3 class="font_3" style="font-size:24px;"></h3>

        <h3 class="font_3" style="font-size:24px;"><span style="font-size:24px;">6. Links</span></h3>

        <p class="font_8" style="font-size:18px;">SlapItOnAR has not reviewed all of the sites linked to its app and is
          not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement
          by SlapItOnAR of the site. Use of any such linked website is at the user's own risk.</p>

        <h3 class="font_3" style="font-size:24px;"></h3>

        <h3 class="font_3" style="font-size:24px;"><span style="font-size:24px;">7. Modifications</span></h3>

        <p class="font_8" style="font-size:18px;">SlapItOnAR may revise these terms of service for its app at any time
          without notice. By using SlapitonAR you are agreeing to be bound by the then current version of these terms of
          service.</p>

        <h3 class="font_3" style="font-size:24px;"></h3>

        <h3 class="font_3" style="font-size:24px;"><span style="font-size:24px;">8. Governing Law</span></h3>

        <p class="font_8" style="font-size:18px;">These terms and conditions are governed by and construed in accordance
          with the laws of Arizona and you irrevocably submit to the exclusive jurisdiction of the courts in that State
          or location.</p></div>
      <div id="comp-kjaswada" class="_1Q9if _3bcaz" data-testid="richTextElement"><h1 class="font_0"
                                                                                      style="font-size:18px; text-align:center;">
        <span style="color:#F60419;"><span style="font-size:18px;">©2021 SlapItOnAR</span></span></h1></div>
    </div>
  </div>
</template>

<script>
import './_TermsComponent.scss'

export default {
  name: 'TermsComponent'
}
</script>
