<template>
    <div class="TopBannerComponent">
      <v-carousel  delimiter-icon="mdi-checkbox-blank-circle-outline" height="100%"
                   v-model="index" :hide-delimiter-background="true" :show-arrows-on-hover="true" :show-arrows="false" class="Carousel" cycle :interval="120000">
        <v-carousel-item v-for="(item, index) in items" v-bind:key="index">
          <v-container fluid class="fill-height ma-0 pa-0">
            <v-row dense>
              <v-col align-self="center" align="center">
                <v-img :src="item" width="100vw" height="100%" max-width="2048px" contain>
                  <div class="TextBanner">
                    Take A Deeper Dive
                    <div v-if="false">
                      <v-btn rounded x-large color="#5BB0FE" dark class="text-capitalize BottomBanner" @click="scroll('Videos')">Watch More</v-btn>
                    </div>
                  </div>
                  <div class="TextBanner" style="top: 60%; max-width: 70%" align="right">
                    <v-img :src="require('@/assets/aquarium/brett.png')" max-width="256px"  width="30%" contain class="mb-3"></v-img>
                    <div class="font-weight-regular" style="font-size: clamp(0.5rem, 2vw, 1.2rem); line-height: 150%; letter-spacing: 1px; ">SLAPITON PRESENTS THE AQUARIUM COLLECTION</div>
                    <div class="font-weight-bold" style="font-size: clamp(0.5rem, 4vw, 2.5rem); line-height: 150%; letter-spacing: 2px;">Featuring Brett Raymer</div>
                    <div class="font-weight-regular" style="font-size: clamp(0.5rem, 2.5vw, 1.6rem); line-height: 150%; letter-spacing: 2px;">HOST OF ANIMAL PLANET'S TANKED</div>
                  </div>
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>
      </v-carousel>
    </div>
</template>

<script>
import './_TopBannerComponent.scss'
export default {
  name: 'TopBannerComponent',
  data () {
    return {
      index: 0,
      items: [
        require('@/assets/aquarium/bannerTop.png'),
        require('@/assets/aquarium/bannerTop.png')
      ]
    }
  },
  methods: {
    scroll (id) {
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth', block: 'nearest'
        })
      }, 500)
    }
  }
}
</script>
