import {
  Euler,
  EventDispatcher,
  Vector3,
  MathUtils,
  Quaternion
} from 'three'

const _euler = new Euler(0, 0, 0, 'YXZ')
const _vector = new Vector3()

const _changeEvent = { type: 'change' }
const _lockEvent = { type: 'lock' }
const _unlockEvent = { type: 'unlock' }

const _PI_2 = Math.PI / 2

class PointerLockControls extends EventDispatcher {
  constructor (camera, domElement) {
    super()

    if (domElement === undefined) {
      console.warn('THREE.PointerLockControls: The second parameter "domElement" is now mandatory.')
      domElement = document.body
    }

    this.domElement = domElement
    this.isLocked = false

    // Set to constrain the pitch of the camera
    // Range is 0 to Math.PI radians
    this.minPolarAngle = 0 // radians
    this.maxPolarAngle = Math.PI // radians

    this.prevTouch = false
    this.screenOrientation = window.orientation || 0
    this.modeVR = false
    this.mobile = false

    const scope = this

    function onScreenOrientationChangeEvent (event) {
      scope.screenOrientation = window.orientation || 0
    }

    function onMouseMove (event) {
      if (scope.isLocked === false) return

      const movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0
      const movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0

      _euler.setFromQuaternion(camera.quaternion)

      _euler.y -= movementX * 0.002
      _euler.x -= movementY * 0.002

      _euler.x = Math.max(_PI_2 - scope.maxPolarAngle, Math.min(_PI_2 - scope.minPolarAngle, _euler.x))

      camera.quaternion.setFromEuler(_euler)

      scope.dispatchEvent(_changeEvent)
    }

    const setObjectQuaternion = (function () {
      const zee = new Vector3(0, 0, 1)
      const euler = new Euler()
      const q0 = new Quaternion()
      const q1 = new Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5))

      return function (quaternion, alpha, beta, gamma, orient) {
        // 'ZXY' for the device, but 'YXZ' for us
        euler.set(beta, alpha, -gamma, 'YXZ')

        // Orient the device
        quaternion.setFromEuler(euler)

        // camera looks out the back of the device, not the top
        quaternion.multiply(q1)

        // adjust for screen orientation
        quaternion.multiply(q0.setFromAxisAngle(zee, -orient))
      }
    }())

    function onAccelMove (event) {
      if (scope.isLocked === false) return
      if (scope.modeVR === false) return
      const alpha = event.rotationRate.alpha
      const beta = event.acceleration.beta
      // const gamma = event.acceleration.gamma
      if (scope.screenOrientation === 0 || scope.screenOrientation === 180) {
        if (alpha > 50) {
          // scope.moveForward(2)
        }
      }
      if (scope.screenOrientation === 90 || scope.screenOrientation === -90) {
        if (alpha > 50 || beta > 50) {
          // scope.moveForward(2)
        }
      }
    }

    function onOrientationMove (event) {
      if (scope.isLocked === false) return
      if (scope.modeVR === false) return

      // Z
      const alpha = event.alpha
        ? MathUtils.degToRad(event.alpha)
        : 0

      // X'
      const beta = event.beta
        ? MathUtils.degToRad(event.beta)
        : 0

      // Y''
      const gamma = event.gamma
        ? MathUtils.degToRad(event.gamma)
        : 0

      // O
      const orient = scope.screenOrientation
        ? MathUtils.degToRad(scope.screenOrientation)
        : 0

      const currentQ = new Quaternion().copy(camera.quaternion)
      setObjectQuaternion(currentQ, alpha, beta, gamma, orient)
      camera.quaternion = currentQ
      scope.dispatchEvent(_changeEvent)
    }

    function onTouchMove (event) {
      // event.preventDefault()
      if (scope.isLocked === false) return
      const touch = event.touches[0]
      if (!scope.prevTouch) {
        scope.prevTouch = touch
      }
      const movementX = touch.pageX - scope.prevTouch.pageX
      const movementY = touch.pageY - scope.prevTouch.pageY

      _euler.setFromQuaternion(camera.quaternion)

      _euler.y -= -movementX * 0.002
      _euler.x -= -movementY * 0.002

      _euler.x = Math.max(_PI_2 - scope.maxPolarAngle, Math.min(_PI_2 - scope.minPolarAngle, _euler.x))

      camera.quaternion.setFromEuler(_euler)

      scope.prevTouch = touch

      scope.dispatchEvent(_changeEvent)
    }

    function onPointerlockChange () {
      if (scope.domElement.ownerDocument.pointerLockElement === scope.domElement) {
        scope.dispatchEvent(_lockEvent)
        scope.isLocked = true
      } else {
        scope.dispatchEvent(_unlockEvent)
        scope.isLocked = false
      }
    }

    function onPointerlockError () {
      console.error('THREE.PointerLockControls: Unable to use Pointer Lock API')
    }

    this.connect = function () {
      scope.domElement.ownerDocument.addEventListener('mousemove', onMouseMove)
      scope.domElement.ownerDocument.addEventListener('touchmove', onTouchMove)
      scope.domElement.ownerDocument.addEventListener('touchend', () => { scope.prevTouch = false })
      scope.domElement.ownerDocument.addEventListener('pointerlockchange', onPointerlockChange)
      scope.domElement.ownerDocument.addEventListener('pointerlockerror', onPointerlockError)
      window.addEventListener('deviceorientation', onOrientationMove)
      window.addEventListener('devicemotion', onAccelMove)
      window.addEventListener('orientationchange', onScreenOrientationChangeEvent)
    }

    this.disconnect = function () {
      scope.domElement.ownerDocument.removeEventListener('mousemove', onMouseMove)
      scope.domElement.ownerDocument.removeEventListener('touchmove', onTouchMove)
      scope.domElement.ownerDocument.removeEventListener('touchend', () => { scope.prevTouch = false })
      scope.domElement.ownerDocument.removeEventListener('pointerlockchange', onPointerlockChange)
      scope.domElement.ownerDocument.removeEventListener('pointerlockerror', onPointerlockError)
      window.removeEventListener('deviceorientation', onOrientationMove)
      window.removeEventListener('devicemotion', onAccelMove)
      window.removeEventListener('orientationchange', onScreenOrientationChangeEvent)
    }

    this.dispose = function () {
      this.disconnect()
    }

    this.getObject = function () { // retaining this method for backward compatibility
      return camera
    }

    this.getDirection = (function () {
      const direction = new Vector3(0, 0, -1)

      return function (v) {
        return v.copy(direction).applyQuaternion(camera.quaternion)
      }
    }())

    this.moveForward = function (distance) {
      // move forward parallel to the xz-plane
      // assumes camera.up is y-up

      _vector.setFromMatrixColumn(camera.matrix, 0)

      _vector.crossVectors(camera.up, _vector)

      camera.position.addScaledVector(_vector, distance)
    }

    this.moveRight = function (distance) {
      _vector.setFromMatrixColumn(camera.matrix, 0)

      camera.position.addScaledVector(_vector, distance)
    }

    this.modeVREnabled = function () {
      scope.modeVR = true
    }

    this.modeVRDisabled = function () {
      scope.modeVR = false
    }

    this.lock = function () {
      this.domElement.requestPointerLock()
    }

    this.lockMobile = function () {
      scope.isLocked = true
      scope.mobile = true
      window.blockMenuHeaderScroll = true
      this.connect()
    }

    this.unlock = function () {
      if (!scope.mobile) {
        scope.domElement.ownerDocument.exitPointerLock()
      } else {
        scope.isLocked = false
        scope.mobile = false
        window.blockMenuHeaderScroll = false
      }
    }

    this.connect()
  }
}

export { PointerLockControls }
