<template>
    <div class="VideoClipsComponent">
        <v-container fluid>
          <v-row>
            <v-col align-self="center" align="center" cols="12" sm="6">
              <div class="Content" align="left">
                <span style="color: #5BB0FE">VIDEO </span><span>CLIPS</span>
                <div class="Text" align="left">
                  Get to know our products and digital content
                </div>
              </div>
            </v-col>
            <v-col align-self="center" align="center">
              <v-carousel  delimiter-icon="mdi-checkbox-blank-circle-outline" :hide-delimiter-background="true" :show-arrows-on-hover="true" :show-arrows="true" class="Carousel" cycle :interval="90000">
                <v-carousel-item v-for="(item, index) in items" v-bind:key="index">
                  <v-container fluid>
                    <v-row>
                      <v-col align-self="center" align="center">
                        <video :ref="'vc'+index" class="Video" preload="auto" :poster="item.poster" style="width: inherit; max-width: 889px; max-height: 500px">
                          <source :src="item.video" type="application/x-mpegURL">
                        </video>
                        <v-btn :ref="'bvc'+index" rounded x-large color="#5BB0FE" dark class="Play" @click="play('vc'+index, 'bvc'+index)">Play</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_VideoClipsComponent.scss'
import videojs from 'video.js'

export default {
  name: 'VideoClipsComponent',
  data () {
    return {
      items: [
        { video: 'https://cloudflarestream.com/8f549ca0dc8d0f8acbed0640879642e9/manifest/video.m3u8', poster: require('@/assets/aquarium/videos/brettPoster.png') },
        { video: 'https://cloudflarestream.com/9b13d54e31bfd01bcb465d9ede09c9ea/manifest/video.m3u8', poster: require('@/assets/aquarium/videos/kidsPoster.png') }
      ]
    }
  },
  methods: {
    play (value, button) {
      const player = videojs(this.$refs[value][0])
      player.play()
      this.$refs[value][0].controls = true
      this.$refs[button][0].$el.style.display = 'none'
    }
  }
}
</script>
