<template>
    <div class="PartnersComponent">
    </div>
</template>

<script>
import './_PartnersComponent.scss'
export default {
  name: 'PartnersComponent'
}
</script>
