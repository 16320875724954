<template>
  <div class="AppBar">
    <DrawerApp v-if="false"  />
    <v-app-bar
      app
      :class="{ navbar: true, mobileNavbar: $route.meta.mobile }"
      height="120px"
      elevation="2"
      :dark="hiddenBar"
      :background-color="hiddenBar ? '#fff' : ''"
      :style="hiddenBar ? 'box-shadow: unset !important' : ''"
      style="box-shadow: 5px 10px #fff;"
      >
      <div class="logoHeader">
      <router-link :to="content.to" class="mr-5" :style="{  cursor: 'pointer', height: '80%', margin: '5px'  }" alt="Home - Logo" tag="img"  :src="content.logo" />
      </div>
      <p class="pioneering hidden-sm-and-down" style="font-size:1.5rem">{{ content.slogan }}</p>
      <v-spacer></v-spacer>
      <v-btn v-for="(button, idx) in buttons" class="menuWords" v-bind:key="idx" v-show="!$device.mobile" elevation="0" dark rounded :to="button.to ? button.to : null" @click="button.click ? scroll(button.click) : null">{{ button.text }}</v-btn>
      <v-btn v-if="!identity && !$device.mobile" @click="sign" class="menuWords" elevation="0" dark rounded>Login</v-btn>
      <v-btn v-if="$route.name === 'Rocarbon'" href="https://rocx.rocarbonlabs.com/#demo" outlined class="icons hidden-sm-and-down" rounded><v-icon>mdi-home-circle-outline</v-icon></v-btn>
      <v-btn v-if="$route.name === 'Rocarbon'" href="https://rocx.rocarbonlabs.com/#demo" outlined class="hidden-md-and-up" rounded><v-icon class="icons">mdi-home</v-icon></v-btn>
      <v-btn v-if="$route.name === 'Rocarbon'" href="https://rocx.rocarbonlabs.com/#demo" dark color="green lighten-1" rounded class="icons mr-5 hidden-md-and-down">Book A Demo</v-btn>
      <v-btn v-if="$store.state.context === 'Aquarium'"  @click="$store.state.aquarium.download = true" text rounded class="hidden-md-and-down"><v-img :src="require('@/assets/aquarium/get_the_app_header.svg')" contain max-width="150px"></v-img></v-btn>
      <v-btn v-if="$store.state.context === 'SlapItOn'"  @click="$store.state.aquarium.download = true" text rounded class="hidden-md-and-down"><v-img :src="require('@/assets/slapItOn/gettheapp.svg')" contain max-width="150px"></v-img></v-btn>
      <v-menu offset-y right content-class="MenuWishlist" :close-on-content-click="false" rounded>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="loadWishlist()"
            class="mr-5" icon><v-icon v-if="getWishlistCount() === 0" color="white">mdi-heart-outline</v-icon><v-icon color="red" v-if="getWishlistCount() > 0">mdi-heart</v-icon>
          </v-btn>
        </template>
        <v-container style="background-color: #ffffffee; border-radius: 15px; min-width: clamp(300px, 90vw, 500px)">
          <v-row>
            <v-col align-self="center" align="center">
              <strong>Wishlist</strong>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="left">
              <v-container>
                <v-row v-if="getWishlistCount() === 0">
                  <v-col align-self="center" align="center">
                    Nothing Here
                  </v-col>
                </v-row>
                <v-row dense v-for="(item, index) in getWishlist()" v-bind:key="index" style="font-size: 0.9rem">
                  <v-col>
                    <v-btn icon x-small @click="removeWishlist(item)"><v-icon>mdi-delete</v-icon></v-btn> {{ item.title }}
                  </v-col>
                  <v-col cols="3">
                    <div align="right">
                      $ {{ item.collection.individual ? item.collection.prices[item.hash] : item.collection.price }}
                      <div style="font-size: 0.7rem; line-height: 100%">{{ currencies.find(currency => currency.code === item.collection.currency).name }}</div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="right" class="font-weight-bold">
                    Total
                  </v-col>
                  <v-col align="right" class="font-weight-bold" cols="3">
                    <div align="right">
                      $ {{ getWishlistTotal() }}
                      <div style="font-size: 0.7rem; line-height: 100%">T4G-USD</div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <v-btn rounded color="#70AFD0" x-large target="_blank" :to="'/p/wishlist?context=' + (this.$store.state.context !== 'default' ? this.$store.state.context : undefined)">
                pay now
              </v-btn>
              <div>
                <v-btn text x-small @click="clearWishlist()">remove all</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-menu>
      <!--  <v-btn v-if="identity" to="/myassets" outlined class="icons mr-5 hidden-md-and-down" rounded>My Assets</v-btn>-->
      <div :class="{ MenuIconButton: true, MenuIconButtonMobileMain: $device.mobile && !showMenu }" @click="showMenu = !showMenu">
        <v-btn fab small style="color: black !important;">
          <div class="activeLogoutButton" @mouseover="showLogoutButton = true" @mouseleave="showLogoutButton = false">
            <v-img v-if="identity" class="avatar-profile" contain :src="avatar" width="48" height="48" max-width="48" max-height="48">
            </v-img>
            <v-icon v-if="!identity">mdi-menu</v-icon>
            <div class="logoutButton slide-in-fwd-center" v-show="showLogoutButton" v-if="identity">
              <v-btn @click="logout" fab color="white" style="background-color: red" icon x-small><v-icon>mdi-power</v-icon></v-btn>
            </div>
          </div>
        </v-btn>
      </div>
    </v-app-bar>
    <div :class="{ menuShow: showMenu, menuHidden: !showMenu, LateralMenu: true }" @click="showMenu = false">
      <div v-show="showMenu" align="center">
        <v-btn @click="sign" v-if="$device.mobile" icon tile dark :x-large="$vuetify.breakpoint.mdAndUp" class="MenuListIconButton">
          <v-icon :x-large="$vuetify.breakpoint.mdAndUp">mdi-login-variant</v-icon>
          <div class="MenuListIconButtonTooltip blink-2">Login</div>
        </v-btn>
        <v-btn v-if="false" icon tile dark :x-large="$vuetify.breakpoint.mdAndUp" class="MenuListIconButton">
          <v-icon :x-large="$vuetify.breakpoint.mdAndUp">mdi-magnify</v-icon>
          <div class="MenuListIconButtonTooltip blink-2">Search</div>
        </v-btn>
        <v-btn to="/profile" v-if="identity" icon tile dark :x-large="$vuetify.breakpoint.mdAndUp" class="MenuListIconButton">
          <v-icon :x-large="$vuetify.breakpoint.mdAndUp">mdi-account-circle-outline</v-icon>
          <div class="MenuListIconButtonTooltip blink-2">Profile</div>
        </v-btn>
        <v-btn to="/myassets" v-if="identity" icon tile dark :x-large="$vuetify.breakpoint.mdAndUp" class="MenuListIconButton">
          <v-icon :x-large="$vuetify.breakpoint.mdAndUp">mdi-security</v-icon>
          <div class="MenuListIconButtonTooltip blink-2">Assets</div>
        </v-btn>
        <v-btn v-for="(button, idx) in buttons" :to="button.to ? button.to : null" @click="button.click ? scroll(button.click) : null" v-bind:key="idx" icon tile dark :x-large="$vuetify.breakpoint.mdAndUp" class="MenuListIconButton">
          <v-icon :x-large="$vuetify.breakpoint.mdAndUp">{{ button.icon }}</v-icon>
          <div class="MenuListIconButtonTooltip blink-2">{{ button.text }}</div>
        </v-btn>
        <div class="MenuFooter" align="center">
          <div class="MenuLine"></div>
          <v-img :src="require('@/assets/clickIcon.svg')" contain max-width="32" max-height="32" @click="instantPay" />
          <v-btn color="white" shaped @click="instantPay" class="menuWords elevation-0" plain>
            <div class="text-h6" style="color: white; line-height: 12px">{{ $store.state.instantPayLimit }}
              <br><span style="font-size: 0.8rem">T4G-USD</span>
            </div>
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog fullscreen v-model="dialogSignIn" v-if="dialogSignIn" content-class="AppBar">
      <v-container fluid class="fill-height dialog-signin">
        <v-row>
          <v-col align-self="center" align="center">
            <div v-if="dialogInstantPayment" class="overline">Instant Payment Authorization</div>
            <v-progress-circular v-if="loading"
                                 indeterminate
                                 color="black lighten-5"
                                 size="128"
                                 width="15"
            ></v-progress-circular>
            <v-img v-if="!loading && !$device.mobile" :src="qr" contain width="300px" height="300px" class="mb-10 qr"/>
            <div class="text-h4 font-weight-bold" style="color: #00355B">
              Connect your ID4Good
            </div>
            <div class="text-h6 font-weight-thin">
              The most comprehensive NFT platform for minting, selling, and connecting with your audience.<br> With built in payment processing, support for custom URLs. and much more, <br>what are you waiting for?
            </div>
            <div class="py-10 d-inline-flex">
              <v-img :src="require('@/assets/apple-store-badge.svg')" max-height="40px" max-width="120px" contain class="mx-5"></v-img>
              <v-img :src="require('@/assets/google-play-badge.png')" max-height="40px" max-width="120px" contain class="mx-5"></v-img>
            </div>
            <div>
              <v-btn style="color: black" :href="link" v-if="!loading && link && ($device.ios || $device.android)">Sign In with ID4Good</v-btn>
            </div>
            <div class="closeFloatButton">
              <v-btn style="color: black" fab small @click="dialogSignIn = false"><v-icon>mdi-close</v-icon></v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog fullscreen v-model="dialogInstantPaymentLimit" v-if="dialogInstantPaymentLimit" content-class="AppBar">
      <v-container fluid class="fill-height dialog-signin">
        <v-row>
          <v-col align-self="center" align="center">
            <div style="width: 300px">
            <div class="overline">Instant Payment Limit</div>
            <v-progress-circular v-if="loading"
                                 indeterminate
                                 color="black lighten-5"
                                 size="128"
                                 width="15"
            ></v-progress-circular>
            <v-text-field v-if="!loading" label="Limit Amount" v-model="limit" suffix="Any Currency"></v-text-field>
            <v-btn v-if="!loading" @click="assignInstantPayLimit" outlined>Accept</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog fullscreen v-model="instantLoading" v-if="instantLoading" content-class="AppBar">
      <v-container fluid class="fill-height dialog-signin">
        <v-row>
          <v-col align-self="center" align="center">
            <div style="width: 300px">
              <div class="overline" v-if="instantLoadingResult === false">Sync</div>
              <div v-if="instantLoadingResult === 'success'" class="overline" style="color: green; font-size: 1.2rem !important;">Buy Complete</div>
              <div v-if="instantLoadingResult === 'fail'" class="overline" style="color: orangered; font-size: 1.2rem !important;">Buy Failed</div>
              <v-progress-circular v-if="instantLoading && instantLoadingResult === false"
                                   indeterminate
                                   color="black lighten-5"
                                   size="128"
                                   width="15"
                                   class="my-5"
              ></v-progress-circular>
              <diV>
                  <v-btn class="my-5" outlined v-if="instantLoadingResult !== false" @click="$store.state.instantLoading = false">Close</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import DrawerApp from '../DrawerApp/DrawerApp'

import { mapState } from 'vuex'
import { getChallenge, generateQR, waitChallenge } from 'id4good.auth'
import './_AppBar.scss'
import { logEvent } from 'firebase/analytics'
import colors from '../../mixins/colors'
import wishlist from '../../mixins/wishlist'

export default {
  name: 'AppBar',
  components: { DrawerApp },
  data () {
    return {
      showMenu: false,
      showLogoutButton: false,
      drawer: false,
      avatar: require('@/assets/default_profile.svg'),
      qr: null,
      link: false,
      dialogSignIn: false,
      dialogInstantPayment: false,
      dialogInstantPaymentLimit: false,
      loading: false,
      limit: 0,
      VRSupported: false,
      currentHash: null,
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      buttons: [
        { text: 'Explore', to: '/main', icon: 'mdi-compass' },
        { text: 'Create', to: '/myassets/mint', icon: 'mdi-office-building-cog' },
        { text: 'Empowerment', to: '/empowerment', icon: 'mdi-semantic-web' },
        { text: 'Resources', to: '/resources', icon: 'mdi-account-group' },
        { text: 'Home', to: '/', icon: 'mdi-home' }
      ],
      content: {
        logo: require('@/assets/dog-engine.svg'),
        slogan: '',
        to: '/'
      }
    }
  },
  mixins: [colors, wishlist],
  mounted () {
    console.log('mounted')
    this.loadContent()
    const self = this
    if ('xr' in navigator) {
      navigator.xr.isSessionSupported('immersive-vr').then((supported) => {
        self.VRSupported = supported
      })
    }
  },
  watch: {
    $route (to, from) {
      this.loadContent()
    },
    identity: {
      deep: true,
      handler: function () {
      }
    }
  },
  methods: {
    scroll (id) {
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth', block: 'nearest'
        })
      }, 1500)
    },
    async loadContent () {
      const domain = window.location.host
      if (this.$route.path.includes('slapiton') || this.$route.query.context === 'SlapItOn' || domain === 'slapitonofficial.com' || domain === 'slapiton.com' || domain === 'beta.slapiton.com') {
        this.$store.state.context = 'SlapItOn'
        this.setColor('header', 'rgba(10,7,7,0.45)')
        this.setColor('footer', 'black')
        this.setColor('secondary', 'black')
        this.setColor('headerFont', '#ffffff')
        this.setColor('font', '#ffffff')
        this.setBackground(require('@/assets/slapItOn/background-01.png'))
        this.setColors()
        this.buttons = [
          // { text: 'Purchase', to: '/m/slapiton/purchase', icon: 'mdi-cart-variant' },
          { text: 'Aquarium', to: { name: 'Aquarium' }, icon: 'mdi-fishbowl-outline' },
          { text: 'Subscribe', to: '/m/slapiton?subscribe', icon: 'mdi-semantic-web', click: 'subscribe' },
          { text: 'About', to: '/m/slapiton/about', icon: 'mdi-information-variant' },
          { text: 'Help', to: '/m/slapiton/help', icon: 'mdi-help' }
        ]
      }
      if (this.$route.path.includes('aquarium') || this.$route.query.context === 'Aquarium') {
        this.$store.state.context = 'Aquarium'
        this.setColor('header', '#141420aa')
        this.setColor('footer', '#182236')
        this.setColor('secondary', '#141420aa')
        this.setColor('headerFont', '#ffffff')
        this.setColor('font', '#ffffff')
        this.setBackground(require('@/assets/aquarium/background.png'))
        this.setColors()
        this.buttons = [
          // { text: 'Purchase', to: '/m/aquarium/purchase', icon: 'mdi-cart-variant' },
          { text: 'Aquarium', to: '/m/aquarium', icon: 'mdi-fishbowl-outline' },
          { text: 'Subscribe', to: '/m/aquarium?subscribe', icon: 'mdi-semantic-web', click: 'subscribe' },
          { text: 'About', to: '/m/aquarium/about', icon: 'mdi-information-variant' },
          { text: 'Help', to: '/m/aquarium/help', icon: 'mdi-help' }
        ]
      }
      if (this.$route.name === 'Rocarbon' || this.$route.query.context === 'Rocarbon') {
        this.$store.state.context = 'Rocarbon'
      }
      const { data } = await this.$axios.get('/api/core/getContent', {
        params: {
          component: 'Header',
          context: this.$store.state.context,
          crc: Date.now() / 10000
        }
      })
      console.log(data)
      this.content = data
    },
    logout () {
      this.$store.state.identity = false
      this.$store.state.walletIdentity = false
    },
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    getDeviceMotionEvent () {
      return !!window.DeviceMotionEvent
    },
    modeVR () {
      if (window.DeviceMotionEvent && typeof window.DeviceMotionEvent.requestPermission === 'function') {
        window.DeviceMotionEvent.requestPermission().then(response => {
          if (response === 'granted') {
            this.$store.state.modeVR = !this.$store.state.modeVR
          }
        })
      } else if (window.DeviceMotionEvent) {
        this.$store.state.modeVR = !this.$store.state.modeVR
      }
    },
    getUser () {
      this.$axios.get('/api/users/getUser', {
        params: {
          identity: this.$store.state.identity,
          crc: Date.now()
        }
      }).then((result) => {
        this.avatar = result.data.user.avatar ? result.data.user.avatar : this.avatar
      })
    },
    async assignInstantPayLimit () {
      this.loading = true
      this.$axios.get('/api/auth/getIdentityWallet', {
        params: {
          identity: this.$store.state.identity,
          hash: this.currentHash,
          limit: this.limit,
          crc: Date.now()
        }
      }).then((result) => {
        this.$store.state.instantPay = result.data.address
        this.$store.state.instantPayLimit = this.limit
        this.dialogInstantPaymentLimit = false
        this.loading = false
      })
    },
    async instantPay () {
      this.loading = true
      this.dialogSignIn = true
      this.dialogInstantPayment = true
      const challenge = await getChallenge('wallet4good.com')
      this.qr = await generateQR(challenge.code)
      if (this.$device.mobile) {
        if (this.$device.ios) {
          this.link = 'https://id4good.com/' + challenge.code
        }
        if (this.$device.android) {
          this.link = 'id4good://wallet4good/' + challenge.code + ';scheme=id4good;package=com.bloqs4good.id4good;end'
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = this.link
          a.click()
          setTimeout(() => {
            document.body.removeChild(a)
          }, 0)
        }
      }
      this.loading = false
      waitChallenge(challenge.hash, 0).then((result) => {
        this.loading = true
        if (result.identity === true) {
          this.loading = false
          this.dialogInstantPaymentLimit = true
          this.dialogSignIn = false
          this.dialogInstantPayment = false
          this.currentHash = challenge.hash
        }
      }).catch((error) => {
        this.dialogSignIn = false
        this.dialogInstantPayment = false
        console.log(error)
      })
    },
    async sign () {
      this.loading = true
      this.dialogInstantPayment = false
      this.dialogSignIn = true
      logEvent(this.$analytics, 'login', { component: 'Main', device: this.$device, user: this.$store.state.identity })
      const challenge = await getChallenge('wallet4good.com')
      this.qr = await generateQR(challenge.code)
      if (this.$device.ios || this.$device.android) {
        if (this.$device.ios) {
          this.link = 'https://id4good.com/' + challenge.code
        }
        if (this.$device.android) {
          this.link = 'id4good://wallet4good/' + challenge.code + ';scheme=id4good;package=com.bloqs4good.id4good;end'
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = this.link
          a.click()
          setTimeout(() => {
            document.body.removeChild(a)
          }, 0)
        }
      }
      this.loading = false
      waitChallenge(challenge.hash, 0).then((result) => {
        this.loading = true
        if (result.identity === true) {
          this.$axios.get('/api/auth/getUnifyIdentity', {
            params: {
              hash: challenge.hash,
              crc: Date.now()
            }
          }).then((result) => {
            this.$store.state.identity = result.data.identity
            this.$store.state.walletIdentity = result.data.walletIdentity
            this.$store.state.wallet = result.data.address
            this.getUser()
            this.dialogSignIn = false
            this.loading = false
          })
        }
      }).catch((error) => {
        this.dialogSignIn = false
        console.log(error)
      })
    }
  },
  computed: {
    ...mapState(
      {
        identity: state => state.identity,
        hiddenBar: state => state.hiddenBar,
        instantLoading: state => state.instantLoading,
        instantLoadingResult: state => state.instantLoadingResult
      })
  }
}
</script>
