<template>
  <div class="Virtual">
    <v-container fluid v-if="loading || !render" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center" class="overline">
          <v-progress-circular v-if="loading || !render"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          >Render
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <div v-show="!loading && render">
      <div class="pointer" v-if="!loading">+</div>
      <div class="start-button" v-if="!ready">
        <v-btn dark x-large rounded @click="onClick">Press to Start</v-btn>
      </div>
      <div class="qr-box" align="center" v-if="!($device.ios || $device.android) && !loading && !collection.exhibition">
        <v-img width="200" height="200" contain  style="border-radius: 10px" :src="collection.soldComplete ? require('@/assets/sold.png') : qr"></v-img>
        <v-btn :disabled="collection.soldComplete" plain class="overline mt-1 text-uppercase" :href="link">buy now</v-btn>
        <v-btn :disabled="collection.soldComplete" v-if="$store.state.instantPay" @click="getInstantPayment" color="green" icon><v-icon>mdi-credit-card-settings-outline</v-icon></v-btn>
      </div>
      <div class="keyboard" v-if="$device.ios || $device.android">
        <v-container>
          <v-row>
            <v-col align="center">
              <v-btn icon @click="pressForward"><v-icon>mdi-arrow-up</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <v-btn icon @click="pressLeft"><v-icon>mdi-arrow-left</v-icon></v-btn>
            </v-col>
            <v-col align="center">
              <v-btn icon @click="pressBackward"><v-icon>mdi-arrow-down</v-icon></v-btn>
            </v-col>
            <v-col align="center">
              <v-btn icon @click="pressRight"><v-icon>mdi-arrow-right</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="buttons">
        <div><v-switch v-model="underSea" dark class="mt-0" prepend-icon="mdi-fishbowl-outline"></v-switch></div>
        <div><v-switch v-model="renderObject" dark class="mt-0" prepend-icon="mdi-video-3d"></v-switch></div>
        <div v-if="getDeviceMotionEvent"><v-switch v-model="$store.state.modeVR" dark class="mt-0" prepend-icon="mdi-virtual-reality"></v-switch></div>
        <div><v-switch v-model="$store.state.modeTour" dark class="mt-0" prepend-icon="mdi-play"></v-switch></div>
      </div>
    </div>
    <div ref="renderBox" id="render-box" @click="onClick" :class="{ underSea: underSea }"></div>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        {{ text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_Virtual.scss'
import * as THREE from 'three'
import * as TWEEN from '@tweenjs/tween.js'
import { PointerLockControls } from '../../../mixins/control'
import assets from '../../../mixins/assets'
import { generateQR } from 'id4good.auth'
// import { VRButton } from '../../../mixins/vrbutton'
import WebVRPolyfill from 'webvr-polyfill'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import instant from '../../../mixins/instant'
import { mapState } from 'vuex'
import { logEvent } from 'firebase/analytics'

export default {
  name: 'Virtual',
  data () {
    return {
      renderObject: false,
      underSea: false,
      qr: null,
      link: null,
      id: null,
      ready: false,
      snackbar: false,
      text: '',
      currentDisplay: 0,
      art: null,
      dialogArt: false,
      renderer: null,
      scene: null,
      camera: null,
      controls: null,
      raycaster: null,
      moveForward: false,
      moveBackward: false,
      moveLeft: false,
      moveRight: false,
      canJump: false,
      collection: null,
      loading: false,
      assets: [],
      destiny: false,
      startMove: false,
      prevTime: performance.now(),
      videos: [],
      arts: [],
      plate: [],
      line: null,
      velocity: new THREE.Vector3(),
      direction: new THREE.Vector3(),
      pointer: new THREE.Vector2(),
      template: null,
      render: false,
      mixer: [],
      speed: 400,
      oldSpeed: 400,
      tour: false,
      timer: null,
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      limit: {
        maxx: 3000,
        minx: -3000,
        maxz: 3000,
        minz: -3000
      }
    }
  },
  mixins: [assets, instant],
  watch: {
    renderObject: {
      deep: true,
      handler: function () {
        this.$refs.renderBox.innerHTML = ''
        this.init()
      }
    },
    underSea: {
      deep: true,
      handler: function () {
        this.$refs.renderBox.innerHTML = ''
        this.init()
      }
    },
    modeVR: {
      deep: true,
      handler: function (value) {
        if (window.DeviceMotionEvent && typeof window.DeviceMotionEvent.requestPermission === 'function') {
          window.DeviceMotionEvent.requestPermission().then(response => {
            if (response === 'granted') {
            }
          })
        } else if (window.DeviceMotionEvent) {
          return
        }
        if (value) {
          this.controls.modeVREnabled()
          this.oldSpeed = this.speed
          this.speed = this.speed * 0.2
          this.moveForward = true
        } else {
          this.controls.modeVRDisabled()
          this.speed = this.oldSpeed
          this.moveForward = false
        }
      }
    },
    modeTour: {
      deep: true,
      handler: function (value) {
        console.log(value)
        if (value) {
          if (this.$device.ios || this.$device.android) {
            this.controls.lockMobile()
          } else {
            this.controls.lock()
          }
          this.ready = true
          if (this.tour === false) {
            this.tour = 0
          }
          setTimeout(() => {
            this.runTour()
          }, 618)
        } else {
          if (this.controls.isLocked) {
            this.ready = false
            this.controls.unlock()
          }
          clearInterval(this.timer)
        }
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.$store.state.modeTour = false
  },
  async mounted () {
    this.$store.state.modeTour = false
    if (this.$store.state.context === 'Aquarium') {
      this.underSea = true
    }
    if (this.$route.query.template) {
      this.template = this.$route.query.template
    }
    const polyfill = new WebVRPolyfill()
    console.log(polyfill.enabled)
    this.id = this.$route.params.id.replaceAll('%2F', '/')
    const revolved = this.$router.resolve({
      name: 'Payment',
      params: { id: this.$route.params.id }
    })
    this.instant = {
      id: this.id,
      individual: false
    }
    const base = (window.location.href.split('#'))[0]
    this.link = base + revolved.href
    generateQR(base + revolved.href).then((qr) => {
      this.qr = qr
    })
    this.getCollection()
  },
  computed: {
    ...mapState(
      {
        modeTour: state => state.modeTour,
        modeVR: state => state.modeVR
      }
    ),
    previewSize: function () {
      return this.$vuetify.breakpoint.width < 600 ? parseInt(this.$vuetify.breakpoint.width * 0.7) + 'px' : '400px'
    },
    size: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.$vuetify.breakpoint.height * 0.6
      }
      return this.$vuetify.breakpoint.width * 0.6
    }
  },
  methods: {
    getDeviceMotionEvent () {
      return !!window.DeviceMotionEvent
    },
    showCardDetail (item) {
      console.log('show')
      this.$store.state.cardDetail = item
      logEvent(this.$analytics, 'view_item', { component: 'Main', asset_hash: item.key, name: item.title, device: this.$device, user: this.$store.state.identity })
    },
    runTour () {
      console.log(this.tour)
      this.destiny = this.arts[this.tour]
      // this.camera.lookAt(this.destiny.position)
      this.startMove = false
      this.tour = (this.tour + 1) % this.arts.length
      this.timer = setInterval(() => {
        console.log(this.tour)
        this.destiny = this.arts[this.tour]
        // this.camera.lookAt(this.destiny.position)
        this.startMove = false
        this.tour = (this.tour + 1) % this.arts.length
      }, 6180)
    },
    getCollection () {
      this.$axios.get('/api/collections/getCollection', {
        params: {
          hash: this.id,
          crc: Date.now()
        }
      }).then((result) => {
        this.user = result.data.user
        this.collection = result.data.collection
        if (this.collection.sellComplete) {
          generateQR('https://engine.deptofgood.com/').then((qr) => {
            this.qr = qr
          })
        }
        let count = this.collection.assets.length
        for (const key in this.collection.assets) {
          this.$axios.get('/api/assets/getAssetInfo', {
            params: {
              hash: this.collection.assets[key],
              crc: parseInt(Date.now() / 100000)
            }
          }).then(async (result) => {
            this.assets[key] = result.data.asset
            count--
            if (count === 0) {
              await this.init()
              // this.animate()
              this.renderer.setAnimationLoop(this.animate)
              this.loading = false
            }
          })
        }
      })
    },
    pressForward () {
      this.moveForward = true
      setTimeout(() => { this.moveForward = false }, 500)
    },
    pressBackward () {
      this.moveBackward = true
      setTimeout(() => { this.moveBackward = false }, 500)
    },
    pressRight () {
      this.moveRight = true
      setTimeout(() => { this.moveRight = false }, 300)
    },
    pressLeft () {
      this.moveLeft = true
      setTimeout(() => { this.moveLeft = false }, 300)
    },
    async showArt (art, index) {
      art.available = 1
      art.collection = this.collection
      if (this.collection.individual) {
        this.instant = {
          id: this.id,
          index: index,
          individual: true
        }
        const revolved = this.$router.resolve({
          name: 'PaymentIndividual',
          params: { id: this.$route.params.id, index: index }
        })
        const base = (window.location.href.split('#'))[0]
        this.link = base + revolved.href
        generateQR(base + revolved.href).then((qr) => {
          this.qr = qr
        })
        art.price = this.collection.prices[this.collection.assets[index]]
        art.available = this.collection.unit ? (this.collection.unit[this.collection.assets[index]] ? this.collection.unit[this.collection.assets[index]] : 1) : 1
      }
      this.controls.unlock()
      this.currentDisplay = 0
      if (typeof art.metadata !== 'object') {
        const metadata = await this.getMetadata(art.metadata)
        art.metadata = metadata
      }
      art.collection.key = this.$route.params.id
      art.title = art.metadata.name ? art.metadata.name : art.collection.name
      art.author = this.user.name
      art.avatar = this.user.avatar
      art.pieces = parseFloat(art.metadata.quantity)
      art.available = parseFloat(1)
      console.log('col', art.collection)
      art.type = art.collection.assets ? (art.collection.assets.length > 1 ? 'Collection' : 'Single') : 'Single'
      art.image = art.previews ? art.previews[0].url : art.metadata.image_url
      art.name = art.metadata.name
      art.description = art.metadata.description
      art.quantity = art.metadata.quantity
      art.creator = art.metadata.creator
      art.created = art.metadata.created
      art.sold = false
      if (this.collection.sold) {
        if (this.collection.sold[this.collection.assets[index]] === 'sold') {
          art.sold = true
        }
        if (this.collection.sold[this.collection.assets[index]] > 0) {
          art.available = art.available - this.collection.sold[this.collection.assets[index]]
          if (art.available === 0) {
            art.sold = true
          }
        }
      }
      this.art = art
      this.showCardDetail(art)
      // this.dialogArt = true
    },
    async init () {
      let count = this.assets.length
      console.log(this.assets)
      const renderBox = document.getElementById('render-box')
      this.camera = new THREE.PerspectiveCamera(60, renderBox.clientWidth / (window.innerHeight - 98), 1, 1000)
      this.camera.position.y = 20
      this.scene = new THREE.Scene()

      this.scene.fog = new THREE.Fog(0xEEEEEE, 0, 1000)
      if (this.underSea) {
        this.scene.fog.far = 500
      }

      const uniforms = {
        topColor: { value: new THREE.Color(0xdddddd) },
        bottomColor: { value: new THREE.Color(0xffffff) }
      }
      const skyMat = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: `
            varying vec2 vUv;

            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
            }
        `,
        fragmentShader: `
            uniform vec3 topColor;
            uniform vec3 bottomColor;

            varying vec2 vUv;

            void main() {
              gl_FragColor = vec4(mix(topColor, bottomColor, vUv.y), 1.0);
            }
      `,
        side: THREE.BackSide
        // wireframe: true
      })
      const skyGeo = new THREE.SphereGeometry(2000, 100, 100)
      const sky = new THREE.Mesh(skyGeo, skyMat)
      if (!this.renderObject) {
        this.scene.add(sky)
      }

      if (!this.renderObject) {
        const lightGlobal = new THREE.HemisphereLight(0xEEEEEE, 0xFFFFFF, 1)
        lightGlobal.position.set(0, 1, 0)
        this.scene.add(lightGlobal)
      }
      if (this.renderObject) {
        const lightGlobal = new THREE.AmbientLight(0xFFFFFF, 1)
        lightGlobal.position.set(1000, 1000, 1000)
        this.scene.add(lightGlobal)
      }

      if (['01', '02', '03', '04', '05', '06', '07', '08', '09', '010', '011', '012', '013', '014', '015', '016', '017', '018', '019', '020', '022', '023', '024', '025', '026'].includes(this.template)) {
        const loader = new GLTFLoader()
        loader.load(
          '/virtual/' + this.template + '/scene.gltf',
          async (gltf) => {
            // this.speed = 10
            // this.limit.x = 1000
            // this.limit.z = 1000
            let positions = []
            // called when the resource is loaded

            if (this.template === '01') {
              gltf.scene.scale.multiplyScalar(1 / 3)
              this.speed = 400
              this.limit.maxx = 38
              this.limit.minx = -280
              this.limit.maxz = 54
              this.limit.minz = -66
              positions = [
                {
                  x: 5,
                  z: 59,
                  rotate: 0
                },
                {
                  x: 5,
                  z: -71,
                  rotate: 0
                },
                {
                  x: -55,
                  z: 21,
                  rotate: 0
                },
                {
                  x: -188,
                  z: -33,
                  rotate: 0
                },
                {
                  x: -120,
                  z: 59,
                  rotate: 0
                },
                {
                  x: -255,
                  z: 59,
                  rotate: 0
                },
                {
                  x: -200,
                  z: -71,
                  rotate: 0
                },
                {
                  x: -255,
                  z: -71,
                  rotate: 0
                },
                {
                  x: 43,
                  z: -6,
                  rotate: Math.PI / 2
                },
                {
                  x: -50,
                  z: -71,
                  rotate: 0
                },
                {
                  x: -285,
                  z: -6,
                  rotate: Math.PI / 2
                }
              ]
            }
            if (this.template === '02') {
              gltf.scene.scale.multiplyScalar(1 / 2)
              gltf.scene.position.x = 350
              this.speed = 400
              this.limit.maxx = 150
              this.limit.minx = -20
              this.limit.maxz = 185
              this.limit.minz = -185
              positions = [
                {
                  x: 42,
                  z: -150,
                  rotate: 0
                },
                {
                  x: 90,
                  z: -50,
                  rotate: 0
                },
                {
                  x: 28,
                  z: 50,
                  rotate: 0
                },
                {
                  x: 90,
                  z: 50,
                  rotate: 0
                },
                {
                  x: 45,
                  z: -50,
                  rotate: 0
                },
                {
                  x: 40,
                  z: 145,
                  rotate: 0
                },
                {
                  x: 90,
                  z: 145,
                  rotate: 0
                }
              ]
            }
            if (this.template === '03') {
              this.speed = 400
              this.limit.maxx = 190
              this.limit.minx = -90
              this.limit.maxz = 210
              this.limit.minz = -110
              gltf.scene.scale.multiplyScalar(6)
              gltf.scene.position.x = 50
              gltf.scene.position.z = 50
              gltf.scene.position.y = 10
              positions = [
                {
                  x: 20,
                  z: 40,
                  rotate: 0
                },
                {
                  x: 20,
                  z: -120,
                  rotate: 0
                },
                {
                  x: -100,
                  z: -10,
                  rotate: Math.PI / 2
                },
                {
                  x: 200,
                  z: -10,
                  rotate: Math.PI / 2
                },
                {
                  x: 30,
                  z: 110,
                  rotate: Math.PI / 2
                },
                {
                  x: 200,
                  z: 110,
                  rotate: Math.PI / 2
                },
                {
                  x: 140,
                  z: 220,
                  rotate: 0
                }
              ]
            }
            if (this.template === '04') {
              this.speed = 450
              this.limit.minx = -95
              this.limit.maxx = 95
              this.limit.minz = -185
              this.limit.maxz = 185
              gltf.scene.scale.multiplyScalar(1 / 4)
              positions = [
                {
                  x: -98,
                  z: -100,
                  rotate: Math.PI / 2
                },
                {
                  x: -98,
                  z: 100,
                  rotate: Math.PI / 2
                },
                {
                  x: 100,
                  z: -30,
                  rotate: Math.PI / 2
                },
                {
                  x: 100,
                  z: 30,
                  rotate: Math.PI / 2
                },
                {
                  x: 50,
                  z: -187,
                  rotate: 0
                },
                {
                  x: 0,
                  z: -187,
                  rotate: 0
                },
                {
                  x: -50,
                  z: -187,
                  rotate: 0
                },
                {
                  x: 50,
                  z: 194,
                  rotate: 0
                },
                {
                  x: 0,
                  z: 194,
                  rotate: 0
                },
                {
                  x: -50,
                  z: 194,
                  rotate: 0
                }
              ]
            }
            if (this.template === '05') {
              this.speed = 25
              this.limit.minx = -2
              this.limit.maxx = 24
              this.limit.minz = -20
              this.limit.maxz = 1
              gltf.scene.position.x = 15
              gltf.scene.position.z = -20
              gltf.scene.position.y = 18
            }
            if (this.template === '06') {
              gltf.scene.scale.multiplyScalar(1 / 4)
              gltf.scene.position.x = 0
              gltf.scene.position.z = -323
              gltf.scene.position.y = -10
              this.speed = 400
              this.limit.minx = -75
              this.limit.maxx = 75
              this.limit.minz = -180
              this.limit.maxz = 0
              positions = [
                {
                  x: 0,
                  z: -90,
                  rotate: 0
                }]
            }
            if (this.template === '07') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = -50
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(1 / 6)
              this.speed = 400
              this.limit.minx = -45
              this.limit.maxx = 45
              this.limit.minz = -95
              this.limit.maxz = 0
              positions = [
                {
                  x: 0,
                  z: -50,
                  rotate: 0
                }]
            }
            if (this.template === '022') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = -50
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(1 / 6)
              this.speed = 400
              this.limit.minx = -45
              this.limit.maxx = 45
              this.limit.minz = -95
              this.limit.maxz = 0
              positions = [
                {
                  x: 0,
                  z: -50,
                  rotate: 0
                }]
            }
            if (this.template === '023') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = -50
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(1 / 6)
              this.speed = 400
              this.limit.minx = -45
              this.limit.maxx = 45
              this.limit.minz = -95
              this.limit.maxz = 0
              positions = [
                {
                  x: 0,
                  z: -50,
                  rotate: 0
                }]
            }
            if (this.template === '024') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = -50
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(1 / 6)
              this.speed = 400
              this.limit.minx = -45
              this.limit.maxx = 45
              this.limit.minz = -95
              this.limit.maxz = 0
              positions = [
                {
                  x: 0,
                  z: -50,
                  rotate: 0
                }]
            }
            if (this.template === '025') {
              gltf.scene.position.x = 50
              gltf.scene.position.z = -100
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(15)
              this.speed = 400
              this.limit.minx = -50
              this.limit.maxx = 150
              this.limit.minz = -200
              this.limit.maxz = 0
              positions = [
                {
                  x: 25,
                  z: -100,
                  rotate: 0
                }]
            }
            if (this.template === '026') {
              gltf.scene.position.x = 50
              gltf.scene.position.z = -100
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(5)
              this.speed = 400
              this.limit.minx = -50
              this.limit.maxx = 150
              this.limit.minz = -200
              this.limit.maxz = 0
              positions = [
                {
                  x: 25,
                  z: -100,
                  rotate: 0
                }]
            }
            if (this.template === '09') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = 0
              gltf.scene.position.y = -120
              gltf.scene.scale.multiplyScalar(15)
              this.speed = 400
              this.limit.minx = -300
              this.limit.maxx = 300
              this.limit.minz = -300
              this.limit.maxz = 300
              positions = [
                {
                  x: 0,
                  z: 0,
                  rotate: 0
                },
                {
                  x: 0,
                  z: -275,
                  rotate: 0
                },
                {
                  x: 0,
                  z: 275,
                  rotate: 0
                },
                {
                  x: 275,
                  z: 0,
                  rotate: Math.PI / 2
                },
                {
                  x: -275,
                  z: 0,
                  rotate: Math.PI / 2
                }
              ]
            }
            if (this.template === '010') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = -50
              gltf.scene.position.y = -20
              gltf.scene.scale.multiplyScalar(8)
              this.speed = 10
              this.limit.minx = -1000
              this.limit.maxx = 1000
              this.limit.minz = -1000
              this.limit.maxz = 1000
              positions = [
                {
                  x: 0,
                  z: -50,
                  rotate: 0
                }]
            }
            if (this.template === '011') {
              gltf.scene.position.x = -40
              gltf.scene.position.z = -90
              gltf.scene.position.y = -5
              gltf.scene.scale.multiplyScalar(10)
              this.speed = 400
              this.limit.minx = -200
              this.limit.maxx = 200
              this.limit.minz = -200
              this.limit.maxz = 200
              positions = [
                {
                  x: -40,
                  z: -90,
                  rotate: 0
                }]
            }
            if (this.template === '012') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = 0
              gltf.scene.position.y = 5
              gltf.scene.scale.multiplyScalar(5)
              positions = [
                {
                  x: 0,
                  z: 0,
                  rotate: Math.PI / 2
                }]
            }
            if (this.template === '013') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = 0
              gltf.scene.position.y = -5
              // gltf.scene.camera.rotate = Math.PI / 4
              gltf.scene.scale.multiplyScalar(15)
              this.speed = 400
              this.limit.minx = -140
              this.limit.maxx = 140
              this.limit.minz = -140
              this.limit.maxz = 140
              positions = [
                {
                  x: 0,
                  z: -50,
                  rotate: 0
                },
                {
                  x: 0,
                  z: 50,
                  rotate: 0
                },
                {
                  x: 60,
                  z: 0,
                  rotate: Math.PI / 2
                },
                {
                  x: -60,
                  z: 0,
                  rotate: Math.PI / 2
                },
                {
                  x: 140,
                  z: 0,
                  rotate: 0
                },
                {
                  x: -140,
                  z: 0,
                  rotate: 0
                }
              ]
            }
            if (this.template === '014') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = -60
              gltf.scene.position.y = -15
              gltf.scene.scale.multiplyScalar(4)
              this.speed = 400
              this.limit.minx = -65
              this.limit.maxx = 25
              this.limit.minz = -85
              this.limit.maxz = 15
              positions = [
                {
                  x: 10,
                  z: -100,
                  rotate: 0
                }
              ]
            }
            if (this.template === '015') {
              gltf.scene.position.x = -80
              gltf.scene.position.z = -50
              gltf.scene.position.y = -10
              gltf.scene.scale.multiplyScalar(6)
              positions = [
                {
                  x: 15,
                  z: -80,
                  rotate: 0
                }
              ]
            }
            if (this.template === '016') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = 0
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(10)
              positions = [
                {
                  x: 0,
                  z: 0,
                  rotate: 0
                }
              ]
            }
            if (this.template === '017') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = -350
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(10)
              this.speed = 400
              this.limit.minx = -125
              this.limit.maxx = 125
              this.limit.minz = -325
              this.limit.maxz = 0
              positions = [
                {
                  x: 0,
                  z: -200,
                  rotate: 0
                }
              ]
            }
            if (this.template === '018') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = -60
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(15)
              this.speed = 400
              this.limit.minx = -80
              this.limit.maxx = 0
              this.limit.minz = -120
              this.limit.maxz = 0
              positions = [
                {
                  x: -88,
                  z: -100,
                  rotate: Math.PI / 2
                },
                {
                  x: -88,
                  z: 0,
                  rotate: Math.PI / 2
                },
                {
                  x: -88,
                  z: -50,
                  rotate: Math.PI / 2
                },
                {
                  x: -40,
                  z: 28,
                  rotate: 0
                }
              ]
            }
            if (this.template === '019') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = -50
              gltf.scene.position.y = 0
              this.speed = 400
              this.limit.minx = -25
              this.limit.maxx = 25
              this.limit.minz = -65
              this.limit.maxz = 0
              // gltf.scene.scale.multiplyScalar(10)
              positions = [
                {
                  x: 0,
                  z: -70,
                  rotate: 0
                },
                {
                  x: 0,
                  z: 0,
                  rotate: 0
                }
              ]
            }
            if (this.template === '020') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = 0
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(10)
              this.speed = 400
              this.limit.minx = -270
              this.limit.maxx = 540
              this.limit.minz = -270
              this.limit.maxz = 270
              positions = [
                {
                  x: 0,
                  z: -200,
                  rotate: 0
                },
                {
                  x: -100,
                  z: -200,
                  rotate: 0
                },
                {
                  x: 100,
                  z: -200,
                  rotate: 0
                },
                {
                  x: 0,
                  z: 200,
                  rotate: 0
                },
                {
                  x: -100,
                  z: 200,
                  rotate: 0
                },
                {
                  x: 100,
                  z: 200,
                  rotate: 0
                }
              ]
            }
            if (this.template === '021') {
              gltf.scene.position.x = 0
              gltf.scene.position.z = 0
              gltf.scene.position.y = 0
              gltf.scene.scale.multiplyScalar(125)
              positions = [
              /*  {
                  x: 0,
                  z: 0,
                  rotate: 0
                } */
              ]
            }
            gltf.scene.castShadow = true
            gltf.scene.receiveShadow = true
            gltf.scene.traverse(n => {
              if (n.isMesh) {
                n.castShadow = true
                n.receiveShadow = true
                if (n.material.map) n.material.map.anisotropy = 16
              }
            })

            this.scene.add(gltf.scene)

            for (const position of positions) {
              if (count) {
                this.scene.add(await this.createArt(this.assets[count - 1], count - 1, position.x, position.z, position.rotate))
                // this.scene.add(await this.createPlate(this.assets[count - 1], count - 1, position.x, -position.z, position.rotate))
                position.index = count - 1
                count--
              }
            }

            this.render = true
          },
          (xhr) => {
            // called while loading is progressing
            console.log(`${(xhr.loaded / xhr.total * 100)}% loaded`)
          },
          (error) => {
            // called when loading has errors
            console.error('An error happened', error)
          }
        )
      } else {
        this.speed = 400
        this.limit.minx = -220
        this.limit.maxx = 220
        this.limit.minz = -220
        this.limit.maxz = 220
        const skyGeo = new THREE.SphereGeometry(3000, 100, 100)
        const sky = new THREE.Mesh(skyGeo, skyMat)
        if (!this.renderObject) {
          this.scene.add(sky)
        }

        const floorGeometry = new THREE.PlaneGeometry(6000, 6000, 100, 100)
        floorGeometry.rotateX(-Math.PI / 2)
        const floorMaterial = new THREE.MeshStandardMaterial({
          color: 0xFFFFFF,
          side: THREE.DoubleSide,
          combine: THREE.MixOperation
        })
        const floor = new THREE.Mesh(floorGeometry, floorMaterial)
        floor.receiveShadow = true
        floor.castShadow = true
        if (!this.underSea) {
          this.scene.add(floor)
        }

        const light = new THREE.PointLight(0xffffff, 0.1)
        light.position.set(0, 50, 0)
        light.castShadow = true
        if (this.renderObject) {
          light.intensity = 0.5
        }
        this.scene.add(light)

        const dLight = new THREE.DirectionalLight(0xffffff, 0.045)
        dLight.position.set(0, 50, 0)
        dLight.castShadow = true
        if (this.renderObject) {
          dLight.intensity = 0.5
        }
        this.scene.add(dLight)

        const positions = [
          { x: -40, z: -97.5, rotate: 0 },
          { x: -90, z: -97.5, rotate: 0 },
          { x: -150, z: -97.5, rotate: 0 },
          { x: -197.5, z: -50, rotate: Math.PI / 2 },
          { x: 97.5, z: -100, rotate: Math.PI / 2 },
          { x: 97.5, z: 0, rotate: Math.PI / 2 },
          { x: 0, z: 97.5, rotate: 0 },
          { x: -50, z: 97.5, rotate: 0 },
          { x: -100, z: 97.5, rotate: 0 },
          { x: -150, z: 97.5, rotate: 0 },
          { x: -200, z: 97.5, rotate: 0 }
        ]

        if (!this.underSea) {
          let wall = null
          wall = this.createWall(200, 100, -100, 0, -100)
          this.scene.add(wall)
          // this.scene.add(this.createLight(wall))
          // this.scene.add(this.createLightDirectional(wall))

          wall = this.createWall(100, 100, -200, 0, -52.5, Math.PI / 2)
          this.scene.add(wall)
          // this.scene.add(this.createLight(wall))
          // this.scene.add(this.createLightDirectional(wall))

          wall = this.createWall(200, 150, 100, 0, -52.5, Math.PI / 2)
          this.scene.add(wall)
          // this.scene.add(this.createLight(wall))
          // this.scene.add(this.createLightDirectional(wall))

          wall = this.createWall(300, 100, -100, 0, 100)
          this.scene.add(wall)
          // this.scene.add(this.createLight(wall))
          // this.scene.add(this.createLightDirectional(wall))
        }

        for (const position of positions) {
          if (count) {
            this.scene.add(await this.createArt(this.assets[count - 1], count - 1, position.x, position.z, position.rotate))
            // this.scene.add(await this.createPlate(this.assets[count - 1], count - 1, position.x, -position.z, position.rotate))
            position.index = count - 1
            count--
          }
        }

        this.render = true
      }
      this.controls = new PointerLockControls(this.camera, renderBox)
      this.scene.add(this.controls.getObject())

      const self = this
      window.addEventListener('keydown', (event) => {
        self.onKeyDown(event)
        event.preventDefault()
      })
      window.addEventListener('keyup', (event) => {
        self.onKeyUp(event)
        event.preventDefault()
      })
      renderBox.addEventListener('mousemove', (event) => {
        if (this.controls.isLocked && this.underSea) {
        }
        self.onPointerMove(event)
      })

      this.raycaster = new THREE.Raycaster()

      const geometry = new THREE.BufferGeometry()
      geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(5 * 3), 3))
      geometry.dinamic = true
      this.line = new THREE.LineSegments(geometry, new THREE.LineBasicMaterial({
        color: 0x2596be,
        linewidth: 5,
        transparent: true
      }))
      this.scene.add(this.line)

      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true
      })
      this.renderer.shadowMap.enabled = true
      // this.renderer.physicallyCorrectLights = true
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(renderBox.clientWidth, (window.innerHeight - 98))
      this.renderer.setClearColor(0x000000, 0.0)
      if (this.renderObject) {
        this.renderer.outputEncoding = THREE.sRGBEncoding
      }
      // this.renderer.xr.enabled = true
      // this.renderer.xr.cameraAutoUpdate = false
      // this.renderer.xr.updateCamera(this.camera)
      // this.renderer.xr.setReferenceSpaceType('local')
      renderBox.appendChild(this.renderer.domElement)
      // renderBox.appendChild(VRButton.createButton(this.renderer))

      window.addEventListener('resize', this.onWindowResize)
      window.addEventListener('orientationchange', this.onWindowResize)
      this.onWindowResize()
    },
    onWindowResize () {
      setTimeout(() => {
        let rest = 98
        const orientation = window.orientation || 0
        if (orientation === 90 || orientation === 270) {
          if (this.$store.state.modeVR) {
            rest = 0
          }
        }
        const renderBox = document.getElementById('render-box')
        this.camera.aspect = renderBox.clientWidth / (window.innerHeight - rest)
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(renderBox.clientWidth, (window.innerHeight - rest))
      }, 618)
    },
    createWall (width, height, x, y, z, rotate = 0) {
      const wall = new THREE.Mesh(new THREE.BoxGeometry(width, height, 5), new THREE.MeshPhongMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide
      }))
      wall.rotation.y = rotate
      wall.position.x = x
      wall.position.y = y
      wall.position.z = z
      wall.castShadow = true
      wall.receiveShadow = true

      return wall
    },
    createLight (target) {
      const light = new THREE.SpotLight(0xffffff, 0.1)
      light.position.set(0, 100, 0)
      light.target = target
      light.castShadow = true
      return light
    },
    createLightDirectional (target) {
      const light = new THREE.DirectionalLight(0xffffff, 3)
      light.position.set(0, 50, 0)
      light.target = target
      light.castShadow = true
      return light
    },
    async loadTexture (url) {
      return new Promise(resolve => {
        const loader = new THREE.TextureLoader()
        loader.crossOrigin = 'Anonymous'
        loader.load(url, resolve)
      })
    },
    async createArt (asset, index, x, z, rotate = 0) {
      const loader = new THREE.TextureLoader()
      loader.crossOrigin = 'Anonymous'
      let material
      let width
      let height

      if (!this.renderObject || asset.previews.length === 1 || (asset.previews[1].type !== 'videooff' && asset.previews[1].type !== 'render')) {
        const newTexture = await loader.loadAsync(asset.previews[0].url)
        width = newTexture.image.width
        height = newTexture.image.height
        width = (width * 30) / 1024
        height = (height * 30) / 1024
        material = new THREE.MeshBasicMaterial({
          map: newTexture
        })
      } else if (asset.previews.length > 1 && asset.previews[1].type === 'video') {
        console.log('video')
        const video = document.createElement('video')
        video.crossOrigin = 'Anonymous'
        video.src = asset.previews[1].url
        video.autoplay = true
        video.muted = 'muted'
        video.loop = true
        await video.load()
        await video.loadeddata
        this.videos.push(video)
        const newTexture = new THREE.VideoTexture(video)
        console.log(newTexture)
        width = newTexture.image.videoWidth || '720'
        height = newTexture.image.videoHeight || '1080'
        width = (width * 30) / 1024
        height = (height * 30) / 1024
        material = new THREE.MeshBasicMaterial({
          map: newTexture
        })
      } else if (this.renderObject && asset.previews.length > 1 && asset.previews[1].type === 'render') {
        const loader = new GLTFLoader()
        await loader.load(asset.previews[1].url, async (gltf) => {
          console.log(gltf)
          const picture = gltf.scene
          picture.add(this.createLightDirectional(picture))
          const clips = gltf.animations || []
          // picture.scale.multiplyScalar(2)
          picture.rotation.y = rotate
          picture.position.x = x
          picture.position.y = 25
          picture.position.z = z
          picture.castShadow = true
          picture.receiveShadow = true
          picture.name = 'picture'
          picture.userData = {
            vector: rotate === 0 ? 'z' : 'x',
            asset: asset,
            index: index
          }
          this.arts.push(picture)
          this.scene.add(picture)
          if (clips.length > 0) {
            const mixer = new THREE.AnimationMixer(gltf.scene)
            clips.forEach((clip) => {
              mixer.clipAction(clip).reset().play()
            })
            this.mixer.push(mixer)
          }
          return picture
        })
        return
      }
      const picture = new THREE.Mesh(new THREE.BoxGeometry(width, height, 1), material)
      picture.rotation.y = rotate
      picture.position.x = x
      picture.position.y = 25
      picture.position.z = z
      picture.castShadow = true
      picture.receiveShadow = true
      picture.name = 'picture'
      picture.userData = {
        vector: rotate === 0 ? 'z' : 'x',
        asset: asset,
        index: index
      }
      this.arts.push(picture)
      return picture
    },
    async createPlate (asset, index, x, z, rotate = 0) {
      const loader = new THREE.TextureLoader()
      loader.crossOrigin = 'Anonymous'

      const newTexture = await loader.loadAsync(require('@/assets/plate.png'))
      let width = newTexture.image.width
      let height = newTexture.image.height
      width = 2
      height = 1
      const material = new THREE.MeshBasicMaterial({
        map: newTexture
      })
      const picture = new THREE.Mesh(new THREE.BoxGeometry(width, height, 0.2), material)
      picture.rotation.y = rotate
      picture.position.x = x
      picture.position.y = 8
      picture.position.z = z
      picture.castShadow = true
      picture.receiveShadow = true
      picture.name = 'plate'
      picture.userData = {
        asset: asset,
        index: index
      }
      this.plate.push(picture)
      return picture
    },
    onClick (event) {
      this.$store.state.modeTour = false
      console.log('lock')
      this.videos.forEach(item => {
        item.play()
      })
      if (!this.controls.isLocked) {
        if (this.$device.ios || this.$device.android) {
          this.controls.lockMobile()
        } else {
          this.controls.lock()
        }
        this.text = 'Ready'
        this.snackbar = true
        this.ready = true
      } else {
        // const renderBox = document.getElementById('render-box')
        if (this.$device.ios || this.$device.android) {
          this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1
          this.pointer.y = -((event.clientY + 28) / window.innerHeight) * 2 + 1
        }
        this.raycaster.setFromCamera(this.pointer, this.camera)
        const intersections = this.raycaster.intersectObjects([...this.arts, ...this.plate], false)
        if (intersections.length > 0) {
          if (intersections[0].object.name === 'picture') {
            const intersect = intersections[0]
            this.destiny = intersect.object
            this.startMove = false
            this.$store.state.modeTour = false
            this.$store.state.modeVR = false
          }
          if (intersections[0].object.name === 'plate') {
            const intersect = intersections[0]
            this.showArt(intersect.object.userData.asset, intersect.object.userData.index)
          }
        }
      }
    },
    onPointerMove (event) {
      event.preventDefault()
      // const renderBox = document.getElementById('render-box')
      this.pointer.x = 0
      this.pointer.y = 0
      this.raycaster.setFromCamera(this.pointer, this.camera)
      const intersections = this.raycaster.intersectObjects([...this.arts, ...this.plate], false)

      if (intersections.length > 0) {
        if (intersections[0].object.name === 'picture' || intersections[0].object.name === 'plate') {
          const intersect = intersections[0]

          const geo = new THREE.EdgesGeometry(intersect.object.geometry)
          this.line.geometry.copy(geo)

          intersect.object.updateMatrix()

          this.line.geometry.applyMatrix4(intersect.object.matrix)
          if (!this.renderObject) {
            this.line.visible = true
          }
        } else {
          this.line.visible = false
        }
      } else {
        this.line.visible = false
      }
    },
    onKeyUp (event) {
      switch (event.code) {
        case 'ArrowUp':
        case 'KeyW':
          this.moveForward = false
          break
        case 'ArrowLeft':
        case 'KeyA':
          this.moveLeft = false
          break
        case 'ArrowDown':
        case 'KeyS':
          this.moveBackward = false
          break
        case 'ArrowRight':
        case 'KeyD':
          this.moveRight = false
          break
      }
    },
    onKeyDown (event) {
      switch (event.code) {
        case 'ArrowUp':
        case 'KeyW':
          this.moveForward = true
          break
        case 'ArrowLeft':
        case 'KeyA':
          this.moveLeft = true
          break
        case 'ArrowDown':
        case 'KeyS':
          this.moveBackward = true
          break
        case 'ArrowRight':
        case 'KeyD':
          this.moveRight = true
          break
        case 'Space':
          if (this.canJump === true) this.velocity.y += 350
          this.canJump = false
          break
      }
    },
    animate () {
      // requestAnimationFrame(this.animate)
      const time = performance.now()
      if (this.controls.isLocked === true) {
        if (this.destiny) {
          const deltaX = this.destiny.userData.vector === 'x' ? this.destiny.position.x > this.camera.position.x ? -30 : 30 : 0
          const deltaZ = this.destiny.userData.vector === 'z' ? this.destiny.position.z > this.camera.position.z ? -30 : 30 : 0
          const position = {
            x: this.destiny.position.x + deltaX,
            y: this.destiny.position.y,
            z: this.destiny.position.z + deltaZ
          }
          this.camera.position.lerp(position, 0.04)
          if (this.modeTour) {
            this.camera.lookAt(this.destiny.position)
          } else {
            this.camera.lookAt(this.destiny.position)
          }
          if (Math.abs(this.destiny.position.x + deltaX - this.camera.position.x) < 1 && Math.abs(this.destiny.position.z + deltaZ - this.camera.position.z) < 1) {
            if (!this.$store.state.modeTour) {
              this.showArt(this.destiny.userData.asset, this.destiny.userData.index)
            }
            this.destiny = false
          }
          this.startMove = true
        }
        const delta = (time - this.prevTime) / 1000

        for (const mixer of this.mixer) {
          mixer && mixer.update(delta)
        }

        this.velocity.x -= this.velocity.x * 10 * delta
        this.velocity.z -= this.velocity.z * 10 * delta

        this.velocity.y -= 9.8 * 100.0 * delta // 100.0 = mass

        this.direction.z = Number(this.moveForward) - Number(this.moveBackward)
        this.direction.x = Number(this.moveRight) - Number(this.moveLeft)
        this.direction.normalize() // this ensures consistent movements in all directions

        if (this.moveForward || this.moveBackward) this.velocity.z -= this.direction.z * this.speed * delta
        if (this.moveLeft || this.moveRight) this.velocity.x -= this.direction.x * this.speed * delta

        this.controls.moveRight(-this.velocity.x * delta)
        this.controls.moveForward(-this.velocity.z * delta)
        if (this.camera.position.x < this.limit.minx || this.camera.position.x > this.limit.maxx) {
          this.controls.moveRight(this.velocity.x * delta)
          this.controls.moveForward(this.velocity.z * delta)
        }
        if (this.camera.position.z < this.limit.minz || this.camera.position.z > this.limit.maxz) {
          this.controls.moveRight(this.velocity.x * delta)
          this.controls.moveForward(this.velocity.z * delta)
        }
        this.controls.getObject().position.y += (this.velocity.y * delta) // new behavior
      }

      this.prevTime = time
      this.camera.position.y = 20
      TWEEN.update()
      this.renderer.render(this.scene, this.camera)
    }
  }
}
</script>
