<template>
  <div class="CreateCampaign">
    <div v-if="!loading && !loadingCreate" class="float-menu">
      <v-btn class="closeButtonIcon mr-5" to="/campaign" x-small fab color="#ffffff55">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn rounded :disabled="name.length === 0 || description.length === 0 || title.length === 0" color="#70AFD0" class="mr-5" @click="create">Create</v-btn>
    </div>
    <Nav />
    <v-container fluid v-if="loadingCreate" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loadingCreate"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="fill-height" v-if="!loadingCreate">
      <v-row class="pt-10">
        <v-col cols="1"></v-col>
        <v-col align-self="center" align="center">
          <div class="titleSection text-h3" align="left">Create Campaign</div>
          <div class="font-weight-thin title mb-5 mt-2" align="left">
            You could share the link of your campaign on social networks
          </div>
          <v-text-field v-model="name" label="Campaign Name" filled rounded></v-text-field>
          <v-text-field v-model="title" label="Campaign Title" filled rounded></v-text-field>
          <v-textarea v-model="description" label="Description" filled rounded></v-textarea>
          <v-combobox
            v-model="keywords"
            item-color="color"
            item-text="value"
            chips
            clearable
            label="Keywords"
            multiple
            filled rounded
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                color="#70AFD0"
                text-color="white"
                @click:close="removeKeyword(item)"
              >
                <strong>{{ item }}</strong>
              </v-chip>
            </template>
          </v-combobox>
          <div v-if="$vuetify.breakpoint.width < 960">
            <v-text-field v-model="startDate" label="Start Date" filled rounded type="date" style="min-width: 300px"></v-text-field>
            <v-text-field v-model="endDate" label="End Date" filled rounded type="date" style="min-width: 300px"></v-text-field>
            <v-select label="Mode" :items="modes" item-text="name" item-value="code" v-model="mode.select" filled rounded></v-select>
            <v-text-field v-if="mode.select === 'amount'" v-model="mode.amount" label="Discount Amount" filled rounded type="number" style="min-width: 300px" prefix="$"></v-text-field>
            <v-text-field class="ml-2" v-if="mode.select === 'percent'" v-model="mode.percent" label="Discount Percent" filled rounded type="number" min="0" max="100" style="min-width: 300px" suffix="%"></v-text-field>
          </div>
          <div class="d-inline-flex" v-if="$vuetify.breakpoint.width >= 960">
            <v-text-field v-model="startDate" label="Start Date" filled rounded type="date" style="min-width: 300px"></v-text-field>
            <v-text-field v-model="endDate" label="End Date" filled rounded type="date" style="min-width: 300px"></v-text-field>
          </div>
          <div></div>
          <div class="d-inline-flex" v-if="$vuetify.breakpoint.width >= 960">
            <v-select label="Mode" :items="modes" item-text="name" item-value="code" v-model="mode.select" filled rounded></v-select>
            <v-text-field v-if="mode.select === 'amount'" v-model="mode.amount" label="Discount Amount" filled rounded type="number" style="min-width: 300px" prefix="$"></v-text-field>
            <v-text-field class="ml-2" v-if="mode.select === 'percent'" v-model="mode.percent" label="Discount Percent" filled rounded type="number" min="0" max="100" style="min-width: 300px" suffix="%"></v-text-field>
          </div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.width < 960 ? 12 : 3" class="mr-10 mt-16">
          <div class="galleryTemplate mt-11">
            <div align="center" class="text-h5">Preview Image</div>
            <div align="center">
              <v-img v-if="preview != null" contain :src="preview" width="100%" height="100%" max-width="400" max-height="400" class="rounded-lg my-5"></v-img>
            </div>
            <div align="center">
              <v-btn class="my-5" @click="$refs.inputAvatar.click()" rounded color="#70AFD0" dark large><v-icon class="mx-2">mdi-upload</v-icon><span v-if="$vuetify.breakpoint.width > 1280">Upload from your device</span></v-btn>
            </div>
            <div class="font-weight-thin title" align="center">
              This image will appear in the metadata of the link you share
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="left" class="titleSection text-h4 ml-15 pl-10 mt-10">
          Available Published
        </v-col>
      </v-row>
      <v-row v-if="loading">
          <v-col align-self="center" align="center">
            <v-progress-circular v-if="loading"
                                 indeterminate
                                 color="black lighten-5"
                                 size="128"
                                 width="15"
            ></v-progress-circular>
          </v-col>
      </v-row>
      <v-row v-if="collections.length === 0 && !loading">
        <v-col align-self="center" align="center" class="title">
          <div>OOPS!! You don't have publishes available</div>
          <div class="overline mt-2">
            First you need create a publish
          </div>
        </v-col>
      </v-row>
      <v-row v-if="collections.length > 0 && !loading" class="mx-15">
        <v-col v-for="(collection, index) in collections" :key="index" align-self="center" align="center" :cols="$vuetify.breakpoint.width > 1900 ? 3 : $vuetify.breakpoint.width > 1400 ? 4 : $vuetify.breakpoint.width > 960 ? 6 : 12">
          <v-container class="Card">
            <v-row dense>
              <v-col align-self="center" align="center">
                <div class="d-inline-flex CardImages">
                  <v-img v-for="(preview, index) in collection.previews" v-bind:key="index" :src="preview" height="150px" width="150px" ></v-img>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col align-self="center" align="left">
                <div class="mr-2">
                  <div class="CardMain">{{ collection.name }}</div>
                  <div class="CardSub">{{ collection.assets.length }} pieces / {{ collection.visit ? collection.visit : 0  }} views</div>
                </div>
              </v-col>
              <v-col align-self="center" align="right">
                <div class="d-inline-flex">
                  <div class="mr-2">
                    <div class="CardSub">Created</div>
                    <div class="CardSub" style="font-weight: bolder">{{ new Date(collection.created * 1000).toLocaleString() }}</div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col align-self="center" align="center">
                <div class="CardActions">
                  <div v-if="collection.exhibition" class="text-h5 pt-3 font-weight-thin">
                    Exhibition
                  </div>
                  <div class="d-inline-flex" v-if="!collection.exhibition">
                    <div class="text-h4 pt-1 font-weight-light">
                      ${{ collection.price }}
                    </div>
                    <div class="ml-1">
                      <div class="text-h5 pt-3 font-weight-thin">{{ currencies.find(item => item.code === collection.currency).name }}</div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col align="center">
                <v-switch class="switch mx-5" v-model="collection.selected" label="Selected" style="width: 120px">
                </v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Successful creation
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_CreateCampaign.scss'
import assets from '../../../mixins/assets'
import Nav from '../../../components/Nav/Nav'
import { Picsum } from 'picsum-photos'
import moment from 'moment'
export default {
  name: 'CreateCampaign',
  components: { Nav },
  props: {
    asset: Object
  },
  data () {
    return {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      preview: null,
      exhibition: false,
      readyItems: false,
      snackbar: false,
      name: '',
      title: '',
      description: '',
      price: 0,
      individual: false,
      publicAccess: false,
      currency: 't4g',
      template: '00',
      collections: [],
      category: null,
      keywords: [],
      categories: ['General', 'Rocarbon'],
      templates: [
        { code: '00', src: require('@/assets/templates/00.png') },
        { code: '01', src: require('@/assets/templates/01.png') },
        { code: '02', src: require('@/assets/templates/02.png') },
        { code: '03', src: require('@/assets/templates/03.png') },
        { code: '04', src: require('@/assets/templates/04.png') },
        { code: '06', src: require('@/assets/templates/06.png') },
        { code: '07', src: require('@/assets/templates/07.png') },
        { code: '09', src: require('@/assets/templates/09.png') },
        { code: '013', src: require('@/assets/templates/013.png') },
        { code: '011', src: require('@/assets/templates/011.png') },
        { code: '014', src: require('@/assets/templates/014.png') },
        { code: '017', src: require('@/assets/templates/017.png') },
        { code: '018', src: require('@/assets/templates/018.png') },
        { code: '019', src: require('@/assets/templates/019.png') },
        { code: '020', src: require('@/assets/templates/020.png') }
        /*
        { code: '05', src: require('@/assets/templates/05.png') },
        { code: '06', src: require('@/assets/templates/06.png') },
        { code: '07', src: require('@/assets/templates/07.png') },
        { code: '09', src: require('@/assets/templates/09.png') },
        { code: '010', src: require('@/assets/templates/010.png') },
        { code: '011', src: require('@/assets/templates/011.png') },
        { code: '012', src: require('@/assets/templates/012.png') },
        { code: '013', src: require('@/assets/templates/013.png') }
        * */
      ],
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      modes: [
        { code: 'percent', name: 'Discount by Percent' },
        { code: 'amount', name: 'Discount by Amount' },
        { code: 'link', name: 'Only Share Link' }
      ],
      mode: {
        select: null,
        amount: null,
        percent: null
      },
      loading: false,
      loadingCreate: false
    }
  },
  async mounted () {
    const picture = await Picsum.random()
    this.preview = picture.download_url
    console.log(this.preview)
    if (this.asset) {
      this.name = this.asset.name
      this.description = this.asset.description
    }
    this.getCollections()
  },
  updated () {
    console.log(this.startDate)
  },
  mixins: [assets],
  watch: {
    loading (val) {
      if (!val) {
        setTimeout(() => {
          for (const asset of this.assets) {
            console.log(asset)
            this.checkSelected(asset)
          }
        }, 500)
      }
    }
  },
  methods: {
    updateSelected (asset) {
      asset.selected = !asset.selected
      this.$forceUpdate()
    },
    removeKeyword (item) {
      this.keywords.splice(this.keywords.indexOf(item), 1)
      this.keywords = [...this.keywords]
    },
    modeExhibition () {
      this.individual = false
    },
    changeIndividual () {
      console.log('change')
      if (this.individual) {
        let readyItems = true
        for (const asset of this.assets) {
          if (!asset.price && asset.selected) {
            asset.price = 0
            asset.available = 1
            readyItems = false
          }
        }
        this.readyItems = readyItems
      }
    },
    checkSelected (asset) {
      console.log('load')
      if (this.asset) {
        if (asset.hashInternal === this.asset.hashInternal) {
          console.log('mash')
          asset.selected = true
          this.$forceUpdate()
        }
      }
    },
    getCollections () {
      this.$axios.get('/api/collections/getCollections', {
        params: {
          identity: this.$store.state.identity,
          crc: Date.now()
        }
      }).then((result) => {
        console.log(result)
        for (const collection in result.data.collections) {
          const item = result.data.collections[collection]
          item.key = collection
          item.previews = []
          for (const asset of item.assets) {
            this.$axios.get('/api/assets/getAssetInfo', {
              params: {
                hash: asset,
                crc: parseInt(Date.now() / 100000)
              }
            }).then((result) => {
              for (const preview of result.data.asset.previews) {
                if (preview.type === 'image') {
                  item.previews.push(preview.url)
                }
              }
              this.$forceUpdate()
              console.log(this.collections)
            })
          }
          this.collections.push(item)
        }
        this.loading = false
      })
    },
    fixAvailable (asset) {
      asset.available = Number(asset.available).toFixed(0)
      if (asset.available <= 0) {
        asset.available = 1
      }
      if (asset.available >= asset.balance - asset.inUsed) {
        asset.available = asset.balance - asset.inUsed
      }
      this.$forceUpdate()
    },
    create () {
      this.loadingCreate = true
      const campaign = {
        name: this.name,
        description: this.description,
        keywords: this.keywords,
        startDate: this.startDate,
        endDate: this.endDate,
        mode: this.mode,
        amount: this.amount,
        discount: this.discount,
        preview: this.preview,
        collections: []
      }
      for (const collection of this.collections) {
        if (collection.selected) {
          campaign.collections.push(collection.key)
        }
      }
      this.$axios.post('/api/campaign/createCampaign', {
        identity: this.$store.state.identity,
        campaign: campaign,
        params: {
          crc: Date.now()
        }
      }).then((result) => {
        this.loadingCreate = false
        this.snackbar = true
        setTimeout(() => {
          this.$router.push({ name: 'Campaign' })
        }, 1000)
      })
    }
  }
}
</script>
