<template>
    <div class="FooterMenuComponent">
        <v-container fluid>
          <v-row>
            <v-col align-self="flex-start" align="left" >
              <div class="title pl-5 mb-3">Explore</div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">All NFT's</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Departments</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Trending</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Partners</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Activity</v-btn></div>
            </v-col>
            <v-col align-self="flex-start" align="left">
              <div class="title pl-5 mb-3">Create</div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Get Started</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Plan</v-btn></div>
            </v-col>
            <v-col align-self="flex-start" align="left">
              <div class="title pl-5 mb-3">Company</div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">About</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Empowerment</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Products</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Careers</v-btn></div>
            </v-col>
            <v-col align-self="flex-start" align="left">
              <div class="title pl-5 mb-3">Profile</div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">ID4Good</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Wallet4Good</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Account</v-btn></div>
              <div><v-btn disabled height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Assets</v-btn></div>
            </v-col>
            <v-col align-self="flex-start" align="left">
              <div class="title pl-5 mb-3">Resources</div>
              <div><v-btn :disabled="!helpCenter" :to="helpCenter" height="32" :x-large="$vuetify.breakpoint.lgAndUp" @click="scroll('helpCenter')" plain dark class="pl-5 text-capitalize">Help Center</v-btn></div>
              <div><v-btn :disabled="!contact" :to="contact" height="32" :x-large="$vuetify.breakpoint.lgAndUp" @click="scroll('contact')" plain dark class="pl-5 text-capitalize">Contact</v-btn></div>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_FooterMenuComponent.scss'
export default {
  name: 'FooterMenuComponent',
  data () {
    return {
      contact: false,
      helpCenter: false
    }
  },
  mounted () {
    if (this.$store.state.context === 'SlapItOn') {
      this.contact = '/m/slapiton/help?contact'
      this.helpCenter = '/m/slapiton/help'
    }
    if (this.$store.state.context === 'Aquarium') {
      this.contact = '/m/aquarium/help?contact'
      this.helpCenter = '/m/aquarium/help'
    }
  },
  methods: {
    scroll (id) {
      setTimeout(() => {
        const item = document.getElementById(id)
        if (item === undefined) {
          return
        }
        item.scrollIntoView({
          behavior: 'smooth', block: 'nearest'
        })
      }, 1500)
    }
  }
}
</script>
