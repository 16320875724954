<template>
  <div class="Cash">
    <Nav />
    <div v-if="!loading" class="float-menu">
      <v-btn outlined class="mr-5">Create Report</v-btn>
    </div>
    <v-container fuild v-if="collections.length === 0 && !loading" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center" class="title">
          <div>OOPS!! You don't have income</div>
          <div class="overline mt-2">
            You must first create collections to publish to generate income
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="loading" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_Cash.scss'
import Nav from '../../components/Nav/Nav'

export default {
  name: 'Cash',
  components: { Nav },
  data () {
    return {
      loading: false,
      collections: []
    }
  },
  mounted () {
    this.loading = true
    this.loading = false
  }
}
</script>
