<template>
  <div class="Rocarbon">
    <v-container fluid v-if="loading" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="!loading">
      <v-row>
        <v-col align-self="center" align="center" class="title mt-10">
          <v-img :src="require('@/assets/ROCx.png')" contain height="75px"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
        <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.width < 600 ? 6 : ''"><v-select hide-details multiple :items="tags.type" v-model="selectTags.type" label="Type" outlined style="max-width: 200px">
          <template v-slot:append>
            <v-icon color="#00cca6">mdi-numeric-{{ tags.type.length }}-box</v-icon>
          </template>
        </v-select></v-col>
        <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.width < 600 ? 6 : ''"><v-select hide-details multiple :items="tags.sdg" v-model="selectTags.sdg" label="SDGs" outlined style="max-width: 200px">
          <template v-slot:append>
            <v-icon color="#ffb634">mdi-numeric-{{ tags.sdg.length }}-box</v-icon>
          </template>
        </v-select></v-col>
        <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.width < 600 ? 6 : ''"><v-select hide-details multiple :items="tags.location" v-model="selectTags.location" label="Location" outlined style="max-width: 200px">
          <template v-slot:append>
            <v-icon color="#0da488">mdi-numeric-{{ tags.location.length }}-box</v-icon>
          </template>
        </v-select></v-col>
        <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.width < 600 ? 6 : ''"><v-select hide-details multiple :items="tags.price" v-model="selectTags.price" label="Price" outlined style="max-width: 200px">
          <template v-slot:append>
            <v-icon color="#2f4859">mdi-numeric-{{ tags.price.length }}-box</v-icon>
          </template>
        </v-select></v-col>
        <v-col cols="3"  v-if="$vuetify.breakpoint.lgAndUp"></v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <div class="tags">
            <span v-for="(item, idx) in tags.sdg" :index="idx" :key="idx" :style="{'font-size': '1rem !important', 'background-color': sdgColors[item.toLowerCase().replace('-', '')] }">{{ item.trim() }}</span>
            <span v-for="(item, idx) in tags.price" :index="idx" :key="idx" :style="{'font-size': '1rem !important', 'background-color': '#2f4859' }">USD ${{ item }}/tonne CO<sub>2</sub>e</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <div :style="{ with: size, height: size - 200}" v-if="viewMode" data-aos="zoom-in">
            <v-container fluid style="max-width: 900px">
              <v-row>
                <v-col
                  v-for="(asset, i) in filterAssets(RocarbonView)" :index="i" :key="i"
                  class="d-flex child-flex"
                  :cols="12"
                >
                  <v-card dense outlined class="rounded my-5 box-shadow Rocarbon" dark>
                    <v-card-text align="center" class="pa-0">
                      <v-container>
                        <v-row v-if="$device.mobile">
                          <v-col>
                            <div class="tags" align="left">
                              <v-tooltip top v-for="(item, idx) in asset.attr.Project.SDGAPIGoals" :index="'sdg'+idx" :key="'sdg'+idx">
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on" :style="{ 'background-color': sdgColors['sdg'+item.pubSDGAPIGoalCode] }">SDG-{{ item.pubSDGAPIGoalCode.trim() }}</span>
                                </template>
                                <span>{{ sdgDesc['sdg'+item.pubSDGAPIGoalCode] }}</span>
                              </v-tooltip>
                              <span v-for="(item, idx) in (asset.attr.Project.Tags).filter(obj => !obj.pubProjectTag.startsWith('SDG'))" :index="'t'+idx" :key="'t'+idx">{{ item.pubProjectTag.trim() }}</span>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row v-if="$device.mobile">
                          <v-col>
                            <div align="left" class="mt-3" :style="{ 'max-width': $vuetify.breakpoint.width < 500 ? '250px' : '350px' }">
                              <v-img class="shadow-currency shadow-currency-border back-2" v-if="asset.src" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.src" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 250 : 350" />
                              <v-img class="shadow-currency shadow-currency-border back-1" v-if="asset.src" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.src" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 250 : 350" />
                              <v-img class="shadow-currency shadow-currency-border back-0" v-if="asset.src" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.src" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 250 : 350" />
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.width >= 960 ? 6 : 12" class="pa-5">
                            <div class="tags" align="left" v-if="!$device.mobile">
                              <v-tooltip top v-for="(item, idx) in asset.attr.Project.SDGAPIGoals" :index="'sdg'+idx" :key="'sdg'+idx">
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on" :style="{ 'background-color': sdgColors['sdg'+item.pubSDGAPIGoalCode] }">SDG-{{ item.pubSDGAPIGoalCode.trim() }}</span>
                                </template>
                                <span>{{ sdgDesc['sdg'+item.pubSDGAPIGoalCode] }}</span>
                              </v-tooltip>
                              <span v-for="(item, idx) in (asset.attr.Project.Tags).filter(obj => !obj.pubProjectTag.startsWith('SDG'))" :index="'t'+idx" :key="'t'+idx">{{ item.pubProjectTag.trim() }}</span>
                            </div>
                            <div align="left" class="overline">
                          <span class="subtitle-1 " v-if="asset.attr.Project.pubProjectName.length < 128">
                            {{ asset.attr.Project.pubProjectName }}
                          </span>
                              <span class="subtitle-1" v-if="asset.attr.Project.pubProjectName.length >= 128">
                          {{ asset.attr.Project.pubProjectName.substring(0, 128) }}...
                        </span>
                            </div>
                            <div align="left" class="overline location-text mb-2" v-if="asset.metadata">
                              <span style="font-size: 0.8rem;">{{ asset.attr.Project.Sites[0].Regions[0].pubRegionStateProv }}, {{ asset.attr.Project.Sites[0].Regions[0].pubRegionCountry }}</span>
                            </div>
                            <div align="left" class="description-text" v-if="!$device.mobile && asset.attr && asset.attr.Project.pubProjectExcerpt.length < 368">
                              <span style="font-size: 1.2rem; font-weight: normal;">{{ asset.attr.Project.pubProjectExcerpt }}</span>
                            </div>
                            <div align="left" class="description-text" v-if="!$device.mobile && asset.metadata && asset.attr.Project.pubProjectExcerpt.length >= 368">
                              <span style="font-size: 1.2rem; font-weight: normal;">{{ asset.attr.Project.pubProjectExcerpt.substring(0, 368) }}...</span>
                            </div>
                            <div align="left" v-if="asset.metadata" class="mt-5 subtitle-2" style="font-size: 1.6rem; color: white">
                              <img :src="require('@/assets/roct.png')" width="20px" height="20px" />
                              USD ${{ asset.attr.pubRecommendedPricePerTonne }}/tonne CO<sub>2</sub>e
                            </div><v-btn plain align="left" style=" left: -115px;
margin-top: 10px;
cursor: pointer; font-size: 1.2rem; color: #ffb634; font-weight: bold" @click="showArt(asset.hash)">

                              Learn More &#x2192;
                            </v-btn>
                          </v-col>
                          <v-col align="center"  :class="{ 'pa-5': !$device.mobile, 'pt-15': !$device.mobile}" :cols="$vuetify.breakpoint.width >= 960 ? 6 : 12">
                            <v-container fluid>
                              <v-row v-if="!$device.mobile" dense>
                                <v-col align-self="center" align="center">
                                  <div align="left" :style="{ 'max-width': $vuetify.breakpoint.width < 500 ? '250px' : '350px' }">
                                    <v-img class="shadow-currency shadow-currency-border back-2" v-if="asset.src" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.src" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 250 : 350" />
                                    <v-img class="shadow-currency shadow-currency-border back-1" v-if="asset.src" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.src" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 250 : 350" />
                                    <v-img class="shadow-currency shadow-currency-border back-0" v-if="asset.src" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.src" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 250 : 350" />
                                  </div>
                                  <v-tooltip bottom v-if="asset.kind >= 3 && asset.kind < 10">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn @click="showAsset(asset)" class="mx-1"
                                             :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                             icon v-bind="attrs" v-on="on">
                                        <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-folder-information-outline</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Preview</span>
                                  </v-tooltip>
                                  <v-tooltip bottom v-if="asset.kind >= 3 && asset.kind < 10 && asset.kind != 5">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn @click="showAssetFiles(asset)" class="mx-1"
                                             :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                             icon v-bind="attrs" v-on="on">
                                        <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-file-settings-outline</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Files</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col>
                                  <div style="padding-top: 25px" class="text-no-wrap">
                                    <v-tooltip top v-for="(item, idx) in asset.attr.Project.SDGAPIGoals" :index="'sdg'+idx" :key="'sdg'+idx">
                                      <template v-slot:activator="{ on, attrs }">
                                        <img class="ma-1" v-bind="attrs" v-on="on" :src="require('@/assets/goals/' + item.pubSDGAPIGoalCode.trim() + '.png')" height="70px" width="70px" />
                                      </template>
                                      <span>{{ sdgDesc['sdg'+item.pubSDGAPIGoalCode] }}</span>
                                    </v-tooltip>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col></v-col>
        <v-col align-self="center" align="center" class="overline title mt-5 mb-10">
          <v-img :src="require('@/assets/rocarbon5.png')" contain height="50px"/>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-container>
    <v-dialog fullscreen v-model="dialogArt" v-if="dialogArt" content-class="Rocarbon menu-margin">
      <v-container fluid class="fill-height dialog-art">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="content-box">
              <v-container :class="$vuetify.breakpoint.mdAndUp ? 'mx-5' : ''">
                <v-row>
                  <v-col align-self="center" align="center" class="overline title mt-5">
                    <v-img :src="require('@/assets/ROCx.png')" contain height="100px"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="tags" align="left">
                      <v-tooltip bottom v-for="(item, idx) in art.attr.Project.SDGAPIGoals" :index="'sdg'+idx" :key="'sdg'+idx">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" :style="{ 'background-color': sdgColors['sdg'+item.pubSDGAPIGoalCode] }">SDG-{{ item.pubSDGAPIGoalCode.trim() }}</span>
                        </template>
                        <span>{{ sdgDesc['sdg'+item.pubSDGAPIGoalCode] }}</span>
                      </v-tooltip>
                      <span v-for="(item, idx) in (art.attr.Project.Tags).filter(obj => !obj.pubProjectTag.startsWith('SDG'))" :index="'t'+idx" :key="'t'+idx">{{ item.pubProjectTag.trim() }}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="$device.mobile">
                  <v-col align="right">
                    <div class="subtitle-1 text-uppercase" style="color: #2f4758; font-weight: 100 !important; font-size: 1.2rem !important;">{{ art.attr.Project.Sites[0].Regions[0].pubRegionStateProv }}, {{ art.attr.Project.Sites[0].Regions[0].pubRegionCountry }}</div>
                    <div class="subtitle-1 text-uppercase mb-6" style="color: #2f4758; font-weight: 100 !important; font-size: 1.2rem !important;">{{ (art.attr.Project.pubProjectStartDate) }}</div>
                  </v-col>
                </v-row>
                <v-row v-if="$device.mobile">
                  <v-col align-self="center" align="center">
                    <div align="left" :style="{ 'max-width': $vuetify.breakpoint.width < 500 ? '300px' : '400px' }">
                      <v-img class="shadow-currency shadow-currency-border back-2" v-if="art.previews[currentDisplay].url" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="art.previews[currentDisplay].url" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                      <v-img class="shadow-currency shadow-currency-border back-1" v-if="art.previews[currentDisplay].url" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="art.previews[currentDisplay].url" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                      <v-img class="shadow-currency shadow-currency-border back-0" v-if="art.previews[currentDisplay].url" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="art.previews[currentDisplay].url" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <div class="subtitle-1 my-3" style="color: #2f4758;  line-height: 2rem !important;">
                      {{ art.attr.Project.pubProjectName }} - {{ art.attr.Project.pubOrganizationName }}
                      <br>
                      {{ art.attr.Project.Sites[0].pubSiteName }}
                    </div>
                    <div v-if="!$device.mobile" class="subtitle-1 text-uppercase" style="color: #2f4758; font-weight: 100 !important; font-size: 1.2rem !important;">{{ art.attr.Project.Sites[0].Regions[0].pubRegionStateProv }}, {{ art.attr.Project.Sites[0].Regions[0].pubRegionCountry }}</div>
                    <div v-if="!$device.mobile" class="subtitle-1 text-uppercase mb-6" style="color: #2f4758; font-weight: 100 !important; font-size: 1.2rem !important;">{{ (art.attr.Project.pubProjectStartDate) }}</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <div class="description-text" style="color: black; font-size: 1.2rem !important; font-weight: 100 !important; line-height: unset">{{ art.attr.Project.pubProjectDetails }}</div>
                    <div class="description-text my-4" style="color: black; font-size: 1.2rem !important; font-weight: 100 !important; line-height: unset">{{ art.attr.Project.Sites[0].pubSiteDescription }}</div>
                    <div class="description-text my-4" style="color: black; font-size: 1.2rem !important; font-weight: 100 !important; line-height: unset">Carbon Storage: {{ art.attr.Project.pubCarbonStorage }}</div>
                    <div class="description-text my-4" style="color: black; font-size: 1.2rem !important; font-weight: 100 !important; line-height: unset">Project Type: {{ art.attr.Project.pubProjectTypeMethod }}</div>
                  </v-col>
                  <v-col>
                    <v-container>
                      <v-row v-if="!$device.mobile">
                        <v-col align-self="center" align="center">
                          <div align="left" :style="{ 'max-width': $vuetify.breakpoint.width < 500 ? '300px' : '400px' }">
                            <v-img class="shadow-currency shadow-currency-border back-2" v-if="art.previews[currentDisplay].url" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="art.previews[currentDisplay].url" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                            <v-img class="shadow-currency shadow-currency-border back-1" v-if="art.previews[currentDisplay].url" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="art.previews[currentDisplay].url" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                            <v-img class="shadow-currency shadow-currency-border back-0" v-if="art.previews[currentDisplay].url" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="art.previews[currentDisplay].url" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <div class="text-no-wrap">
                            <v-tooltip top v-for="(item, idx) in art.attr.Project.SDGAPIGoals" :index="'sdg'+idx" :key="'sdg'+idx">
                              <template v-slot:activator="{ on, attrs }">
                                <img class="ma-1" v-bind="attrs" v-on="on" :src="require('@/assets/goals/' + item.pubSDGAPIGoalCode.trim() + '.png')" height="70px" width="70px" />
                              </template>
                              <span>{{ sdgDesc['sdg'+item.pubSDGAPIGoalCode] }}</span>
                            </v-tooltip>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-10">
                    <div class="Rocarbon mx-10">
                      <v-container class="px-10">
                        <v-row dense>
                          <v-col align-self="center">
                            <div :align="$vuetify.breakpoint.mdAndUp ? 'left' : 'center'" class="subtitle-2 mt-10 mb-10" style="font-size: 1.4rem !important; font-weight: bold; color: white;">
                              <img :src="require('@/assets/roct.png')" width="20px" height="20px" />
                              USD ${{ art.attr.pubRecommendedPricePerTonne }}/tonne CO<sub>2</sub>e
                            </div>
                          </v-col>
                          <v-col :align="$vuetify.breakpoint.mdAndUp ? 'right' : 'center'" align-self="center" :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12">
                            <v-container fluid style="font-size: 1.3rem">
                              <v-row dense v-if="!art.exhibition">
                                <v-col align-self="center" :align="$vuetify.breakpoint.mdAndUp ? 'right' : 'center'" class="font-weight-thin" style="font-weight: 400 !important">
                                  $ {{ sell.price | numFormat('0,0.00') }} {{ currencies.find(item => item.code === art.currency).name }}
                                </v-col>
                              </v-row>
                              <v-row dense v-if="!art.exhibition">
                                <v-col v-if="balance" align-self="center" :align="$vuetify.breakpoint.mdAndUp ? 'right' : 'center'" class="" style="font-weight: 800 !important">

                                  <v-text-field flat style="width: 200px; font-size: 1.4rem !important;" dark suffix="Tonne" dense v-model="sell.roctInput" v-on:input="updatePriceInput">
                                    <template v-slot:prepend>
                                    <img :src="require('@/assets/roct.png')" width="24px" height="24px" />
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col align-self="center" align="center" class="overline">
                                  Select Amount
                                  <v-slider
                                    dark
                                    thumb-color="orange"
                                    :thumb-size="36"
                                    v-model="sell.roct"
                                    :max="balance.roct"
                                    :min="0.001"
                                    :step="0.001"
                                    @change="updatePrice"
                                    thumb-label
                                  ></v-slider>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-col>
                          <v-col v-if="!art.exhibition" align-self="center" align="center" :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12">
                            <v-img width="150" height="150" contain  style="border-radius: 10px" :src="art.sold === 'sold' || art.sold ? require('@/assets/sold.png') : art.qr"></v-img>
                            <v-btn :disabled="art.sold === 'sold' || art.sold" v-if="art.collectionCount == 1 || art.individual" color="orange" class="overline mt-1 text-uppercase" small :href="art.buy">buy now</v-btn>
                            <v-btn :disabled="art.sold === 'sold' || art.sold" v-if="art.collectionCount > 1 && !art.individual" color="orange" class="overline mt-1 text-uppercase" small :href="art.buy">buy collection now</v-btn>
<!--                            <v-btn :disabled="art.sold === 'sold' || art.sold" v-if="$store.state.instantPay" @click="getInstantPayment" color="green" dark icon><v-icon>mdi-credit-card-settings-outline</v-icon></v-btn>-->
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row>
                  <v-col align-self="center">
<!--                    <v-btn class="mx-2" dark :href="art.link" v-if="art.collectionCount > 1">Go to Collection</v-btn>
                    <v-btn class="mx-2" dark :href="art.link" v-if="art.collectionCount == 1">Go to Sale</v-btn>-->
                    <v-btn fab fixed top right :x-small="$device.mobile" class="mx-2" style="position: fixed" @click="dialogArt = false">X</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog fullscreen v-model="dialogFullscreen" v-if="dialogFullscreen" content-class="Collection">
      <v-container fluid class="fill-height dialog-art">
        <v-row>
          <v-col align-self="center" align="center">
            <v-img :src="art.previews[currentDisplay].url" width="100%" height="100%" max-width="1024px" max-height="1024px" contain></v-img>
            <v-btn icon @click="dialogFullscreen = false"><v-icon>mdi-fullscreen-exit</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import './_Rocarbon.scss'
import assets from '../../mixins/assets'
import { generateQR } from 'id4good.auth'
import instant from '../../mixins/instant'

export default {
  name: 'Rocarbon',
  data () {
    return {
      dialogArt: false,
      viewMode: true,
      dialogFullscreen: false,
      art: null,
      top: [],
      recent: [],
      balance: null,
      sdgDesc: {
        sdg1: 'Goal 1 - No Poverty: End poverty in all its forms everywhere.',
        sdg2: 'Goal 2 - Zero Hunger: End hunger, achieve food security and improved nutrition and promote sustainable agriculture.',
        sdg3: 'Goal 3 - Good Health and Well-Being: Ensure healthy lives and promote well-being for all at all ages.',
        sdg4: 'Goal 4 - Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
        sdg5: 'Goal 5 - Gender Equality: Achieve gender equality and empower all women and girls.',
        sdg6: 'Goal 6 - Clean Water and Sanitation: Ensure availability and sustainable management of water and sanitation for all.',
        sdg7: 'Goal 7 - Affordable and Clean Energy: Ensure access to affordable, reliable, sustainable and modern energy for all.',
        sdg8: 'Goal 8: Decent Work and Economic Growth: Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.',
        sdg9: 'Goal 9 - Industry, Innovation, and Infrastructure: Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.',
        sdg10: 'Goal 10 - Reduced Inequalities: Reduce inequality within and among countries.',
        sdg11: 'Goal 11 - Sustainable Cities and Communities: Make cities and human settlements inclusive, safe, resilient and sustainable.',
        sdg12: 'Goal 12 - Responsible Consumption and Production: Ensure sustainable consumption and production patterns.',
        sdg13: 'Goal 13 - Climate Action: Take urgent action to combat climate change and its impacts.',
        sdg14: 'Goal 14 - Life Below Water: Conserve and sustainably use the oceans, seas and marine resources for sustainable development.',
        sdg15: 'Goal 15 - Life on Land: Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.',
        sdg16: 'Goal 16 - Peace, Justice, and Strong Institutions: Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.',
        sdg17: 'Goal 17 - Partnerships for the Goals: Strengthen the means of implementation and revitalize the global partnership for sustainable development.'
      },
      sdgColors: {
        sdg1: '#e5243b',
        sdg2: '#dda63a',
        sdg3: '#4c9f38',
        sdg4: '#c5192d',
        sdg5: '#ff3a21',
        sdg6: '#26bde2',
        sdg7: '#fcc30b',
        sdg8: '#a21942',
        sdg9: '#fd6925',
        sdg10: '#dd1367',
        sdg11: '#fd9d24',
        sdg12: '#bf8b2e',
        sdg13: '#3f7e44',
        sdg14: '#0a97d9',
        sdg15: '#56c02b',
        sdg16: '#00689d',
        sdg17: '#19486a'
      },
      sell: {
        price: 0,
        roct: 0,
        roctInput: 0
      },
      paymentURL: null,
      assets: {},
      loading: true,
      currentDisplay: 0,
      selectTags: {
        sdg: [],
        price: [],
        location: [],
        type: []
      },
      tags: {
        sdg: [],
        price: [],
        location: [],
        type: []
      },
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      RocarbonView: [],
      slides: [
      ]
    }
  },
  mixins: [assets, instant],
  mounted () {
    this.getCollections()
  },
  watch: {
    loading: {
      deep: true,
      handler: function () {
        this.processRecent()
      }
    }
  },
  computed: {
    previewSize: function () {
      return this.$vuetify.breakpoint.width < 600 ? parseInt(this.$vuetify.breakpoint.width * 0.7) + 'px' : '400px'
    },
    size: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.$vuetify.breakpoint.height * 0.6
      }
      return this.$vuetify.breakpoint.width * 0.6
    }
  },
  methods: {
    filterAssets (items) {
      const result = []
      items.forEach(item => {
        const filter = {}
        console.log(this.selectTags)
        filter.sdg = true
        if (this.selectTags.sdg.length > 0) {
          filter.sdg = false
          for (const tag of item.attr.Project.SDGAPIGoals) {
            if (this.selectTags.sdg.includes('SDG-' + tag.pubSDGAPIGoalCode)) {
              filter.sdg = true
            }
          }
        }
        filter.price = true
        if (this.selectTags.price.length > 0) {
          filter.price = false
          if (this.selectTags.price.includes(item.attr.pubRecommendedPricePerTonne)) {
            filter.price = true
          }
        }
        filter.location = true
        if (this.selectTags.location.length > 0) {
          filter.location = false
          if (this.selectTags.location.includes(item.attr.Project.Sites[0].Regions[0].pubRegionCountry)) {
            filter.location = true
          }
        }
        filter.type = true
        if (this.selectTags.type.length > 0) {
          filter.type = false
          for (const tag of item.attr.Project.Tags.filter(obj => !obj.pubProjectTag.startsWith('SDG'))) {
            if (this.selectTags.type.includes(tag.pubProjectTag)) {
              filter.type = true
            }
          }
        }
        if (filter.sdg && filter.price && filter.location && filter.type) {
          result.push(item)
        }
      })
      return result
    },
    updatePriceInput () {
      setTimeout(() => {
        this.sell.roct = parseFloat(this.sell.roctInput).toFixed(3)
        this.updatePrice()
      }, 800)
    },
    updatePrice () {
      this.sell.roctInput = this.sell.roct
      console.log('rocx', this.sell.roct, this.balance.roct, this.art.price)
      this.sell.price = Math.ceil(this.sell.roct * this.art.price * this.balance.roct / this.balance.roct * 10) / 10
      this.art.buy = this.paymentURL + '?amount=' + this.sell.price
      generateQR(this.art.buy).then((qr) => {
        this.art.qr = qr
      })
    },
    getCollectionsFilter (items) {
      const collections = items.slice(0, 5)
      const result = []
      for (const collection of collections) {
        const index = parseInt(collection.assets.length * Math.random())
        const item = collection.assets[index]
        if (this.assets[item]) {
          if (this.assets[item].previews !== undefined) {
            result.push({
              hash: item,
              src: this.assets[item].previews[0].url,
              name: collection.name,
              description: collection.description
            })
          }
        }
      }
      return result
    },
    processRecent () {
      for (const collection of this.recent) {
        const index = parseInt(collection.assets.length * Math.random())
        const item = collection.assets[index]
        if (!this.assets[item]) {
          this.$axios.get('/api/assets/getAssetInfo', {
            params: {
              hash: item,
              crc: parseInt(Date.now() / 100000)
            }
          }).then((result) => {
            this.assets[item] = result.data.asset
            this.assets[item].collection = collection.key
            this.assets[item].index = index
            this.assets[item].currency = collection.currency
            this.assets[item].individual = collection.individual
            this.assets[item].exhibition = collection.exhibition
            if (collection.individual) {
              this.assets[item].price = collection.prices[item]
            } else {
              this.assets[item].price = collection.price
            }
            this.assets[item].collectionCount = collection.assets.length
          })
        }
      }
    },
    getCollections () {
      this.$axios.get('/api/collections/getPublicAssetsRocarbon', {
        params: {
          crc: parseInt(Date.now() / 100000)
        }
      }).then((result) => {
        this.top = result.data.top
        this.recent = result.data.recent
        let count = 0
        for (const collection of this.top) {
          count += 1
          const index = parseInt(collection.assets.length * Math.random())
          const item = collection.assets[index]
          if (!this.assets[item]) {
            this.$axios.get('/api/assets/getAssetInfo', {
              params: {
                hash: item,
                crc: parseInt(Date.now() / 100000)
              }
            }).then(async (result) => {
              if (result.data.asset.previews && !this.assets[item]) {
                const metadata = await this.getMetadata(result.data.asset.metadata)
                const attr = typeof metadata.attr === 'string' ? JSON.parse(metadata.attr) : metadata.attr
                for (const tag of attr.Project.SDGAPIGoals) {
                  this.tags.sdg.push('SDG-' + tag.pubSDGAPIGoalCode)
                }
                this.tags.price.push(attr.pubRecommendedPricePerTonne)
                this.tags.location.push(attr.Project.Sites[0].Regions[0].pubRegionCountry)
                for (const tag of attr.Project.Tags.filter(obj => !obj.pubProjectTag.startsWith('SDG'))) {
                  this.tags.type.push(tag.pubProjectTag)
                }
                this.RocarbonView.push({ hash: item, name: metadata.name, metadata: metadata, attr: attr, description: metadata.description, src: result.data.asset.previews[0].url })
              }
              this.assets[item] = result.data.asset
              this.assets[item].collection = collection.key
              this.assets[item].index = index
              this.assets[item].currency = collection.currency
              this.assets[item].individual = collection.individual
              this.assets[item].exhibition = collection.exhibition
              if (collection.individual) {
                this.assets[item].price = collection.prices[item]
                this.assets[item].available = collection.unit ? (collection.unit[item] ? collection.unit[item] : 1) : 1
                this.assets[item].sold = collection.sold ? collection.sold[item] : false
                this.assets[item].available = this.assets[item].available - (this.assets[item].sold > 0 ? this.assets[item].sold : 0)
                if (this.assets[item].available === 0) {
                  this.assets[item].sold = true
                }
              } else {
                this.assets[item].available = 1
                this.assets[item].price = collection.price
                this.assets[item].sold = !!collection.soldComplete
              }
              this.assets[item].collectionCount = collection.assets.length
              count--
              if (count === 0) {
                this.loading = false
              }
            })
          } else {
            count--
            if (count === 0) {
              this.loading = false
            }
          }
        }
      })
    },
    fullscreen () {
      this.dialogFullscreen = true
    },
    prevAsset (asset) {
      const collection = [...this.top, ...this.recent].find(item => item.key === asset.collection)
      const current = collection.assets.findIndex(item => item === asset.hash)
      let prev = current - 1
      if (prev < 0) {
        prev = collection.assets.length - 1
      }
      console.log(asset)
      console.log(collection.assets[prev])
      this.showArt(collection.assets[prev])
    },
    nextAsset (asset) {
      const collection = [...this.top, ...this.recent].find(item => item.key === asset.collection)
      const current = collection.assets.findIndex(item => item === asset.hash)
      let next = current + 1
      if (next >= collection.assets.length) {
        next = 0
      }
      console.log(asset)
      console.log(collection.assets[next])
      this.showArt(collection.assets[next])
    },
    async showArt (hash) {
      this.loading = true
      this.currentDisplay = 0
      const art = this.assets[hash]
      const metadata = await this.getMetadata(art.metadata)
      art.hash = hash
      art.name = metadata.name
      art.description = metadata.description
      art.quantity = metadata.quantity
      art.creator = metadata.creator
      art.created = metadata.created
      this.$axios.get('/api/assets/getAssetBalance', {
        params: {
          hash: hash,
          crc: parseInt(Date.now() / 10000)
        }
      }).then((result) => {
        this.balance = result.data.balance
        this.sell.roct = this.balance.roct
        this.sell.roctInput = parseFloat(this.balance.roct).toFixed(3)
        this.sell.price = art.price * this.balance.roct
        console.log(this.balance)
      })
      art.attr = typeof metadata.attr === 'string' ? JSON.parse(metadata.attr) : metadata.attr
      const base = (window.location.href.split('#'))[0]
      art.link = base + '#/c/' + art.collection.toString().replaceAll('/', '%2F')
      if (art.individual) {
        this.instant = {
          id: art.collection.toString().replaceAll('/', '%2F'),
          index: art.index,
          individual: true
        }
        const revolved = this.$router.resolve({
          name: 'PaymentIndividual',
          params: { id: art.collection.toString().replaceAll('/', '%2F'), index: art.index }
        })
        art.buy = base + revolved.href
        await generateQR(base + revolved.href).then((qr) => {
          art.qr = qr
        })
      } else {
        this.instant = {
          id: art.collection.toString().replaceAll('/', '%2F'),
          individual: false
        }
        const revolved = this.$router.resolve({
          name: 'Payment',
          params: { id: art.collection.toString().replaceAll('/', '%2F') }
        })
        art.buy = base + revolved.href
        await generateQR(base + revolved.href).then((qr) => {
          art.qr = qr
        })
      }
      this.art = art
      this.paymentURL = art.buy
      this.loading = false
      this.dialogArt = true
    }
  }
}
</script>
