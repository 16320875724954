<template>
    <div class="SubscribeAquariumComponent" id="subscribe">
      <div class="filter">
        <div class="hsform">
          <v-container>
            <v-row>
              <v-col cols="2" align-self="center" align="right" class="text-h6 white--text font-weight-bold">Email</v-col>
              <v-col align-self="center" align="left">
                <script type="application/javascript">
                  window.hbspt.forms.create({
                    region: "na1",
                    portalId: "5179500",
                    formId: "e0d97df3-6e10-43f2-b8f3-819531272b23"
                  });
                </script>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
</template>

<script>
import './_SubscribeAquariumComponent.scss'
export default {
  name: 'SubscribeAquariumComponent',
  mounted () {
    setTimeout(() => {
      this.setStyles()
    }, 1000)
  },
  methods: {
    setStyles () {
      const frameElement = document.getElementsByClassName('hbspt-form')[0].children
      console.log(frameElement)
      const doc = frameElement[0].contentDocument
      console.log(doc)
      doc.head.innerHTML = doc.head.innerHTML + '<style> .submitted-message { height: 100%; align-content: center; display: grid; color: white !important; font-size: 1.3rem !important; } form { display: flex; align-items: flex-start; } label { visibility: hidden; position: absolute } .hs-input { font-size 1.2rem !important; border-color: transparent !important; padding: 22px 10px !important; border-radius: 25px !important; color: white !important; background-color: transparent !important; } .actions { margin-top: 0px !important; padding-top: 0px !important; margin-left: 10px; } .actions input { border-color: red !important; background-color: red !important; color: white !important; padding: 15px 40px !important; font-size: 1.2rem !important; border-radius: 25px !important; }</style>'
    }
  }
}
</script>
