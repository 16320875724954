<template>
<div class="leftColumn " :align="$vuetify.breakpoint.width < 960 ? 'center' : 'left'">
  <h1 class="slogan"
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1100" data-aos-delay="1500">Build. Collect.</h1>
  <h1 class="sloganEmp"
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1100" data-aos-delay="1500">Empower.</h1>
  <h2 class="subheading-2"
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1100" data-aos-delay="2000">The Future Economy made easy</h2>
  <div  data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1500" data-aos-delay="1600" :align="$vuetify.breakpoint.width < 960 ? 'center' : 'left'" class="mt-10">
      <v-btn to="/getstarted" rounded class="getStarted" elevation="0">GET STARTED</v-btn>
      <v-btn to="/main" rounded class="ml-2 explore" elevation="0">EXPLORE</v-btn>
  </div>
  </div>
</template>

<script>
import './_leftColumn.scss'
export default {
  name: 'leftColumn'
}
</script>

<style scoped>

</style>
