<template>
  <div class="ProductSliderComponent">
    <Splide :options="{ drag: 'free', rewind: true, perPage: perPage, perMove: 1, trimSpace: false }" v-if="ready">
      <SplideSlide v-for="(item, index) in items" v-bind:key="index">
        <CardAquariumComponent :title="item.title" :image="item.image" :author="item.author" :avatar="item.avatar" :type="item.type" :pieces="item.pieces" :available="item.available" :item="item" @click.native="showCardDetail(item)" />
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
import '@splidejs/splide/dist/css/splide.min.css'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import './_ProductSliderComponent.scss'
import CardAquariumComponent from '../CardAquariumComponent/CardAquariumComponent'

export default {
  name: 'ProductSliderComponent',
  props: {
    items: Array
  },
  components: {
    CardAquariumComponent,
    Splide,
    SplideSlide
  },
  data () {
    return {
      perPage: 3,
      ready: false
    }
  },
  created () {
    this.perPage = Math.round(this.$vuetify.breakpoint.width / 500) - 1
    if (this.perPage === 0) {
      this.perPage = 1
    }
    console.log(this.perPage)
    this.ready = true
  },
  methods: {
    showCardDetail (item) {
      console.log('show')
      this.$store.state.cardDetail = item
    }
  }
}
</script>
