<template>
    <div class="AquariumSeriesComponent">
      <div class="Content pt-10">
        <span style="color: #5BB0FE">AQUARIUM </span><span v-if="$vuetify.breakpoint.width > 600">COLLECTION</span>
        <div v-if="$vuetify.breakpoint.width <= 600">COLLECTION</div>
        <div class="AppPromo">
          <div v-if="$vuetify.breakpoint.width > 600">
          Now you can create your own aquarium, no water required. High resolution wall decals that come to life when scanned with the SlapItOn App
          </div>
          <div class="my-5">
            <a @click="$store.state.aquarium.download = true">
              <v-btn rounded dark color="#5BB0FE" class="text-capitalize font-weight-bold py-6 px-5"><v-icon>mdi-download</v-icon> Download the App</v-btn>
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import './_AquariumSeriesComponent.scss'
export default {
  name: 'AquariumSeriesComponent'
}
</script>
