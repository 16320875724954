<template>
  <div class="BuyToken">
    <Nav />
    <v-container fuild v-if="!loading">
      <v-row>
        <v-col align="center" class="title text-uppercase font-weight-bold my-10">
          Buy Token
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center" class="title">
          <v-btn @click="buy(5)" class="my-1" block color="#10395D" dark>
            5 T4G-USD (USD$ 5)
          </v-btn>
          <v-btn @click="buy(10)" class="my-1" block color="#10395D" dark>
            10 T4G-USD (USD$ 10)
          </v-btn>
          <v-btn @click="buy(50)" class="my-1" block color="#10395D" dark>
            50 T4G-USD (USD$ 50)
          </v-btn>
          <v-btn @click="buy(100)" class="my-1" block color="#10395D" dark>
            100 T4G-USD (USD$ 100)
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="subtitle-1 text-uppercase font-weight-bold">
          Custom Amount
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center" cols="8">
          <v-text-field hide-details solo placeholder="1000" prefix="$" suffix="T4G-USD" v-model="amount"></v-text-field>
        </v-col>
        <v-col align-self="center" align="center" cols="4">
          <v-btn @click="buy(amount)" class="pa-6" block color="#10395D" dark>Buy</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mt-5 overline text-uppercase" align="right">
          <div class="font-weight-bold">1 Token4GOOD (T4G-USD) = USD$ 1</div>
          <div>Some transactions have additional fees</div>
        </v-col>
      </v-row>
      <v-row v-if="qr && !loading">
        <v-col align="center">
          <v-img v-if="!loading" :src="qr" contain width="300px" height="300px" class="mb-10 qr"/>
          <v-btn :href="link" target="_blank" class="pa-6" block color="#10395D" dark>Goto Pay4Good</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="loading" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_BuyToken.scss'
import Nav from '../../../components/Nav/Nav'
import { createInstantPayment, generateQR } from 'id4good.auth'

export default {
  name: 'ButToken',
  components: { Nav },
  data () {
    return {
      loading: false,
      amount: 5,
      callback: null,
      link: null,
      qr: null
    }
  },
  mounted () {
    this.loading = true
    this.callback = window.location.href
    this.loading = false
  },
  methods: {
    async buy (amount) {
      this.loading = true
      const payment = await createInstantPayment(this.$store.state.wallet, 'Wallet4Good', amount, this.callback, 'US')
      this.link = 'https://pay4good.com/?hash=' + payment.hash
      this.qr = await generateQR(this.link)
      this.loading = false
    }
  }
}
</script>
