<template>
  <div class="rightColumn">
      <div>
        <v-carousel  delimiter-icon="mdi-checkbox-blank-circle-outline" :height="$vuetify.breakpoint.width < 960 ? '400px' : '600px'"
          v-model="model" :hide-delimiter-background="true" :show-arrows-on-hover="true" :show-arrows="false" class="Carousel" cycle :interval="15000">
          <v-carousel-item v-for="(item, index) in items" v-bind:key="index" style="background-color:rgba(85,159,198,0.21) ; border-radius: 25px"
          >
            <v-container fluid class="fill-height">
              <v-row>
                <v-col align-self="center" align="center">
                  <v-img :src="item" max-width="1000px" width="30vw" :max-height="$vuetify.breakpoint.width < 960 ? '400px' : '600px'" contain v-if="item.search('.mp4') === -1"  />
                  <VideoPlayer :src="item" v-if="item.search('.mp4') > 0" width="720" height="400" :poster="require('@/assets/colors.jpg')" style="justify-content: center; display: flex" />
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </div>
  </div>
</template>

<script>
import './_rightColumn.scss'
import VideoPlayer from '../../VideoPlayer/VideoPlayer'
export default {
  name: 'rightColumn',
  components: { VideoPlayer },
  data: () => ({
    model: 0,
    items: [
      require('@/assets/landing/demo.mp4'),
      require('@/assets/landing/DEFAULT.png'),
      require('@/assets/landing/ARTIST.png'),
      require('@/assets/landing/MUSIC.png'),
      require('@/assets/landing/PETS.png')
    ]
  })
}
</script>

<style scoped>

</style>
