<template>
  <div class="Publish">
    <Nav />
    <div v-if="!loading" class="float-menu">
      <v-btn to="/publish/create" rounded color="#70AFD0" dark large class="mr-5">Create Publish</v-btn>
    </div>
    <v-container fuild v-if="collections.length === 0 && !loading" class="fill-height min-height">
      <v-row style="margin-left: 42px">
        <v-col align-self="center" align="center" class="title pt-10">
          <div>OOPS!! You don't have assets published</div>
          <div class="overline mt-2">
            You must first create a publish
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fuild v-if="collections.length > 0 && !loading" class="min-height">
      <v-row class="pt-10">
        <v-col>
          <div class="titleSection text-h3 mb-5" align="left">My Publishes</div>
        </v-col>
      </v-row>
      <v-row class="pt-10" style="margin-left: 42px">
        <v-col v-for="(collection, index) in collections" :key="index" align-self="center" align="center" :cols="$vuetify.breakpoint.width > 1900 ? 3 : $vuetify.breakpoint.width > 1400 ? 4 : $vuetify.breakpoint.width > 960 ? 6 : 12">
          <v-container class="Card">
            <v-row dense>
              <v-col align-self="center" align="center">
                <div class="d-inline-flex CardImages">
                  <v-img v-for="(preview, index) in collection.previews" v-bind:key="index" :src="preview" max-height="150px" height="150px" width="150px" class="CardImage"></v-img>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col align-self="center" align="left">
                <div class="mr-2">
                  <div class="CardMain">{{ collection.name }}</div>
                  <div class="CardSub">{{ collection.assets ? collection.assets.length : 0 }} pieces / {{ collection.visit ? collection.visit : 0  }} views</div>
                </div>
              </v-col>
              <v-col align-self="center" align="right" cols="4">
                <div class="d-inline-flex">
                  <div class="mr-2">
                    <div class="CardSub">Created</div>
                    <div class="CardSub" style="font-weight: bolder">{{ new Date(collection.created * 1000).toLocaleString() }}</div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col align-self="center" align="center">
                <div class="CardActions">
                  <div v-if="collection.exhibition" class="text-h5 pt-3 font-weight-thin">
                    Exhibition
                  </div>
                  <div class="d-inline-flex" v-if="!collection.exhibition">
                    <div class="text-h4 pt-1 font-weight-light">
                      ${{ collection.price }}
                    </div>
                    <div class="ml-1">
                      <div class="text-h5 pt-3 font-weight-thin">{{ currencies.find(item => item.code === collection.currency).name }}</div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="2">
                <v-switch class="switch mx-5" v-model="collection.publicAccess" @click="setPublic(collection)">
                </v-switch>
              </v-col>
              <v-col cols="2" align="left" align-self="center" class="CardSub">
                {{ collection.publicAccess ? 'Public Access Allowed' : 'Public access Denied'  }}
              </v-col>
              <v-col align-self="center" align="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small tile fab v-bind="attrs" v-on="on" @click="copy(collection)" color="#70AFD055" class="mx-1 elevation-3">
                      <v-icon color="#70AFD0" size="28">mdi-link</v-icon>
                    </v-btn>
                  </template>
                  Copy link
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small tile fab v-bind="attrs" v-on="on" @click="goto(collection)" color="#70AFD055" class="mx-1 elevation-3">
                      <v-icon color="#70AFD0" size="28">mdi-page-next</v-icon>
                    </v-btn>
                  </template>
                  Go to Collection
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small tile fab v-bind="attrs" v-on="on" @click="setPause(collection)" color="#70AFD055" class="mx-1 elevation-3">
                      <v-icon color="#70AFD0" size="28" v-if="!collection.pause">mdi-pause</v-icon>
                      <v-icon color="#70AFD0" size="28" v-if="collection.pause">mdi-play</v-icon>
                    </v-btn>
                  </template>
                  Pause Collection
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small tile fab v-bind="attrs" v-on="on" @click="removeCollection(collection, index)" color="#70AFD055" class="mx-1 elevation-3">
                      <v-icon color="#70AFD0" size="28">mdi-card-remove</v-icon>
                    </v-btn>
                  </template>
                  Remove Collection
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="loading" class="fill-height mt-10 min-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Copy Success
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_Publish.scss'
import Nav from '../../components/Nav/Nav'
import CryptoJS from 'crypto-js'

export default {
  name: 'Publish',
  components: { Nav },
  data () {
    return {
      snackbar: false,
      loading: false,
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      collections: []
    }
  },
  mounted () {
    this.loading = true
    this.getCollections()
  },
  methods: {
    copy (collection) {
      console.log('copy')
      const key = CryptoJS.AES.encrypt(collection.key, 'link')
      const base = (window.location.href.split('#'))[0]
      navigator.clipboard.writeText(base + '#/c/' + key.toString().replaceAll('/', '%2F'))
      this.snackbar = true
    },
    goto (collection) {
      const key = CryptoJS.AES.encrypt(collection.key, 'link')
      const base = (window.location.href.split('#'))[0]
      window.open(base + '#/c/' + key.toString().replaceAll('/', '%2F'), '_blank')
    },
    setPublic (collection) {
      this.$axios.post('/api/collections/setPublic', {
        identity: this.$store.state.identity,
        id: collection.key,
        status: collection.publicAccess,
        params: {
          crc: Date.now()
        }
      }).then((result) => {})
    },
    removeCollection (collection, index) {
      this.$axios.post('/api/collections/removeCollection', {
        identity: this.$store.state.identity,
        id: collection.key,
        params: {
          crc: Date.now()
        }
      }).then((result) => {
        this.collections.splice(index, 1)
        this.$forceUpdate()
      })
    },
    setPause (collection) {
      collection.pause = !collection.pause
      this.$axios.post('/api/collections/setPause', {
        identity: this.$store.state.identity,
        id: collection.key,
        status: collection.pause,
        params: {
          crc: Date.now()
        }
      }).then((result) => {})
    },
    getCollections () {
      this.$axios.get('/api/collections/getCollections', {
        params: {
          identity: this.$store.state.identity,
          crc: Date.now()
        }
      }).then((result) => {
        console.log(result)
        for (const collection in result.data.collections) {
          const item = result.data.collections[collection]
          item.key = collection
          console.log(item)
          item.previews = []
          if (item.assets) {
            for (const asset of item.assets) {
              this.$axios.get('/api/assets/getAssetInfo', {
                params: {
                  hash: asset,
                  crc: parseInt(Date.now() / 100000)
                }
              }).then((result) => {
                for (const preview of result.data.asset.previews) {
                  if (preview.type === 'image') {
                    item.previews.push(preview.url)
                  }
                }
                this.$forceUpdate()
                console.log(this.collections)
              })
            }
          }
          this.collections.push(item)
        }
        this.loading = false
      })
    }
  }
}
</script>
