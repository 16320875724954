<template>
  <div class="Profile">
    <Nav />
    <v-container fluid v-if="loading" class="fill-height mt-10 min-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <div :class="{ ColorsNav: true, 'custom--theme--light': !$vuetify.theme.dark, 'custom--theme--dark': $vuetify.theme.dark }" v-if="false">
      <div class="colorSelect" :style="{ backgroundColor: backgroundColor }" @click="switchBackgroundColorPicker">
      </div>
      <div :class="{ colorPicker: true, 'custom--theme--light': !$vuetify.theme.dark, 'custom--theme--dark': $vuetify.theme.dark }" v-if="backgroundColorPicker" align="center">
        <v-color-picker
          dot-size="25"
          swatches-max-height="200"
          mode="rgba"
          v-model="backgroundColor"
          style="background-color: transparent"
        ></v-color-picker>
        <v-btn color="#70AFD0" rounded @click="switchBackgroundColorPicker">Close</v-btn>
      </div>
      <div class="colorSelect" :style="{ backgroundColor: primaryColor }" @click="switchPrimaryColorPicker">
      </div>
      <div :class="{ colorPicker: true, 'custom--theme--light': !$vuetify.theme.dark, 'custom--theme--dark': $vuetify.theme.dark }" v-if="primaryColorPicker" align="center">
        <v-color-picker
          dot-size="25"
          swatches-max-height="200"
          mode="rgba"
          v-model="primaryColor"
          style="background-color: transparent"
        ></v-color-picker>
        <v-btn color="#70AFD0" rounded @click="switchPrimaryColorPicker">Close</v-btn>
      </div>
      <div class="colorSelect" :style="{ backgroundColor: secondaryColor }" @click="switchSecondaryColorPicker"></div>
      <div :class="{ colorPicker: true, 'custom--theme--light': !$vuetify.theme.dark, 'custom--theme--dark': $vuetify.theme.dark }" v-if="secondaryColorPicker" align="center">
        <v-color-picker
          dot-size="25"
          swatches-max-height="200"
          mode="rgba"
          v-model="secondaryColor"
          style="background-color: transparent"
        ></v-color-picker>
        <v-btn color="#70AFD0" rounded @click="switchSecondaryColorPicker">Close</v-btn>
      </div>
      <div align="center" style="width: 100%">
        <v-btn :color="$vuetify.theme.dark ? 'white' : 'black'" icon @click="$vuetify.theme.dark = !$vuetify.theme.dark"><v-icon>mdi-theme-light-dark</v-icon></v-btn>
      </div>
    </div>
      <v-container fluid class="fill-height min-height" v-if="!loading">
        <v-row>
          <v-col class="pa-0 ma-0" style="margin-bottom: 150px !important;">
            <v-img ref="banner" :src="banner" width="100%" min-width="100%" max-height="350px" :class="{ BannerDisplay: true }">
              <div class="display-3 NameDisplay mt-10" align="center">
                <span :class="{ darkMode: darkMode }">{{ name }}</span><v-btn :dark="darkMode" icon class="mt-5" @click="edit('name')"><v-icon>mdi-playlist-edit</v-icon></v-btn>
              </div>
              <div class="description-profile mt-2" align="center">
                <div :class="{ darkMode: darkMode }" style="max-width: 700px">{{ description }}<v-btn :dark="darkMode" icon class="mt-0" @click="edit('description')"><v-icon>mdi-playlist-edit</v-icon></v-btn></div>
              </div>
              <div align="right" style="position: absolute; width: 100%; bottom: 3px">
                <v-btn fab x-small class="mx-1" @click="$refs.inputBanner.click()"><v-icon>mdi-upload</v-icon></v-btn>
                <v-btn fab x-small class="mx-1" @click="editBanner = true"><v-icon>mdi-image-edit-outline</v-icon></v-btn>
              </div>
            </v-img>
            <div class="AvatarDisplay">
              <v-img class="avatar-profile" contain :src="avatar" width="100%" height="100%" max-width="300" max-height="300">
                <div align="center" style="position: absolute; width: 100%; bottom: 3px">
                  <v-btn fab x-small class="mx-1" @click="$refs.inputAvatar.click()"><v-icon>mdi-upload</v-icon></v-btn>
                  <v-btn fab x-small class="mx-1" @click="editAvatar = true"><v-icon>mdi-image-edit-outline</v-icon></v-btn>
                </div>
              </v-img>

              </div>
          </v-col>
        </v-row>
        <v-row style="margin-left: 42px">
          <v-col cols="1" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
          <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12">

          </v-col>
          <v-col align-self="center" align="center">
            <div v-if="false">
              <v-btn icon class="mx-2">
                <v-icon :color="!facebook ? 'grey' : '#4267B2'" large @click="edit('facebook')">mdi-facebook</v-icon>
              </v-btn>
              <v-btn icon class="mx-2">
                <v-icon :color="!twitter ? 'grey' : '#00acee'" large @click="edit('twitter')">mdi-twitter</v-icon>
              </v-btn>
              <v-btn icon class="mx-2">
                <v-icon :color="!instagram ? 'grey' : '#8134af'" large @click="edit('instagram')">mdi-instagram</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="1" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
        </v-row>
        <v-row style="margin-left: 42px">
          <v-col align-self="center" align="center">
            <div class="display-3">
              Publishes
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-left: 42px" v-if="collections.length === 0">
          <v-col align-self="center" align="center">
            <div class="title" v-if="collections.length === 0">
              <div>OOPS!! You don't have collections</div>
              <div class="overline mt-2">
                You must first create collections
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-left: 42px" v-if="collections.length > 0">
          <v-col v-for="(collection, index) in collections" :key="index" align="center" align-self="center" :cols="$vuetify.breakpoint.width > 1900 ? 3 : $vuetify.breakpoint.width > 1400 ? 4 : $vuetify.breakpoint.width > 960 ? 6 : 12">
            <v-container class="Card">
              <v-row dense>
                <v-col align-self="center" align="center">
                  <div class="d-inline-flex CardImages">
                    <v-img v-for="(preview, index) in collection.previews" v-bind:key="index" :src="preview" height="150px" width="150px" class="CardImage"></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col align-self="center" align="left">
                  <div class="mr-2">
                    <div class="CardMain">{{ collection.name }}</div>
                    <div class="CardSub">{{ collection.assets.length }} pieces / {{ collection.visit ? collection.visit : 0  }} views</div>
                  </div>
                </v-col>
                <v-col align-self="center" align="right">
                  <div class="d-inline-flex">
                    <div class="mr-2">
                      <div class="CardSub">Created</div>
                      <div class="CardSub" style="font-weight: bolder">{{ new Date(collection.created * 1000).toLocaleString() }}</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col align-self="center" align="center">
                  <div class="CardActions">
                    <div v-if="collection.exhibition" class="text-h5 pt-3 font-weight-thin">
                      Exhibition
                    </div>
                    <div class="d-inline-flex" v-if="!collection.exhibition">
                      <div class="text-h4 pt-1 font-weight-light">
                        ${{ collection.price }}
                      </div>
                      <div class="ml-1">
                        <div class="text-h5 pt-3 font-weight-thin">{{ currencies.find(item => item.code === collection.currency).name }}</div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    <v-dialog fullscreen v-model="editAvatar" v-if="editAvatar" content-class="Profile">
      <v-container fluid class="fill-height dialog-profile">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="editor-box">
              <PinturaEditor
                v-bind="editorDefaults"
                :src="avatar"
                :imageCropAspectRatio="1"
                @pintura:process="finishAvatar"
              ></PinturaEditor>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog fullscreen v-model="editBanner" v-if="editBanner" content-class="Profile">
      <v-container fluid class="fill-height dialog-profile">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="editor-box">
              <PinturaEditor
                v-bind="editorDefaultsBanner"
                :src="banner"
                @pintura:process="finishBanner"
              ></PinturaEditor>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog fullscreen v-model="editBox" v-if="editBox" content-class="Profile">
      <v-container fluid class="fill-height dialog-profile">
        <v-row>
          <v-col align-self="center" align="center">
            <div style="max-width: 60%; background-color: #ffffffee" class="dialog-profile pa-10 rounded">
              <v-text-field outlined v-if="editItem === 'name'" v-model="name"></v-text-field>
              <v-textarea outlined v-if="editItem === 'description'" v-model="description"></v-textarea>
              <v-text-field outlined v-if="editItem === 'facebook'" v-model="facebook" type="url"></v-text-field>
              <v-text-field outlined v-if="editItem === 'instagram'" v-model="instagram" type="url"></v-text-field>
              <v-text-field outlined v-if="editItem === 'twitter'" v-model="twitter" type="url"></v-text-field>
              <v-btn @click="save">Save</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <input type="file" style="display: none" accept="image/*" ref="inputAvatar"
           @change="_uploadImageAvatar"/>
    <input type="file" style="display: none" accept="image/*" ref="inputBanner"
           @change="_uploadImageBanner"/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Successful update
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_Profile.scss'
import Nav from '../../components/Nav/Nav'
import { getEditorDefaults } from 'pintura'
import { PinturaEditor } from 'vue-pintura'
import { create, urlSource } from 'ipfs-http-client'
import chroma from 'chroma-js'
import colors from '../../mixins/colors'

export default {
  name: 'Profile',
  components: { Nav, PinturaEditor },
  data () {
    return {
      snackbar: false,
      facebook: null,
      instagram: null,
      twitter: null,
      editBox: false,
      editItem: null,
      editAvatar: false,
      editBanner: false,
      darkMode: false,
      editorDefaultsBanner: getEditorDefaults(),
      editorDefaults: getEditorDefaults(),
      avatar: require('@/assets/default_profile.svg'),
      banner: require('@/assets/background.svg'),
      name: 'My Name',
      description: 'Every content creator needs a description of who he is, here you can make known who is behind all the content that you will publish',
      collections: [],
      backgroundColor: '#063e5b22',
      backgroundColorPicker: false,
      primaryColor: '#517d9cff',
      primaryColorPicker: false,
      secondaryColor: '#70AFD0FF',
      secondaryColorPicker: false,
      loading: false,
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ]
    }
  },
  watch: {
    backgroundColor: {
      deep: true,
      handler: function () {
        this.changeColors()
      }
    },
    primaryColor: {
      deep: true,
      handler: function () {
        this.changeColors()
      }
    },
    secondaryColor: {
      deep: true,
      handler: function () {
        this.changeColors()
      }
    }
  },
  mixins: [colors],
  mounted () {
    this.loading = true
    this.primaryColor = this.$store.state.colors.primary
    this.secondaryColor = this.$store.state.colors.secondary
    this.backgroundColor = this.$store.state.colors.background
    this.loadUser()
    this.getCollections()
    this.editorDefaults.willRenderCanvas = (shapes, state) => {
      const {
        utilVisibility,
        selectionRect,
        lineColor,
        backgroundColor
      } = state

      // Exit if crop utils is not visible
      if (utilVisibility.crop <= 0) return shapes

      // Get variable shortcuts to the crop selection rect
      const { x, y, width, height } = selectionRect

      return {
        // Copy all props from current shapes
        ...shapes,

        // Now we add an inverted ellipse shape to the interface shapes array
        interfaceShapes: [
          {
            x: x + width * 0.5,
            y: y + height * 0.5,
            rx: width * 0.5,
            ry: height * 0.5,
            opacity: utilVisibility.crop,
            inverted: true,
            backgroundColor: [...backgroundColor, 0.5],
            strokeWidth: 5,
            strokeColor: [...lineColor]
          },
          // Spread all existing interface shapes onto the array
          ...shapes.interfaceShapes
        ]
      }
    }
  },
  beforeUpdate () {
    console.log('update')
    this.setDarkMode()
  },
  methods: {
    setDarkMode () {
      const blockSize = 5
      const canvas = document.createElement('canvas')
      const context = canvas.getContext && canvas.getContext('2d')

      const imgEl = new Image()
      imgEl.src = this.banner
      imgEl.crossOrigin = ''
      imgEl.onload = () => {
        let data
        let i = -4
        const rgb = {
          r: 0,
          g: 0,
          b: 0
        }
        let count = 0

        if (!context) {
          this.darkMode = false
          return
        }

        const height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height
        const width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width

        context.drawImage(imgEl, 0, 0)

        try {
          data = context.getImageData(0, 0, width, height)
        } catch (e) {
          console.log(e)
          this.darkMode = false
          return
        }

        const length = data.data.length

        while ((i += blockSize * 4) < length) {
          ++count
          rgb.r += data.data[i]
          rgb.g += data.data[i + 1]
          rgb.b += data.data[i + 2]
        }

        rgb.r = ~~(rgb.r / count)
        rgb.g = ~~(rgb.g / count)
        rgb.b = ~~(rgb.b / count)

        if (rgb.r > 150 && rgb.g > 150 && rgb.b > 150) {
          this.darkMode = false
        } else {
          this.darkMode = true
        }
        console.log('darkmode', this.darkMode)
      }
    },
    switchBackgroundColorPicker () {
      this.backgroundColorPicker = !this.backgroundColorPicker
      this.secondaryColorPicker = false
    },
    switchPrimaryColorPicker () {
      this.primaryColorPicker = !this.primaryColorPicker
      this.secondaryColorPicker = false
    },
    switchSecondaryColorPicker () {
      this.secondaryColorPicker = !this.secondaryColorPicker
      this.primaryColorPicker = false
    },
    changeColors () {
      const alphaPrimary = chroma(this.primaryColor).alpha()
      const alphaSecondary = chroma(this.secondaryColor).alpha()
      const colors = chroma.bezier([this.primaryColor, this.secondaryColor])
        .scale()
        .colors(6)
      this.setColor('background', this.backgroundColor)
      this.setColor('secondary', chroma(colors[5]).alpha(1).hex())
      this.setColor('primary', chroma(colors[0]).alpha(1).hex())
      this.setColor('color1', chroma(colors[1]).alpha(alphaPrimary).hex())
      this.setColor('color2', chroma(colors[2]).alpha(alphaPrimary).hex())
      this.setColor('color3', chroma(colors[3]).alpha(alphaSecondary).hex())
      this.setColor('color4', chroma(colors[4]).alpha(alphaSecondary).hex())
      this.setColors()
    },
    getCollections () {
      this.$axios.get('/api/collections/getCollections', {
        params: {
          identity: this.$store.state.identity,
          crc: Date.now()
        }
      }).then((result) => {
        for (const collection in result.data.collections) {
          const item = result.data.collections[collection]
          item.key = collection
          item.previews = []
          for (const asset of item.assets) {
            this.$axios.get('/api/assets/getAssetInfo', {
              params: {
                hash: asset,
                crc: parseInt(Date.now() / 100000)
              }
            }).then((result) => {
              for (const preview of result.data.asset.previews) {
                if (preview.type === 'image') {
                  item.previews.push(preview.url)
                }
              }
              this.$forceUpdate()
              console.log(this.collections)
            })
          }
          this.collections.push(item)
        }
      })
    },
    loadUser () {
      this.$axios.get('/api/users/getUser', {
        params: {
          identity: this.$store.state.identity,
          crc: Date.now()
        }
      }).then((result) => {
        this.name = result.data.user.name ? result.data.user.name : this.name
        this.description = result.data.user.description ? result.data.user.description : this.description
        this.avatar = result.data.user.avatar ? result.data.user.avatar : this.avatar
        this.banner = result.data.user.banner ? result.data.user.banner : this.banner
        this.loading = false
      })
    },
    edit (item) {
      this.editItem = item
      this.editBox = true
    },
    save () {
      switch (this.editItem) {
        case 'name':
          this.$axios.get('/api/users/setName', {
            params: {
              identity: this.$store.state.identity,
              name: this.name,
              crc: Date.now()
            }
          }).then(() => {
            this.snackbar = true
          })
          break
        case 'description':
          this.$axios.get('/api/users/setDescription', {
            params: {
              identity: this.$store.state.identity,
              description: this.description,
              crc: Date.now()
            }
          }).then(() => {
            this.snackbar = true
          })
          break
      }
      this.editBox = false
    },
    async finishAvatar (result) {
      console.log(result)
      this.avatar = URL.createObjectURL(result.dest)
      const ipfs = create(process.env.VUE_APP_IPFS)
      const { cid } = await ipfs.add(urlSource(this.avatar))
      await this.$axios.get('https://pay4good.com/core/registerUploadFile', { params: { cid: cid.toString() } })
      this.$axios.get('/api/users/setAvatar', {
        params: {
          identity: this.$store.state.identity,
          cid: cid.toString(),
          crc: Date.now()
        }
      }).then(() => {
        this.snackbar = true
      })
      this.editAvatar = false
    },
    async finishBanner (result) {
      console.log(result)
      this.banner = URL.createObjectURL(result.dest)
      const ipfs = create(process.env.VUE_APP_IPFS)
      const { cid } = await ipfs.add(urlSource(this.banner))
      await this.$axios.get('https://pay4good.com/core/registerUploadFile', { params: { cid: cid.toString() } })
      this.$axios.get('/api/users/setBanner', {
        params: {
          identity: this.$store.state.identity,
          cid: cid.toString(),
          crc: Date.now()
        }
      }).then(() => {
        this.snackbar = true
      })
      this.editBanner = false
      this.setDarkMode()
    },
    async _uploadImageAvatar (e) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      this.avatar = url
      this.editAvatar = true
    },
    async _uploadImageBanner (e) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      this.banner = url
      this.editBanner = true
    }
  }
}
</script>
