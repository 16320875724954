<template>
    <div class="PrivacyComponent">
      <div data-mesh-id="ContainermqnzcinlineContent-gridContainer" data-testid="mesh-container-content" align="left">

        <div id="comp-khpg25bo" class="_1Q9if _3bcaz" data-testid="richTextElement"><h1 class="font_0"
                                                                                        style="text-align:center; font-size:37px;">
          <span style="color:#FFFFFF;">PRIVACY POLICY</span></h1></div>
        <div id="comp-khpg2kgq" class="_1Q9if _3bcaz" data-testid="richTextElement"><p class="font_8"
                                                                                       style="font-size:18px;">
          <span style="font-weight:bold;">Effective date November 23, 2020</span></p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;">Your privacy and the protection of your personal information
            is important to us. For U.S. residents, personal information means information that identifies,
            describes, or is reasonably capable of being associated with or linked to, directly or indirectly, an
            individual or household. For EU and UK residents, personal information means information relating to
            an identified or identifiable person – that is, a person who can be identified directly or indirectly
            by reference to an identifier. &nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">This Privacy Policy explains the types of personal information
            collected about you by SlapItOnAR, Inc.,&nbsp;its parents, subsidiaries and/or affiliated companies
            (collectively, “SlapItOnAR,” “we,” “our,” or “us”), as well as how we use the information we collect;
            how we may share the information we collect; the security we employ to protect the information we
            collect; and how you may contact us and opt out of certain uses or sharing of your personal
            information in connection with our Websites imaginear.com and stuio.imaginear.com (collectively,
            “Websites”), our mobile application (or “App”), and/or other services that we provide (collectively
            hereinafter, “Services”). In this Privacy Policy, “you” means anyone who uses our Services, contacts
            us, makes an inquiry to us, or applies for employment with us.</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">TABLE OF CONTENTS</span></p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">This table of contents provides a summary of this Privacy
            Policy in connection with our Services (including our Websites and App). It is for your convenience
            only and intended only to help guide you. It should not be read as a complete presentation of all of
            the terms and conditions contained herein. We encourage you to review the entire Privacy Policy.</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">Scope of this Privacy Policy. This Privacy Policy applies to
            the collection and processing of your personal information in connection with our Services.&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">International Transfers of Data. We process data in the U.S.
            and in the EU only. If you are located outside of these jurisdictions, and you access or use our
            Services, you do so at your own risk. You acknowledge that your personal information may be sent to
            the U.S., where data protection and other laws may provide a less comprehensive or protective standard
            of protection than those in your country.&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">Your Rights (U.S. Residents), Including Rights in California
            and Nevada. If you are a U.S. resident, depending on where you live, you may have certain rights with
            respect to your personal information.&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">Your Rights (EU Residents). If you are an EU residents, you
            have certain rights with respect to their personal information.</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">Children’s Privacy. Our Services are not designed or intended
            for children under eighteen (18) years old. Users under eighteen (18) years old are prohibited from
            using our Services without parent or guardian consent.</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;">---------&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Modification.</span> We
            reserve the right to modify this Privacy Policy at any time to reflect changes to our practices or
            applicable law.</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Information We Collect.</span>
            We may collect certain information about you.&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span
            style="font-weight:bold;">How We Use Your Information</span>. How we may use the information we
            collect about you.</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">How We May Share Your Information</span>.&nbsp;
            How we may share the information we collect about you with others.&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">How We Protect Your Information</span>.&nbsp;
            We use reasonable physical, technical, and administrative safeguards designed to protect the security
            of personal information that we collect.&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Cookies, Web Beacons, and Google Analytics</span>.
            How we use cookies, web beacons, and Google Analytics.&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span
            style="font-weight:bold;">Communications with You.</span> How we may communicate with you and your
            right to opt-out.&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Linked Sites</span>. Our
            Services may be accessed through certain websites.&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">How to Contact Us</span>. You
            may contact us regarding the collection, use, disclosure, or security of your personal information by
            one of the means provided below. In contacting us, we may ask you to provide additional information.&nbsp;
          </p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span
            style="font-weight:bold;">SCOPE OF THIS PRIVACY POLICY</span></p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">This Privacy Policy applies to our collection of personal
            information in connection with our Services, whether collected directly from you or shared with us by
            another person or organization.&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-style:italic;">Please review this Privacy Policy carefully.</span>
            By accessing and using our Services (which includes our Websites and App), and/or by otherwise
            submitting information to us for instance, when you create an account with us – you confirm that you
            have read and understood the terms of this Privacy Policy. If you do not agree with any part of this
            Privacy Policy, please do not provide us with any information and do not use our Services. Please
            review our Terms of Use. The Terms of Use describes the terms and conditions for use of our Services.&nbsp;
            &nbsp;</p>
          <p class="font_8" style="font-size:18px;">---------</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">INTERNATIONAL TRANSFERS OF DATA</span>
          </p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;"><span
            class="wixGuard"></span></span></p>
          <p class="font_8" style="font-size:18px;">U.S. and Non-EU and non-UK Residents. SlapItOnAR is located
            and operates in the United States. Our Services, including our App and Websites, are governed by and
            operated in accordance with the laws of the United States, its territories, possessions, and
            protectorates (“U.S.”). If you are a non-EU and non-UK resident located outside of the U.S., and you
            access or use our Services, you do so at your own risk. You also acknowledge and consent that it is
            necessary to transfer your personal information to and process in the U.S., where our central
            databases operate. By using our Services, you (a) acknowledge that the data protection and other laws
            of the U.S. may provide a less comprehensive or protective standard of protection than those in your
            country, and (b) you expressly consent to your personal information being collected, processed, and
            transferred as set forth in this Privacy Policy and under U.S. law.</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;">EU and UK Residents. Personal data collected by us directly
            from EU or UK residents is processed in the EEA, and is not transferred to the U.S. except under use
            of the Standard Contractual Clauses with any appropriate supplementary measures, or other legal
            mechanism approved under applicable EU or UK law.</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">CHILDREN’S PRIVACY</span></p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">Our Services, including our platform, are not designed to
            attract children aged thirteen (13) and under. Further, you must be sixteen (16) years old or older to
            use our Services. If we learn we have collected or received personal information from a child under 13
            without verification of parental consent, we will delete that information, including any associated
            account, and prohibit use of our Services. If you believe we might have any information from or about
            a child under 13 without parental consent, please contact us via the contact methods below.</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">MODIFICATION</span></p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">We reserve the right to modify this Privacy Policy at any time
            to reflect changes to our practices or applicable law. We will make the revised Privacy Policy
            accessible through access to our Websites and App. Please check this Policy periodically for updates.
            The date at the top of the Privacy Policy tells you when it was last updated.&nbsp;</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;">If we make material changes to the way we collect or use your
            information, you will be provided with appropriate notice, including by, but not limited to, posting
            the updated Privacy Policy on our Websites and App, providing notice when you access or use our
            Services, and/or providing notice in accordance with applicable legal requirements. Any changes to
            this Privacy Policy will become effective when the revised Privacy Policy is posted. By continuing to
            use our Services following notice of any changes to this Privacy Policy, you acknowledge and confirm
            that you have read and understand the updated Privacy Policy, and you understand that we will process,
            collect, use, and share information as stated therein.&nbsp;</p>
          <p class="font_8" style="font-size:18px;">----------</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;">INFORMATION WE COLLECT</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Information You Give Us or Which We Automatically Collect.</span>&nbsp;
          </p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">We collect personal information you choose to share with us
            when you use establish an account with us and choose to use our Services, including your name, email
            address, location, picture or image, and a password when you create an account. We also may collect
            your phone number and content you post to use when using our premium Services. We also automatically
            collect certain information when you use our Services, like your IP address, browser and device
            characteristics, operating system, language preferences, referring URLs, device name, geolocation,
            information about how and when you use our Apps. If you wish to change our access or permissions for
            collecting your geolocation or information about your device, you may do so in your device’s settings.
            We also collect information through cookies and similar technologies (see our Cookie Policy below).&nbsp;</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;">When using our App, we may collect information regarding your
            geolocation, mobile device, and push notifications. For instance, we may request access or permission
            to and track location based on your mobile device. We may request access or permission to certain
            features from your mobile device, including your mobile device’s camera, microphone, sensors, blue
            tooth, social media accounts, storage, sms messages, reminders, and other features. We may
            automatically collect device information (such as your mobile device ID, model and manufacturer),
            operating system, version information and IP address. If you apply for employment with us, we may
            collect information from you for employment-related purposes.</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Information We Collect From Third Parties.</span>&nbsp;
          </p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">We may collect information about you from third-parties whom
            you authorize to share information in connection with our Services, such as joint marketing partners,
            social media platforms (like Instagram or Facebook). Examples of information we receive from other
            sources include social media profile information (like your name, gender, birthday, email, current
            city, state and country, user identification numbers for your contacts, profile picture); marketing
            leads and search results and links, including paid listings (such as sponsored links).</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;">Our App offers you the ability to register and login using
            your third-party social media account details (like your Facebook or Twitter logins). Where you choose
            to do this, we will receive certain profile information about you from your social media provider. The
            profile Information we receive may vary depending on the social media provider concerned, but will
            often include your name, e-mail address, friends list, profile picture as well as other information
            you choose to make public. We may collect your information from our service providers in the operation
            of our Services. If you apply for employment with us, we may collect your information from you, or
            through a third party in the business of placing people for employment.</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">----------</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span
            style="font-weight:bold;">HOW WE USE YOUR INFORMATION</span></p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">We use the personal information we collect about you in
            connection with our Services, including the operation of our Services to provide augmented reality
            experiences. If you do not provide us with your personal information for these purposes, we cannot
            provide our Services to you. If you request information or Services from us (like, for instance, when
            using our premium Services), we will use your information to fulfill your request or any other purpose
            for which you provide it. We may use your information to respond to inquiries made by you, or for
            other customer service related reasons. We may use your information to provide you with information
            about our Services or to better understand your needs, or to give you notice of and/or resolve any
            problems that may arise in connection with our Services. We may use your information for our marketing
            purposes. If you choose to link your account with us to a third party account (like a Google or
            Facebook account), we use the information to facilitate account creation and logon process. See “How
            Do We Handle Your Social Logins” section below for more information.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">We may use your information to deliver information to you to
            participate in any interactive features of our Services, to enable user-to-user communications (with
            each user’s consent), or to develop or display content and advertising tailored to your interests
            and/or location and to measure its effectiveness. We may use your information to administer prize
            draws and competitions when you elect to participate in such competitions. We may use information
            collected from our service providers to better render our Services for you and/or to help with our
            operations and for fraud prevention. Please note that we do not control, and are not responsible for,
            other uses of your personal information by your third party social media provider. You should review
            their privacy policies to understand how they collect, use and share your personal information.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">We may use your information to enforce our rights, including
            for billing and collection, or to comply with applicable law; to detect or prevent fraud (financial or
            otherwise), intellectual property infringement, potential misuse of our Services, or breach of our
            Terms of Use. We may use your information to manage third-party risks or other risks to our business,
            or to protect the reputation of our businesses. We may use your information to request feedback and to
            contact you about your use of our Services. We may use your information to improve the quality and/or
            your personal experience on our Websites, our App, or other aspects of our platform, and/or to analyze
            the success of our Services. If you apply for employment with us, we will use your information for
            hiring-related purposes. We may anonymize, aggregate or combine information we collect online or
            elsewhere for the above purposes or other purposes, to improve our Services, to develop new services,
            and/or to analyze statistics on usage and trends. Please note that aggregated or de-identified data
            may not constitute personal information. &nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">We may aggregate data to help improve our platform,
            applications and/or Website experience for our users. If you apply for employment with us, we will
            collect your information for hiring-related purposes.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">HOW WE MAY SHARE YOUR INFORMATION</span>&nbsp;
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">We share the information we collect about you for business
            operations, including with third-party vendors, in order to perform our Services. We may share
            information with third parties to market their products or services to you. If so, we contractually
            require these third parties to keep personal information confidential and use it only for the purposes
            for which we disclose it to them. We may share your information with service providers to better or
            Services and/or for operational purposes,&nbsp; promotion of administration, and fraud prevention.
            Examples include payment processing, email delivery and hosting services. We may allow selected third
            parties to use tracking technology collected from our Apps to enable them to collect data about how
            you interact with the App over time. This information may be used to, among other things, analyze and
            track data, determine the popularity of certain content and better understand online
            activity.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">When you share personal information (for example, by posting
            comments, contributions or other content to the App) or otherwise interact with public areas of the
            App, such personal information may be viewed by all users and may be publicly distributed outside the
            App in perpetuity. If you interact with other users of our App or other Services, and/or register
            through a social network (such as Facebook), your contacts on the social network will see your name,
            profile photo, and descriptions of your activity. Similarly, other users will be able to view
            descriptions of your activity, communicate with you within our App, and view your profile. We may post
            testimonials from you on our App and Websites. Prior to posting a testimonial, we will obtain your
            consent to use your name and testimonial. If you wish to update, or delete your testimonial, please
            contact us at <span style="color:#F60419;"><span style="text-decoration:underline;"><a
              href="mailto:info@slapiton.com" target="_self">info@slapiton.com</a></span></span>. Be sure to
            include your name, testimonial location, and contact information.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">We may share information we collect, as appropriate, with law
            enforcement or otherwise to protect and enforce the legal and contractual rights, privacy, and safety
            of us or others; to protect against possible fraud or other illegal activity; to respond to requests
            from government and other authorities; and/or to comply with legal processes. In the event that
            Imagine AR and/or an affiliate is involved in a merger, acquisition, transfer of control, bankruptcy,
            reorganization or sale of assets, or diligence associated with such matters, we may sell or transfer
            the information described in this Privacy Policy as part of that transaction.&nbsp;</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">HOW WE PROTECT YOUR INFORMATION</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">We employ reasonable physical, technical, and administrative
            safeguards designed to protect the security of personal information that we collect in connection with
            our Services. Safeguards we employ include firewall barriers, SSL encryption techniques, and
            authentication procedures, to help protect personal information from loss, theft, misuse, and
            unauthorized access, disclosure, alteration, and destruction. These safeguards vary depending upon a
            variety of factors including the sensitivity of the information we collect and use.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">Despite all reasonable practices, no security method is
            infallible. We cannot guarantee the security of the networks, systems, servers, devices, and databases
            we operate, that are operated on our behalf, or which you employ to access our Services. Nor can we
            guarantee the security of</p>

          <p class="font_8" style="font-size:18px;">third-party networks, including cellular networks and storage
            servers, used in connection with our Services.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">COOKIES, WEB BEACONS, GOOGLE TOOLS</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;"><span
            class="wixGuard"></span></span></p>

          <p class="font_8" style="font-size:18px;"><span
            style="font-weight:bold;">Cookies and Web Beacons.&nbsp;</span></p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">Our Websites use cookies and similar tracking technologies
            (like web beacons and pixels) to access or store information. Cookies are small data files sent to
            your computer on the basis of the information that we have collected about your visit. You may refuse
            to accept browser cookies by activating the appropriate setting on your browser. However, if you
            select this setting you may be unable to access certain parts of our Website. Unless you have adjusted
            your browser setting so that it will refuse cookies, our system will issue cookies when you direct
            your browser to our Website. Certain features of our Websites may use locally-stored objects (or Flash
            cookies) to collect and store information about your preferences and navigation to, from, and on our
            Website. Flash cookies are not managed by the same browser settings as are used for browser
            cookies.</p>

          <p class="font_8" style="font-size:18px;">Your web browser will have an option you may select that will
            block cookies. Below are links to information about popular web browsers and how to block cookies
            using them.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="color:#008AFC;"><span
            style="text-decoration:underline;"><a
            href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&amp;redirectslug=Enabling+and+disabling+cookies"
            target="_blank">https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&amp;redirectslug=Enabling+and+disabling+cookies</a></span>&nbsp;</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span style="color:#008AFC;"><span
            class="wixGuard"></span></span></p>

          <p class="font_8" style="font-size:18px;"><span style="color:#008AFC;"><span
            style="text-decoration:underline;"><a
            href="https://support.google.com/chrome/answer/95647?hl=en&amp;p=cpn_cookies" target="_blank">https://support.google.com/chrome/answer/95647?hl=en&amp;p=cpn_cookies</a></span></span>
          </p>

          <p class="font_8" style="font-size:18px;"><span style="color:#008AFC;"><span
            style="text-decoration:underline;"><span class="wixGuard"></span></span></span></p>

          <p class="font_8" style="font-size:18px;"><span style="color:#008AFC;"><span
            style="text-decoration:underline;"><a
            href="https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies" target="_blank">https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies</a></span></span>
          </p>

          <p class="font_8" style="font-size:18px;"><span style="color:#008AFC;"><span
            style="text-decoration:underline;"><span class="wixGuard"></span></span></span></p>

          <p class="font_8" style="font-size:18px;"><span style="color:#008AFC;"><span
            style="text-decoration:underline;"><a
            href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank">https://support.apple.com/en-gb/guide/safari/sfri11471/mac</a></span></span>&nbsp;
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">Our Website and emails we send also may contain small
            electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
            gifs) that permit us, for example, to count users who have visited those pages or opened an email and
            for other related website statistics (for example, recording the popularity of certain website content
            and verifying system and server integrity).&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">We also may use cookies and web beacons to collect information
            about your online activities over time and across third-party websites or other online services
            (behavioral tracking). The information we collect automatically may include personal information or we
            may maintain it or associate it with personal information we collect in other ways or receive from
            third parties. It helps us to improve our Websites and to deliver a better and more personalized
            service, including to recognize you when you return to our Websites and/or to improve the experiences
            of users of our Websites.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Google Maps</span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;"><span
            class="wixGuard"></span></span></p>

          <p class="font_8" style="font-size:18px;">Our Services use Google Maps APIs for the purpose of providing
            better Services. By using our Google Maps API implementation, you agree to be bound by Google’s Terms
            of Service. You agree to allow us to obtain or cache your location. You may revoke your consent at any
            time. We use information about location in conjunction with data from other data providers.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Google Analytics</span></p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">Our Websites employ Google Analytics, a web analytics tool
            provided by Google, Inc. that helps website owners understand how visitors engage with their websites.
            We can view a variety of reports about how visitors interact with our Websites so we can improve them.
            Google Analytics collects information using cookies and IP addresses, and it reports website trends
            without identifying individual visitors. In the event a visitor to our Websites submit contact
            information to Google, Google may then associate such information with the applicable IP address to
            enable us to see that visitor’s specific interactions with our Websites.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">You can opt-out of Google Analytics by installing the Google
            Analytics Opt-out Browser Add-On. For information about and to install Google Analytics Opt-out
            Browser Add-on, click here:&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="color:#008AFC;"><span
            style="text-decoration:underline;"><a href="https://policies.google.com/technologies/partner-sites"
                                                  target="_blank">https://policies.google.com/technologies/partner-sites</a></span>&nbsp;</span>
            or&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="color:#008AFC;"><span
            style="text-decoration:underline;"><a href="https://support.google.com/analytics/answer/6004245"
                                                  target="_blank">https://support.google.com/analytics/answer/6004245</a></span></span>
          </p>

          <p class="font_8" style="font-size:18px;"><span style="text-decoration:underline;"><span
            class="wixGuard"></span></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">DO NOT TRACK SIGNALS</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">Certain web browsers provide users with an option by which you
            may have your browser send a “Do Not Track” signal to websites that you are visiting, advising the
            recipient websites that you do not want to have your online activity tracked. However, the way
            browsers communicate such “Do Not Track” signals is not yet uniform and, accordingly, our Websites
            does not take any action in response to such signals. In the event a final standard is developed and
            accepted, we may reassess how we should respond to such signals.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">COMMUNICATIONS WITH YOU</span>
          </p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Our Communications with You and Opting Out of Electronic Communications</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;"><span
            class="wixGuard"></span></span></p>

          <p class="font_8" style="font-size:18px;">We may use your personal information to contact you via phone,
            texting, email, or by other means to the extent such activity is permitted by law. This information
            may be used to help us and our commercial partners, co-sponsors and services providers deliver
            information to you, and for other purposes as are reasonably necessary to provide the services
            requested by you. We may contact you to enable us to respond to an inquiry or comment from you, and/or
            to help us provide our Services to you.&nbsp;</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">By providing use with your mobile phone number and/or email
            address, and clicking the box allowing us to contact you, you authorize us to contact you by texting,
            phone, or other specified means. You may opt-out of receiving future communications from us by
            changing your account settings and preferences. You may opt-out of communications by following the
            instructions provided with our communications, for instance in the email message we send if we
            communicate with you by email.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">We may request to send you push notifications regarding your
            account or for the App. If you wish to opt-out from receiving these types of communications, you may
            turn them off in your device’s settings.</p>

          <p class="font_8" style="font-size:18px;">&nbsp; &nbsp; &nbsp;</p>

          <p class="font_8" style="font-size:18px;">You also may&nbsp; opt-out or change your preferences by
            providing opt-out instructions – e.g. sending an email or postal mail as provided below:</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">• Postal Mail: SlapItOnAR, 7904 E. Chaparral Road, Suite
            100-151, Scottsdale, AZ 85250</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">• Email Privacy: <span style="color:#E21C21;"><span
            style="text-decoration:underline;"><a href="mailto:info@slapiton.com"
                                                  target="_self">info@slapiton.com</a></span>&nbsp;</span></p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">Be sure to include the contact information we are using to
            communicate with you, such as your email address, and let us know the specific types of communications
            you no longer wish to receive.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">YOUR RIGHTS</span>&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">You have the right to access and correct your personal
            information that you submit to us when you create an account by accessing and changing your account
            information. Depending on where you live, you may have certain additional rights with respect to your
            information. Please note that you may be located in a jurisdiction where we are not obligated, or are
            unable, to fulfill a request.&nbsp; In such a case, your request may not be fulfilled.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">If you prefer that we do not share personal information about
            you with our affiliates, or to nonaffiliated third parties, for marketing purposes, except as
            otherwise permitted or required by law, you may direct us not to do so by contacting us in the manners
            provided below. If you prefer that we do not direct market to you via our affiliates and nonaffiliated
            third parties through e-mail, postal mail, and/or telemarketing, you may direct us not to do so by
            contacting us in the manners provided below, or by sending a request by mail to the address above.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">• Postal Mail: SlapItOnAR, 7904 E. Chaparral Road, Suite
            100-151, Scottsdale, AZ 85250</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">• Email Privacy: <span style="color:#E21C21;"><span
            style="text-decoration:underline;"><a href="mailto:info@slapiton.com"
                                                  target="_self">info@slapiton.com</a></span>&nbsp;</span></p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Rights of California Residents.</span>&nbsp;
            California residents with rights under the California Consumer Privacy Act (“CCPA”), or their
            authorized agents, may click here to learn those rights and make for access or deletion requests.&nbsp;
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="text-decoration:underline;"><span
            style="font-weight:bold;"><span style="font-style:italic;">Do Not Sell My Personal Information.</span></span></span>
            California residents may opt out of the “sale” of their personal information.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span
            style="font-weight:bold;">Rights of Nevada Residents.</span>&nbsp; Residents of Nevada may learn about
            their rights here.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span
            style="font-weight:bold;">Rights of EU and UK Residents.</span> EU and UK residents with rights under
            the EU General Data Protection Regulation (GDPR) or UK data protection laws may click here to learn
            those rights and make for access or deletion requests.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">LINKED WEBSITES</span></p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">You may access our Website from a third-party website (i.e., a
            website owned or operated by another entity) (“Linked Sites”). Any such linking should not be
            construed as an endorsement by us. Linked Sites are not controlled by Imagine AR; nor are they
            governed by this Privacy Policy. This Privacy Policy governs only personal information we collect in
            connection with our Services. When visiting third-party websites, including Linked Sites, we encourage
            users to review the privacy policy governing such site.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">CONTACT US</span></p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">For any questions or complaints about this Privacy Policy or
            our privacy practices, please contact us at:&nbsp;</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">Postal Mail: SlapItOnAR, 7904 E. Chaparral Road, Suite
            100-151, Scottsdale, AZ 85250</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">• Email Privacy: <span style="color:#E21C21;"><span
            style="text-decoration:underline;"><a href="mailto:info@slapiton.com"
                                                  target="_self">info@slapiton.com</a></span>&nbsp;</span></p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Privacy Policy for California Residents</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">This Privacy Policy is for California Residents (the
            “California Policy”) and supplements the information contained in our main Privacy Policy. This
            California Policy explains the personal information we collect or process about California residents
            in connection with our Services, including our Websites and App, how we use, share, and protect that
            personal information, and what your rights are with respect to your personal information that we
            gather and process. This policy only applies to consumers who access or use our Services in California
            and who are California residents.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">We adopt this California Policy to comply with the California
            Consumer Privacy Act of 2018 (CCPA) and other California privacy laws. Any terms defined in CCPA have
            the same meaning when used in this California Policy, including the term “personal information,” which
            means “information that identifies, relates to, describes, is reasonably capable of being associated
            with, or could reasonably be linked, directly or indirectly, with a particular consumer or household,
            Personal information does not include information that has been de-identified or aggregated.”</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Direct Marketing Lists – California Shine the Light Law</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">California residents can request a list of all the third
            parties to which we have disclosed certain personal information (as defined by California’s Shine the
            Light law) during the preceding year for those third parties’ direct marketing purposes. California
            residents may contact us at SlapItOnAR, 7904 E. Chaparral Road, Suite 100-151, Scottsdale, AZ
            85250.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">In the body of your request, you must include: “California
            Shine the Light Request”, your name, a current California address including, street address, city,
            state, and zip code, as well as sufficient information for us to determine if the law applies to you.
            You will need to attest to the fact that you are a California resident.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">We will not accept requests by telephone, email, or facsimile,
            and we are not responsible for notices that are not labeled or sent properly, or that do not have
            complete information.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Personal Information We Collect and For What Purpose</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">In the preceding 12 months, we have collected the following
            categories of personal information: identifiers; information subject to Cal. Civ. Code § 1798.80(e);
            protected classifications characteristics under California or U.S. law; internet or other electronic
            network activity information; geolocation data; employment-related information; inferences drawn about
            you from other personal information that relate to, describe or are reasonably capable of being
            associated with you.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">For examples of the precise data points we collect and the
            sources from which we collect this personal information, please see “Information We Collect” in our
            main Privacy Policy.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">We may also use or share de-identified information that is not
            reasonably likely to identify you for commercially legitimate business purposes.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Personal Information We Collect and Have Disclosed</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">We may share your personal information with third parties as
            described in the “How We May Share Your Information” in our main Privacy Policy. In the preceding 12
            months, we have disclosed the following categories of personal information for business or commercial
            purposes to the following categories of recipients:</p></div>
        <div id="comp-kjasubog" class="_1Q9if _3bcaz" data-testid="richTextElement"><h1 class="font_0"
                                                                                        style="font-size:18px; text-align:center;">
          <span style="color:#F60419;"><span style="font-size:18px;">©2021 SlapItOnAR</span></span></h1></div>
        <div id="comp-khw5iqfz" class="_1ncY2">
          <div class="_13Lxq" data-testid="container-bg"></div>
          <div data-mesh-id="comp-khw5iqfzinlineContent" data-testid="inline-content" class="">
            <div data-mesh-id="comp-khw5iqfzinlineContent-gridContainer" data-testid="mesh-container-content">
              <div id="comp-khw5vfxw" class="_1Q9if _3bcaz" data-testid="richTextElement"><p class="font_7"
                                                                                             style="font-size:18px;">
                      <span style="font-size:18px;"><span
                        style="font-family:din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;"><span
                        style="font-weight:bold;">Categories of Personal Information Disclosed for a Business Purpose</span></span></span>
              </p></div>
            </div>
          </div>
        </div>
        <div id="comp-khw5itcz" class="_1ncY2">
          <div class="_13Lxq" data-testid="container-bg"></div>
          <div data-mesh-id="comp-khw5itczinlineContent" data-testid="inline-content" class="">
            <div data-mesh-id="comp-khw5itczinlineContent-gridContainer" data-testid="mesh-container-content">
              <div id="comp-khw5x4i6" class="_1Q9if _3bcaz" data-testid="richTextElement">
                <ul class="font_8" style="font-size:18px;">
                  <li>
                    <p class="font_8" style="font-size:18px;">Identifiers like your name, address, online
                      identifier, a unique account number, or IP address</p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

                    <p class="font_8" style="font-size:18px;">Information subject to Cal. Civ. Code § 1798.80 like
                      computerized information that identifies, relates to, describes, or is capable of being
                      associated with, a particular individual, including, but not limited to name or
                      signature</p>

                    <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;">Commercial information like products being searched
                      for</p>

                    <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;">Internet or other electronic network activity
                      information like your inquiry and browsing history, your interaction with our Website or
                      app</p>

                    <p class="font_8" style="font-size:18px;">&nbsp;</p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;">Geolocation data like your location</p>

                    <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;">Inferences, which is any of the above information
                      that can be used to create a profile of you to understand your preferences, abilities,
                      characteristics, aptitudes or behaviors</p>

                    <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;">Identifiers like your name, address, online
                      identifier, a unique account number, or IP address</p>

                    <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;">Information subject to Cal. Civ. Code § 1798.80 like
                      computerized information that identifies, relates to, describes, or is capable of being
                      associated with, a particular individual, including, but not limited to name or
                      signature</p>

                    <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;">Geolocation data like your location</p>

                    <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="comp-khw5fdn4" class="_1ncY2">
          <div class="_13Lxq" data-testid="container-bg"></div>
          <div data-mesh-id="comp-khw5fdn4inlineContent" data-testid="inline-content" class="">
            <div data-mesh-id="comp-khw5fdn4inlineContent-gridContainer" data-testid="mesh-container-content">
              <div id="comp-khw5lihe" class="_1Q9if _3bcaz" data-testid="richTextElement"><p class="font_7"
                                                                                             style="font-size:18px;">
                      <span style="font-size:18px;"><span
                        style="font-family:din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;"><span
                        style="font-weight:bold;">Categories of Third Parties</span></span></span></p></div>
            </div>
          </div>
        </div>
        <div id="comp-khw5eef2" class="_1ncY2">
          <div class="_13Lxq" data-testid="container-bg"></div>
          <div data-mesh-id="comp-khw5eef2inlineContent" data-testid="inline-content" class="">
            <div data-mesh-id="comp-khw5eef2inlineContent-gridContainer" data-testid="mesh-container-content">
              <div id="comp-khw5ml1u" class="_1Q9if _3bcaz" data-testid="richTextElement">
                <ul class="font_8" style="font-size:18px;">
                  <li>
                    <p class="font_8" style="font-size:18px;">Vendors, consultants and other service providers who
                      perform services on our behalf</p>

                    <p class="font_8" style="font-size:18px;">&nbsp;</p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;">Third-party advertisers</p>

                    <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;">Third-party business partners</p>

                    <p class="font_8" style="font-size:18px;">&nbsp;</p>
                  </li>
                  <li>
                    <p class="font_8" style="font-size:18px;">Other users of our Services</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="comp-khw6041b" class="_1Q9if _3bcaz" data-testid="richTextElement"><p class="font_8"
                                                                                       style="font-size:18px;">
          <span style="font-weight:bold;">Personal Information We Collect and Have Sold</span>&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">California residents may opt out of the “sale” of their
            personal information, which under the CCPA broadly means scenarios in which we have shared personal
            information with partners in exchange for valuable consideration. CCPA defines a “sale” as selling,
            renting, releasing, disclosing, disseminating, making available, transferring, or otherwise
            communicating orally, in writing, or by electronic or other means, a consumer’s personal information
            by the business to another business or a third party for monetary or other valuable consideration.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">We have not sold personal information in the last twelve
            months.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Your Rights Under CCPA As A California Resident</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">Under CCPA, California residents have specific rights
            regarding their personal information as described below. These rights are subject to certain
            exceptions. We explain how you may exercise this right below.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-style:italic;">1. The Right to Disclosure of Information.</span>
            You have the right to request that we disclose certain information regarding our practices with
            respect to personal information. If you submit a valid and verifiable request and we confirm your
            identity and/or authority to make the request, we will disclose to you any of the following at your
            direction: (i) the categories of personal information we have collected about you in the last 12
            months, (ii) the categories of sources for the personal information we have collected about you in the
            last 12 months, (iii) our business or commercial purpose for collecting that personal information,
            (iv) the categories of third parties with whom we share that personal information, and (v) the
            specific pieces of personal information we &nbsp;</p>

          <p class="font_8" style="font-size:18px;">collected about you. In addition, if we sold your personal
            information or disclosed it to a third party for a business purpose, we will provide a list of the
            personal information types that each category of recipient purchased. We explain how you may exercise
            this right below.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">2. Right to Deletion. You have the right to request that we
            delete any of your personal information that we collected from you and retained, subject to certain
            exceptions. If you submit a valid and verifiable request and we can confirm your identity and/or
            authority to make the request, we will determine if retaining the information is necessary for us or
            our service providers to:</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p><span class="wixGuard"></span></p>

          <ul class="font_8" style="font-size:18px;">
            <li>
              <p class="font_8" style="font-size:18px;">Complete a transaction for which we collected the personal
                information, provide a good or service that you requested, take actions reasonably anticipated
                within the context of our ongoing business relationship with you, or otherwise perform our
                contract with you</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Detect security incidents, protect against malicious,
                deceptive, fraudulent, or illegal activity, or prosecute those responsible for such
                activities;</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Debug products to identify and repair errors that impair
                existing intended functionality.</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Exercise free speech, ensure the right of another consumer
                to exercise their free speech rights, or exercise another right provided for by law;</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Comply with the California Electronic Communications
                Privacy Act (Cal. Penal Code §&nbsp;1546 et seq.);</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Engage in public or peer-reviewed scientific, historical,
                or statistical research in the public interest that adheres to all other applicable ethics and
                privacy laws, when the information's deletion may likely render impossible or seriously impair the
                research's achievement, if you previously provided informed consent;</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Enable solely internal uses that are reasonably aligned
                with consumer expectations based on your relationship with us;</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Comply with a legal obligation; and/or</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Make other internal and lawful uses of that information
                that are compatible with the context in which you provided it.</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
          </ul>

          <p class="font_8" style="font-size:18px;">If none of the above retention conditions apply, we will
            delete your personal information from our records and direct our service providers to do the same. We
            explain how you may exercise this right below. Please note that deletion of your information may
            affect your experience accessing or using our Website or Services.</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">3. Right to Opt-Out of the Sale of Your Personal Information.
            As a California resident, you have the right to direct a business that sells your personal information
            to third parties not to sell your</p>

          <p class="font_8" style="font-size:18px;">personal information. This right is referred to as “the right
            to opt-out.” We explain how you may exercise this right below.&nbsp;</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">4. Right Against Discrimination. You may exercise your rights
            under the CCPA without discrimination. For example, unless the CCPA provides an exception, we will
            not:</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <ul class="font_8" style="font-size:18px;">
            <li>
              <p class="font_8" style="font-size:18px;">Deny you goods or services;</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Charge you different prices or rates for goods or
                services, including through granting discounts or other benefits, or imposing penalties;</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Provide you a different level or quality of goods or
                services; or</p>

              <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
            </li>
            <li>
              <p class="font_8" style="font-size:18px;">Suggest that you may receive a different price or rate for
                goods or services or a different level or quality of goods or services.</p>

              <p class="font_8" style="font-size:18px;">&nbsp;</p>
            </li>
          </ul>

          <p class="font_8" style="font-size:18px;">We may offer you financial incentives to provide us with
            personal information that is reasonably related to the information’s value. This could result in
            different prices, rates, or quality levels for our products or services. Any financial incentive we
            offer will be described in written terms that explain the material aspects of the financial incentive
            program. You must opt-in to any financial incentive program and may revoke your consent at any time by
            contacting us as indicated below.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">How To Exercise Your Rights Under the CCPA</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">To submit general questions or requests regarding this Privacy
            Notice or our privacy practices, please contact us at <span style="color:#E21C21;"><span
              style="text-decoration:underline;"><a href="mailto:info@slapiton.com" target="_self">info@slapiton.com</a></span>. &nbsp;</span>
          </p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">To submit a verifiable request under the CCPA to access or
            delete your information, please visit our online request portal and fill in the form by visiting
            here.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">Alternatively, you may call us at this number:&nbsp; <span
            style="font-weight:bold;">480-628-7738</span></p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">Please note that you if you choose to make a CCPA request, we
            will require certain information in order to verify who you are, including your name, account
            information, address, and telephone number. This information will be requested via the
            webform.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">You may have an authorized agent, as defined by CCPA and/or
            related regulations, submit a request on your behalf. However, we may require proof of such agent’s
            status as an authorized agent, including an executed permission by you, and verification of the
            agent’s own identity. We also still will require the information requested via the webform.&nbsp;</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;">&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">Nevada Residents</span></p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">SlapIton does not sell “covered information” for monetary
            consideration. However Nevada law requires us to post the following:&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">Nevada consumers may opt out of the sale of “covered
            information” for monetary consideration to a person for that person to license or sell such
            information.&nbsp; “Covered information” means any one or more of the following items of personally
            identifiable information about a consumer: first and last name, home or other physical address which
            includes the name of a street and the name of a city or town, e-mail address, telephone number, social
            security number, an identifier that allows a specific person to be contacted either physically or
            online, or other information maintained in combination with an identifier that makes the information
            personally identifiable.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">If you are a Nevada resident who has purchased goods or
            services from us, you may submit a request to opt out of any potential future sales under Nevada law
            by emailing us at <span style="color:#E21C21;"><span style="text-decoration:underline;"><a
              href="mailto:info@slapiton.com" target="_self">info@slapiton.com</a></span></span>. Please use
            “Nevada Do Not Sell” in the subject line. Note we will take reasonable steps to verify your identity
            and the authenticity of the request.&nbsp; Once verified, we will maintain your request in the event
            our practices change. &nbsp;</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;">&nbsp;</p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;">EU and UK Residents</span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;"><span
            class="wixGuard"></span></span></p>
          <p class="font_8" style="font-size:18px;"><span style="font-style:italic;"><span
            style="font-weight:bold;">Specific rights for EU and UK residents</span></span></p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>
          <p class="font_8" style="font-size:18px;">You have certain rights in relation to your personal
            information. If you would like further information in relation to these or would like to exercise any
            of them, please contact us via email at <span style="color:#E21C21;"><span
              style="text-decoration:underline;"><a href="mailto:info@slapiton.com" target="_self">info@slapiton.com</a></span> </span>at
            any time. You have the following rights:</p>
          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span
            style="font-style:italic;">1.&nbsp; Right of access.</span> You have a right of access to any personal
            information we hold about you.&nbsp; You can ask us for a copy of your personal information;
            confirmation as to whether your personal information is being used by us; details about how and why it
            is being used; and details of the safeguards which are in place if we transfer your information
            outside of the EU or UK.&nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-style:italic;">2.&nbsp; Right to update your information.</span>
            You have a right to request an update to any of your personal information which is out of date or
            incorrect.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-style:italic;">3. Right to delete your information.</span>
            You have a right to ask us to delete any personal information which we are holding about you in
            certain specific circumstances.&nbsp; You can ask us for further information on these specific
            circumstances by contacting us. We will pass your request onto other recipients of your personal
            information unless that is impossible or involves disproportionate effort.&nbsp; You can ask us who
            the recipients are by contacting us.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-style:italic;">4. Right to restrict use of your information.</span>
            You have a right to ask us to restrict the way that we process your personal information in certain
            specific circumstances.&nbsp; You can ask us for further information on these specific circumstances
            by contacting us. We will pass your request onto other recipients of your personal information unless
            that is impossible or involves disproportionate effort.&nbsp; You can ask us who the recipients are by
            contacting us.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span
            style="font-style:italic;">5. Right to stop marketing.</span> You have a right to ask us to stop using
            your personal information for direct marketing purposes.&nbsp; If you exercise this right, we will
            stop using your personal information for this purpose.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span
            style="font-style:italic;">6. Right to data portability. </span>You have a right to ask us to provide
            your personal information to a third party provider of services. This right only applies where we use
            your personal information on the basis of your consent or performance of a contract; and where our use
            of your information is carried out by automated means.</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-style:italic;">7. Right to object.</span>
            You have a right to ask us to consider any valid objections which you have to our use of your personal
            information where we process your personal information on the basis of our or another person's
            legitimate interest. We will consider all such requests and provide our response within a reasonable
            period (and in any event within one month of your request unless we tell you we are entitled to a
            longer period under applicable law). Please note, however, that certain personal information may be
            exempt from such requests in certain circumstances, for example if we need to keep using the
            information to comply with our own legal obligations or to establish, exercise or defend legal claims.
            If an exception applies, we will tell you this when responding to your request.&nbsp; We may request
            you provide us with information necessary to confirm your identity before responding to any request
            you make. &nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;"><span style="font-style:italic;"><span
            style="font-weight:bold;">Complaints</span></span></p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p>

          <p class="font_8" style="font-size:18px;">If you have any queries or complaints about our collection,
            use or storage of your personal information, or if you wish to exercise any of your rights in relation
            to your personal information, please contact us. We will investigate and attempt to resolve any such
            complaint or dispute regarding the use or disclosure of your personal information.<br>
            &nbsp;</p>

          <p class="font_8" style="font-size:18px;">You may also make a complaint to the relevant supervisory
            authority, namely: [the Information Commissioner’s Office in the UK, or the member state in which you
            reside. &nbsp;</p>

          <p class="font_8" style="font-size:18px;"><span class="wixGuard"></span></p></div>
      </div>
    </div>
</template>

<script>
import './_PrivacyComponent.scss'
export default {
  name: 'PrivacyComponent'
}
</script>
