<template>
    <div class="TopPicksComponent">
        <v-container class="mb-10">
          <v-row>
            <v-col></v-col>
            <v-col align="right">
              <div class="SortButton d-flex">
                <v-btn icon dark @click="desc = !desc"><v-icon v-if="desc">mdi-sort-descending</v-icon><v-icon v-if="!desc">mdi-sort-ascending</v-icon></v-btn> Sort By:
                <v-select v-model="sort" class="Selector" hide-details rounded x-large dark solo :items="sorts" item-text="name" item-value="code"></v-select>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols0="12" md="6" lg="4" xl="3" v-for="(item, index) in items.slice(0, limit)" v-bind:key="index" align-self="center" align="center">
              <CardAquariumComponent style="max-width: 350px" :title="item.title" :image="item.image" :author="item.author" :avatar="item.avatar" :type="item.type" :pieces="item.pieces" :available="item.available" :item="item" @click.native="showCardDetail(item)" />
            </v-col>
            <v-col cols0="12" md="6" lg="4" xl="3"></v-col>
            <v-col cols0="12" md="6" lg="4" xl="3"></v-col>
            <v-col cols0="12" md="6" lg="4" xl="3"></v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <v-btn rounded x-large color="#5BB0FE" dark class="" @click="limit = limit + 8">Load More</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_TopPicksComponent.scss'
import CardAquariumComponent from '../CardAquariumComponent/CardAquariumComponent'
export default {
  name: 'TopPicksComponent',
  props: {
    items: Array
  },
  components: { CardAquariumComponent },
  data () {
    return {
      limit: 8,
      desc: false,
      sort: 'visit',
      sorts: [
        { code: 'visit', name: 'Visits' },
        { code: 'created', name: 'Created' },
        { code: 'price', name: 'Price' }
      ]
    }
  },
  mounted () {
    console.log(this.items)
    this.sortItems()
  },
  watch: {
    desc: {
      deep: true,
      handler: function () {
        this.sortItems()
      }
    },
    sort: {
      deep: true,
      handler: function () {
        this.sortItems()
      }
    }
  },
  methods: {
    sortItems () {
      if (this.sort === 'visit') {
        this.items.sort((a, b) => {
          if (a.collection.visit < b.collection.visit) {
            return -1
          }
          if (a.collection.visit > b.collection.visit) {
            return 1
          }
          return 0
        })
      }
      if (this.sort === 'created') {
        this.items.sort((a, b) => {
          if (a.created < b.created) {
            return -1
          }
          if (a.created > b.created) {
            return 1
          }
          return 0
        })
      }
      if (this.sort === 'price') {
        this.items.sort((a, b) => {
          const aPrice = a.collection.individual ? a.collection.prices[a.hash] : a.collection.price
          const bPrice = b.collection.individual ? b.collection.prices[b.hash] : b.collection.price
          if (aPrice < bPrice) {
            return -1
          }
          if (aPrice > bPrice) {
            return 1
          }
          return 0
        })
      }
      if (!this.desc) {
        this.items = this.items.reverse()
      }
    },
    showCardDetail (item) {
      console.log('show')
      this.$store.state.cardDetail = item
    }
  }
}
</script>
