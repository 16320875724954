<template>
    <v-navigation-drawer v-model="$store.state.drawer"  elevation="0" width="300" tile app right style="background-color: #70AFD0 !important;border-radius: 0 !important; border-radius: 0; background-color: #70AFD0 !important">
      <v-divider></v-divider>
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          style="border-bottom: 1px solid white;border-radius: 0"
        >
          <v-list-item-content>
            <v-list-item-title class="icons my-5 title mr-3" align="right">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon class="icons my-6">{{ item.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
  name: 'DrawerApp',
  data () {
    return {
      items: [
        { title: 'Explore', icon: 'mdi-magnify' },
        { title: 'Create', icon: 'mdi-image' },
        { title: 'Profile', icon: 'mdi-account-circle-outline' },
        { title: 'My Assets', icon: 'mdi-security' },
        { title: 'My Collections', icon: 'mdi-image-multiple' },
        { title: 'Stats', icon: 'mdi-help-box' },
        { title: 'Resources', icon: 'mdi-semantic-web' },
        { title: 'Empowerment', icon: 'mdi-account-group' }
      ]

    }
  }
}
</script>

<style scoped>

</style>
