export default {
  data () {
    return {
      instant: {
      }
    }
  },
  methods: {
    getInstantPayment () {
      this.$confirm('Are you sure of the purchase?').then((result) => {
        if (result) {
          this.$store.state.instantLoadingResult = false
          this.$store.state.instantLoading = true
          this.$axios.get('/api/payment/getInstantPayment', {
          // this.$axios.get('http://localhost:5001/deptofgood-bloqs4good/us-central1/payment-getInstantPayment', {
            params: {
              hash: this.instant.id,
              individual: this.instant.individual,
              index: this.instant.individual ? this.instant.index : null,
              from: this.$store.state.instantPay,
              crc: Date.now()
            }
          }).then((result) => {
            this.$store.state.instantLoadingResult = 'success'
          }).catch(() => {
            this.$store.state.instantLoadingResult = 'fail'
          })
        }
      })
    }
  }
}
