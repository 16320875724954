<template>
  <div>
    <div class="video-container" >
      <iframe width="100%" height="60vh" src="https://www.youtube.com/embed/dX2fFV7sg0k?controls=1&autoplay=1&mute=1&enablejsapi=1&yt:stretch=4:3" title="Bloqs4Good Canada" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <v-row>
    <v-col cols="9" class="mx-auto" align-self="center" align="center" style="position:relative;top:-150px">
      <h3 class="titleResources"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-delay="30"
          data-aos-duration="800"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center">Support by People for People</h3>
      <p data-aos="fade-up"
         data-aos-offset="100"
         data-aos-delay="30"
         data-aos-duration="800"
         data-aos-easing="ease-in-out"
         data-aos-mirror="true"
         data-aos-once="false"
         data-aos-anchor-placement="top-center"
      class="py-10">At Bloqs4Good, we're people too, and we understand how difficult it's become in recent times to get good customer support. With Web3 products and services, we feel this trend has only gotten worst, and we're here to change that. Dept. of Good is only as good as the people that contribute to it, and we're here to support them. Please be patient with us as we onboard more people to support you!</p>
      <a href="https://support.bloqs4good.com" ><v-btn data-aos="fade-up"
                                                       data-aos-offset="100"
                                                       data-aos-delay="30"
                                                       data-aos-duration="800"
                                                       data-aos-easing="ease-in-out"
                                                       data-aos-mirror="true"
                                                       data-aos-once="false"
                                                       data-aos-anchor-placement="top-center" class="resourceBtn">Contact Us</v-btn></a>
    </v-col>
  </v-row>
</div>
</template>

<script>
import './_resourcesContent.scss'
export default {
  name: 'resourcesContent'
}
</script>

<style scoped>

</style>
