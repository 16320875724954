<template>
  <div>
    <div v-if="!isMobile()" class="mb-8">
    <v-row class="my-6 py-5">
      <v-col cols="6" align-self="left" align="left" class="px-6 py-6 " style="position: relative;">
        <div  class="leftCol">
          <h1 class="trending my-10" data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-in" data-aos-duration="1500">Pioneering the Good Economy</h1>
          <div align="center" class="body " style="text-align: left">
            <p data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-in" data-aos-duration="1500">
              Welcome to our global marketplace; a community of conscious buyers and sellers, Pioneering the Good
              Economy. We're committed to collaborating with all who believe in giving back. Our mission is to create,
              cultivate and curate innovative products, locally and globally, and build a bridge between the digital
              world of e-commerce and community independent retailers. We are trail-blazing a different way of buying
              and selling what we like using our platform of community-based, experiential retail.
            </p>
          </div>
          <v-btn class="empowerBtn my-10" align-self="right"
                 data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-in" data-aos-duration="1500">Learn More</v-btn>
        </div>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-img data-aos="fade-down" data-aos-delay="100" data-aos-easing="ease-in" data-aos-duration="1500" class="mx-auto mt-5" :src="require('@/assets/Empowerment02-1000.png')" max-width="400px"></v-img>
        </v-row>
      </v-col>
    </v-row>
      <v-row><br><br></v-row>
    </div>
    <div v-else class="mx-auto">
<v-row class="mx-auto">
  <v-col class="mx-auto" cols="10" align="center" align-self="center">
    <h2 class="pioneering mt-8" >Pioneering the Good Economy</h2>
  </v-col>
  <v-row>
    <v-col class="mx-auto">
      <v-img class="mx-auto mt-5" :src="require('@/assets/Empowerment02-1000.png')" max-width="250px"></v-img>
    </v-col>
  </v-row>
</v-row>
      <v-row>
        <v-col cols="10" class="mx-auto">
        <div align="center"  style="text-align: justify; ">
          <p >
            Welcome to our global marketplace; a community of conscious buyers and sellers, Pioneering the Good
            Economy. We're committed to collaborating with all who believe in giving back. Our mission is to create,
            cultivate and curate innovative products, locally and globally, and build a bridge between the digital
            world of e-commerce and community independent retailers. We are trail-bl azing a different way of buying
            and selling what we like using our platform of community-based, experiential retail.
          </p>
        </div>
        </v-col>
      </v-row>
     <v-row >
       <v-col align="center" align-self="center">
       <v-btn class="empowerBtn mx-auto" >Learn More</v-btn>
       </v-col>
     </v-row>
    </div>
  </div>
</template>

<script>
import './_EmpowermentHero.scss'

export default {
  name: 'EmpowermentHero',
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
