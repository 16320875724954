<template>
  <v-stepper v-model="e1" light>
    <v-stepper-header class="px-10">
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Login
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Collections
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 3"
        step="3">
        Pick and Set
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4">
        Share
      </v-stepper-step>

    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <h1 class="text-center">Login to Dept. of Good
        </h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
        >

          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas">
                Go to the Create section of Dept. of Good and login using your ID4Good to authenticate - you can do this from any support browser.
              </p>
            </div>
          </div>

        </v-card>

        <v-btn
          color="primary"
          @click="e1 = 2"
          rounded
        >
          Continue
        </v-btn>

        <v-btn rounded text>
          Cancel
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <h1 class="text-center">
          Create
        </h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
        >

          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas">
                Make sure you've previously minted assets on Dept. of Good before trying to create a collection.
                Afterwards, go to My Collections and create a new one.
              </p>
            </div>
          </div>

        </v-card>

        <v-btn
          rounded
          color="primary"
          @click="e1 = 3"
        >
          Continue
        </v-btn>

        <v-btn rounded text>
          Cancel
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <h1 class="text-center">Design your space</h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
        >

          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas">
                When creating your collection, decide if it will be public or private.
                Select from creating a price for the overall collection or sell each asset individually.
                Find the metaverse space that best suits your creations.
              </p>
            </div>
          </div>

        </v-card>

        <v-btn
          rounded
          color="primary"
          @click="e1 = 4"
        >
          Continue
        </v-btn>

        <v-btn
          rounded
          text>
          Cancel
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="4">
        <h1 class="text-center">Share</h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
        >

          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas">
                Start sharing your gallery. Copy the direct link to your gallery and generate traffic to your space.
                If your space is public - let others find your space organically on Dept. of Good.
              </p>
            </div>
          </div>

        </v-card>

        <v-btn
          rounded
          color="primary"
          @click="e1 = 1"
        >
          Go to Engine
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
export default {
  name: 'createCollection',
  data () {
    return {
      e1: 1
    }
  }
}

</script>
