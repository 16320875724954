<template>
  <div class="Campaign">
    <Nav />
    <div v-if="!loading" class="float-menu">
      <v-btn to="/campaign/create" rounded color="#70AFD0" dark large class="mr-5">Create Campaign</v-btn>
    </div>
    <v-container fuild v-if="campaigns.length === 0 && !loading" class="fill-height min-height">
      <v-row style="margin-left: 42px">
        <v-col align-self="center" align="center" class="title pt-10">
          <div>OOPS!! You don't have assets campaigned</div>
          <div class="overline mt-2">
            You must first create a campaign
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fuild v-if="campaigns.length > 0 && !loading" class="min-height">
      <v-row class="pt-10">
        <v-col>
          <div class="titleSection text-h3 mb-5" align="left">My Campaigns</div>
        </v-col>
      </v-row>
      <v-row class="pt-10" style="margin-left: 42px">
        <v-col v-for="(campaign, index) in campaigns" :key="index" align-self="center" align="center" :cols="$vuetify.breakpoint.width > 1900 ? 3 : $vuetify.breakpoint.width > 1400 ? 4 : $vuetify.breakpoint.width > 960 ? 6 : 12">
          <v-container class="Card">
            <v-row dense>
              <v-col align-self="center" align="center">
                <div class="CardImages">
                  <v-img :src="campaign.preview" height="150px" width="100%" ></v-img>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col align-self="center" align="left">
                <div class="mr-2">
                  <div class="CardMain">{{ campaign.name }}</div>
                  <div class="CardSub" v-if="campaign.stats"> {{ campaign.stats.count }} views</div>
                </div>
              </v-col>
              <v-col align-self="center" align="right">
                <div class="d-inline-flex">
                  <div class="mr-2">
                    <div class="CardSub">Created</div>
                    <div class="CardSub" style="font-weight: bolder">{{ new Date(campaign.created * 1000).toLocaleString() }}</div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col align-self="center" align="center">
                <div class="CardActions">
                  <div v-if="campaign.stats" class="text-h5 pt-3 font-weight-thin">
                    <v-container fluid>
                      <v-row>
                        <v-col align-self="end">
                          <div class="CardMain">
                            {{ campaign.stats.referer.direct }}
                          </div>
                          <div class="CardBar" :style="{ height: parseInt(campaign.stats.referer.direct * 100 / campaign.stats.count) + 'px', 'background-color': 'brown' }"></div>
                          <div><v-icon color="brown">mdi-cursor-default-click</v-icon></div>
                        </v-col>
                        <v-col align-self="end">
                          <div class="CardMain">
                            {{ campaign.stats.referer.facebook }}
                          </div>
                          <div class="CardBar" :style="{ height: parseInt(campaign.stats.referer.facebook * 100 / campaign.stats.count) + 'px', 'background-color': 'blue' }"></div>
                          <div><v-icon color="blue">mdi-facebook</v-icon></div>
                        </v-col>
                        <v-col align-self="end">
                          <div class="CardMain">
                            {{ campaign.stats.referer.instagram }}
                          </div>
                          <div class="CardBar" :style="{ height: parseInt(campaign.stats.referer.instagram * 100 / campaign.stats.count) + 'px', 'background-color': 'orange' }"></div>
                          <div><v-icon color="orange">mdi-instagram</v-icon></div>
                        </v-col>
                        <v-col align-self="end">
                          <div class="CardMain">
                            {{ campaign.stats.referer.twitter }}
                          </div>
                          <div class="CardBar" :style="{ height: parseInt(campaign.stats.referer.twitter * 100 / campaign.stats.count) + 'px', 'background-color': 'cyan' }"></div>
                          <div><v-icon color="cyan">mdi-twitter</v-icon></div>
                        </v-col>
                        <v-col align-self="end">
                          <div class="CardMain">
                            {{ campaign.stats.referer.whatsapp }}
                          </div>
                          <div class="CardBar" :style="{ height: parseInt(campaign.stats.referer.whatsapp * 100 / campaign.stats.count) + 'px', 'background-color': 'green' }"></div>
                          <div><v-icon color="green">mdi-whatsapp</v-icon></div>
                        </v-col>
                        <v-col align-self="end">
                          <div class="CardMain">
                            {{ campaign.stats.referer.linkedin }}
                          </div>
                          <div class="CardBar" :style="{ height: parseInt(campaign.stats.referer.linkedin * 100 / campaign.stats.count) + 'px', 'background-color': 'cyan' }"></div>
                          <div><v-icon color="cyan">mdi-linkedin</v-icon></div>
                        </v-col>
                        <v-col align-self="end">
                          <div class="CardMain">
                            {{ campaign.stats.referer.youtube }}
                          </div>
                          <div class="CardBar" :style="{ height: parseInt(campaign.stats.referer.youtube * 100 / campaign.stats.count) + 'px', 'background-color': 'red' }"></div>
                          <div><v-icon color="red">mdi-youtube</v-icon></div>
                        </v-col>
                        <v-col align-self="end">
                          <div class="CardMain">
                            {{ campaign.stats.referer.others }}
                          </div>
                          <div class="CardBar" :style="{ height: parseInt(campaign.stats.referer.others * 100 / campaign.stats.count) + 'px', 'background-color': 'purple' }"></div>
                          <div><v-icon color="purple">mdi-more</v-icon></div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col align-self="center" align="center">
                <div class="CardActions">
                  <div class="pt-3 CampaignLink">
                    {{ getLink(campaign) }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col align-self="center" align="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small tile fab v-bind="attrs" v-on="on" @click="copy(campaign)" color="#70AFD055" class="mx-1 elevation-3">
                      <v-icon color="#70AFD0" size="28">mdi-link</v-icon>
                    </v-btn>
                  </template>
                  Copy link
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small tile fab v-bind="attrs" v-on="on" @click="setPause(campaign)" color="#70AFD055" class="mx-1 elevation-3">
                      <v-icon color="#70AFD0" size="28" v-if="!campaign.pause">mdi-pause</v-icon>
                      <v-icon color="#70AFD0" size="28" v-if="campaign.pause">mdi-play</v-icon>
                    </v-btn>
                  </template>
                  Pause Campaign
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small tile fab v-bind="attrs" v-on="on" @click="removeCollection(campaign, index)" color="#70AFD055" class="mx-1 elevation-3">
                      <v-icon color="#70AFD0" size="28">mdi-card-remove</v-icon>
                    </v-btn>
                  </template>
                  Remove Campaign
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="loading" class="fill-height mt-10 min-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Copy Success
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_Campaign.scss'
import Nav from '../../components/Nav/Nav'

export default {
  name: 'Campaign',
  components: { Nav },
  data () {
    return {
      snackbar: false,
      loading: false,
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      campaigns: []
    }
  },
  mounted () {
    this.loading = true
    this.getCampaigns()
  },
  methods: {
    getLink (campaign) {
      const base = (window.location.href.split('#'))[0]
      return base + 't/' + campaign.key
    },
    copy (campaign) {
      console.log('copy')
      const base = (window.location.href.split('#'))[0]
      navigator.clipboard.writeText(base + 't/' + campaign.key)
      this.snackbar = true
    },
    goto (campaign) {
      const base = (window.location.href.split('#'))[0]
      window.open(base + 't/' + campaign.key, '_blank')
    },
    setPublic (campaign) {
      this.$axios.post('/api/campaigns/setPublic', {
        identity: this.$store.state.identity,
        id: campaign.key,
        status: campaign.publicAccess,
        params: {
          crc: Date.now()
        }
      }).then((result) => {})
    },
    removeCollection (campaign, index) {
      this.$axios.post('/api/campaigns/removeCollection', {
        identity: this.$store.state.identity,
        id: campaign.key,
        params: {
          crc: Date.now()
        }
      }).then((result) => {
        this.campaigns.splice(index, 1)
        this.$forceUpdate()
      })
    },
    setPause (campaign) {
      campaign.pause = !campaign.pause
      this.$axios.post('/api/campaigns/setPause', {
        identity: this.$store.state.identity,
        id: campaign.key,
        status: campaign.pause,
        params: {
          crc: Date.now()
        }
      }).then((result) => {})
    },
    getCampaigns () {
      this.$axios.get('/api/campaign/getCampaigns', {
        params: {
          identity: this.$store.state.identity,
          crc: Date.now()
        }
      }).then((result) => {
        console.log(result)
        for (const campaign in result.data.campaigns) {
          const item = result.data.campaigns[campaign]
          item.key = campaign
          this.$axios.get('/api/data/getCampaignView', {
            params: {
              hash: campaign,
              crc: parseInt(Date.now() / 100000)
            }
          }).then((result) => {
            item.stats = result.data
            this.$forceUpdate()
          })
          this.campaigns.push(item)
        }
        this.loading = false
      })
    }
  }
}
</script>
