import CryptoJS from 'crypto-js'
export default {
  data () {
    return {
      assets: [],
      slides: [
        { src: 'https://i1.wp.com/painterskeys.com/wp-content/uploads/2013/03/031213_michael-schlicting.jpg' },
        { src: 'https://i1.wp.com/painterskeys.com/wp-content/uploads/2013/03/031213_tom-hoffmann.jpg' },
        { src: 'https://i1.wp.com/painterskeys.com/wp-content/uploads/2013/03/031213_susan-burns.jpg' },
        { src: 'https://princewilliamlivingweb.s3-accelerate.amazonaws.com/2021/08/69_Swart_Sunset-on-the-Danube.jpg' },
        { src: 'https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/f22-raptor-demo-j-biggadike.jpg' },
        { src: 'http://resourcemagonline.com/wp-content/uploads/2018/10/Screen-Shot-2018-10-08-at-12.59.47-AM.png' },
        { src: 'http://resourcemagonline.com/wp-content/uploads/2018/10/snelling.png' },
        { src: 'https://thumbs-prod.si-cdn.com/mEnu04_y_h8eBQGTlCYbtHcuuWA=/800x600/filters:no_upscale()/https://public-media.si-cdn.com/filer/b9/f5/b9f508e9-2e85-4fd4-b390-1ccbd1275a4d/wassily_kandinsky_aquarell_6_kunstdrucke_auf_japanpapier.jpg' },
        { src: 'https://princewilliamlivingweb.s3-accelerate.amazonaws.com/2021/08/69_Swart_Sunset-on-the-Danube.jpg' },
        { src: 'https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/f22-raptor-demo-j-biggadike.jpg' }
      ]
    }
  },
  methods: {
    async downloadAssetFile (file, key) {
      this.loadingDialog = true
      const uri = file.file_url
      const data = await this.$axios.get(uri)
      const parts = data.data
      const result = []
      parts.forEach((item) => {
        const decrypted = CryptoJS.AES.decrypt(item, key).toString(CryptoJS.enc.Utf8)
        result.push(new Uint8Array(JSON.parse(decrypted)).buffer)
      })
      const blob = new File(result, file.name, {
        type: file.type,
        lastModified: file.lastModified
      })
      const a = document.createElement('a')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = file.name
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
      this.loadingDialog = false
    },
    async decodeFile (file, key) {
      const data = await this.$axios.get(file.file_url)
      const parts = data.data
      const result = []
      parts.forEach((item) => {
        const decrypted = CryptoJS.AES.decrypt(item, key).toString(CryptoJS.enc.Utf8)
        result.push(new Uint8Array(JSON.parse(decrypted)).buffer)
      })
      const blob = new File(result, file.name, {
        type: file.type,
        lastModified: file.lastModified
      })
      return window.URL.createObjectURL(blob)
    },
    async decodeFileDecode (file, key) {
      const data = await this.$axios.get(file.file_url)
      const parts = data.data
      let result = ''
      const dec = new TextDecoder()
      parts.forEach((item) => {
        const decrypted = CryptoJS.AES.decrypt(item, key).toString(CryptoJS.enc.Utf8)
        result = result + dec.decode(new Uint8Array(JSON.parse(decrypted)).buffer)
      })
      return result
    },
    async getMetadata (url) {
      const metadataRaw = await this.$axios.get(url)
      const metadata = metadataRaw.data
      return metadata
    },
    loadAssets () {
      this.loading = true
      this.$axios.get('/api/assets/getAssetsAvailable', {
        params: {
          identity: this.$store.state.identity,
          identityWallet: this.$store.state.walletIdentity,
          address: this.$store.state.wallet,
          crc: Date.now()
        }
      }).then(async (result) => {
        this.loading = false
        const assets = result.data.assets
        for (const asset in assets) {
          assets[asset].ready = false
          const length = this.assets.push(assets[asset])
          const current = this.assets[length - 1]
          const metadataRaw = await this.$axios.get('https://asset4good.com/' + assets[asset].cid)
          const metadata = metadataRaw.data
          current.hashInternal = asset
          current.name = metadata.name
          current.creator = metadata.creator
          current.owner = metadata.owner
          current.description = metadata.description
          current.inUsed = Number(current.inUsed)
          const preview = await this.$axios.get(metadata.image_url)
          const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
          current.preview = window.URL.createObjectURL(blob)
          current.images = metadata.attributes.filter(item => item.type.includes('image'))
          current.videos = metadata.attributes.filter(item => item.type.includes('video'))
          current.renders = metadata.attributes.filter(item => item.type.includes('gltf'))
          current.pieces = metadata.attributes
          current.ready = true
          this.$forceUpdate()
        }
      })
    }
  }
}
