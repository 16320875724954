<template>
  <div class="EmpowermentBanner">
    <v-container fluid class="px-0 pb-0 ">
      <v-row class="mx-0">
        <v-col class="px-0 pb-0">
          <v-parallax ref="banner" :src="require('@/assets/hannah-busing-Zyx1bK9mqmA-unsplash.jpg')" class="filter" height="500" />
          <div align="center" class="content">
            <div class="mb-2 giving" data-aos="fade-up"
                 data-aos-offset="100"
                 data-aos-delay="30"
                 data-aos-duration="800"
                 data-aos-easing="ease-in-out"
                 data-aos-mirror="true"
                 data-aos-once="false"
                 data-aos-anchor-placement="top-center"
                 ><b>Giving</b> back for <b>Good</b>.</div>
            <div class="mb-5 restTxt" data-aos="fade-up"
                 data-aos-offset="200"
                 data-aos-delay="50"
                 data-aos-duration="1000"
                 data-aos-easing="ease-in-out"
                 data-aos-mirror="true"
                 data-aos-once="false"
                 data-aos-anchor-placement="top-center"
                 >We donate 10% of all net transactional revenue from Dept. of Good. <br> We partner with community organizations focussing on art programs and children empowerment.</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_EmpowermentBanner.scss'
export default {
  name: 'EmpowermentBanner'
}
</script>

<style scoped>

</style>
