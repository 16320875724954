<template>
  <div class="CardCollectionSlapitonComponent" @mouseover="showFullImage" @mouseleave="hiddenFullImage">
    <v-container fluid class="Card pa-6" v-if="!loading">
      <v-row dense>
        <v-col align-self="center" align="center">
          <div class="CardImages">
            <div v-for="(asset, index) in assets.slice(0, 3)" v-bind:key="'c' + index" v-show="assets.length > 1">
              <v-img :src="asset.previews[0].url" :class="{ CardImage1: index === 0, CardImage2: index === 1, CardImage3: index === 2 }"></v-img>
            </div>
            <div v-for="(preview, index) in assets[0].previews.slice(0, 3)" v-bind:key="'s' +index" v-show="assets.length === 1">
              <v-img :src="preview.url" :class="{ CardImage1: index === 0, CardImage2: index === 1, CardImage3: index === 2 }"></v-img>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col align-self="center" align="center">
          <div class="CardMain">
            {{ collection.name }}
          </div>
          <div class="CarAvatar">
            <v-avatar size="128" color="white"><v-img :src="user.avatar"></v-img></v-avatar>
          </div>
          <span class="CardSub">Created by </span>
          <span class="CardAuthor" v-if="user.name">{{ user.name.length > 14 ? user.name.substr(0, 14) + '...' : user.name }}</span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_CardCollectionSlapitonComponent.scss'
export default {
  name: 'CardCollectionSlapitonComponent',
  props: {
    hash: String
  },
  data () {
    return {
      collection: null,
      user: null,
      assets: [],
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      fullImage: false,
      hiddenFooter: false,
      transition: 'out',
      loading: true,
      defaultAvatar: require('@/assets/default_profile.svg')
    }
  },
  mounted () {
    this.loadCollection()
  },
  methods: {
    loadCollection () {
      this.$axios.get('/api/collections/getCollection', {
        params: {
          hash: this.hash,
          crc: Date.now()
        }
      }).then((result) => {
        console.log(result)
        this.user = result.data.user
        this.collection = result.data.collection
        let count = this.collection.assets.length
        for (const key in this.collection.assets) {
          this.$axios.get('/api/assets/getAssetInfo', {
            params: {
              hash: this.collection.assets[key],
              crc: parseInt(Date.now() / 100000)
            }
          }).then((result) => {
            this.assets[key] = result.data.asset
            count--
            if (count === 0) {
              this.loading = false
            }
          })
        }
      }).catch(() => {
        console.log('error')
        this.loading = false
      })
    },
    showFullImage () {
      this.fullImage = true
      this.hiddenFooter = true
    },
    hiddenFullImage () {
      this.fullImage = false
      setTimeout(() => {
        this.hiddenFooter = false
      }, 300)
    }
  }
}
</script>

<style scoped>

</style>
