<template>
    <div class="CardComponent" @mouseover="showFullImage" @mouseleave="hiddenFullImage">
        <v-container fluid class="Card">
          <v-row dense>
            <v-col align-self="center" align="center">
              <v-img class="CardImage" :max-height="fullImage ? '300px' : '250px'" :min-height="fullImage ? '300px' : '250px'" :src="image" />
            </v-col>
          </v-row>
          <v-row dense v-show="!hiddenFooter" :class="{ CardFooter: true }">
            <v-col align-self="center" align="left">
              <div class="mr-2">
                <div class="CardMain">{{ title ? title.length > 25 ? title.substr(0, 25) + '...' : title : title }}</div>
                <div class="CardSub" v-if="type === 'Collection'">Collection</div>
                <div class="CardSub" v-if="type === 'Single' && pieces > 1">{{ pieces }} pieces / {{ available }} available</div>
                <div class="CardSub" v-if="type === 'Single' && pieces === 1">Unique</div>
              </div>
            </v-col>
            <v-col align-self="center" align="right" cols="4">
              <div class="d-inline-flex">
                <div class="mr-2">
                  <div class="CardSub">Created by</div>
                  <div class="CardAuthor" v-if="author">{{ author.length > 10 ? author.substr(0, 8) + '_' : author }}</div>
                </div>
                <v-avatar size="32"><v-img :src="avatar ? avatar : defaultAvatar" contain max-height="32px" max-width="32px" /></v-avatar>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="description">
            <v-col align="left" class="font-weight-thin">
              {{ description ? description.length > 100 ? description.substr(0, 100) + '...' : description : description }}
            </v-col>
          </v-row>
          <v-row v-if="actions">
            <v-col class="CarActions">
              <slot name="actions"></slot>
            </v-col>
          </v-row>
        </v-container>
      </div>
</template>

<script>
import './_CardComponent.scss'
export default {
  name: 'CardComponent',
  props: {
    title: String,
    image: String,
    author: String,
    description: String,
    actions: Boolean,
    avatar: {
      type: String,
      default: undefined
    },
    type: String,
    pieces: Number,
    available: Number
  },
  data () {
    return {
      fullImage: false,
      hiddenFooter: false,
      transition: 'out',
      defaultAvatar: require('@/assets/default_profile.svg')
    }
  },
  mounted () {
    console.log(this.image)
  },
  methods: {
    showFullImage () {
      this.fullImage = true
      this.hiddenFooter = true
    },
    hiddenFullImage () {
      this.fullImage = false
      setTimeout(() => {
        this.hiddenFooter = false
      }, 300)
    }
  }
}
</script>
