<template>
    <div class="CardAquariumComponent" @mouseover="showFullImage" @mouseleave="hiddenFullImage">
        <v-container fluid :class="{ Card: true, 'pa-6': true, CardPhysical: category === 'Physical', CardHybrid: category === 'Hybrid', CardDigital: category === 'Digital' }">
          <v-row dense>
            <v-col align-self="center" align="center">
              <v-img class="CardImage" :max-height="fullImage ? '450px' : '300px'" :min-height="fullImage ? '450px' : '300px'" :src="image">
                <v-img v-if="item.collection.labels.indexOf('SharkCon') >= 0" :src="require('@/assets/aquarium/logosharkcon.png')" max-height="150px" contain position="right" style="margin-top: 75px; filter: drop-shadow(0 0 10px #ffffff)" />
              </v-img>
            </v-col>
          </v-row>
          <v-row v-show="!hiddenFooter" :class="{ CardFooter: true }">
            <v-col align-self="center" align="left">
              <div class="mr-2" style="margin-bottom: 10px">
                <div class="CardMain">{{ title ? title.length > 40 ? title.substr(0, 40) + '...' : title : title }}</div>
                <div style="display: flex;justify-content: space-between">
                  <div class="CardSub Small">SKU {{ item.metadata.sku ? item.metadata.sku : item.sku }}</div>
                  <div align="right">
                    <div class="CardSub Small" v-if="type === 'Collection'">Collection</div>
                    <div class="CardSub Small" v-if="item.collection.labels.indexOf('Collection') >= 0">Collection</div>
                    <div class="CardSub Small" v-if="type === 'Single' && pieces > 1 && item.collection.labels.indexOf('Collection') < 0">Single</div>
                    <div class="CardSub Small" v-if="type === 'Single' && pieces === 1">Unique</div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-show="!hiddenFooter" :class="{ CardFooter: true }">
            <v-col align="left">
              <div class="d-inline-flex">
                <v-avatar size="48" tile class="rounded-lg"><v-img :src="avatar ? avatar : defaultAvatar" contain max-height="48px" max-width="48px" /></v-avatar>
                <div class="ml-2">
                  <div class="CardSub">Created by</div>
                  <div class="CardAuthor" v-if="author">{{ author.length > 14 ? author.substr(0, 14) + '...' : author }}</div>
                </div>
              </div>
            </v-col>
            <v-col align="right" cols="5">
              <div class="mr-2">
                <div class="CardSub">Price</div>
                <div class="CardAuthor">$ {{ item.collection.individual ? item.collection.prices[item.hash] : item.collection.price }} <v-img class="d-inline-flex" :src="require('@/assets/t4g.svg')" max-width="16px" max-height="16px"></v-img></div>
                <div class="CardSub Small">{{ (currencies.find(currency => currency.code === item.collection.currency)).name }}</div>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-btn rounded x-large color="#5BB0FE" dark class="">Buy Now</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="description && false">
            <v-col align="left" class="font-weight-thin">
              {{ description }}
            </v-col>
          </v-row>
          <v-row v-if="actions">
            <v-col class="CarActions">
              <slot name="actions"></slot>
            </v-col>
          </v-row>
        </v-container>
      </div>
</template>

<script>
import './_CardAquariumComponent.scss'
export default {
  name: 'CardAquariumComponent',
  props: {
    title: String,
    image: String,
    author: String,
    description: String,
    actions: Boolean,
    item: Object,
    avatar: {
      type: String,
      default: undefined
    },
    type: String,
    pieces: Number,
    available: Number
  },
  data () {
    return {
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      fullImage: false,
      hiddenFooter: false,
      transition: 'out',
      defaultAvatar: require('@/assets/default_profile.svg'),
      category: null
    }
  },
  mounted () {
    console.log(this.item.collection.labels)
    this.item.collection.labels.forEach(item => {
      if (item === 'Digital') {
        this.category = 'Digital'
      }
      if (item === 'Hybrid') {
        this.category = 'Hybrid'
      }
      if (item === 'Physical') {
        this.category = 'Physical'
      }
    })
  },
  methods: {
    showFullImage () {
      this.fullImage = true
      this.hiddenFooter = true
    },
    hiddenFullImage () {
      this.fullImage = false
      setTimeout(() => {
        this.hiddenFooter = false
      }, 300)
    }
  }
}
</script>
