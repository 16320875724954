import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Main from '../views/Main/Main'
import MyArts from '../views/MyArts/MyArts'
import Publish from '../views/Publish/Publish'
import Cash from '../views/Cash/Cash'
import Profile from '../views/Profile/Profile'
import CreatePublish from '../views/Publish/CreatePublish/CreatePublish'
import Previews from '../views/MyArts/Previews/Previews'
import Reviews from '../views/MyArts/Reviews/Reviews'
import BuyToken from '../views/MyArts/BuyToken/BuyToken'
import Collection from '../views/Collection/Collection'
import Payment from '../views/Payment/Payment'
import Virtual from '../views/Collection/Virtual/Virtual'
import Mint from '../views/MyArts/Mint/Mint'
import Rocarbon from '../views/Rocarbon/Rocarbon'
import TestView from '../views/TestView/TestView'
import Landing from '../views/Landing/Landing'
import Empowerment from '../views/Empowerment/Empowerment'
import Resources from '../views/Resources/Resources'
import Campaign from '../views/Campaign/Campaign'
import CreateCampaign from '../views/Campaign/CreateCampaign/CreateCampaign'
import MainMobile from '../views/MainMobile/MainMobile'
import GetStarted from '../views/GetStarted/GetStarted'
import Aquarium from '../views/Aquarium/Aquarium'
import AquariumAboutView from '../views/Aquarium/AquariumAboutView/AquariumAboutView'
import AquariumHelpView from '../views/Aquarium/AquariumHelpView/AquariumHelpView'
import SlapItOn from '../views/SlapItOn/SlapItOn'
import SlapItOnHelpView from '../views/SlapItOn/SlapItOnHelpView/SlapItOnHelpView'
import SlapItOnAboutView from '../views/SlapItOn/SlapItOnAboutView/SlapItOnAboutView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/main/mobile',
    name: 'MainMobile',
    component: MainMobile,
    meta: {
      mobile: true
    }
  },
  {
    path: '/empowerment',
    name: 'Empowerment',
    component: Empowerment
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources
  },
  {
    path: '/getstarted',
    name: 'Get Started',
    component: GetStarted
  },
  {
    path: '/testview',
    name: 'TestView',
    component: TestView
  },
  {
    path: '/m/rocarbon',
    name: 'Rocarbon',
    component: Rocarbon
  },
  {
    path: '/m/aquarium',
    name: 'Aquarium',
    component: Aquarium
  },
  {
    path: '/m/slapiton',
    name: 'SlapItOn',
    component: SlapItOn
  },
  {
    path: '/m/aquarium/about',
    name: 'AquariumAbout',
    component: AquariumAboutView
  },
  {
    path: '/m/slapiton/about',
    name: 'SlapItOnAbout',
    component: SlapItOnAboutView
  },
  {
    path: '/m/aquarium/help',
    name: 'AquariumHelp',
    component: AquariumHelpView
  },
  {
    path: '/m/slapiton/help',
    name: 'SlapItOnHelp',
    component: SlapItOnHelpView
  },
  {
    path: '/c/:id',
    name: 'Collection',
    component: Collection
  },
  {
    path: '/v/:id',
    name: 'Virtual',
    component: Virtual
  },
  {
    path: '/v/aquarium/:id',
    name: 'VirtualAquarium',
    component: Virtual
  },
  {
    path: '/p/wishlist',
    name: 'PaymentWishlist',
    component: Payment,
    meta: {
      wishlist: true
    }
  },
  {
    path: '/p/:id',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/p/:id/:index',
    name: 'PaymentIndividual',
    component: Payment,
    meta: {
      individual: true
    }
  },
  {
    path: '/p/:id/finish/:hash',
    name: 'PaymentFinish',
    component: Payment,
    meta: {
      finish: true
    }
  },
  {
    path: '/p/:id/:index/finish/:hash',
    name: 'PaymentIndividualFinish',
    component: Payment,
    meta: {
      individual: true,
      finish: true
    }
  },
  {
    path: '/myassets',
    name: 'MyArts',
    meta: {
      auth: true
    },
    component: MyArts
  },
  {
    path: '/myassets/buytoken',
    name: 'BuyToken',
    meta: {
      auth: true
    },
    component: BuyToken
  },
  {
    path: '/myassets/mint',
    name: 'Mint',
    meta: {
      auth: false
    },
    component: Mint
  },
  {
    path: '/myassets/previews',
    name: 'Previews',
    meta: {
      auth: true
    },
    props: true,
    component: Previews
  },
  {
    path: '/myassets/reviews',
    name: 'Reviews',
    meta: {
      auth: true
    },
    props: true,
    component: Reviews
  },
  {
    path: '/publish',
    name: 'Publish',
    meta: {
      auth: true
    },
    component: Publish
  },
  {
    path: '/publish/create',
    name: 'CreatePublish',
    meta: {
      auth: true
    },
    props: true,
    component: CreatePublish
  },
  {
    path: '/campaign',
    name: 'Campaign',
    meta: {
      auth: true
    },
    component: Campaign
  },
  {
    path: '/campaign/create',
    name: 'CreateCampaign',
    meta: {
      auth: true
    },
    props: true,
    component: CreateCampaign
  },
  {
    path: '/cash',
    name: 'Cash',
    meta: {
      auth: true
    },
    component: Cash
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      auth: true,
      single: true
    },
    component: Profile
  }
]

const domain = window.location.host

if (domain === 'slapiton.com' || domain === 'slapitonofficial.com' || domain === 'beta.slapiton.com') {
  routes.forEach(item => {
    if (item.path === '/') {
      item.name = 'LandingSlapItOn'
      item.component = SlapItOn
    }
  })
}

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && to.meta.single && !store.state.identity) {
    next({ name: 'Main' })
  }
  if (to.meta.auth && !store.state.identity && !store.state.walletIdentity) {
    next({ name: 'Main' })
  }
  next()
})

export default router
