<template>
  <div class="ArYourWorldComponent">
      <v-container fluid class="my-10">
        <v-row>
          <v-col align-self="center" align="center" cols="12" md="6">
            <div class="Content mx-auto mt-5" align="center">
              <v-img align-self="center" align="center" :src="require('@/assets/slapItOn/ayw-01.png')" max-width="650px" width="80vw" contain style="text-align: center" ></v-img>
              <div class="TextAr" align="left" v-if="$vuetify.breakpoint.width > 600">
                Create your very own augmented reality Aquarium with<br>
                SlapItOn activated prints in 4 different sizes to choose from!
                <a @click="$store.state.aquarium.download = true">
                <v-img class="mt-8" :src="require('@/assets/slapItOn/gettheapp.svg')" contain max-width="150px"></v-img>
                </a>
              </div>
            </div>
          </v-col>
          <v-col align-self="center" align="center">
            <v-carousel   delimiter-icon="mdi-checkbox-blank-circle-outline" v-model="index" :hide-delimiter-background="true" :show-arrows-on-hover="true" :show-arrows="true" class="Carousel" cycle :interval="45000">
              <v-carousel-item v-for="(item, index) in items" v-bind:key="index">
                <v-container fluid>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <video :ref="'vc2'+index" class="Video" preload="auto" :poster="item.poster" style="width: inherit; max-width: 889px; max-height: 500px">
                        <source :src="item.video" type="application/x-mpegURL">
                      </video>
                      <v-btn :ref="'bvc'+index" rounded x-large color="red" dark class="Play" @click="play('vc2'+index, 'bvc'+index)">Play</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_ArYourWorldComponent.scss'
import videojs from 'video.js'

export default {
  name: 'ArYourWorldComponent',
  data () {
    return {
      items: [
        { video: 'https://cloudflarestream.com/8f549ca0dc8d0f8acbed0640879642e9/manifest/video.m3u8', poster: require('@/assets/aquarium/videos/brettPoster.png') },
        { video: 'https://cloudflarestream.com/9b13d54e31bfd01bcb465d9ede09c9ea/manifest/video.m3u8', poster: require('@/assets/aquarium/videos/kidsPoster.png') }
      ]
    }
  },
  mounted () {
  },
  methods: {
    play (value, button) {
      const player = videojs(this.$refs[value][0])
      player.play()
      // this.$refs[value][0].play()
      this.$refs[value][0].controls = true
      this.$refs[button][0].$el.style.display = 'none'
    }
  }
}
</script>
