<template>
    <div class="SeparatorAquariumComponent">
        <div v-for="item of items" v-bind:key="item.toString()" class="ImageBox">
          <v-img class="Image" :src="require('@/assets/aquarium/bar/' + parseFloat(item + 1) + '.jpg')" max-height="100px" min-width="100px" height="100px" width="100px" aspect-ratio="1" />
        </div>
    </div>
</template>

<script>
import './_SeparatorAquariumComponent.scss'
export default {
  name: 'SeparatorAquariumComponent',
  data () {
    return {
      items: [...Array(22).keys()]
    }
  }
}
</script>
