<template>
    <div class="AquariumAboutView">
        <v-container fluid class="ma-0 pa-0">
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
              <AquariumSeriesComponent />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
              <div style="color: white;max-width: 755px" class="my-10">
                <div id="comp-kh3mee3u" class="_1Q9if _3bcaz" data-testid="richTextElement"><h3 class="font_3" style="font-size:60px;"><span style="font-size:60px;">About Us:</span></h3></div>
                <div id="comp-kh3mf61s" class="_1Q9if _3bcaz" data-testid="richTextElement"><p class="font_7" style="font-size:22px;"><span style="color:#F60419;">SlapItOnAR</span> is owned and operated by true legends in the world of sports in the United States. The company was started by NFL All-Pro Placekicker Mike Vanderjagt with a desire to create a new dynamic engagement platform for fans of sports, entertainment, fitness and influencers. Mike chatted with a few of his close friends including Mike Modano and Johnny Damon, who also happen to be truly legendary in their respective sports, and <span style="color:#E21C21;">SlapItOnAR</span> launched in 2020. By Integrating laser-printed life-size stickers with mobile augmented reality, a new digital channel of fan engagement has emerged for truly interactive and immersive experiences.</p></div>
                <v-img :src="require('@/assets/slapItOn/Owners_Top_Row.webp')" contain max-width="100%"></v-img>
                <v-img :src="require('@/assets/slapItOn/Owners_Bottom_Row.webp')" contain max-width="100%"></v-img>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
              <SeparatorAquariumComponent />
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_AquariumAboutView.scss'
import AquariumSeriesComponent from '../../../components/Aquarium/AquariumSeriesComponent/AquariumSeriesComponent'
import SeparatorAquariumComponent from '../../../components/Aquarium/SeparatorAquariumComponent/SeparatorAquariumComponent'

export default {
  name: 'AquariumAboutView',
  components: { SeparatorAquariumComponent, AquariumSeriesComponent },
  mounted () {
    this.setColors()
  }
}
</script>
