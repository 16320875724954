<template>
  <div class="CreatePublish">
    <div v-if="!loading && !loadingCreate" class="float-menu">
      <v-btn class="closeButtonIcon mr-5" to="/publish" x-small fab color="#ffffff55">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn rounded :disabled="name.length === 0 || description.length === 0 || (!individual && price == '0' && !exhibition) || (individual && !readyItems && !exhibition)" color="#70AFD0" class="mr-5" @click="create">Create</v-btn>
    </div>
    <Nav />
    <v-container fluid v-if="loadingCreate" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loadingCreate"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="fill-height" v-if="!loadingCreate">
      <v-row class="pt-10">
        <v-col cols="1"></v-col>
        <v-col align-self="center" align="center">
          <div class="titleSection text-h3 mb-5" align="left">Create Publish</div>
          <v-text-field v-model="name" label="Publish Name" filled rounded></v-text-field>
          <v-textarea v-model="description" label="Description" filled rounded></v-textarea>
          <v-combobox
            v-model="labels"
            item-color="color"
            item-text="value"
            chips
            clearable
            label="Labels"
            multiple
            filled rounded
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                color="#70AFD0"
                text-color="white"
                @click:close="removeLabel(item)"
              >
                <strong>{{ item }}</strong>
              </v-chip>
            </template>
          </v-combobox>
          <div>
            <v-select v-if="$vuetify.breakpoint.width < 960" :disabled="exhibition" label="Currency" :items="currencies" item-text="name" item-value="code" v-model="currency" filled rounded></v-select>
          </div>
          <div :class="{ 'd-inline-flex': $vuetify.breakpoint.width > 960 }">
            <v-switch class="switch mx-5" label="Shipping" v-model="shipping">
            </v-switch>
            <v-switch class="switch mx-5" label="Exhibition" v-model="exhibition" @click="modeExhibition()">
            </v-switch>
            <v-switch class="switch mx-5" label="Public" v-model="publicAccess">
            </v-switch>
            <v-switch class="switch mx-5" label="Sell All" v-model="all">
            </v-switch>
            <v-select v-if="$vuetify.breakpoint.width >= 960" :disabled="exhibition" label="Currency" :items="currencies" item-text="name" item-value="code" v-model="currency" filled rounded></v-select>
          </div>
          <div :align="$vuetify.breakpoint.width > 960 ? 'left' : 'center'">
            <div style="align-self: center">
              <div :class="{ 'd-inline-flex': $vuetify.breakpoint.width > 960 }" >
                <v-text-field class="priceSection mr-5 my-2" :disabled="exhibition" v-model="units" type="number" prefix="Quantity" :min="1" filled rounded hide-details suffix="Units" v-if="!all">
                </v-text-field>
                <v-text-field class="priceSection mr-5 my-2" :disabled="exhibition" v-model="price" prefix="Publish Price" filled rounded hide-details :suffix="currencies.find(item => item.code === currency).name">
                </v-text-field>
              </div>
              <div align="right" class="overline ml-2" v-if="!exhibition" style="align-self: center">
                fee: ${{ price * 0.10 }} {{ currencies.find(item => item.code === currency).name }}
              </div>
            </div>
            <div style="align-self: center">

            </div>
          </div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.width < 1600 ? 12 : 3" class="mt-16">
          <div class="galleryTemplate">
            <div align="center" class="text-h5">Virtual Gallery</div>
            <div align="center">
              <v-carousel height="300" style="max-width: 500px" v-model="template" hide-delimiters>
                <v-carousel-item
                  v-for="(item,i) in templates"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                ></v-carousel-item>
              </v-carousel>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="left" class="titleSection text-h4 ml-15 pl-10 mt-10">
          Available Assets
        </v-col>
      </v-row>
      <v-row v-if="loading">
          <v-col align-self="center" align="center">
            <v-progress-circular v-if="loading"
                                 indeterminate
                                 color="black lighten-5"
                                 size="128"
                                 width="15"
            ></v-progress-circular>
          </v-col>
      </v-row>
      <v-row v-if="assets.length === 0 && !loading">
        <v-col align-self="center" align="center" class="title">
          <div>OOPS!! You don't have DAFs available</div>
          <div class="overline mt-2">
            First you need to publish your DAFs from your Wallet4Good
          </div>
        </v-col>
      </v-row>
      <v-row v-if="assets.length > 0 && !loading" class="mx-15">
        <v-col v-for="(asset, index) in assets.filter(item => exhibition || !item.inUsed || item.inUsed < item.balance)" :key="index" align-self="center" align="center" :cols="$vuetify.breakpoint.mdAndUp ? 4 : $vuetify.breakpoint.smAndUp ? 6 : $vuetify.breakpoint.width > 700 ? 6 : 12">
          <CardComponent :class="{ 'assetSelected': asset.selected }" @click.native="updateSelected(asset)" :title="asset.name" :image="asset.preview" :author="asset.creator" :avatar="asset.avatar" :type="'Single'" :pieces="parseFloat(asset.quantity)" :available="asset.balance - asset.inUsed"></CardComponent>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Successful creation
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_CreatePublish.scss'
import assets from '../../../mixins/assets'
import Nav from '../../../components/Nav/Nav'
import CardComponent from '../../../components/CardComponent/CardComponent'

export default {
  name: 'CreatePublish',
  components: { CardComponent, Nav },
  props: {
    asset: Object
  },
  data () {
    return {
      exhibition: false,
      readyItems: false,
      snackbar: false,
      name: '',
      description: '',
      price: 0,
      individual: false,
      publicAccess: false,
      shipping: false,
      all: false,
      units: 1,
      currency: 't4g',
      template: '00',
      category: null,
      labels: [],
      categories: ['General', 'Rocarbon'],
      templates: [
        { code: '00', src: require('@/assets/templates/00.png') },
        { code: '01', src: require('@/assets/templates/01.png') },
        { code: '02', src: require('@/assets/templates/02.png') },
        { code: '03', src: require('@/assets/templates/03.png') },
        { code: '04', src: require('@/assets/templates/04.png') },
        { code: '06', src: require('@/assets/templates/06.png') },
        { code: '07', src: require('@/assets/templates/07.png') },
        { code: '09', src: require('@/assets/templates/09.png') },
        { code: '013', src: require('@/assets/templates/013.png') },
        { code: '011', src: require('@/assets/templates/011.png') },
        { code: '014', src: require('@/assets/templates/014.png') },
        { code: '017', src: require('@/assets/templates/017.png') },
        { code: '018', src: require('@/assets/templates/018.png') },
        { code: '019', src: require('@/assets/templates/019.png') },
        { code: '020', src: require('@/assets/templates/020.png') }
        /*
        { code: '05', src: require('@/assets/templates/05.png') },
        { code: '06', src: require('@/assets/templates/06.png') },
        { code: '07', src: require('@/assets/templates/07.png') },
        { code: '09', src: require('@/assets/templates/09.png') },
        { code: '010', src: require('@/assets/templates/010.png') },
        { code: '011', src: require('@/assets/templates/011.png') },
        { code: '012', src: require('@/assets/templates/012.png') },
        { code: '013', src: require('@/assets/templates/013.png') }
        * */
      ],
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      loading: false,
      loadingCreate: false
    }
  },
  mounted () {
    if (this.asset) {
      this.name = this.asset.name
      this.description = this.asset.description
    }
    this.loadAssets()
  },
  mixins: [assets],
  watch: {
    loading (val) {
      if (!val) {
        setTimeout(() => {
          for (const asset of this.assets) {
            console.log(asset)
            this.checkSelected(asset)
          }
        }, 1500)
      }
    }
  },
  methods: {
    updateSelected (asset) {
      asset.selected = !asset.selected
      this.$forceUpdate()
    },
    removeLabel (item) {
      this.labels.splice(this.labels.indexOf(item), 1)
      this.labels = [...this.labels]
    },
    modeExhibition () {
      this.individual = false
    },
    changeIndividual () {
      console.log('change')
      if (this.individual) {
        let readyItems = true
        for (const asset of this.assets) {
          if (!asset.price && asset.selected) {
            asset.price = 0
            asset.available = 1
            readyItems = false
          }
        }
        this.readyItems = readyItems
      }
    },
    checkSelected (asset) {
      console.log('load')
      if (this.asset) {
        if (asset.hashInternal === this.asset.hashInternal) {
          console.log('mash')
          asset.selected = true
          this.$forceUpdate()
        }
      }
    },
    fixAvailable (asset) {
      asset.available = Number(asset.available).toFixed(0)
      if (asset.available <= 0) {
        asset.available = 1
      }
      if (asset.available >= asset.balance - asset.inUsed) {
        asset.available = asset.balance - asset.inUsed
      }
      this.$forceUpdate()
    },
    create () {
      this.loadingCreate = true
      const publish = {
        name: this.name,
        description: this.description,
        price: this.price,
        currency: this.currency,
        labels: this.labels,
        publicAccess: this.publicAccess,
        shipping: this.shipping,
        all: this.all,
        units: this.units,
        exhibition: this.exhibition,
        category: this.category,
        template: this.templates[this.template].code,
        assets: [],
        prices: {},
        unit: {}
      }
      for (const asset of this.assets) {
        if (asset.selected) {
          publish.assets.push(asset.hashInternal)
          publish.prices[asset.hashInternal] = asset.price
          publish.unit[asset.hashInternal] = asset.available ? asset.available : 1
        }
      }
      this.$axios.post('/api/publish/createPublish', {
        identity: this.$store.state.identity,
        publish: publish,
        params: {
          crc: Date.now()
        }
      }).then((result) => {
        this.loadingCreate = false
        this.snackbar = true
        setTimeout(() => {
          this.$router.push({ name: 'Publish' })
        }, 1000)
      })
    }
  }
}
</script>
