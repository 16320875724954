<template>
    <div class="Aquarium">
      <v-container fluid class="ma-0 pa-0">
        <v-row dense>
          <v-col align-self="center" align="center" class="pa-0">
            <TopBannerComponent />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="center" align="center" class="pa-0">
            <AquariumSeriesComponent />
          </v-col>
        </v-row>
        <v-row id="Story" dense>
          <v-col align-self="center" align="center" class="pa-0">
            <div class="ml-10" align="left"><h1 class="trending">Stories</h1></div>
            <StoriesComponent v-on:selectStory="selectStory" />
          </v-col>
        </v-row>
        <v-row id="Products" dense style="background-color: #ffffff1a" v-if="slider.top.length === 0">
          <v-col align-self="center" align="center" class="pa-0">
            <div align="center" v-if="story"><h1 class="trending" style="font-size: 4rem; margin-top: 150px !important;">{{ story }} Story</h1></div>
            <div align="center"><h1 class="trending">Coming Soon</h1></div>
          </v-col>
        </v-row>
        <v-row id="Products" dense style="background-color: #ffffff1a" v-show="slider.top.length > 0">
          <v-col align-self="center" align="center" class="pa-0">
            <div align="center" v-if="story"><h1 class="trending" style="font-size: 4rem; margin-top: 150px !important;">{{ story }} Story</h1></div>
            <div class="ml-10" align="left"><h1 class="trending">Products</h1></div>
            <ProductSliderComponent :items="slider.top" v-if="slider.top.length > 0 && !loading" />
          </v-col>
        </v-row>
        <v-row dense id="Videos">
          <v-col align-self="center" align="center" class="pa-0">
            <VideoClipsComponent />
          </v-col>
        </v-row>
        <v-row dense style="background-color: #ffffff1a" v-if="slider.top.length > 0">
          <v-col align-self="center" align="center" class="pa-0">
            <div class="ml-10" align="left"><h1 class="trending">Top Picks</h1></div>
            <TopPicksComponent :items="slider.top" v-if="slider.top.length > 0 && !loading" />
          </v-col>
        </v-row>
        <v-row dense v-if="!story && slider.top.length > 0 && $vuetify.breakpoint.width > 600">
          <v-col align-self="center" align="center" class="pa-0">
            <div class="ml-10" align="left"><h1 class="trending">Popular Collections</h1></div>
            <PopularCollectionsComponent :items="slider.top.filter(item => item.type === 'Collection' || item.collection.labels.indexOf('Collection') > 0)" v-if="slider.top.length > 0 && !loading" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="center" align="center" class="pa-0">
            <SeparatorAquariumComponent />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="center" align="center" class="pa-0">
            <SubscribeAquariumComponent />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_Aquarium.scss'
import TopBannerComponent from '../../components/Aquarium/TopBannerComponent/TopBannerComponent'
import AquariumSeriesComponent from '../../components/Aquarium/AquariumSeriesComponent/AquariumSeriesComponent'
import ProductSliderComponent from '../../components/Aquarium/ProductSliderComponent/ProductSliderComponent'
import assets from '../../mixins/assets'
import VideoClipsComponent from '../../components/Aquarium/VideoClipsComponent/VideoClipsComponent'
import TopPicksComponent from '../../components/Aquarium/TopPicksComponent/TopPicksComponent'
import PopularCollectionsComponent from '../../components/Aquarium/PopularCollectionsComponent/PopularCollectionsComponent'
import SeparatorAquariumComponent from '../../components/Aquarium/SeparatorAquariumComponent/SeparatorAquariumComponent'
import SubscribeAquariumComponent from '../../components/Aquarium/SubscribeAquariumComponent/SubscribeAquariumComponent'
import StoriesComponent from '../../components/Aquarium/StoriesComponent/StoriesComponent'

export default {
  name: 'Aquarium',
  components: { StoriesComponent, SubscribeAquariumComponent, SeparatorAquariumComponent, PopularCollectionsComponent, TopPicksComponent, VideoClipsComponent, ProductSliderComponent, AquariumSeriesComponent, TopBannerComponent },
  data () {
    return {
      loading: true,
      story: null,
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      slider: {
        top: []
      }
    }
  },
  metaInfo: {
    title: 'SlapItOn :: Aquarium',
    meta: [
    ]
  },
  mixins: [assets],
  mounted () {
    this.getTopSelling()
  },
  methods: {
    scroll (id) {
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth'
        })
      }, 800)
    },
    selectStory (story) {
      console.log(story)
      this.loading = true
      this.story = story
      this.slider.top = []
      this.getTopSelling([story, 'Aquarium'])
      this.scroll('Products')
    },
    getTopSelling (filter) {
      this.$axios.get('/api/data/getTopSelling', {
        params: {
          filter: filter || 'Aquarium'
        }
      }).then(async (result) => {
        for (const item of result.data.items) {
          item.metadata = await this.getMetadata('https://asset4good.com/' + item.cid)
          item.title = item.metadata.name ? item.metadata.name : item.collection.name
          item.author = item.identity.name
          item.avatar = item.identity.avatar
          item.pieces = parseFloat(item.metadata.quantity)
          item.available = parseFloat(1)
          item.type = item.collection.count > 1 ? 'Collection' : 'Single'
          item.image = item.previews ? item.previews[0].url : item.metadata.image_url
          this.slider.top.push(item)
        }
        this.loading = false
      })
    }
  }
}
</script>
