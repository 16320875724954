<template>
  <div class="VideoPlayer" :style="{ backgroundImage: 'url(' + poster + ')', backgroundSize: 'cover', 'border-radius': radius }">
    <video ref="videoPlayer" class="video-js" :style="{ maxHeight: height, maxWidth: width, width: width, height: height, 'backdrop-filter': 'blur(20px)', 'border-radius': radius }"></video>
  </div>
</template>

<script>
import './_VideoPlayer.scss'
import videojs from 'video.js'

export default {
  name: 'VideoPlayer',
  props: {
    src: {
      type: String,
      default () {
        return ''
      }
    },
    height: {
      type: String,
      default () {
        return '480'
      }
    },
    width: {
      type: String,
      default () {
        return '600'
      }
    },
    poster: {
      type: String,
      default () {
        return null
      }
    },
    radius: {
      type: String,
      default () {
        return '0'
      }
    }
  },
  data () {
    return {
      player: null,
      options: {
        height: 480,
        width: 600,
        autoplay: true,
        controls: true,
        loop: true,
        sources: [
        ]
      }
    }
  },
  mounted () {
    this.options.height = this.height
    this.options.width = this.width
    if (this.poster) {
      this.options.poster = this.poster
    }
    console.log(this.options)
    this.options.sources.push({ src: this.src, type: 'video/mp4' })
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady () {
      console.log('onPlayerReady', this)
      setTimeout(() => {
        this.play()
      }, 100)
    })
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>
