<template>
  <div>
    <v-row>
      <v-col cols="12">
        <br><br>
    <h1 style="text-align: center; color:#36657E;font-family: 'Verdana'">A Greener Choice</h1><br></v-col>
    </v-row>
    <v-row style="text-align: center; position: relative;top:0;">
      <v-col cols="12">
        <v-card class="mx-2" style="display: inline-block; width: 250px; border-radius: 10%; height: 250px;" elevation="2"> <i class="fas fa-car-side" style="color: white;"></i>
          <p class="txtLearn2">
            326 miles <br> drive by an average passenger vehicle
          </p></v-card>
        <v-card class="mx-2" style="display: inline-block; width: 250px;border-radius: 10%; height: 250px;" elevation="2">
          <i class="fas fa-battery-full" style="color: white"></i>
          <p class="txtLearn2">
            15,782 <br> smartphones charged
          </p>
        </v-card>
        <v-card class="mx-2" style="display: inline-block; width: 250px;border-radius: 10%; height: 250px;" elevation="2">
          <i class="far fa-lightbulb" style="color:white;"></i>
          <p class="txtLearn2">
            127 days <br> runtime of a 60W light bulb
          </p>
        </v-card>
      </v-col>
    </v-row>
</div>
</template>

<script>
export default {
  name: 'WhyDept'
}
</script>

<style scoped>

</style>
