<template>
  <v-app>
    <AppBar v-if="!$store.state.hiddenMenus || $route.path !== '/main/mobile'" elevation="2" />
    <v-main class="contentMain" :style="$store.state.hiddenBar ? 'padding: 0 0 0 0' : ''" v-if="$route.path !== '/'">
      <router-view/>
    </v-main>
    <v-main  v-if="$route.path === '/'">
      <router-view/>
    </v-main>
    <!-- Custom component Start -->
    <CardDetailComponent v-if="$store.state.cardDetail" />
    <DownloadAquariumAppComponent v-if="$store.state.aquarium.download" />
    <!-- Custom component End -->
    <FooterComponent v-if="!$store.state.hiddenFooter && $route.name !== 'Landing'" />
    <!---- Landing Footer ---->
    <!---- END OF LANDING FOOTER ---->
    <!-- Custom Layout -->
  </v-app>
</template>

<script>

import AppBar from './components/AppBar/AppBar'
import { mapState } from 'vuex'
import FooterComponent from './components/FooterComponent/FooterComponent'
import CardDetailComponent from './components/CardDetailComponent/CardDetailComponent'
import colors from './mixins/colors'
import DownloadAquariumAppComponent from './components/Aquarium/DownloadAquariumAppComponent/DownloadAquariumAppComponent'
export default {
  name: 'App',
  components: { DownloadAquariumAppComponent, CardDetailComponent, FooterComponent, AppBar },
  data: () => ({
    version: process.env.VUE_APP_VERSION,
    hidden: false
  }),
  mounted () {
    this.onScreenOrientationChangeEvent()
    window.addEventListener('orientationchange', this.onScreenOrientationChangeEvent)
  },
  mixins: [colors],
  watch: {
    modeVR: {
      deep: true,
      handler: function () {
        this.onScreenOrientationChangeEvent()
      }
    }
  },
  computed: {
    ...mapState(
      {
        modeVR: state => state.modeVR
      }
    )
  },
  methods: {
    onScreenOrientationChangeEvent () {
      const orientation = window.orientation || 0
      if (orientation === 90 || orientation === -90) {
        if (this.$store.state.modeVR) {
          console.log('hidden')
          this.$store.state.hiddenBar = true
        }
      } else {
        this.$store.state.hiddenBar = false
      }
    }
  }
}
</script>

<style>
:root {
  --app-background-color: unset;
  --app-primary-color: unset;
  --app-secondary-color: unset;
  --app-header-color: unset;
  --app-footer-color: unset;
  --app-header-font-color: unset;
  --app-font-color: unset;
  --app-background-image: url('./assets/background.svg');
}

body {
  background-color: #eef6f9;
  overscroll-behavior-y: contain;
}

#app {
  background: var(--app-background-image);
  background-size: cover;
  transition: background-color 1s;
}

#app:before {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: inherit;
  content: ' ';
}

#app {
  background-color: var(--app-background-color);
}

.footer {
  background-color: transparent !important;
}

.contentMain {
  min-height: 500px !important;
}

@font-face {
  font-family: 'Gibson';
  src: url('/assets/Fonts/GibsonSemiBdIt.OTF') format('OTF');
}
</style>
