<template>
  <div class="SlapitonVideoBannerComponent">
    <v-container fluid>
      <v-row>
        <v-col align-self="center" align="center">
          <v-carousel delimiter-icon="mdi-checkbox-blank-circle-outline" :height="$vuetify.breakpoint.width < 600 ? '35vh !important' : '60vh !important'" v-model="index" :hide-delimiter-background="true" :show-arrows-on-hover="true" :show-arrows="true" class="mt-8 Carousel" cycle :interval="45000">
            <v-carousel-item v-for="(item, index) in items" v-bind:key="index">
              <v-container fluid class="fill-height">
                <v-row>
                  <v-col align-self="center" align="center">
                    <video :ref="'vc'+index" class="Video" preload="auto" :poster="require('@/assets/aquarium/videos/brettPoster.png')" style="width: inherit; max-width: 889px; max-height: 500px">
                      <source :src="item" type="application/x-mpegURL">
                    </video>
                    <v-btn :ref="'bvc'+index" rounded x-large color="red" dark class="Play" @click="play('vc'+index, 'bvc'+index)">Play</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col align-self="center" align="center" cols="12" sm="12" md="6">
          <div>
            <div style="position: relative; left: clamp(10px, 25%, 150px); top: 50px; max-width: clamp(200px, 50vw, 300px)">
              <v-img class="handover" :src="require('@/assets/slapItOn/manos-decal.png')" contain height="100%" width="50vw" max-width="300px" @click="$router.push({ name: 'Aquarium' })"></v-img>
            </div>
            <div style="position: relative; left: clamp(-150px, -25%, -10px); top: -50px; max-width: 300px">
              <v-img class="handover" :src="require('@/assets/slapItOn/manos-nft.png')" contain height="100%" width="50vw" max-width="300px" @click="$router.push({ name: 'Aquarium' })"></v-img>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_SlapitonVideoBannerComponent.scss'
import videojs from 'video.js'
export default {
  name: 'SlapitonVideoBannerComponent',
  data () {
    return {
      items: [
        'https://cloudflarestream.com/8f549ca0dc8d0f8acbed0640879642e9/manifest/video.m3u8'
      ]
    }
  },
  methods: {
    play (value, button) {
      const player = videojs(this.$refs[value][0])
      player.play()
      this.$refs[value][0].controls = true
      this.$refs[button][0].$el.style.display = 'none'
    }
  }
}
</script>

<style scoped>

</style>
