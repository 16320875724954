<template>
  <v-stepper v-model="e1" light>
    <v-stepper-header class="px-10">
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        ID4Good
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Login
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 3"
        step="3">
        Buy Tokens
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4">
        Mint
      </v-stepper-step>

    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <h1 class="text-center">Download ID4Good for free on your device
        </h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
        >

          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas">ID4Good is used to login to Dept. of Good on any device. No personal data collection to join - ID4Good is private and secure.
              </p>
              <v-row>
                <v-col-8>
                  <img style="margin-bottom: 9.5px" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1623801600&h=0be826ae6347e24d2c33dfcef7429046" alt="Download on the App Store">
                  <img alt='Get it on Google Play' style="width: 20%" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'>
                </v-col-8>
              </v-row>
            </div>
          </div>

        </v-card>

        <v-btn
          color="primary"
          @click="e1 = 2"
          rounded>
          Continue
        </v-btn>

        <v-btn text rounded>
          Cancel
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <h1 class="text-center">
          Login to Dept. of Good
        </h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
        >

          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas">
                Go to the Create section of Dept. of Good and login using your ID4Good to authenticate - you can do this from any support browser.
              </p>
            </div>
          </div>

        </v-card>

        <v-btn
          color="primary"
          @click="e1 = 3"
          rounded>
          Continue
        </v-btn>

        <v-btn text rounded>
          Cancel
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <h1 class="text-center">Add Tokens</h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
        >

          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas">
                Add Token4Good-USD to your Wallet4Good so you can start minting and buying on Dept. of Good.
                You can buy tokens from inside your Dept. of Good account or inside your Wallet.
                Your Wallet4Good (www.wallet4good.com) is created automatically when you download ID4Good.
                Purchase tokens using the supported payment method of your choice - Pay4Good supports over 50+ wallets.
              </p>
            </div>
          </div>

        </v-card>

        <v-btn
          color="primary"
          @click="e1 = 4"
          rounded>
          Continue
        </v-btn>

        <v-btn text rounded>
          Cancel
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="4">
        <h1 class="text-center">Mint Asset</h1>
        <v-card
          class="mb-12"
          color="white lighten-1"
          height="200px"
        >

          <div class="text-center">
            <div class="d-flex flex-column align-items-center justify-content-center h-100">
              <p class="txtCajas">
                Start minting your creations - go to My Assets on Dept. of Good to start uploading your files.
                Fees are as low as $1. Create tags that help you get noticed and pick the department that best matches your asset.
                Remember, once something is minted, you cant change it!
              </p>
            </div>
          </div>

        </v-card>

        <v-btn
          color="primary"
          @click="e1 = 1"
          rounded>
          Go to Engine
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
export default {
  name: 'howToMake',
  data () {
    return {
      e1: 1
    }
  }
}
</script>

<style scoped>

</style>
