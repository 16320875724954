<template>
  <div class="Collection">
    <v-container fluid v-if="loading" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="notfound" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center" style="font-size: 2rem !important;">
          <div>Not Found</div>
          <div class="overline mt-2">
            Nothing Here
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fuild v-if="!loading && !notfound">
      <v-row class="pt-10">
        <v-col align-self="center" align="center" class="display-1 text-uppercase" v-if="collection">
          {{ collection.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <v-container class="content-box pa-10">
            <v-row>
              <v-col cols="1" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
              <v-col align-self="center" :align="$vuetify.breakpoint.mdAndUp ? 'left' : 'center'">
                <div class="font-weight-bold text-uppercase">{{ collection.name }}</div>
                <div class="" style="line-height: unset">{{ collection.description }}</div>
              </v-col>
              <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12" v-if="!collection.individual && !collection.exhibition">
                <v-img width="200" height="200" contain  style="border-radius: 10px" :src="collection.soldComplete ? require('@/assets/sold.png') : qr"></v-img>
                <v-btn :disabled="collection.soldComplete" plain class="overline mt-1 text-uppercase" :href="link">buy now</v-btn>
                <v-btn :disabled="collection.soldComplete" v-if="$store.state.instantPay" @click="getInstantPayment" color="green" icon><v-icon>mdi-credit-card-settings-outline</v-icon></v-btn>
              </v-col>
              <v-col cols="1" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
            </v-row>
            <v-row v-if="!collection.individual">
              <v-col align-self="center" align="center" class="text-h3 font-weight-thin" style="font-weight: 400 !important">
                $ {{ collection.price }} {{ currencies.find(item => item.code === collection.currency).name }}
              </v-col>
            </v-row>
          </v-container>
          <v-btn class="mt-5" dark :href="getVirtualLink()">Virtual Gallery</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center" v-if="assets.length > 0">
          <carousel-3d display="3" :width="size" :height="size">
            <slide v-for="(asset, i) in assets" :index="i" :key="i">
              <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
                <v-container fluid class="fill-height">
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-img v-if="asset.previews[0].type === 'image'" @click="isCurrent ? showArt(asset, i) : null" style="border-radius: 15px" contain :max-height="size - 20" :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="asset.previews[0].url" >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                              size="128"
                              width="15"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </slide>
          </carousel-3d>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <div align="center" class="avatar-box" v-if="user.avatar">
            <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 200 : 100">
              <v-img :src="user.avatar" width="100%" height="100%" max-width="200px" max-height="200px" contain></v-img>
            </v-avatar>
            <div v-if="user.name" class="overline avatar-text-box">{{ user.name }}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog fullscreen v-model="dialogArt" v-if="dialogArt" content-class="Collection">
      <v-container fluid class="fill-height dialog-art">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="content-box">
              <v-container :class="$vuetify.breakpoint.mdAndUp ? 'mx-5' : ''">
                <v-row v-if="!$vuetify.breakpoint.mdAndUp">
                  <v-col align-self="center" align="center">
                    <div class="font-weight-bold text-uppercase">{{ art.name }}</div>
                    <div class="overline" style="line-height: unset">{{ art.description }}</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12" align-self="center" class="pt-15" :align="!$vuetify.breakpoint.mdAndUp ? 'center' : ''">
                    <v-img align="right" v-if="art.previews[currentDisplay].type === 'image'" :src="art.previews[currentDisplay].url" contain width="100%" :max-width="previewSize" style="border-radius: 5px">
                      <v-btn dark icon @click="fullscreen()"><v-icon>mdi-fullscreen</v-icon></v-btn>
                    </v-img>
                    <VideoPlayer v-if="art.previews[currentDisplay].type === 'video'" style="border-radius: 5px;" :width="previewSize" :height="previewSize" :src="art.previews[currentDisplay].url" />
                    <div class="flex-nowrap d-flex mt-2">
                      <v-spacer></v-spacer>
                      <span v-for="(preview, index) in art.previews" :key="index" @click="currentDisplay = index" class="flex-nowrap d-flex preview-select">
                        <v-img v-if="currentDisplay !== index && preview.type === 'image'" class="mx-1" :src="preview.url" contain width="50" height="50" max-width="50" style="border-radius: 2px" />
                        <v-icon style="border: 1px solid #0072F5EF; border-radius: 5px; padding: 10px 10px 10px 10px" color="#0072F5EF" size="30" v-if="currentDisplay !== index && preview.type === 'video'" class="mx-1">mdi-video-vintage</v-icon>
                      </span>
                      <v-spacer></v-spacer>
                    </div>
                    <div  class="overline" v-if="art.quantity == 1 && $vuetify.breakpoint.mdAndUp">Unique</div>
                    <div class="overline" v-if="art.quantity > 1 && $vuetify.breakpoint.mdAndUp">{{ art.available }} of {{ art.quantity }} Pieces</div>
                  </v-col>
                  <v-col align-self="center" align="left">
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="font-weight-bold text-uppercase">{{ art.name }}</div>
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="overline" style="line-height: unset">{{ art.description }}</div>
                    <div class="mt-5 overline font-weight-bold">{{ art.quantity == 1 ? 'Unique Piece' : art.available + ' of ' + art.quantity + ' Pieces' }}</div>
                    <div class="mt-5 overline font-weight-bold" style="line-height: unset">Created by {{ art.creator }}</div>
                    <div class="overline font-weight-bold" style="line-height: unset">Created on {{ new Date(art.created * 1000).toLocaleString() }}</div>
                  </v-col>
                  <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12" v-if="collection.individual && !collection.exhibition">
                    <v-img width="200" height="200" contain  style="border-radius: 10px" :src="art.sold ? require('@/assets/sold.png') : qr"></v-img>
                    <v-btn :disabled="art.sold" plain class="overline mt-1 text-uppercase" :href="link">buy now</v-btn>
                    <v-btn :disabled="art.sold" v-if="$store.state.instantPay" @click="getInstantPayment" color="green" icon><v-icon>mdi-credit-card-settings-outline</v-icon></v-btn>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row v-if="collection.individual && !collection.exhibition">
                  <v-col align-self="center" align="center" class="text-h4 font-weight-thin" style="font-weight: 400 !important">
                    $ {{ art.price }} {{ currencies.find(item => item.code === collection.currency).name }}
                    <div class="overline">Sale by Unit</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col  align-self="center">
                    <v-btn class="mx-2" dark @click="dialogArt = false">close</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog fullscreen v-model="dialogFullscreen" v-if="dialogFullscreen" content-class="Collection">
      <v-container fluid class="fill-height dialog-art">
        <v-row>
          <v-col align-self="center" align="center">
            <v-img :src="art.previews[currentDisplay].url" width="100%" height="100%" max-width="1024px" max-height="1024px" contain></v-img>
            <v-btn icon @click="dialogFullscreen = false"><v-icon>mdi-fullscreen-exit</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import './_Collection.scss'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer'
import assets from '../../mixins/assets'
import { generateQR } from 'id4good.auth'
import instant from '../../mixins/instant'

export default {
  name: 'Collection',
  components: { VideoPlayer },
  data () {
    return {
      notfound: false,
      qr: null,
      link: null,
      id: null,
      collection: null,
      loading: false,
      assets: [],
      dialogArt: false,
      dialogFullscreen: false,
      art: null,
      currentDisplay: 0,
      user: null,
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ]
    }
  },
  mixins: [assets, instant],
  mounted () {
    this.id = this.$route.params.id.replaceAll('%2F', '/')
    const revolved = this.$router.resolve({
      name: 'Payment',
      params: { id: this.$route.params.id }
    })
    console.log(revolved)
    const base = (window.location.href.split('#'))[0]
    this.link = base + revolved.href
    this.instant = {
      id: this.id,
      individual: false
    }
    generateQR(base + revolved.href).then((qr) => {
      this.qr = qr
    })
    this.loading = true
    this.getCollection()
  },
  computed: {
    previewSize: function () {
      return this.$vuetify.breakpoint.width < 600 ? parseInt(this.$vuetify.breakpoint.width * 0.7) + 'px' : '400px'
    },
    size: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.$vuetify.breakpoint.height * 0.6
      }
      return this.$vuetify.breakpoint.width * 0.6
    }
  },
  methods: {
    getVirtualLink () {
      const revolved = this.$router.resolve({
        name: 'Virtual',
        params: { id: this.$route.params.id }
      })
      const base = (window.location.href.split('#'))[0]
      if (this.collection.template) {
        return base + revolved.href + '?template=' + this.collection.template
      }
      return base + revolved.href
    },
    getCollection () {
      this.$axios.get('/api/collections/getCollection', {
        params: {
          hash: this.id,
          crc: Date.now()
        }
      }).then((result) => {
        this.user = result.data.user
        this.collection = result.data.collection
        let count = this.collection.assets.length
        for (const key in this.collection.assets) {
          this.$axios.get('/api/assets/getAssetInfo', {
            params: {
              hash: this.collection.assets[key],
              crc: parseInt(Date.now() / 100000)
            }
          }).then((result) => {
            this.assets[key] = result.data.asset
            count--
            if (count === 0) {
              this.loading = false
            }
          })
        }
      }).catch(() => {
        console.log('error')
        this.loading = false
        this.notfound = true
      })
    },
    fullscreen () {
      this.dialogFullscreen = true
    },
    async showArt (art, index) {
      art.available = 1
      if (this.collection.individual) {
        this.instant = {
          id: this.id,
          index: index,
          individual: true
        }
        const revolved = this.$router.resolve({
          name: 'PaymentIndividual',
          params: { id: this.$route.params.id, index: index }
        })
        const base = (window.location.href.split('#'))[0]
        this.link = base + revolved.href
        generateQR(base + revolved.href).then((qr) => {
          this.qr = qr
        })
        art.price = this.collection.prices[this.collection.assets[index]]
        art.available = this.collection.unit ? (this.collection.unit[this.collection.assets[index]] ? this.collection.unit[this.collection.assets[index]] : 1) : 1
      }
      this.currentDisplay = 0
      const metadata = await this.getMetadata(art.metadata)
      art.name = metadata.name
      art.description = metadata.description
      art.quantity = metadata.quantity
      art.creator = metadata.creator
      art.created = metadata.created
      art.sold = false
      if (this.collection.sold) {
        if (this.collection.sold[this.collection.assets[index]] === 'sold') {
          art.sold = true
        }
        if (this.collection.sold[this.collection.assets[index]] > 0) {
          art.available = art.available - this.collection.sold[this.collection.assets[index]]
          if (art.available === 0) {
            art.sold = true
          }
        }
      }
      this.art = art
      this.dialogArt = true
    }
  }
}
</script>
