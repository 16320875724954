<template>
  <div class="MyArts">
    <Nav />
    <div v-if="!loading" class="float-menu">
      <v-btn to="/myassets/buytoken" rounded color="#70AFD0" dark large class="mr-5">Buy Token</v-btn>
      <v-btn to="/myassets/mint" rounded color="#70AFD0" dark large class="mr-5">Mint Asset</v-btn>
      <div v-if="balanceToken > 0" class="overline mr-5" align="right">Balance: {{ (balanceToken) | numFormat('0,0.[00]') }} T4G-USD</div>
    </div>
    <v-container fuild v-if="assets.length === 0 && !loading" class="fill-height min-height">
      <v-row>
        <v-col align-self="center" align="center" class="title">
          <div>OOPS!! You don't have DAFs available</div>
          <div class="overline mt-2">
            First you need to publish your DAFs from your Wallet4Good
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fuild v-if="assets.length > 0 && !loading" class="min-height">
      <v-row class="pt-10">
        <v-col>
          <div class="titleSection text-h3 mb-5" align="left">My Assets</div>
        </v-col>
      </v-row>
      <v-row style="margin-left: 42px">
        <v-col v-for="(asset, index) in assets" :key="index" align-self="center" align="center" :cols="$vuetify.breakpoint.width > 1900 ? 3 : $vuetify.breakpoint.width > 1400 ? 4 : $vuetify.breakpoint.width > 960 ? 6 : 12">
          <CardComponent :actions="true" :title="asset.name" type="Single" :available="parseFloat(asset.balance)" :pieces="parseFloat(asset.quantity)" :author="asset.creator" :image="asset.preview" :description="asset.description" style="min-height: 500px">
            <template v-slot:actions align="center">
              <div>
                <v-tooltip bottom v-if="asset.kind.toString() === '7'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small tile fab v-bind="attrs" v-on="on" @click="$router.push({ name: 'Reviews', params: { asset: asset }})" color="#70AFD055" class="mx-1 elevation-3">
                      <v-img :src="require('@/assets/roct.png')" max-width="28px" max-height="28px" />
                    </v-btn>
                  </template>
                  Review
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small tile fab v-bind="attrs" v-on="on" @click="$router.push({ name: 'Previews', params: { asset: asset }})" color="#70AFD055" class="mx-1 elevation-3">
                      <v-icon color="#70AFD0" size="28">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  Edit Preview
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="!asset.inUsed && asset.inUsed >= asset.balance" small tile fab v-bind="attrs" v-on="on" @click="$router.push({ name: 'CreatePublish', params: { asset: asset }})" color="#70AFD055" class="mx-1 elevation-3">
                      <v-icon color="#70AFD0" size="28">mdi-publish</v-icon>
                    </v-btn>
                  </template>
                  Publish
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small tile fab v-bind="attrs" v-on="on" @click="unregisterAsset(asset, index)" color="#70AFD055" class="mx-1 elevation-3">
                      <v-icon color="#70AFD0" size="28">mdi-card-remove</v-icon>
                    </v-btn>
                  </template>
                  Remove from Here
                </v-tooltip>
                </div>
              </template>
          </CardComponent>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="loading && !dialogSignIn" class="fill-height mt-10 min-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog fullscreen v-model="dialogSignIn" v-if="dialogSignIn" content-class="MyArts">
      <v-container fluid class="fill-height dialog-signin">
        <v-row>
          <v-col align-self="center" align="center">
            <v-progress-circular v-if="loading"
                                 indeterminate
                                 color="black lighten-5"
                                 size="128"
                                 width="15"
            ></v-progress-circular>
            <v-img v-if="!loading && !this.$device.mobile" :src="qr" contain width="300px" height="300px" class="mb-10 qr"/>
            <div>
              <v-btn :href="link" v-if="!loading && link && ($device.ios || $device.android)">Sign In with ID4Good</v-btn>
            </div>
            <div class="text-h4 font-weight-bold" style="color: #00355B">
              Connect your ID4Good
            </div>
            <div class="text-h6 font-weight-thin">
              The most comprehensive NFT platform for minting, selling, and connecting with your audience.<br> With built in payment processing, support for custom URLs. and much more, <br>what are you waiting for?
            </div>
            <div class="py-10 d-inline-flex">
              <v-img :src="require('@/assets/apple-store-badge.svg')" max-height="40px" max-width="120px" contain class="mx-5"></v-img>
              <v-img :src="require('@/assets/google-play-badge.png')" max-height="40px" max-width="120px" contain class="mx-5"></v-img>
            </div>
            <div>
              <v-btn :href="link" v-if="!loading && link && ($device.ios || $device.android)">Sign In with ID4Good</v-btn>
            </div>
            <div>
              <v-btn fab small @click="dialogSignIn = false"><v-icon>mdi-close</v-icon></v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getChallenge, generateQR, waitChallenge } from 'id4good.auth'
import './_MyArts.scss'
import Nav from '../../components/Nav/Nav'
import assets from '../../mixins/assets'
import CardComponent from '../../components/CardComponent/CardComponent'

export default {
  name: 'MyArts',
  components: { CardComponent, Nav },
  data () {
    return {
      qr: null,
      link: false,
      dialogSignIn: false,
      loading: false,
      balanceToken: 0
    }
  },
  mixins: [assets],
  async mounted () {
    if (!this.$store.state.walletIdentity) {
      this.sign()
    } else {
      this.loadAssets()
      this.balanceToken = (await this.$axios.get('/api/auth/getWalletBalance', { params: { address: this.$store.state.wallet } })).data.balance
    }
  },
  watch: {
    identity: {
      deep: true,
      handler: function () {
      }
    }
  },
  methods: {
    async unregisterAsset (asset, index) {
      this.$axios.get('/api/asset/unregisterAsset', {
        params: {
          identity: this.$store.state.walletIdentity,
          hash: asset.hash,
          crc: Date.now()
        }
      })
    },
    async sign () {
      this.loading = true
      this.dialogSignIn = true
      const challenge = await getChallenge('wallet4good.com')
      this.qr = await generateQR(challenge.code)
      if (this.$device.mobile) {
        if (this.$device.ios) {
          this.link = 'https://id4good.com/' + challenge.code
        }
        if (this.$device.android) {
          this.link = 'id4good://wallet4good/' + challenge.code + ';scheme=id4good;package=com.bloqs4good.id4good;end'
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = this.link
          a.click()
          setTimeout(() => {
            document.body.removeChild(a)
          }, 0)
        }
      }
      this.loading = false
      waitChallenge(challenge.hash, 0).then((result) => {
        this.loading = true
        if (result.identity === true) {
          this.$axios.get('/api/auth/getIdentityWallet', {
            params: {
              identity: this.$store.state.identity,
              hash: challenge.hash,
              crc: Date.now()
            }
          }).then(async (result) => {
            console.log(result)
            this.$store.state.walletIdentity = result.data.identity
            this.$store.state.wallet = result.data.address
            this.dialogSignIn = false
            this.loading = false
            this.loadAssets()
            this.balanceToken = (await this.$axios.get('/api/auth/getWalletBalance', { params: { address: this.$store.state.wallet } })).balance
          })
        }
      }).catch((error) => {
        this.dialogSignIn = false
        console.log(error)
      })
    }
  },
  computed: {
    ...mapState(
      {
        identity: state => state.wallet
      })
  }
}
</script>
