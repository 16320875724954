<template>
    <div class="ModelPlayer">
      <div :id="'render-box-' + id" :style="{ width: width, height: height, minWidth: width, minHeight: height }" align="center" style="align-items: center; display: flex">
        <v-progress-circular width="10" size="200" v-if="!ready" color="white" class="overline">
          Loading...
        </v-progress-circular>
      </div>
    </div>
</template>

<script>
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import moment from 'moment'

export default {
  name: 'ModelPlayer',
  props: {
    src: {
      type: String,
      default () {
        return ''
      }
    },
    height: {
      type: String,
      default () {
        return '500px'
      }
    },
    width: {
      type: String,
      default () {
        return '500px'
      }
    }
  },
  data () {
    return {
      id: Math.random(),
      camera: null,
      scene: null,
      renderer: null,
      mixer: null,
      prevTime: 0,
      ready: false,
      url: 'http://localhost:8080/fox.glb'
    }
  },
  created () {
    this.id = Math.random() * moment().unix()
  },
  mounted () {
    console.log('mount render')
    this.load()
  },
  methods: {
    animate (time) {
      this.ready = true
      const dt = (time - this.prevTime) / 1000
      this.controls.update()
      this.renderer.render(this.scene, this.camera)
      this.mixer && this.mixer.update(dt)
      this.prevTime = time
    },
    load () {
      const renderBox = document.getElementById('render-box-' + this.id)
      if (renderBox.clientWidth === 0 || renderBox.clientWidth === 0) {
        setTimeout(() => { this.load() }, 1000)
        return
      }

      this.scene = new THREE.Scene()
      this.camera = new THREE.PerspectiveCamera(60, renderBox.clientWidth / renderBox.clientWidth, 1, 1000)
      this.scene.add(this.camera)

      const lightGlobal = new THREE.HemisphereLight(0xEEEEEE, 0xFFFFFF, 0.7)
      lightGlobal.position.set(0, 0, 0)
      this.scene.add(lightGlobal)

      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true
      })
      this.renderer.shadowMap.enabled = true
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(renderBox.clientWidth, renderBox.clientWidth)
      this.renderer.setClearColor(0x000000, 0.0)
      this.renderer.outputEncoding = THREE.sRGBEncoding
      renderBox.appendChild(this.renderer.domElement)
      this.renderer.setAnimationLoop(this.animate)

      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.enabled = true
      this.controls.enableDamping = true
      this.controls.dampingFactor = 0.25
      this.controls.enableZoom = true
      this.controls.autoRotate = true

      this.camera.position.set(0, 20, 100)
      this.controls.update()

      const loader = new GLTFLoader()
      loader.load(this.src, async (gltf) => {
        const box = new THREE.Box3().setFromObject(gltf.scene)
        const size = box.getSize(new THREE.Vector3()).length() * 2
        const center = box.getCenter(new THREE.Vector3())

        const clips = gltf.animations || []

        gltf.scene.castShadow = true
        gltf.scene.receiveShadow = true
        gltf.scene.position.x += (gltf.scene.position.x - center.x)
        gltf.scene.position.y += (gltf.scene.position.y - center.y)
        gltf.scene.position.z += (gltf.scene.position.z - center.z)
        this.camera.near = size / 100
        this.camera.far = size * 100
        this.camera.position.set(0, size / 2, size)
        this.scene.scale.set(size, size, size)
        this.controls.maxDistance = size * 2
        this.controls.minDistance = size / 2
        const light = new THREE.PointLight(0xffffff, 0.5)
        light.position.set(-size, size * 2, -size)
        light.castShadow = true
        this.scene.add(light)
        const dLight = new THREE.DirectionalLight(0xffffff, 1)
        dLight.position.set(center.x, center.y, center.z)
        dLight.target = gltf.scene
        dLight.castShadow = true
        this.scene.add(dLight)
        this.scene.add(gltf.scene)
        if (clips.length > 0) {
          this.mixer = new THREE.AnimationMixer(gltf.scene)
          clips.forEach((clip) => {
            this.mixer.clipAction(clip).reset().play()
          })
        }
      })
    }
  }
}
</script>
