<template>
  <div style="background-color: #eef6f9 !important;margin-bottom: -200px !important">
    <resources-content class="mx-auto"></resources-content>
    <resources-banner></resources-banner>
</div>
</template>

<script>
import ResourcesBanner from '../../components/Resources/resourcesBanner'
import ResourcesContent from '../../components/Resources/resourcesContent'
export default {
  name: 'Resources',
  components: { ResourcesContent, ResourcesBanner }
}
</script>

<style scoped>

</style>
