export default {
  data () {
    return {
      wishlist: []
    }
  },
  mounted () {
    this.loadWishlist()
  },
  methods: {
    getWishlist () {
      return this.wishlist
    },
    getWishlistTotal () {
      let total = 0
      for (const item of this.wishlist) {
        const value = item.collection.individual ? item.collection.prices[item.hash] : item.collection.price
        total += parseFloat(value)
      }
      return total
    },
    getWishlistCount () {
      console.log(this.wishlist.length)
      return this.wishlist.length
    },
    addWishlist (item) {
      this.wishlist.push(item)
      this.saveWishlist()
    },
    removeWishlist (item) {
      this.wishlist.splice(this.wishlist.findIndex(obj => obj.hash === item.hash), 1)
      this.saveWishlist()
    },
    clearWishlist () {
      this.wishlist = []
      this.saveWishlist()
    },
    findInWishlist (item) {
      if (this.wishlist.find(obj => obj.hash === item.hash)) {
        return true
      }
      return false
    },
    loadWishlist () {
      if (localStorage.getItem('wishlist')) {
        const unparsed = localStorage.getItem('wishlist')
        this.wishlist = JSON.parse(unparsed)
      }
    },
    saveWishlist () {
      const parsed = JSON.stringify(this.wishlist)
      localStorage.setItem('wishlist', parsed)
    }
  }
}
