<template>
  <div class="Reviews">
    <div v-if="!loading" class="float-menu">
      <v-btn to="/myassets" dark class="mr-5">Back</v-btn>
    </div>
    <v-container v-if="!loading" fluid class="fill-height dialog-art">
      <v-row>
        <v-col align-self="center" align="center">
          <div class="content-box">
            <v-container :class="$vuetify.breakpoint.mdAndUp ? 'mx-5' : ''">
              <v-row>
                <v-col align-self="center" align="center" class="overline title mt-5">
                  <v-img :src="require('@/assets/ROCx.png')" contain height="100px"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="tags" align="left">
                    <v-tooltip bottom v-for="(item, idx) in asset.attr.Project.SDGAPIGoals" :index="'sdg'+idx" :key="'sdg'+idx">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" :style="{ 'background-color': sdgColors['sdg'+item.pubSDGAPIGoalCode] }">SDG-{{ item.pubSDGAPIGoalCode.trim() }}</span>
                      </template>
                      <span>{{ sdgDesc['sdg'+item.pubSDGAPIGoalCode] }}</span>
                    </v-tooltip>
                    <span v-for="(item, idx) in (asset.attr.Project.Tags).filter(obj => !obj.pubProjectTag.startsWith('SDG'))" :index="'t'+idx" :key="'t'+idx">{{ item.pubProjectTag.trim() }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="$device.mobile">
                <v-col align="right">
                  <div class="subtitle-1 text-uppercase" style="color: #2f4758; font-weight: 100 !important; font-size: 1.2rem !important;">{{ asset.attr.Project.Sites[0].Regions[0].pubRegionStateProv }}, {{ asset.attr.Project.Sites[0].Regions[0].pubRegionCountry }}</div>
                  <div class="subtitle-1 text-uppercase mb-6" style="color: #2f4758; font-weight: 100 !important; font-size: 1.2rem !important;">{{ (asset.attr.Project.pubProjectStartDate) }}</div>
                </v-col>
              </v-row>
              <v-row v-if="$device.mobile">
                <v-col align-self="center" align="center">
                  <div align="left" :style="{ 'max-width': $vuetify.breakpoint.width < 500 ? '300px' : '400px' }">
                    <v-img class="shadow-currency shadow-currency-border back-2" v-if="asset.previews[0].url" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.previews[0].url" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                    <v-img class="shadow-currency shadow-currency-border back-1" v-if="asset.previews[0].url" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.previews[0].url" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                    <v-img class="shadow-currency shadow-currency-border back-0" v-if="asset.previews[0].url" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.previews[0].url" height="auto" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="left">
                  <div class="subtitle-1 my-3" style="color: #2f4758; font-size: 4rem !important; line-height: 4rem !important;">
                    {{ asset.attr.Project.pubProjectName }} - {{ asset.attr.Project.pubOrganizationName }}
                    <br>
                    {{ asset.attr.Project.Sites[0].pubSiteName }}
                  </div>
                  <div v-if="!$device.mobile" class="subtitle-1 text-uppercase" style="color: #2f4758; font-weight: 100 !important; font-size: 1.2rem !important;">{{ asset.attr.Project.Sites[0].Regions[0].pubRegionStateProv }}, {{ asset.attr.Project.Sites[0].Regions[0].pubRegionCountry }}</div>
                  <div v-if="!$device.mobile" class="subtitle-1 text-uppercase mb-6" style="color: #2f4758; font-weight: 100 !important; font-size: 1.2rem !important;">{{ (asset.attr.Project.pubProjectStartDate) }}</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="left">
                  <div class="description-text" style="color: black; font-size: 1.2rem !important; font-weight: 100 !important; line-height: unset">{{ asset.attr.Project.pubProjectDetails }}</div>
                  <div class="description-text my-4" style="color: black; font-size: 1.2rem !important; font-weight: 100 !important; line-height: unset">{{ asset.attr.Project.Sites[0].pubSiteDescription }}</div>
                  <div class="description-text my-4" style="color: black; font-size: 1.2rem !important; font-weight: 100 !important; line-height: unset">Carbon Storage: {{ asset.attr.Project.pubCarbonStorage }}</div>
                  <div class="description-text my-4" style="color: black; font-size: 1.2rem !important; font-weight: 100 !important; line-height: unset">Project Type: {{ asset.attr.Project.pubProjectTypeMethod }}</div>
                  <div class="description-text my-4" style="color: black; font-size: 1.2rem !important; font-weight: 100 !important; line-height: unset">SBTI Commitment: {{ asset.attr.Project.pubSBTICommitment }}</div>
                  <div class="description-text my-4" style="color: black; font-size: 1.2rem !important; font-weight: 100 !important; line-height: unset">Meter Type: {{ asset.attr.Project.Sites[0].Meters[0].pubMeterType }}</div>
                </v-col>
                <v-col>
                  <v-container>
                    <v-row v-if="!$device.mobile">
                      <v-col align-self="center" align="center">
                        <div align="left" :style="{ 'max-width': $vuetify.breakpoint.width < 500 ? '300px' : '400px' }">
                          <v-img class="shadow-currency shadow-currency-border back-2" v-if="asset.preview" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.preview" height="auto" :width="400" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                          <v-img class="shadow-currency shadow-currency-border back-1" v-if="asset.preview" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.preview" height="auto" :width="400" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                          <v-img class="shadow-currency shadow-currency-border back-0" v-if="asset.preview" style=" border: 6px #ffffffee solid; margin-left: 5px; border-radius: 15px;" :src="asset.preview" height="auto" :width="400" :max-width="$vuetify.breakpoint.width < 500 ? 300 : 400" />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <div class="text-no-wrap">
                          <v-tooltip top v-for="(item, idx) in asset.attr.Project.SDGAPIGoals" :index="'sdg'+idx" :key="'sdg'+idx">
                            <template v-slot:activator="{ on, attrs }">
                              <img class="ma-1" v-bind="attrs" v-on="on" :src="require('@/assets/goals/' + item.pubSDGAPIGoalCode.trim() + '.png')" height="70px" width="70px" />
                            </template>
                            <span>{{ sdgDesc['sdg'+item.pubSDGAPIGoalCode] }}</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-10">
                  <div class="Rocarbon mx-10">
                    <v-container class="px-10">
                      <v-row dense>
                        <v-col align="left">
                          <span class="subtitle-2">PRIVATE INFORMATION</span>
                        </v-col>
                        <v-col align-self="center">
                          <div :align="$vuetify.breakpoint.mdAndUp ? 'right' : 'center'" class="subtitle-2" style="font-size: 1.4rem !important; font-weight: bold; color: white;">
                            <img :src="require('@/assets/roct.png')" width="20px" height="20px" />
                            USD ${{ asset.attr.pubRecommendedPricePerTonne }}/tonne CO<sub>2</sub>e
                          </div>
                          <div :align="$vuetify.breakpoint.mdAndUp ? 'right' : 'center'" class="subtitle-2" style="font-size: 1.4rem !important; font-weight: bold; color: white;">
                            {{ this.balance | numFormat('0,0.000') }} Tonne
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="font-size: 0.9rem">
                        <v-col class="text-uppercase" align="left">
                          <div style="line-height: 18px; margin-bottom: 3px;"><b>Legal Name:</b> {{ asset.attr.Project.privLegalName }}</div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Legal Registration Location:</b> {{ asset.attr.Project.privLegalRegistrationLocation }}</div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Hold Percentage:</b> {{ asset.attr.Project.privProjectProducerHoldPercentage }}</div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Sell Percentage:</b> {{ asset.attr.Project.privProjectProducerSellPercentage }}</div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Wallet ID:</b> {{ asset.attr.Project.Accounting[0].privWalletID }}</div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Wallet Name:</b> {{ asset.attr.Project.Accounting[0].privWalletName }}</div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Wallet Percentage:</b> {{ asset.attr.Project.Accounting[0].privSalePercentage }}</div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Entry Type:</b> {{ asset.attr.Project.Accounting[0].privEntryType }}</div>
                        </v-col>
                        <v-col class="text-uppercase" align="left">
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Additionally:</b> {{ asset.attr.Project.Sites[0].privAdditionalityDescription }} </div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Additionally Multiplier:</b> {{ asset.attr.Project.Sites[0].privAdditionalityMultiplier }}</div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Attributes Description:</b> {{ asset.attr.Project.Sites[0].privAttributesDescription }} </div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Attributes Multiplier:</b> {{ asset.attr.Project.Sites[0].privAttributesMultiplier }} </div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Site Description:</b> {{ asset.attr.Project.Sites[0].privSiteDescription }} </div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Lat/Long:</b> {{ asset.attr.Project.Sites[0].privSiteLat }}/{{ asset.attr.Project.Sites[0].privSiteLong }} </div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Site Name:</b> {{ asset.attr.Project.Sites[0].privSiteName }} </div>
                        </v-col>
                        <v-col class="text-uppercase" align="left">
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Daily Meter Leakage:</b> {{ asset.attr.Project.Sites[0].Meters[0].privDailyMeterLeakage }} </div>
                          <div style="line-height: 18px; margin-bottom: 3px"><b>Meter Serial:</b> {{ asset.attr.Project.Sites[0].Meters[0].privMeterSerial }}</div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="subtitle-2" style="color: #2f4758; font-size: 2rem !important; line-height: 4rem !important;">CONTENT</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div v-for="(piece, idx) in asset.pieces" :index="idx" :key="idx" class="box-shadow mx-10 my-5" style="border-radius: 15px; color: black">
                    <v-container fluid class="overline">
                      <v-row>
                        <v-col>
                          Name: {{ piece.name }}
                        </v-col>
                        <v-col>
                          <v-btn dark @click="downloadAssetFile(piece, asset.owner)">Download</v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1"></v-col>
              </v-row>
              <v-row>
                <v-col align-self="center">
                  <!--                    <v-btn class="mx-2" dark :href="asset.link" v-if="asset.collectionCount > 1">Go to Collection</v-btn>
                                      <v-btn class="mx-2" dark :href="asset.link" v-if="asset.collectionCount == 1">Go to Sale</v-btn>-->
                  <v-btn fab fixed top right :x-small="$device.mobile" class="mx-2" style="position: fixed" @click="dialogArt = false">X</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="loading" class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular v-if="loading"
                               indeterminate
                               color="black lighten-5"
                               size="128"
                               width="15"
                               class="overline"
          >SYNC</v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog fullscreen v-model="editImage" v-if="editImage" content-class="Profile">
      <v-container fluid class="fill-height dialog-profile">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="editor-box">
              <PinturaEditor
                v-bind="editorDefaults"
                :src="image"
                :imageCropAspectRatio="0"
                @pintura:process="finishImage"
              ></PinturaEditor>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <input type="file" style="display: none" :accept="previews.length > 0 ? 'image/*,video/*' : 'image/*'" ref="inputImage"
           @change="_uploadImage"/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Successful save
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_Reviews.scss'
import assets from '../../../mixins/assets'
import { getEditorDefaults, createDefaultImageWriter } from 'pintura'

export default {
  name: 'Reviews',
  props: {
    asset: Object
  },
  data () {
    return {
      loading: false,
      editImage: false,
      image: null,
      snackbar: false,
      balance: 0,
      text: '',
      sdgDesc: {
        sdg1: 'Goal 1 - No Poverty: End poverty in all its forms everywhere.',
        sdg2: 'Goal 2 - Zero Hunger: End hunger, achieve food security and improved nutrition and promote sustainable agriculture.',
        sdg3: 'Goal 3 - Good Health and Well-Being: Ensure healthy lives and promote well-being for all at all ages.',
        sdg4: 'Goal 4 - Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
        sdg5: 'Goal 5 - Gender Equality: Achieve gender equality and empower all women and girls.',
        sdg6: 'Goal 6 - Clean Water and Sanitation: Ensure availability and sustainable management of water and sanitation for all.',
        sdg7: 'Goal 7 - Affordable and Clean Energy: Ensure access to affordable, reliable, sustainable and modern energy for all.',
        sdg8: 'Goal 8: Decent Work and Economic Growth: Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.',
        sdg9: 'Goal 9 - Industry, Innovation, and Infrastructure: Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.',
        sdg10: 'Goal 10 - Reduced Inequalities: Reduce inequality within and among countries.',
        sdg11: 'Goal 11 - Sustainable Cities and Communities: Make cities and human settlements inclusive, safe, resilient and sustainable.',
        sdg12: 'Goal 12 - Responsible Consumption and Production: Ensure sustainable consumption and production patterns.',
        sdg13: 'Goal 13 - Climate Action: Take urgent action to combat climate change and its impacts.',
        sdg14: 'Goal 14 - Life Below Water: Conserve and sustainably use the oceans, seas and marine resources for sustainable development.',
        sdg15: 'Goal 15 - Life on Land: Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.',
        sdg16: 'Goal 16 - Peace, Justice, and Strong Institutions: Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.',
        sdg17: 'Goal 17 - Partnerships for the Goals: Strengthen the means of implementation and revitalize the global partnership for sustainable development.'
      },
      sdgColors: {
        sdg1: '#e5243b',
        sdg2: '#dda63a',
        sdg3: '#4c9f38',
        sdg4: '#c5192d',
        sdg5: '#ff3a21',
        sdg6: '#26bde2',
        sdg7: '#fcc30b',
        sdg8: '#a21942',
        sdg9: '#fd6925',
        sdg10: '#dd1367',
        sdg11: '#fd9d24',
        sdg12: '#bf8b2e',
        sdg13: '#3f7e44',
        sdg14: '#0a97d9',
        sdg15: '#56c02b',
        sdg16: '#00689d',
        sdg17: '#19486a'
      },
      previews: [
      ],
      editorDefaults: getEditorDefaults()
    }
  },
  mixins: [assets],
  async mounted () {
    console.log('asset', this.asset)
    for (const piece of this.asset.pieces) {
      if (piece.tag === 'JSON') {
        console.log(piece, this.asset.key)
        console.log(this.asset.owner)
        const buffer = await this.decodeFileDecode(piece, this.asset.owner)
        this.asset.attr = JSON.parse(buffer)
        console.log('json', this.asset.attr)
      }
    }
    if (this.asset.previews) {
      this.asset.previews.forEach(item => {
        this.previews.push(item)
      })
    }
    this.editorDefaults.imageWriter = createDefaultImageWriter({
      targetSize: {
        width: 1024,
        height: 1024,
        upscale: true
      }
    })
    this.$axios.get('/api/assets/getAssetBalance', {
      params: {
        hash: this.asset.hashInternal,
        crc: parseInt(Date.now() / 10000)
      }
    }).then((result) => {
      console.log(result.data)
      this.balance = result.data.balance.roct
    })
    this.loading = true
    this.loadImages()
    console.log(this.asset)
  },
  computed: {
    size: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.$vuetify.breakpoint.height * 0.3
      }
      return this.$vuetify.breakpoint.width * 0.5
    }
  },
  methods: {
    setPrimary (index) {
      const old = this.previews[0]
      const primary = this.previews[index]
      this.previews[index] = old
      this.previews[0] = primary
      this.$forceUpdate()
    },
    async _uploadImage (e) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      console.log(file.type)
      if (file.type.includes('image/')) {
        this.editCurrentImage(url)
      }
      if (file.type.includes('video/')) {
        this.editCurrentVideo(url)
      }
    },
    finishImage (result) {
      const url = URL.createObjectURL(result.dest)
      this.previews.push({ url: url, type: 'image' })
      this.editImage = false
    },
    editCurrentVideo (video) {
      this.previews.push({ url: video, type: 'video' })
      console.log('add')
    },
    editCurrentImage (image) {
      this.image = image
      this.editImage = true
    },
    async loadImages () {
      for (const image of this.asset.images) {
        image.src = await this.decodeFile(image, this.asset.owner)
      }
      this.loading = false
    }
  }
}
</script>
