<template>
  <div>
    <v-row></v-row>
    <v-row class="mt-10">
      <v-col cols="2"></v-col>
      <v-col cols="4">
        <h3 class="meetDept py-10">Meet Dept. Of Good</h3>
        <p>With low gas fees and fast transaction speeds make NFTs accessible to all. Mint with or without a wallet, and with your credit card too. Your NFTs stay in your wallet or a scrow account until they're sold.</p>
        <div  data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500" data-aos-delay="1600" :align="$vuetify.breakpoint.width < 960 ? 'center' : 'left'" class="mt-10">
          <v-btn rounded class="getStarted" elevation="0">Go to Collections</v-btn>
          <v-btn rounded class="ml-2 explore" elevation="0">Get your ID4Good</v-btn>
        </div>
      </v-col>
      <v-col cols="5">
        <v-img class="mt-5" :src="require('@/assets/landing/DEFAULT.png')"></v-img>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="8">
        <WhyDept></WhyDept>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row style="padding-top: 40px;">
      <v-col cols="2"></v-col>
      <v-col cols="8" align-self="center">
        <h1 class="mt-10" style="text-align: center; color:#00355B ;font-family: Verdana">Step-by-step guides to get you started on Dept. of Good</h1><br>
        <div class="text-center">
          <p class="txtLearn3">
            Step-by-step guides to get you started on Dept. of Good
          </p>
          <br>
        </div>
        <v-expansion-panels popout dark  class="mx-auto">
          <v-expansion-panel
            v-for="(items,i) in items"
            :key="i"
          >
            <v-expansion-panel-header style="background-color:#559FC6; border-radius: inherit"> {{ items.name }}<v-icon color="white"></v-icon> </v-expansion-panel-header>
            <v-expansion-panel-content style="background-color:#559FC6;">
              <loginJoin v-if="i === 0 "></loginJoin>
              <how-to-make v-if="i === 1 "></how-to-make>
              <CreateCollection v-if="i === 2 "></CreateCollection>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <br>
    <v-row style="padding-top: 40px;">
      <v-col cols="2"></v-col>
      <v-col cols="8" align-self="center">
        <h1 class="mt-10" style="text-align: center; color:#559FC6 ;font-family: Verdana">FAQs</h1><br>
        <div class="text-center">
          <p class="txtLearn3">
            For more FAQs visit Bloqs4Good in our Help Center
          </p>
          <br>
        </div>
        <v-expansion-panels popout dark  class="mx-auto">
          <v-expansion-panel
            v-for="(items,i) in items"
            :key="i"
          >
            <v-expansion-panel-header style="background-color:#559FC6; border-radius: inherit"> {{ items.name }}<v-icon color="white"></v-icon> </v-expansion-panel-header>
            <v-expansion-panel-content style="background-color:#559FC6;">
              <loginJoin v-if="i === 3 "></loginJoin>
              <how-to-make v-if="i === 4 "></how-to-make>
              <CreateCollection v-if="i === 5 "></CreateCollection>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
  </div>
</template>

<script>
import CreateCollection from '../../components/CreateCollection/CreateCollection'
import HowToMake from '../../components/CreateCollection/HowToMake'
import LoginJoin from '../../components/CreateCollection/LoginJoin'
import './_GetStarted.scss'
import WhyDept from '../../components/WhyDept/WhyDept'
export default {
  name: 'GetStarted',
  components: { WhyDept, CreateCollection, HowToMake, LoginJoin },
  data () {
    return {
      items: [{ name: 'How to join Dept. Of Good' }, { name: 'How to create NFTs / DAF / SFT' }, { name: 'How to make your own Collection' }, { name: 'What is Bloqs4Good' }, { name: 'What does Dept Of Good beta includes?' }, { name: 'How do i redeem my NFTs?' }]
    }
  }
}
</script>

<style scoped>

</style>
