<template>
  <div class="Nav">
    <v-navigation-drawer
      floating
      fixed
      mini-variant
      :dark="false"
      height="260px"
      style="top: 200px; left: 20px; width: 100px; border-radius: 15px; border: #cccccc 1px solid; filter: drop-shadow(0px 0px 5px #00000055);"
    >
      <v-list class="mx-3">
        <v-list-item link to="/profile" dense class="py-1 ml-1">
          <v-list-item-icon>
            <v-icon :color="$store.state.colors.secondary" large>mdi-account-box</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item link to="/myassets" dense class="py-1 ml-1">
          <v-list-item-icon>
            <v-icon :color="$store.state.colors.secondary" large>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item link to="/publish" dense class="py-1 ml-1">
          <v-list-item-icon>
            <v-icon :color="$store.state.colors.secondary" large>mdi-post</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item link to="/campaign" dense class="py-1 ml-1">
          <v-list-item-icon>
            <v-icon :color="$store.state.colors.secondary" large>mdi-briefcase</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item disabled link to="/cash" dense class="py-1 ml-1">
          <v-list-item-icon>
            <v-icon :color="$store.state.colors.secondary" large>mdi-cash</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>

import './_Nav.scss'
export default {
  name: 'Nav'
}
</script>
